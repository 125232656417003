import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import { API_URL, API_KEY_B } from './../../config/constant.js';
import StepCombination from '../layouts/commonLayout';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
//import './../../assets/css/inputTags.css';
//import InputTag from './TagsInput';
import DatePicker from "react-multi-date-picker";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import Swal from 'sweetalert2';
import ForBidden from './../layouts/denied';
import PreEnrrolment from "../preenrrolment";

function Creator(props) {
	document.title = 'BOstageB - Brand ID Data';
	const ref = useRef();
	let { brId } = useParams();
	const [elementData, setElementData] = useState("");
	const [buttonGray, setButtonGray] = useState(false);
	const [brandID, setBrandID] = useState("");
	const [nameError, setNameError] = useState(false);
	const [name, setName] = useState("");
	const [industry, setIndustry] = useState("");
	const [designLife, setDesignLife] = useState("");
	const [mainProduct, setMainProduct] = useState("");
	const [otherCategories, setOtherCategories] = useState([]);
	const [country, setCountry] = useState("");
	const [collection, setCollection] = useState("");
	//const [activePeriod, setActivePeriod] = useState("");
	const [caption, setCaption] = useState("");
	const [text, setText] = useState("");
	const [industryError, setIndustryError] = useState(false);
	const [designLifeError, setDesignLifeError] = useState(false);
	const [mainProductError, setMainProductError] = useState(false);
	const [countryError, setCountryError] = useState(false);
	const [captionError, setCaptionError] = useState(false);
	const [mainDateRange, setMainDateRange] = useState("");
	const [industryOption, setIndustryOption] = useState("");
	const [lifestyleOption, setLifestyleOption] = useState("");
	const [productCategoryOption, setProductCategoryOption] = useState("");
	const [countryOption, setCountryOption] = useState({});
	const [buttonDisable, setButtonDisable] = useState(false);
	const [clearCss, setClearCss] = useState(true);
	const [brandCollection, setBrandCollection] = useState(true);
	const [otherCat, setOtherCat] = useState("");
	const [status, setStatus] = useState("");
	const [ind, setInd] = useState(null);
	const [lifeS, setLifS] = useState(null);
	const [proCat, setProCat] = useState(null);
	const [tagData, setTagData] = useState([]);
	const [contD, setContD] = useState(null);
	const animatedComponents = makeAnimated();
	const [othCatVal, setOthCatVal] = useState(null);
	const [targetBtb, setTargetBtb] = useState(false);

	const [targetEnable, setTargetEnable] = useState(false);
	const [showPreLoader, setShowPreLoader] = useState(true)
	const [dataReadonly, setDataReadonly] = useState(0);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [brandNameExits, setBrandNameExits] = useState(false);
	const [data2, setData2] = useState(0)

	const [editMode, setEditMode] = useState(0);
	const [editModeBtnFun, setEditModeBtnFun] = useState(0);
	const [buttonName, setButtonName] = useState("NEXT");
	const [tempInd, setTempInd] = useState("");

	let navigate = useNavigate();
	const [denied, setDenied] = useState(false)

	useEffect(() => {
		//    console.log("preEnroll",localStorage.getItem('preEnrollData'))
		//    if(localStorage.getItem('preEnrollData') ==null){
		// 	      console.log("testtstst cdcv");
		//    }
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.BrandsMenu) {
					/*setShowPreLoader(false);
					setDenied(true); */

					setShowPreLoader(true);
					setDenied(false);
				} else {
					setDenied(false)
					//setShowPreLoader(false);
				}
			}
		}

	}, [props.compData]);

	const [mainData, setMainData] = useState(0);

	/*useEffect(()=>{
	   if(mainData && brandCollection.length && industryOption.length && lifestyleOption.length &&  productCategoryOption.length && countryOption.length)
	   {
		   setShowPreLoader(false);
		   //console.log(mainData,brandCollection,industryOption,lifestyleOption,countryOption,productCategoryOption);
	   }
   },[mainData,brandCollection,industryOption,lifestyleOption,countryOption,productCategoryOption]);
   */

	useEffect(() => {
		if (mainData && industryOption.length && productCategoryOption.length && countryOption.length) {
			setShowPreLoader(false);
			//console.log(mainData,brandCollection,industryOption,lifestyleOption,countryOption,productCategoryOption);
		}
	}, [mainData, industryOption, countryOption, productCategoryOption]);


	// const editData = () => {
	// 	if (editMode) {

	// 		setEditModeBtnFun(0);
	// 	}
	// }




	useEffect(() => {
		if ((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') || (localStorage.getItem('preEnrollData') != null)) {
			setBrandNameExits(true);
			setName(localStorage.getItem('brandName'))

			setBrandID(localStorage.getItem('brandID'));
		}
	});


	useEffect(() => {
		//console.log(props.compData?.activeBrand?.brandID);
		if (typeof brId != "undefined") {
			getBrandAllData(brId);
			setEditMode(1);
			setEditModeBtnFun(1);
			setButtonName("SAVE");
			setBrandID(brId);
			//console.log("bd", brId);
		}else{
			localStorage.removeItem('viewMode');
			setEditMode(0)
			getBrandAllData();
		}

		setElementData(ref.current);
		getIndustryData();
		getLifestyleData();
		getProductCategoryData();
		getMobileCountoryCode();
		//getBrandCollectionData();
		setStatus(props?.compData?.completePercentage)


	}, [props.compData]);


	const countryOption1 = [];
	const getMobileCountoryCode = () => {


		axios.post(API_URL + 'get-country-master-data' + API_KEY_B).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				//console.log("con",data)
				data.forEach((da) => {
					countryOption1.push({ value: da.code, label: da.name });
				}
				);
				//console.log("testcont",countryOption1);
				setCountryOption(countryOption1);
			}
		})
	}


	useEffect(() => {
		if (data2 && countryOption.length) {
			let dat = countryOption?.filter((re) => {
				if (re.value == data2) {
					return re;
				}
			});

			setContD(dat[0]);
		}
		// console.log(countryOption,data2);
	}, [countryOption, data2]);

	useEffect (()=>{
		//console.log("editMode-----tempInd,", editMode, tempInd);
		if(editMode && tempInd){

			handleIndustryEdit(tempInd);
			setLifS(lifeS);
		}
	},[editMode, tempInd, lifeS])

	const [preName ,setPreName] = useState("");
	const [preNm ,setPreNm] = useState("");

	const getBrandAllData = (id) => {
		let bid = "";
		if ((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') || (localStorage.getItem('preEnrollData') != null)) {
			bid = localStorage.getItem('brandID');
		} else {
			bid = id
		}
		let formData = {

			"id": bid
		}
  
		axios.post(API_URL + "get-brand-info" + API_KEY_B, formData).then((res) => {
			//console.log("testData", res.data.data?.name);
			if (res.data.status === 200) {
				 
				if(editMode){
					setPreName(res.data.data?.ourID);
					setPreNm(res.data.data?.name);
				}
				//console.log("editMode",res.data.data?.ourID);

				setData2(res.data.data?.country);
				setName(res.data.data?.name);
       
				setCountry(res.data.data?.country);
				setTempInd({ value: res.data.data?.industry, label: res.data.data?.industry })
				setInd({ value: res.data.data?.industry, label: res.data.data?.industry });
				setIndustry(res.data.data?.industry);
				setLifS({ value: res.data.data?.designAndLifestyle, label: res.data.data?.designAndLifestyle });
				setDesignLife(res.data.data?.designAndLifestyle);
				setProCat({ value: res.data.data?.mainProductCategory, label: res.data.data?.mainProductCategory });
				setMainProduct(res.data.data?.mainProductCategory);
				setMainDateRange(res.data.data?.sinceDate);

				setOtherCat(res.data.data?.otherCategories);

				let brandCo1 = [];
				let data = res.data.data?.otherCategories
				//console.log("data",data);
				if(data){
					data?.forEach((da) => {

						brandCo1.push({ value: da, label: da });
					}
					);
					setOthCatVal(brandCo1);
				}
                
				if (res.data.data?.logos?.mainLogoURL) {
					setTargetEnable(true);
				}
				if (res.data.data?.country && res.data.data?.industry && res.data.data?.designAndLifestyle && res.data.data?.mainProductCategory) {
					if ((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') || (localStorage.getItem('preEnrollData') != null)) {
						setDataReadonly(1);
						//	setShowPreLoader(false);  
						//  console.log("test2"); 
					} else {
						//	setShowPreLoader(false);   
						//console.log("test3");
					}
					setTargetBtb(true);
				} else {
					//setShowPreLoader(false);
					//console.log("test4");   
				}

				setMainData(1);

			} else {
				setMainData(1);
				//setShowPreLoader(false);
				//console.log("test5");   
			}
		})
	}
	const handleDate = (e) => {
		if (editMode) {
			setEditModeBtnFun(0);
		}
		setMainDateRange(e)
	}

	const refWarp = useRef(null);   //new datatttt
	useEffect(() => {

		function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode.parentNode.parentNode);
			/*
			 console.log("ref.curren",refWarp.current.contains(event.target)); 
			console.log("eventswq",event.target.value);
			console.log("event",event.target.classList);
			console.log("refWarp.current",refWarp.current); */
			//menu
			let pclass = event.target.parentNode.parentNode.parentNode.parentNode;
			let pclassimg = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
			// rightcontrolserch
			let prClassIcon = event.target.parentNode.parentNode.parentNode;
			let prClass = event.target.parentNode.parentNode;
			//center upper btn
			let pCNClass = event.target.parentNode;

			if (editMode && !editModeBtnFun) {
				//  console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || pclass.classList.contains("menusections") || pclass.classList.contains("navitems-area") || pclassimg.classList.contains("navitems-area") || pclassimg.classList.contains("menusections") || prClassIcon.classList.contains("saveIt") || prClass.classList.contains("saveIt") || pCNClass.classList.contains("saveIt"))) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					}).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);
							handleSubmit(event)
						}
						else if (result.isDenied) {
							setEditModeBtnFun(1);
						}

					})


				}
			}
		}
		document.addEventListener("mousedown", handleSaveOutside);
		return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		};
	}, [refWarp, editMode, editModeBtnFun]);





	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({
			...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
				borderRadius: "10px"
			}
		}),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};

	const handleIndustryEdit = (e) => {
		//console.log("handelEdit");
		setLifS("");
		setLifestyleOption("");
		
		setIndustryError(false);
		setIndustry(e.value);
		setInd({ value: e.value, label: e.value });

		const lifestyleOption1 = [];


		let dat = dataS.filter(r => { return r.name == e.value }).map(r => { return r?.designAndLifestylesIDs });

		let resl = dataLife.filter(res => {
			if (dat[0]?.includes(res?._id)) {
				return res;
			}
		});

		resl.forEach((da) => {
			lifestyleOption1.push({ value: da.name, label: da.name });
		}
		);
		lifestyleOption1.sort(function (a, b) {
			if (a.label < b.label) { return -1; }
			if (a.label > b.label) { return 1; }
			return 0;
		})

		setLifestyleOption(lifestyleOption1);
		
		

		if (e.value && designLife && mainProduct && name && country) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}

	const handleIndustry = (e) => {
		//console.log("handel");
		setLifS("");
		setLifestyleOption("");
		setDesignLife("")
		setIndustryError(false);
		setIndustry(e.value);
		setInd({ value: e.value, label: e.value });

		const lifestyleOption1 = [];


		let dat = dataS.filter(r => { return r.name == e.value }).map(r => { return r?.designAndLifestylesIDs });

		let resl = dataLife.filter(res => {
			if (dat[0].includes(res._id)) {
				return res;
			}
		});

		resl.forEach((da) => {
			lifestyleOption1.push({ value: da.name, label: da.name });
		}
		);
		lifestyleOption1.sort(function (a, b) {
			if (a.label < b.label) { return -1; }
			if (a.label > b.label) { return 1; }
			return 0;
		})

		setLifestyleOption(lifestyleOption1);
		
		if (editMode) {
			setEditModeBtnFun(0);
			
		}

		if (e.value && designLife && mainProduct && name && country) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}
	const handleLifestyle = (e) => { 
		setDesignLifeError(false);
		//console.log("handel",e.value);
		setDesignLife(e.value);
		setLifS({ value: e.value, label: e.value });

		if (editMode) {
			setEditModeBtnFun(0);
		}

		if (e.value && industry && mainProduct && name && country) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}
	const handleMainProduct = (e) => {
		setMainProductError(false);
		setMainProduct(e.value)
		setProCat({ value: e.value, label: e.value });

		const othMain = productCategoryOption.filter(res => {
			if (res.value != e.value) {
				return res
			}
		});
		setOthCatVal("");
		setBrandCollection(othMain);

		if (editMode) {
			setEditModeBtnFun(0);
		}

		if (e.value && industry && designLife && name && country) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}

	const handleCountry = (e) => {
		setCountryError(false);
		setCountry(e.value)
		setContD({ value: e.value, label: e.label });
		if (editMode) {
			setEditModeBtnFun(0);
		}
		if (e.value && industry && designLife && name && mainProduct) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}

	const handelBrandName = (e) => {
		if (e.target.value != " ") {
			setNameError(false);
			setName(e.target.value);
		}
		if (editMode) {
			setEditModeBtnFun(0);
		}
		if (e.target.value && industry && designLife && mainProduct && country) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}

	/*const selectedTags = tags => {
		if(tags.otherCategory){
			setOtherCategories(tags.otherCategory);
		}
	};
  */

	const handleValidation = () => {
		//console.log("designLife",designLife);
		let formIsValid = true;
		if (!industry) {
			setIndustryError(true);
			formIsValid = false;
		}
		if (!name) {
			setNameError(true);
			formIsValid = false;
		}
		if (!designLife) {
			setDesignLifeError(true);
			formIsValid = false;
		}
		if (!mainProduct) {
			setMainProductError(true);
			formIsValid = false;
		}
		if (!country) {
			setCountryError(true);
			formIsValid = false;
		}
		return formIsValid;
	}
	const handleSubmit = (e) => {
		e.preventDefault();
		if (dataReadonly) {
			navigate("/brand/logo/" + brandID);
		} else {
			//setDataReadonly(1);		
			if (handleValidation()) {
				setSubmitLoader(true);
                       

				if ((localStorage.getItem('status') != "100" && localStorage.getItem('status') != 'Completed') || (localStorage.getItem('preEnrollData') != null) || editMode) {
					setDataReadonly(1);
					let formData = '';
					if (editMode) {
						formData = {
							_id: brId,
							//companyID:(typeof props?.compData?.Companies!='undefined') ? props?.compData?.Companies[0]?.companyID : "",
							industry: industry,
							name: name,
							designAndLifestyle: designLife,
							mainProductCategory: mainProduct,
							otherCategories: otherCat,
							//sinceDate: mainDateRange,
							sinceDate: (mainDateRange) ? mainDateRange : (mainDateRange) ? mainDateRange.format() : "-",
							country: country,
							oldBrandName:preNm
						}
					} else {
						formData = {
							_id: brandID,
							//companyID:(typeof props?.compData?.Companies!='undefined') ? props?.compData?.Companies[0]?.companyID : "",
							industry: industry,
							name: name,
							designAndLifestyle: designLife,
							mainProductCategory: mainProduct,
							otherCategories: otherCat,
							//sinceDate: mainDateRange,
							sinceDate: (mainDateRange) ? mainDateRange : (mainDateRange) ? mainDateRange.format() : "-",
							country: country
						}
					}

					let cname = props?.compData?.Companies[0]?.companyName;

					setButtonDisable(true);
					axios.post(API_URL + "update-brand-iddata" + API_KEY_B, formData).then((res) => {
			    	//axios.post("http://localhost:7071/api/update-brand-iddata", formData).then((res) => {
						if (res.data.status == 200) {

							props.loginFun(localStorage.getItem('token'), '30');


							toast.success("Looking good! Brand ID data saved. Let's upload " + name + " logo next, so shoppers can identify your brand even faster.",
								{
									theme: "dark",
									icon: ({ theme, type }) => <img src={errorSuccess} />,
									onClose: () => {

										if (!editMode) {
											if (!props.compData.BrandsMenu) {
												navigate("/brand/brand-pre-logo-upload/" + res.data.insertedID);
											} else {
												navigate("/brand/logo/" + res.data.insertedID);
											}
										} else {
											setEditModeBtnFun(1);
											setDataReadonly(0);
                                             if(localStorage.getItem('brandID') == preName){
												//console.log("ls",name,preName);
                                                 localStorage.setItem('brandName',name);
											 } 
											props.loginFun(localStorage.getItem('token'),'Completed');

										}
									}
								});

							setButtonDisable(false);
							setSubmitLoader(false);
						} else {
							toast.error(res.data.message, {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorWarning} />
							});
							setButtonDisable(false);
							setSubmitLoader(false);
						}

					})
				} else {

					let formData = {

						companyID: (typeof props?.compData?.Companies != 'undefined') ? props?.compData?.Companies[0]?.companyID : "",
						industry: industry,
						name: name,
						designAndLifestyle: designLife,
						mainProductCategory: mainProduct,
						otherCategories: otherCat,
						//sinceDate: mainDateRange,
						sinceDate: (mainDateRange) ? mainDateRange : (mainDateRange) ? mainDateRange.format() : "-",
						country: country
					}

					let cname = props?.compData?.Companies[0]?.companyName;
					setButtonDisable(true);

					axios.post(API_URL + "insert-brand-data" + API_KEY_B, formData).then((res) => {
						//axios.post("http://localhost:7071/api/insert-brand-data", formData).then((res) => {
						if (res.data.status == 200) {

							toast.success("Looking good! Brand ID data saved. Let's upload " + name + " logo next, so shoppers can identify your brand even faster.",
								{
									theme: "dark",
									icon: ({ theme, type }) => <img src={errorSuccess} />,
									onClose: () => {
										if (!props.compData.BrandsMenu) {
											navigate("/brand/brand-pre-logo-upload/" + res.data.insertedID);
										} else {
											navigate("/brand/logo/" + res.data.insertedID);
										}
									}
								});
							setButtonDisable(false);
							setSubmitLoader(false);
						} else {
							toast.error(res.data.message, {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorWarning} />
							});
							setButtonDisable(false);
							setSubmitLoader(false);
						}

					})


				}
			}
		}
	}



	const [dataS, setDataS] = useState([]);


	const getIndustryData = () => {

		const industryOption1 = [];

		axios.post(API_URL + "brand-industry-master" + API_KEY_B).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;

				setDataS(data);
				//console.log("akGP",data);

				data.forEach((da) => {

					industryOption1.push({ value: da.name, label: da.name });
				}
				);

				industryOption1.sort(function (a, b) {
					if (a.label < b.label) { return -1; }
					if (a.label > b.label) { return 1; }
					return 0;
				})


				setIndustryOption(industryOption1);
			}
		})
	}

	const [dataLife, setDataLife] = useState([]);

	const getLifestyleData = () => {

		const lifestyleOption1 = [];

		axios.post(API_URL + "brand-lifestyle-master" + API_KEY_B).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				setDataLife(data);
				//console.log("sas",data);
				data.forEach((da) => {

					lifestyleOption1.push({ value: da.name, label: da.name });
				}
				);
				
				// if(editMode){
				// 	console.log("forTestOnly",lifestyleOption1.length)

				// 	setLifestyleOption(lifestyleOption1);
				// }
					
				//setLifestyleOption(lifestyleOption1);
			}
		})
	}


	const getProductCategoryData = () => {

		const productCategoryOption1 = [];

		axios.post(API_URL + "brand-product-category-master" + API_KEY_B).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {

					productCategoryOption1.push({ value: da.name, label: da.name });
				}
				);

				setProductCategoryOption(productCategoryOption1);
			}
		})
	}



	const getBrandCollectionData = () => {

		const brandCollectionOption1 = [];

		axios.get(API_URL + "get-all-other-categories" + API_KEY_B).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {

					brandCollectionOption1.push({ value: da.name, label: da.name });
				}
				);

				setBrandCollection(brandCollectionOption1);
			}
		})
	}

	useEffect(() => {
		//console.log("test",country , industry , designLife , mainProduct , name);
		if (country && industry && designLife && mainProduct && name) {
			setButtonGray(true);

		}
	},[country , industry , designLife , mainProduct , name]);


	const handleTags = (e) => {
		//console.log(e);	
		let data = []
		let data1 = []
		e.forEach(da => {
			data.push(da.value);
			data1.push({ value: da.value, label: da.label });
		})
		if(editMode){
			setEditModeBtnFun(0);
		}
		setOthCatVal(data1);
		setOtherCat(data);
	}

	const handleNewBrand =()=>{
		localStorage.removeItem("viewMode");
		setEditMode(0);
		setName("");
		setInd("");
		setIndustry("");
		setLifS("");
		setDesignLife("");
		setProCat("");
		setMainProduct("");
		setOthCatVal("");
		setMainDateRange("");
		setCountry("");
		setContD("");
		setLifestyleOption("");
		setTargetBtb(false);
		setTargetEnable(false);
		setDataReadonly(0);
		setEditModeBtnFun(0);
		setButtonDisable(false);
		setButtonGray(false);
		setButtonName("NEXT");

	  }

	return (
		<>
			{(submitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}

			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
					{(showPreLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> : (denied) ? <ForBidden /> : <>
						<div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" className="button-not-active" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
									<span className="searchprodicttext text-center">Brands</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to={(status === 'Completed' || localStorage.getItem('status') == 100) ? "/brand" : ""} className={(status === 'Completed' || localStorage.getItem('status') == 100) ? "btn btn-success saveIt" : "btn btn-success button-not-active"}>Brands</Link>
								<Link to="#" className={(editMode) ? "btn btn-success active saveIt" : "btn btn-success button-not-active"}>Edit Brand</Link>
								<Link to="/brand/creator" className={(editMode) ? "btn btn-success saveIt" : "btn btn-success active saveIt"} onClick={handleNewBrand}>New Brand</Link>
							</div>
							<div className="rightcontrolserch">
								<div className="productsearch rightareas saveIt">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div>
								<div className={(targetBtb) ? "productsearch rightareas saveIt" : "productsearch rightareas notactive"}>
									<Link to={(targetBtb) ? "/brand/logo/" + brandID : "#"} role="button">
										<span className="btn btn-success"><i className="fa-thin fa-sign-hanging"></i></span>
										<span className="searchprodicttext">Logos</span>
									</Link>
								</div>
								<div className={(targetEnable) ? "productsearch rightareas saveIt" : "productsearch rightareas notactive"}>
									<Link data-widget="search" to={(targetEnable) ? "/brand/tags-target/" + brandID : "#"} role="button" >
										<span className="btn btn-success"><i className="fa-thin fa-bullseye"></i></span>
										<span className="searchprodicttext">Targets</span>
									</Link>
								</div>
							</div>
						</div>
						{(showPreLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
							: <><div ref={refWarp}>
								<div className="filtertopcombo">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="filtersection creatorsbtnset enroll-brand ">
													<span className={(buttonGray) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
														<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun) ? true : buttonDisable} >{buttonName} {(buttonDisable) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-chevron-right"></i>)}</button>
													</span>
												</div>
												<StepCombination cdata={props.compData} />

											</div>
										</div>
									</div>
								</div>
								<div className="creators-section">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="cardsection">
													<div className="card-header">
														<h3 className="card-title">BRAND ID <span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
													</div>
													<div className="card-body">
														<div className="row">
															<div className="col-md-3">
																<div className={(brandNameExits ) ? "form-group inActiveborder" : "form-group activeborder"}>
																	{/* <div className="form-group inActiveborder"> */}
																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Brand</label>

																	<input type="text" className={(nameError) ? "form-control error-input" : "form-control"} value={name} onChange={handelBrandName} disabled={brandNameExits} placeholder="" />
																	{/* <input type="text" className={(nameError) ? "form-control error-input" : "form-control"} value={name} readOnly={true} placeholder="" /> */}
																	{(brandNameExits) ? <span className="verfiefd-text">Verified</span> : ""}

																</div>
															</div>

															<div className="col-md-3">

																<div className={(dataReadonly) ? "form-group inActiveborder selectheight" : "form-group activeborder selectheight"} >
																	<label htmlFor="tobrand"><i className="fas fa-info-circle " aria-hidden="true"></i> Industry</label>
																	<Select value={(ind) ? ind : null} className={(industryError) ? "selects error-input" : "selects"}
																		options={industryOption}
																		styles={colourStyles}
																		onChange={handleIndustry}
																		placeholder={<div className="italic-placeholder">Select...</div>}
																		isDisabled={(dataReadonly) ? true : false}
																	/>
																</div>
															</div>
															<div className="col-md-3">
																<div className={(dataReadonly) ? "form-group inActiveborder selectheight" : "form-group activeborder selectheight"}>
																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Design/Lifestyle</label>
																	<Select value={lifeS} className={(designLifeError) ? "selects error-input" : "selects"}
																		options={lifestyleOption}
																		isSearchable={false}
																		styles={colourStyles}
																		onChange={handleLifestyle}
																		placeholder={<div className="italic-placeholder">Select...</div>}
																		isDisabled={(dataReadonly) ? true : false}

																	/>
																</div>
															</div>
															<div className="col-md-3">
																<div className={(dataReadonly) ? "form-group inActiveborder selectheight" : "form-group activeborder selectheight"}>
																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Main product category</label>
																	<Select value={proCat} className={(mainProductError) ? "selects error-input" : "selects"}
																		options={productCategoryOption}

																		styles={colourStyles}
																		onChange={handleMainProduct}
																		placeholder={<div className="italic-placeholder">Select...</div>}
																		isDisabled={(dataReadonly) ? true : false}
																	/>
																</div>
															</div>
														</div>
														<div className="row">

															<div className="col-md-3">

																<div className={(dataReadonly) ? "form-group  selectheight tag-inactive" : "form-group  selectheight multiset-catg"}>
																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Other categories</label>
																	<Select value={othCatVal} className="selects "

																		options={brandCollection}
																		styles={colourStyles}
																		onChange={handleTags}
																		closeMenuOnSelect={false}
																		placeholder={<div className="italic-placeholder">Select...</div>}
																		isMulti
																		isSearchable
																		isDisabled={(dataReadonly) ? true : false}
																	/>
																</div>
															</div>

															<div className="col-md-3 date-pickercal display-property">
																<div className={(dataReadonly) ? "form-group haveaddon lockcalendar" : "form-group haveaddon"}>
																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Since</label>
																	<div className="addonimgleft rmCls float-childstwo remove-left-border">
																		<DatePicker
																			value={mainDateRange}
																			onChange={(e) => handleDate(e)}
																			onlyYearPicker
																			readOnly={(dataReadonly) ? true : false}
																			maxDate={new Date()}

																		/></div>
																</div>
															</div>
															<div className="col-md-3">
																<div className={(dataReadonly) ? "form-group inActiveborder selectheight" : "form-group activeborder selectheight"}>
																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Country</label>
																	<Select className={(countryError) ? "selects error-input" : "selects"}
																		//defaultValue={country}
																		value={contD}
																		options={countryOption}
																		styles={colourStyles}
																		onChange={handleCountry}
																		placeholder={<div className="italic-placeholder">Select...</div>}
																		isDisabled={(dataReadonly) ? true : false}
																	/>
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							</>}
					</>}
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>

		</>
	)
}
export default Creator;
