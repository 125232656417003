import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../../layouts/BrandDashboardHeader";
import BrandAside from "./../../layouts/BrandAside";
import BrandFooter from "./../../layouts/BrandFooter";
import noBoimage from './../../../assets/images/uploads/no_img.png';
import BoimageOne from './../../../assets/images/uploads/boimageone.jpg';
import BoimageTwo from './../../../assets/images/uploads/boimagetwo.jpg';
import PublisherIcon from './../../../assets/images/pulisher-icon.png';
import axios from 'axios';
import { API_URL, API_KEY_B, BASE_URL, BASE_URL_BOTHEBOT } from './../../../config/constant.js';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import errorWarning from './../../../assets/images/error-icon.png';
import errorSuccess from './../../../assets/images/icons8-check-64.png'
//import QRCode from "react-qr-code";
import QRCode from "qrcode.react";
import { Modal } from "react-bootstrap";
import LoaderSvg from './../../../assets/images/loader.svg';
import LoaderIcon from './../../../assets/images/loader-icon.png';
import ForBidden from './../../layouts/denied';
import BeconData from './beconData';
function Dashboard(props) {
	// console.log(props?.compData?.activeBrand?.brandID);
	document.title = 'BOstageB - BOimages';
	const ref = useRef();
	const targetImageRef = useRef();
	const [elementData, setElementData] = useState("");
	const [isPagerefresh, setIsPageRefresh] = useState(false);
	const [preLoader, setPreLoader] = useState(true);

	let navigate = useNavigate();
	const [denied, setDenied] = useState(false)
	const [brandLogo, setBrandLogo] = useState('');

	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.BOimagesMenu) {
					setPreLoader(false);
					setDenied(true)
				} else {
					setDenied(false)
				}
			}
		}

	}, [props.compData]);
     const[dataMsg,setDataMsg] = useState("");
	useEffect(() => {
		setElementData(ref.current);
        if(localStorage.getItem('brandID')){
            getProductListData(localStorage.getItem('brandID'));
			getBrandInfo(localStorage.getItem('brandID'));
      }else{
        setPreLoader(false);
		setDataMsg("There are no records to display");
            } 
      },[localStorage.getItem('brandID')]);

   /*
	useEffect(() => {
		setElementData(ref.current);
		getProductListData(localStorage.getItem('brandID'));
		getBrandInfo(localStorage.getItem('brandID'));
	}, [localStorage.getItem('brandID')]);   */ 

	useEffect(() => {
		if (isPagerefresh) {
			getProductListData(localStorage.getItem('brandID'));
		}
		setIsPageRefresh(false)
	}, [isPagerefresh]);

	const [listItems, setListItems] = useState([]);
	const [showLoader, setShowLoader] = useState(0);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	const [showImg, setShowImg] = useState(false);
	const handleImgClose = () => setShowImg(false);
	// const handleImgShow = () => setShowImg(true);
	const [ratio, setRatio] = useState({ h: 0, w: 0 })
	const [beaconID, setBeaconID] = useState("")
	const [showData, setShowData] = useState(false)
	const [lastInsertIdData, setLastInsertIdData] = useState("");
	const [renderData, setRenderData] = useState("");

	const [imgUrl, setImgUrl] = useState("");


	const onImgLoad = (e) => {
		let height = targetImageRef.current.clientHeight / renderData?.height;
		let width = targetImageRef.current.clientWidth / renderData?.width;
		setRatio({ h: height, w: width });

	}
      const [bOTagdata,setBOTagData] = useState([]);

	const handelData = () => {
		setBeaconID("");
		setBOTagData([]);
	}
    
   

	const handelBoTag = (boid,r) => {
		//console.log("dddt",r)
		setShowData(true);
		setBeaconID(boid);
		setBOTagData(r);
	}

	const productOptions = [
		//   { value: 'product-1', label: 'Product 1' },
		//   { value: 'product-2', label: 'Product 2' },
		//   { value: 'product-3', label: 'Product-3' },
	];
	const locationOptions = [
		//   { value: 'Location-1', label: 'Location 1' },
		//   { value: 'Location-2', label: 'Location 2' },
		//   { value: 'Location-3', label: 'Location-3' },
	];
	const statusOptions = [
		{ value: 'all', label: 'All' },
		{ value: 'with-sync-problems', label: 'With sync problems' },
		{ value: 'missing-product-images', label: 'Missing product images' },
	];
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid #333F50",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({
			...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
				borderRadius: "10px"
			}
		}),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};

	const [dats, setDats] = useState(0);

	const getProductListData = async (brandID) => {
		setListItems([])
		setShowLoader(0)
		setDats(0)
		let formData = {
			"brandID": brandID
		}
		await axios.post(API_URL + "getBoimageList" + API_KEY_B, formData).then((res) => {
			//await axios.post("http://localhost:7071/api/getBoimageList", formData).then((res) => {

			if (res.data.status == 200) {
				setShowLoader(1);
				setListItems(res.data.data);
				////console.log("sfsgfgfg",res.data.data);
				// let data = res?.data?.data;
                // let fDatat = data.filter((re)=>{
                //   return re.brandID == localStorage.getItem('brandID');
                //  });
                //  setListItems(fDatat);

				if (res.data.data.length) {
					setDats(1);
				} else {
					setDats(2);
					setShowLoader(1);
				}
				setPreLoader(false)
			}
			setShowLoader(1);

		});
	}


	const getDataOfLid = async (dataLid) => {
		const formData = {
			"id": dataLid
		}
		await axios.post(API_URL + "get-boimage-id-data-by-id" + API_KEY_B, formData).then((res) => {
			if (res.data.status == 200) {
				let da = res.data.resData.Targets;
				setLastInsertIdData(res.data.resData);
				setRenderData(res.data.resData.renderedIamge)
				// setImageData(res.data.resData.imageURL)
				setShowImg(true);
				//console.log(res.data.resData);


			}

		});
	}

	const deleteItem = (ID) => {
		Swal.fire({
			// text: 'Are you sure want to delete this '+ID+'?',
			text: 'Are you sure want to delete this Boimage?',
			icon: 'success',
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'Yes',
			denyButtonText: 'No',
			background: '#1d2530',
			color: '#fff',
			iconColor: "#0B0",
			width: "500px",
			padding: "10px"
		}).then((result) => {
			if (result.isConfirmed) {
				let formData = {

					'ID': ID
				}
				axios.post(API_URL + 'deleteBOimage' + API_KEY_B, formData).then((res) => {
					if (res.data.status === 200) {
						toast.success(res.data.message, {
							theme: "dark",
							icon: ({ theme, type }) => <img src={errorSuccess} />
						});
						getProductListData(localStorage.getItem('brandID'));
					} else {
						toast.error(res.data.message, {
							theme: "dark",
							width: '390px',
							icon: ({ theme, type }) => <img src={errorWarning} />
						})
					}
				})
			} else if (result.isDenied) {
				Swal.close()
			}
		})
	}

	const copyUrl = (dataUrl) => {
		//console.log(inputId);
		//inputId.select();
		if (dataUrl) {
			navigator.clipboard.writeText(dataUrl);
		}
		// alert("Copied the text: " + dataUrl);
	}

	const Copycode = (pb, ifrm) => {
		if (pb) {
			navigator.clipboard.writeText(ifrm);
		}
	}

	const [qrCode, SetQrCode] = useState("");


	const popUpImg = (img, BOimageIdData) => { //alert(img);
		if (img != "") {
			setImgUrl(img);
			//handleImgShow();
			//window.open(`${window.location.origin}/${url}`)
			getDataOfLid(BOimageIdData);
		}


	}

	const getBrandInfo = (brandID) => {
		let formData = {
			"id": brandID
		}
		axios.post(API_URL + 'get-brand-info' + API_KEY_B, formData).then((res) => {
			if (res.data.status == 200) {
				if (res?.data?.data?.logos?.mainLogoWhiteURL) {
					setBrandLogo(res?.data?.data?.logos?.mainLogoWhiteURL)
				} else {
					setBrandLogo(res?.data?.data?.logos?.mainLogoURL)
				}
			}
		})
	}

	const downloadQRCode = (qrid, qrValue) => {
		// Generate download with use canvas and stream
		const canvas = document.getElementById(qrid);
		//console.log(qrid);
		const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `${qrValue}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};


	const ValidURL = (str) => {
		var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
		if (!regex.test(str)) {
			return false;
		} else {
			return true;
		}
	}

	const dataReloadfunction = (e) => {
		setIsPageRefresh(e);
	}
	useEffect(() => {
		localStorage.removeItem("viewMode")
	}, []);

	//console.log("lastInsertIdData", lastInsertIdData);
	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation dataReloadfunction={dataReloadfunction} data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper">
					{(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> : (denied) ? <ForBidden /> : <>
						<div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" to="#" role="button" className="button-not-active">
									<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
									<span className="searchprodicttext">BOimages</span>
								</Link>
							</div>


							<div className="centerbtn">
								<Link to="#" className="btn btn-success active">Boimages</Link>
								<Link to="#" className="btn btn-success button-not-active">Edit Boimage</Link>
								<Link to="/boimages/canvas" className="btn btn-success">New Boimage</Link>
							</div>

							<div className="rightcontrolserch">
								<div className="productsearch rightareas cutomepublisher">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success active"><i className="fa-thin fa-user-tag"></i></span>
										<span className="searchprodicttext">Publisher</span>
									</Link>
								</div>
								
								<div className="productsearch rightareas notactive">
									<Link data-widget="search" to="/boimages" role="button">
										<span className="btn btn-success"><i className="fa-thin fa-list"></i></span>
										<span className="searchprodicttext">List</span>
									</Link>
								</div>
								<div className="productsearch rightareas notactive">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success"><i className="fa-thin fa-chart-simple"></i></span>
										<span className="searchprodicttext">Stats</span>
									</Link>
								</div>

							</div>


						</div>
						{(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> : <>
							<div className="filtersarea boimagesent">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="serchbopicspr">
												<form className="form-inline" >
													<input className="form-control" type="text" placeholder="Search BOimage:" aria-label="Search" disabled='disabled' />
													<span className="customeset gobtn button-not-active">
														<button type="button" className="btn btn-success">
															<i className="fa-thin fa-arrow-right"></i>
														</button>
														<span className="searchprodicttext">Go</span>
													</span>
												</form>
											</div>
											<div className="filtersection">
												<form className="form-inline" name="filter">
													<p className="form-control-static">Filters:</p>
													<div className="form-group">
														<label htmlFor="collection" className="sr-only">Collection</label>
														<Select
															options={productOptions}
															styles={colourStyles}
															placeholder={<div className="italic-placeholder">Select...</div>}
															isDisabled='true' />
													</div>
													<div className="form-group">
														<label htmlFor="creator" className="sr-only">Creator</label>
														<Select
															options={locationOptions}
															styles={colourStyles}
															placeholder={<div className="italic-placeholder">Select...</div>}
															isDisabled='true' />
													</div>
													<div className="form-group">
														<label htmlFor="status" className="sr-only">Status</label>
														<input type="text" className="form-control" id=" " placeholder="Hashtags" disabled='disabled' />
													</div>
													<span className="btnsets">
														<span className="customeset revertfilters button-not-active">
															<button type="button" className="btn btn-success"><i className="fa-thin fa-rotate-left"></i></button>
															<span className="searchprodicttext">Reset</span>
														</span>
														<span className="customeset gobtn button-not-active">
															<button type="button" className="btn btn-success"><i className="fa-thin fa-arrow-right"></i></button>
															<span className="searchprodicttext">Apply</span>
														</span>
													</span>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="boimagescontent">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12" style={{ "display": (showLoader) ? "none" : "block" }}>
										   <div class="nodatainsde">
											  <p class="nodtafound" style={{"padding": "24px","font-size": "14px","color": "#fff"}}>{dataMsg}</p>
											</div>
										</div>
										<div className="col-md-12">

											{
												(dats == 1) ?
													listItems.map((res, ind) => {
														//var pb = res.publisherURL;
														var pb_url_test = ValidURL(res.publisherURL);
														var pb = res.publisherURL;
														if (!pb_url_test) {
															//var pb = BASE_URL_BOTHEBOT + "handyad/v/" + res.id;
															var pb = BASE_URL_BOTHEBOT +  res.id;
														}

														return (
															<div className="boimageset" key={ind}>
																<div className="producnameidimg">
																	<label className="" htmlFor="publisherurl">Name: {res.name}</label>
																	<div className="primgt">
																		<img src={(res.imageURL) ? res.imageURL : noBoimage} onClick={(e) => { localStorage.setItem("viewMode", "editmode"); navigate("/boimages/canvas/" + res.id); }} />
																	</div>
																	<div className="imgidandpop">
																		<span className="proid">
																			ID: <span className="pridhere">{res.id}</span>
																		</span>
																		<span className="popouticons">
																			<Link to="#" className="btn btn-success" onClick={() => { popUpImg(res.imageURL, res.id); }} disabled={(res.imageURL) ? '' : 'disabled'}>
																				<i className="fa fa-expand"></i>
																			</Link>
																		</span>
																	</div>
																</div>

																<div className="publisherurl firstsides">
																	<div className="form-group">
																		<label className="" htmlFor="publisherurl">Publisher URL:</label>
																		{/* <textarea className="form-control" id={res.id} defaultValue={(res.publisherURL)?res.publisherURL:""}> */}
																		<textarea className="form-control" id={res.id} defaultValue={(res.currentStatus != "Not completed") ? ((pb) ? pb : "") : ""} readOnly={true}>

																		</textarea>
																	</div>
																	<div className="bottompublishbtn">
																		{(res.currentStatus != "Not completed") ?
																			<Link to="#" className="btn btn-success " onClick={() => { copyUrl(pb) }}>Copy URL</Link>
																			:
																			<Link to="#" className="btn btn-success " >Copy URL</Link>
																		}
																		{/* <Link to="#"  className="btn btn-success" onClick={() => {copyUrl(res.publisherURL)}}>Copy URL</Link> */}
																		{/* <Link to="#"  className="btn btn-success" onClick={() => {generateUrl(res.publisherURL)}}   disabled={(res.publisherURL)?'':'disabled'}   >Generate QR code</Link> */}

																	</div>
																</div>

																{/*new*/}
																<div className="publisherurl qrcodes">
																	<div className="form-group">
																		{/* <QRCode	value={res.publisherURL} id={"qrs"+ind} size={800}  level={"H"} includeMargin={true}  />  */}
																		<QRCode value={pb} id={"qrs" + ind} size={800} level={"H"} includeMargin={true} />
																	</div>
																	<div className="bottompublishbtn">
																		{/* <button type="button" className="btn btn-success" onClick={() => {downloadQRCode("qrs"+ind,res.publisherURL)}}>Download QR code</button> */}
																		<button type="button" className="btn btn-success" onClick={() => { downloadQRCode("qrs" + ind, pb) }}>Download</button>
																	</div>
																</div>
																{/*new*/}

																<div className="publisherurl embedcode">
																	<div className="form-group">
																		<label className="" htmlFor="publisherurl">Embed code:</label>
																		<textarea className="form-control" id="embedcode" readOnly={true} defaultValue={(pb) ? '<iframe style="width:560px;height:315px;" src="' + pb + '"  title="' + res.name + '" frameborder="0" allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture" allowfullscreen></iframe>' : ""}>

																		</textarea>
																	</div>
																	<div className="bottompublishbtn">
																		<Link to="#" className="btn btn-success" onClick={() => { Copycode(pb, '<iframe style="width:560px;height:315px;" src="' + pb + '" title="' + res.name + '" frameborder="0" allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture" allowfullscreen></iframe>') }} >Copy code</Link>
																	</div>
																</div>

																<div className="activeperodbtn">
																	<div className="activeperiodtext">
																		<div className="labelgreentext">Active period:<br /><span className="itvalue">{res.activePeriod}</span></div>
																		<div className="labelgreentext">Current status: <span className="itvalue">{res.currentStatus}</span></div>
																		<div className="labelgreentext">Created by: <span className="itvalue">{res.createdBy}</span></div>
																		<div className="labelgreentext">Created on: <span className="itvalue">{res.createdOn}</span></div>
																	</div>
																	<div className="activebtnsets">
																		<div className="btnares fullsizes button-not-active">
																			<Link to="#" className="btn btn-success">SHARE AS&nbsp;<strong>BO</strong>AD</Link>
																		</div>
																		<div className="btnares smallsizes">
																			{/* <Link to={"/boimages/creater/" + res.id} className="btn btn-success">View/edit</Link> */}
																			<Link to={"/boimages/canvas/" + res.id} onClick={(e) => { localStorage.setItem("viewMode", "editmode") }} className="btn btn-success">View/edit</Link>
																		</div>
																		<div className="btnares smallsizes centerlocation button-not-active">
																			<Link to="#" className="btn btn-success">Suspend</Link>
																		</div>
																		<div className="btnares smallsizes">
																			<Link className="btn btn-success" onClick={() => deleteItem(res.id)} to="#">Delete</Link>
																		</div>
																	</div>
																</div>


															</div>
														)
													}) : (dats == 2) ? <div className="nodatainsde" style={{ "position": "" }}><p className="nodtafound">No data found</p></div> : ""

											}


										</div>
									</div>
								</div>
							</div>


						</>}

					</>}


				</div>
				<BrandFooter logo={props.imageData.footer_logo} />


				{/*<Modal className="mymidal" backdrop="static" keyboard={false} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
					<Modal.Header closeButton={handleClose}>
						<Modal.Title>View QR Code</Modal.Title> 
					</Modal.Header>
					<Modal.Body>
						<QRCode
						size={256}
						style={{ height: "auto", maxWidth: "100%", width: "auto" }}
						value={qrCode}
						//viewBox={`0 0 256 256`}
						/>
				
					</Modal.Body>
				</Modal>  */}

				<Modal backdrop="static" className="mymidalImg" keyboard={false} show={showImg} onHide={handleImgClose} size="lg" aria-labelledby="contained-modal-title-vcenters" centered>
					<Modal.Header >
						<Modal.Title>Image</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<div className="preview-imgBo">
                            <div className="preview-imgBoBox">
                                <div className="backtocreator"><Link to="#" onClick={handleImgClose}><i class="fa-light fa-xmark"></i></Link></div>

                                <div className="brandWithLogoName"><img className="brandnamelogo" src={brandLogo} /></div>

                                <div className="imageshowcase target-hashatags-screen">

                                    <div className="sourceimgandname">

                                        <div className="imageupdates">
                                            <div className="image-drag">
                                                <figure style={{ "position": "relative" }}>
                                                    <img ref={targetImageRef} src={imgUrl} onLoad={onImgLoad} onClick={handelData} />
                                                    {
                                                        lastInsertIdData?.BOtags?.map((r, i) => {
                                                            return (
                                                                <>


                                                                    <BeconData data={bOTagdata} beaconID={beaconID} ratio={ratio} showBecon={showData} />

                                                                    <div id={i + 1} onClick={e => { handelBoTag(r?.productID,r) }} style={{ "position": "absolute", "zIndex": "1", "left": (r.beaconXposition * ratio.w) + "px", "top": (r.beaconYposition * ratio.h) + "px" }}  >
                                                                        {/*onClick={e =>{ handelBoTag(r.BotagId)}} */}
                                                                        <div className="custom-class itslinked">
                                                                            <div className="shownumber">{r?.BotagId}</div>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                            )
                                                        })
                                                    }

                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
					</Modal.Body>
				</Modal>


			</div>
		</>
	);
}
export default Dashboard;