import React, { useRef, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import BrandNavigation from "./../layouts/BrandDashboardHeaderStatic";
import StepWizard from "react-step-wizard";
import BrandAside from "./../layouts/BrandAsideStatic";
import TermsConditions from "./../static/termsCondition";
import BrandFooter from "./../layouts/BrandFooter";
import BlueAccountOn from './../../assets/images/blueaccounton.png';
import BlueBrandOn from './../../assets/images/bluebrandon.png';
import BlueBOimageIsOn from './../../assets/images/blueboimageson.png';
import BlueStoresOn from './../../assets/images/bluestoreson.png';
import ExclamtionBlue from './../../assets/images/exclation-blue.png';
import GrayBrandoff from './../../assets/images/graybrandoff.png';
import GrayBoimgoff from './../../assets/images/grayboimageson.png';
import Graystoreoff from './../../assets/images/graystoreson.png';
import InviteCustomIcon from './../../assets/images/invite-store-custom-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import Nav from './Nav';
import axios from 'axios';
import { API_URL_A,API_KEY_A } from './../../config/constant.js';
import Plugs from './Plugs';
import styles from './wizard.less';
import transitions from './transitions.less';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/error-icon.png';
import errorSuccess from './../../assets/images/icons8-check-64.png'
import CreateBoimageCustom from './../../assets/images/create-boimage-custom.png'
import PreloaderLogin from './../layouts/preLoaderLogin';
import Swal from 'sweetalert2';
toast.configure();
function PreEnrrolment(props) {
	const  AutoGenerateUrlId  = useParams();
	document.title = 'BOstageB - PreEnrrolment';
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	useEffect(() => {
		localStorage.clear();
		setElementData(ref.current);
        checkAutogeneratedUrl();
		getGroupList();
     
	}, []);

   const navigate = useNavigate();
   const [check,setCheck] = useState(0);
   const [companyName,setCompanyName] = useState("");
   const [firstName,setFirstName] = useState("");
   const [lastName,setLastName] = useState("");
   const [email,setEmail] = useState("");
   const [userID,setUserID] = useState("");
   const [brandName,setBrandName] = useState("");
   const [groupId,setGroupId] = useState("");
   const [groupList,setGroupList] = useState("");
   const [groupName,setGroupName] = useState("");
   const [billingPlan,setBillingPlan] = useState("-");

	const [companyNameError, setCompanyNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
    const[disabledbtn,setDisabledbtn] =useState(true);
    const[disabledbtnDis,setDisabledbtnDis] =useState(0);
 	const [paraNext,setParaNext] =useState(false);
	 const [showLoader, setShowLoader] = useState(0);
	const [showPreLoader, setShowPreLoader] = useState(true)

      
     const checkAutogeneratedUrl = () => {
          let formData = {
         	"invitedId":AutoGenerateUrlId.id
         }
        axios.post(API_URL_A+'validate-url'+API_KEY_A, formData).then((res)=>{
        //axios.post('http://localhost:7071/api/validate-url', formData).then((res)=>{
             if(res.data.status === 200){
                  setEmail(res.data.data.contactPersonEmail); 
				  setBrandName(res.data.data.entityName);
				  setFirstName(res.data.data.contactPersonFirstName);
				  setLastName(res.data.data.contactPersonLastName); 
				  setGroupId(res.data.data.group); 
				  //setBillingPlan(res.data.data.special_plan);
				 
                   toast.success(res.data.message, {
					theme: "dark",
					icon: ({theme, type}) =>  <img src={errorSuccess}/>
				  })
             }else{ 
				
                   
				if(res.data.status === 300){
					
					//console.log("testd",res.data.data);
					localStorage.setItem('token', res.data.data.token);
					localStorage.setItem('name', res.data?.data?.firstName);
					localStorage.setItem('lastname', res.data?.data?.lastName);
					localStorage.setItem('status', res.data.data.status);
					localStorage.setItem('brandID',res.data.data.brandID);
					localStorage.setItem('brandName',res.data.data.brandName);
					localStorage.setItem('preEnrollData',true);
					if(res?.data?.data?.storeID){
					  localStorage.setItem('storeID', res.data.data.storeID); 
					}
					props.loginFun( res.data.data.token)
					navigate("/brand/creator");

				}else{

					if(res.data.status === 600){
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({theme, type}) =>  <img src={errorWarning}/>
						  })
					    // navigate("/");
					}else{
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({theme, type}) =>  <img src={errorWarning}/>
						  })
					}
              	  navigate("/");
				}
             }
          setShowPreLoader(false)
        })
            
     } 
      
	 const validateEmail = (email) => {

		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	 const handleValidation = () =>{
		let formIsValid = true;
		if(email==""){
			setEmailError(true)
		   formIsValid = false;
		}else{
			if(!validateEmail(email)){
              setEmailError(true)
		      formIsValid = false;
			  toast.error('Please enter valid Email', {
					theme: "dark",
					icon: ({theme, type}) =>  <img src={errorWarning}/>
				  })
			}
	
		} 
		if(companyName==""){
			setCompanyNameError(true);
			 formIsValid = false;
		  }
		 if(firstName==""){
			setFirstNameError(true);
			 formIsValid = false;
		  }
		 /* if(lastName==""){
			setLastNameError(true);
			 formIsValid = false;
		  } */
		  return formIsValid;
	}



	 const preInrollment = () => {
		let formData = {
			"brandName":brandName,
			"email":email,
			"firstName": firstName,
			"lastName": lastName,
			"companyName": companyName,
			"invitedID": AutoGenerateUrlId.id
		
	   }
 if(handleValidation()){
	 setShowLoader(1);
	  axios.post(API_URL_A+'brand-register'+API_KEY_A, formData).then((res)=>{
		   if(res.data.status === 200){
			     setParaNext(true)
                 setUserID(res.data.data.insertedId);
                 setShowLoader(0);
				 toast.success(res.data.message, {
				  theme: "dark",
				  icon: ({theme, type}) =>  <img src={errorSuccess}/>
				})
		   }else{
				toast.error(res.data.message, {
				  theme: "dark",
				  icon: ({theme, type}) =>  <img src={errorWarning}/>
				})
				//navigate("/");
				setShowLoader(0);
		   }
	  })
	}		  
   } 
  

   const resendData = () => { 
	let formData = {
		"id": userID
   }
  axios.post(API_URL_A+'resend-login-detail'+API_KEY_A, formData).then((res)=>{
	   if(res.data.status === 200){
			
			 toast.success(res.data.message, {
			  theme: "dark",
			  icon: ({theme, type}) =>  <img src={errorSuccess}/>
			})
	   }else{
			toast.error(res.data.message, {
			  theme: "dark",
			  icon: ({theme, type}) =>  <img src={errorWarning}/>
			})

		//	navigate("/");
	   }
  })
   }

const login = () =>{
    navigate("/");
} 


const getGroupList = () => {
	axios.post(API_URL_A+'get-group-list'+API_KEY_A).then((res)=>{
		if(res.data.status === 200){
			setGroupList(res?.data?.data);	 
		}
   })
}

useEffect(()=>{
	//console.log(groupList);	 
    if(groupId){
		 groupList.map((x)=>{
			    if(groupId==x.ourID){
					setGroupName(x.name);
				}         
		 });
	}   
},[groupList,groupId]);




   const cancelEvent =() =>{
       
	Swal.fire({
		title: '',
		text: "Do you want to cancel the Pre-enrollment",
		icon: 'warning',
		showDenyButton: true,
		allowOutsideClick: false,
		confirmButtonColor: '#205380',
		denyButtonColor: '#205380',
		confirmButtonText: 'Yes',
		denyButtonText: 'No',
		background: '#1d2530',
		color: '#fff',
		iconColor: "#FF9900",
		width: "500px",
		padding: "10px",
		reverseButtons: false
	  }).then((result) => {
		if (result.isConfirmed) {
			  navigate("/");
		}
	})

   }

	const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: `${transitions.animated} ${transitions.enterRight}`,
            enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
            exitRight: `${transitions.animated} ${transitions.exitRight}`,
            exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
            intro: `${transitions.animated} ${transitions.intro}`,
        },
        demo: true, // uncomment to see more
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    // Do something on step change
    const onStepChange = (stats) => {
      
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });


	const updateCheck = (e) =>{
         if(check==1){
			setCheck(0);
			setDisabledbtnDis(0);
		 } else{
            setCheck(1);
			setDisabledbtnDis(1);
		 }  	
	}

	const updateLastName = (e) =>{
	// 	if(e.target.value!=" "){
	// 		setLastNameError(false);
	// 		setLastName(e.target.value);
	// 	}

	// if (e.target.value && email && companyName && firstName) {
	// 	setDisabledbtn(false);
	// } else {
	// 	setDisabledbtn(true);
	// }
	setLastName(e.target.value);
   }
   const updateFirstName = (e) =>{
	if(e.target.value!=" "){
		setFirstNameError(false);
		setFirstName(e.target.value);
	}
		if (e.target.value && email && companyName) {
			setDisabledbtn(false);
		} else {
			setDisabledbtn(true);
		}
	}
	const updateEmail = (e) =>{
		if(e.target.value!=" "){
			setEmailError(false);
			setEmail(e.target.value);
		}
	
	if (e.target.value && companyName && firstName ) {
		setDisabledbtn(false);
	} else {
		setDisabledbtn(true);
	}
   }
   const updateComapny = (e) =>{
	if(e.target.value!=" "){
		setCompanyNameError(false);
		setCompanyName(e.target.value)
	}

		if (e.target.value && email && firstName ) {
			setDisabledbtn(false);

		} else {
			setDisabledbtn(true);

		}
	 
		
}
	const [termsCondition, setTermsCondtion] = useState('none');
    const setShowTermsCondition = (e) => {
		//e.preventDefault()
		if(termsCondition=='block'){
		  setTermsCondtion('none')
		}
	   

	}
	const isVisibleTermsCondition = (e) => {
		e.preventDefault()
	    setTermsCondtion('block')
	}

	const isVisibleTermsConditionHide = (e) => {
		
	    setTermsCondtion('none')
	}


    const { SW, demo } = state;
	return (
		<>   
			{(showPreLoader)?<PreloaderLogin />:
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} brandData ={brandName} preHide={isVisibleTermsConditionHide} />
				<BrandAside gdata={groupName} bdata={billingPlan} preHide={isVisibleTermsConditionHide}  />
				<div className="conten-wrapper singlepages"  onClick={setShowTermsCondition}>
				<div className="controlbar pre-enrolcontrol">						
						
						<h3 className="titleonly">Hello {firstName}. Welcome to the BOstageB platform.</h3>
					

				</div>					
					
					<div className="mycustom-formwizard">
						<StepWizard
                            onStepChange={onStepChange}
                            isHashEnabled
                            transitions={state.transitions} // comment out for default transitions
                            
                            instance={setInstance}
                        >
                            <First hashKey={'FirstStep'} update={updateForm} firstName={firstName} />
                            <Second form={state.form} />
                            <Three stepName='Three' />
                            <Four stepName='Four' />
                            <Five stepName='Five' />
                            <Six stepName='Six' comapny={companyName} email={email} firstName={firstName} lastName={lastName} check={check} brandName={brandName}
							                   updateCheck={updateCheck} updateLastName={updateLastName} updateFirstName={updateFirstName}  
											   updateEmail={updateEmail} updateComapny={updateComapny} preInrollment={preInrollment} 
											   cancelEvent={cancelEvent} disabledbtnDis={disabledbtnDis} disabledbtn={disabledbtn}
											   companyNameError ={companyNameError}  emailError={emailError} firstNameError={firstNameError} lastNameError ={lastNameError}
											   paraNext={paraNext} showLoader={showLoader} isVisibleTermsCondition={isVisibleTermsCondition}  />
                            {null /* will be ignored */}
                            <Last hashKey={'TheEnd!'} resendData={resendData} email={email} companyName={companyName} login = {login}  />
                        </StepWizard>
					</div>





				</div>
				<TermsConditions setShowTermsCondition={setShowTermsCondition} isVisible={termsCondition}/>
				<BrandFooter logo={props.imageData.footer_logo} preHide={isVisibleTermsConditionHide}  />

			</div>
		}
		</>
	);
}
export default PreEnrrolment;
const Stats = ({
    currentStep,
    firstStep,
    goToStep,
    lastStep,
    nextStep,
    previousStep,
    totalSteps,
    step,
	checkN,
	lastPage,
	processingAction,
}) => (
    <div className="btnsetarea">
    	<div className="col-md-12 firstbtn">
        {
			(lastPage)?lastStep():""
		}
    	{
    		//(step < 7)?(step < 6)?<button className='btn btn-default btn-block' onClick={() => goToStep(6)}>SKIP DETAILS</button>:<><button  className={(processingAction)?'btn btn-success btn-block disabledbtn':"btn btn-success btn-block"}  disabled={(processingAction)?"disabled":""} onClick={currentStep}  >CANCEL</button><button className='btn btn-default btn-block vivsible'>INVISIBLE</button></>:""
    		(step < 7)?(step < 6)?"":<><button  className={(processingAction)?'btn btn-success btn-block disabledbtn':"btn btn-success btn-block"}  disabled={(processingAction)?"disabled":""} onClick={currentStep}  >CANCEL</button></>:""
    	}
		{
			// (step == 1)?<button className='btn btn-default btn-block vivsible'>INVISIBLE</button>:""
			 (step == 1)?<button className="btn btn-primary btn-block" onClick={() => goToStep(6)}>START ENROLLMENT</button>:""
		}
    	{ step > 1 && step < 6 &&
            <button className='btn btn-default btn-block' onClick={previousStep}>PREVIOUS</button>
        }  {/*;lastStep()*/}
        { (step > 6)?"":(step == 6)?<button className={(processingAction)?"btn btn-success btn-block disabledbtn":(checkN)?"btn btn-success btn-block":'btn btn-success btn-block disabledbtn'} disabled={(processingAction)?"disabled":(checkN)?"":"disabled"} onClick={() => {nextStep()}}>PRE-ENROLL</button>:(step == 1)?"":(step < totalSteps) ?
        	(step == 5)?<button className="btn btn-primary btn-block" onClick={nextStep}>START ENROLLMENT</button>:<button className="btn btn-primary btn-block" onClick={nextStep}>NEXT</button>:
            <button className='btn btn-success btn-block' onClick={nextStep}>Finish</button>
        }

       {/* (step > 6)?"":(step == 6)?<button className={(processingAction)?"btn btn-success btn-block disabledbtn":(checkN)?"btn btn-success btn-block":'btn btn-success btn-block disabledbtn'} disabled={(processingAction)?"disabled":(checkN)?"":"disabled"} onClick={() => {nextStep()}}>PRE-ENROLL</button>:(step == 1)?<button className="btn btn-primary btn-block" onClick={nextStep}>VIEW STEP BY STEP</button>:(step < totalSteps) ?
        	<button className="btn btn-primary btn-block" onClick={nextStep}>NEXT</button>:
            <button className='btn btn-success btn-block' onClick={nextStep}>Finish</button>
	*/ }

      </div>
        
    </div>
);

/** Steps */

const First = props => {
    const update = (e) => {
        props.update(e.target.name, e.target.value);
    };

    return (
        <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizard-top"><h4>The BOtag technology platform for Brands.</h4></div>
	        			<div className="wizardtext">		            	
			            	
			            	<h3>4 steps to get started</h3>			            	
			            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">
			            			<div className="col-md-3">
			            				<div className="stepsimg">
			            					<i className="fa-thin fa-user"></i>
			            					<h3>1</h3>
			            					<h4>Create an account</h4>
			            					<p>Create the account with your company information.</p>
			            				</div>
			            			</div>
			            			<div className="col-md-3">
			            				<div className="stepsimg">
			            					<i className="fa-thin fa-tag"></i>
			            					<h3>2</h3>
			            					<h4>Enroll your Brand</h4>
			            					<p>Enroll your Brand so stores can affiliate as fulfillment centers.</p>
			            				</div>
			            			</div>
			            			<div className="col-md-3">
			            				<div className="stepsimg">
			            					<img src={CreateBoimageCustom} />
			            					<h3>3</h3>
			            					<h4>Create BOimages</h4>
			            					<p>Connect your products to<br />stores with BOtags.</p>
			            				</div>
			            				
			            			</div>
			            			<div className="col-md-3">
			            				<div className="stepsimg">
			            					<img src={InviteCustomIcon} />
			            					<h3>4</h3>
			            					<h4>Invite Stores</h4>
			            					<p>Create your network of direct fulfillment centers.</p>
			            				</div>
			            				
			            			</div>
			            		</div>
			            	</div>

			            	<Stats step={1} {...props} />
		            	</div>
		            </div>
	            </div>
            </div>
        </div>
    );
};

const Second = props => {
    

    return (
        <div>
            { props.form.firstname && <h3>Hey {props.form.firstname}! </h3> }
           <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext twosections">
			            	<h3>Head account - Account Owner</h3>
			            				            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="stepsimg">
			            					<i className="fa-light fa-user"></i>
			            					<h3>1</h3>
			            					<h4>Create an account</h4>
			            				</div>
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>This will be the head account of your <span className="whiteandbg">BOstages Network</span>, a network of physical stores turned into online direct Fulfillment Centers for your Brand.
			            					</p>

											<p>You'll create the account with an <span className="whiteandbg">Account Owner</span> and then you'll be asked to set an <span className="whiteandbg">Account Admin</span>. The Account owner has decision power over the Account Admin and both will have different login credentials.</p>

											<p className="lilsmalltext">Right after you finish checking this short guide, you'll land on the
											<span className="whiteandbg"> Pre-enrollment form</span> to start the enrollment process. You can go directly to the pre-enrollment form by pressing the "SKIP DETAILS" button.
											</p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	<Stats step={2} {...props} />
		            	</div>
		            </div>
	            </div>
            </div>
        </div>
     </div>
    );
};

const Three = (props) => {
    

    useEffect(() => {
       
    });

    return (

    	<div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext twosections">
			            	<h3>Brand</h3>
			            				            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="stepsimg">
			            					<i className="fa-light fa-tag"></i>
			            					<h3>2</h3>
			            					<h4>Enroll your Brand</h4>
			            				</div>
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>After creating the account, you'll be invited to enroll a <span className="whiteandbg">Brand</span> owned by the Account Owner. If more than one Brand you can enroll all brands under the same account, as long as they are owned by the same Account Owner.
			            					</p>

											<p>The Brand will be able to share <span className="whiteandbg">Products</span> and invite <span className="whiteandbg">Retailers</span> to enroll their <span className="whiteandbg">Stores</span> and affiliate them as <span className="whiteandbg">Fulfillment Centers</span> to your Brand.
											</p>

											<p>The Brand will also be able to create <span className="whiteandbg">BOimages</span>, enabling shoppers to buy directly from a physical store in its network.
											</p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	    <Stats step={3} {...props} /> 
		            	</div>
		            </div>
	            </div>
            </div>
        </div>

   
    );
};

const Four = (props) => {
    

    useEffect(() => {
       
    });

    return (
    <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext twosections">
			            	<h3>BOimages</h3>
			            				            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="stepsimg">
			            					<i className="fa-light fa-user-tag"></i>
			            					<h3>3</h3>
			            					<h4>Create BOimages</h4>
			            				</div>
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>Turn any image you have featuring your products into a <span className="whiteandbg">BOimage</span> in the <span className="whiteandbg">BOimage creator</span> and link all featured products to stores with BOtags.
			            					</p>

											<p>The BOimage is saved and can be linked to any image you post on social media, on your website or even offline.
											</p>

											<p>Shoppers will use the BOtags in the BOimage to checkout directly from an affiliated physical store.
											</p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	     <Stats step={4} {...props} /> 
		            	</div>
		            </div>
	            </div>
            </div>
        </div>    

     );
};

const Five = (props) => {
    

    useEffect(() => {
       
    });

    return (
    <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext twosections">
			            	<h3>Retailers, Stores and Fulfillment Centers</h3>
			            				            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="stepsimg">
			            					<i className="fa-light fa-store"></i>
			            					<h3>4</h3>
			            					<h4>Invite Stores</h4>
			            				</div>
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>Finally build your BOtag network with your Stores and stores owned by other retailer. They become automatically <span className="whiteandbg">Fulfillment Centers</span> which will provide shoppers with direct <span className="whiteandbg">deliveries</span> of products featured in your BOimages.
			            					</p>

											<p>The delivery options include <span className="whiteandbg">pick-up in store, shipping to an address</span> or <span className="whiteandbg">courier to a location</span>.
											</p>

											<p>A simple invitation by email, generated by your account, will invite them to enroll and affiliate to your Brand.
											</p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	     <Stats step={5} {...props} /> 
		            	</div>
		            </div>
	            </div>
            </div>
        </div>    

     );
};
const Six = (props) => {
    
	const validate = () => {
        if (confirm('Are you sure you want to go back?')) { // eslint-disable-line
            props.previousStep();
        }
    };

    return (
    	<>
    <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        		<div className="wizard-top"><h4>The BOtag technology platform for Brands.</h4></div>
	        			<div className="wizardtext twosections accountsowner">
			            	<h3>Pre-enrollment form - Account Owner</h3>
							<div className="loaderClasses" style={{"display":(props.showLoader)?"block":"none"}}>
								<span  className="processingClass">Processing</span> <span className=""><i className="fa fa-spinner fa-spin fa-2x"></i></span>
							</div>		            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="card-body">
			            					<form>
			            						<div className="row">
												   <div className="col-md-12">
			            								<h2 className="formtexttile"><span>Brand:</span> {props.brandName} </h2>
			            								<div className={(props.showLoader)?"form-group inActiveborder ftColor":"form-group activeborder"}>
			            									<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Company name</label>
			            									<input type="text" className={(props.companyNameError)?"form-control error-input":"form-control"} value={props.comapny} onChange={props.updateComapny} />
			            								</div>
			            							</div>
			            						</div>
			            						<div className="row">
			            							<div className="col-md-12">
			            								<div className={(props.showLoader)?"form-group inActiveborder withicons ftColor":"form-group activeborder withicons"}>
			            									<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Account owner's email</label>
			            									<div className="addafterbefore">
			            										<input type="text" className={(props.emailError)?"form-control error-input":"form-control"} value={props.email} onChange={props.updateEmail} />
																{/* <span className="notverified">not verified</span> */}
			            									</div>
			            								</div>
			            							</div>
			            						</div>
			            						<div className="row">
			            							<div className="col-md-6">
			            								<div className={(props.showLoader)?"form-group inActiveborder ftColor":"form-group activeborder"}>
			            									<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Account owner</label>
			            									<input type="text" className={(props.firstNameError)?"form-control error-input":"form-control"} value={props.firstName} onChange={props.updateFirstName} />
			            									<span className="small-label">First name</span>
			            								</div>
			            							</div>
			            							<div className="col-md-6">
			            								<div className={(props.showLoader)?"ftColor form-group":"form-group"}>
			            								 <label htmlFor="tobrand" className="hiditfornow">&nbsp;</label> 
			            									<input type="text" className={(props.lastNameError)?"form-control error-input":"form-control"} value={props.lastName} onChange={props.updateLastName} />
			            									<span className="small-label">Last name</span>
			            								</div>
			            							</div>
			            						</div>
			            						<div className="row">
			            							<div className="col-md-12 bottomlevel">
			            								<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span>
			            							</div>
			            						</div>
			            					</form>
			            				</div>
			            				
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>A <span className="whiteandbg">BO</span>stage<span className="greentst">B</span> Brand account is created with an <span className="whiteandbg">Account Owner</span> profile. The Account Owner has full decision power over any other user of the account.
			            					</p>

											<p>If you are not the right person to be registered as the Account Owner, please replace your email and name with the correct person's data. Credentials to proceed with the enrollment process will be sent to the provided email address.
											</p>

											<p className="pre-font">Later you'll be asked to create an Account Admin profile. The Account Admin will have its own credentials.
											</p>

											<p className="checkkbox pre-font"><label className="container-checkbox"><input type="checkbox" name="select-row" checked={(props.check)?true:false} onClick={props.updateCheck} disabled={(props.showLoader)?"disabled":""} /><span className="checkmark"></span></label> I have read and I agree with the <Link to="#" onClick={props.isVisibleTermsCondition}>Terms and Conditions.</Link></p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>
			            		
			            	     <Stats step={6} {...props} nextStep={props.preInrollment} currentStep={props.cancelEvent} checkN={props.disabledbtnDis} lastPage={props.paraNext} processingAction={props.showLoader} /> 
		            	</div>
		            </div>
	            </div>
            </div>
        </div>    
        
        </>
     );
};
const Last = (props) => {
    const submit = () => {
        alert('You did it! Yay!') // eslint-disable-line
    };

    return (
    	<div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        		<div className="wizard-top"><h4>The BOtag technology platform for Brands.</h4></div>
	        			<div className="wizardtext twosections lastonesr">			            				            	
			            	<div className="wizadwrap">
			            		<div className="row">
			            			<div className="col-md-12">
			            				<div className="customcheckf">
			            					<img src={ExclamtionBlue} />
			            					
			            				</div>
			            				<div className="rightparatext">
			            					                                          
											<p className="swal-relvant"> Please check email <span className="whiteandbg">{props.email}</span> for credentials and login to your account.
											</p>
											<p className="lilsmalltext">
												Didn't get it? (allow a few seconds)
											</p>
											<div className="btnsetarea">
												<div className="row">
													<div className="col-md-6 firstbtn text-center">
													<button className="btn btn-default btn-block goto-login-page" onClick={props.resendData}>RESEND EMAIL</button>
													
													</div>
													<div className="col-md-6">
													<button className="btn btn-default btn-block goto-login-page" onClick={props.login}>Login</button>
													</div>

                                                    
													
												</div>
											</div>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	     <Stats step={7} {...props} nextStep={submit} />
		            	</div>
		            </div>
	            </div>
            </div>
        </div> 
    );
};