import React from "react";
export default function Denied(){
	return(
		<>
			<div className="access-denied">
				<div className="access-compound">
					<div className="for_not_three">403</div>
					<div className="forbidden-text">Forbidden</div>
					<div className="access-not-allowed">You can't access this page.</div>
				</div>
			</div>
		</>
	)
}