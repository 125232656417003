import React from "react";
import { Link, NavLink } from "react-router-dom";
import LeftLogo from './../../assets/images/logoletf.png';
import MenuIsOn from './../../assets/images/newsidenav/dashboardicon.png';
import MenuIsOff from './../../assets/images/dashoboardoff.png';
import BOimageIsOn from './../../assets/images/newsidenav/boimageson.png';
import BOimageIsOff from './../../assets/images/boimagesoff.png';
import FulfillmentOn from './../../assets/images/newsidenav/fullfillmenton.png';
import FulfillmentOff from './../../assets/images/fullfillmentoff.png';
import StoresOn from './../../assets/images/newsidenav/storeson.png';
import StoresOff from './../../assets/images/storesoff.png';
import BrandOn from './../../assets/images/newsidenav/brandon.png';
import BrandOff from './../../assets/images/brandoff.png';
import RetailersOn from './../../assets/images/newsidenav/retailerson.png';
import RetailersOff from './../../assets/images/retailersonoff.png';
import ProductisOn from './../../assets/images/newsidenav/productison.png';
import ProductisOff from './../../assets/images/productisoff.png';
import AccountOn from './../../assets/images/newsidenav/accounton.png';
import AccountOff from './../../assets/images/accountoff.png';
import BillingOn from './../../assets/images/newsidenav/billingon.png';
import SettingOn from './../../assets/images/newsidenav/settingon.png';
import SupportOn from './../../assets/images/newsidenav/supporton.png';
import Promoter from './../../assets/images/newsidenav/promoters.png';
function BrandAside(props) {
	return(
		<>
			<aside className="main-sidebar newmenuicons">
			    <div className="custom-logo">
			      <div className="logoname">
			      	<img alt="" className="leftmainlogo" src={LeftLogo} />			        
			      </div>
			      <div className="itemsas">Hartlin AS</div>
			    </div>
			    <div className="itemscenter">
			      <ul>
			        <li>Current status: <span>Active</span> <span className="checktext">Check</span></li>
			        <li>Billing plan: <span>Premium</span></li>
			        <li>Group plan: <span>Assigned</span></li>
			        <li>Active stores: <span>45</span></li>
			      </ul>
			    </div>
			    <div className="menusections">
			      <div className="navitems-area">
			        <ul>
			          <li className="fullarea menuisonstate">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="MenuIsOn" className="menuison" src={MenuIsOn} />
			                  <img alt="MenuIsOff" className="menuisoff" src={MenuIsOff} />
			                </div>
			              Dashboard
			              </div>
			            </Link>
			          </li>
			          <li className="fullarea menuisonstate">
			          	<NavLink to="/boimages">
				            <div className="menuimages">
				                <div className="menuimgbg">
				                  <img alt="MenuIsOn" className="menuison" src={BOimageIsOn} />
				                  <img alt="MenuIsOff" className="menuisoff" src={BOimageIsOff} />
				                </div>
				              BOimages
				            </div>
			            </NavLink>
			          </li>
			          <li className="fullarea menuisonstate">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="MenuIsOn" className="menuison" src={FulfillmentOn} />
			                  <img alt="MenuIsOff" className="menuisoff" src={FulfillmentOff} />
			                </div>
			              Fullfillment Centers
			            </div>			              
			            </Link>
			          </li>
			          <li className="fullarea menuisonstate">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			            	<div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="MenuIsOn" className="menuison" src={StoresOn} />
			                  <img alt="MenuIsOff" className="menuisoff" src={StoresOff} />
			                </div>
			              	Stores
			            	</div>
			            </Link>
			          </li>
			          <li className="fullarea menuisonstate">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="MenuIsOn" className="menuison" src={RetailersOn} />
			                  <img alt="MenuIsOff" className="menuisoff" src={RetailersOff} />
			                </div>
			              	Retailers
			            	</div>
			            </Link>
			          </li>
			          <li className="fullarea menuisonstate">
			            <NavLink to="/products">
			              <div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="MenuIsOn" className="menuison" src={ProductisOn} />
			                  <img alt="MenuIsOff" className="menuisoff" src={ProductisOff} />
			                </div>
			              	Products
			            	</div>
			            </NavLink>
			          </li>
			          <li className="fullarea menuisonstate">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="BrandOn"  className="menuison" src={BrandOn} />
			                  <img alt="BrandOff"  className="menuisoff" src={BrandOff} />
			                </div>
			                Brands
			              </div>
			            </Link>
			          </li>
			          <li className="fullarea menuisonstate">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="BrandOn"  className="menuison" src={Promoter} />
			                  <img alt="BrandOff"  className="menuisoff" src={BrandOff} />
			                </div>
			                Promoters
			              </div>
			            </Link>
			          </li>
			          <li className="fullarea menuisonstate">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="menuison" className="menuison" src={AccountOn} />
			                  <img alt="menuisoff" className="menuisoff" src={AccountOff} />
			                </div>
			                Account
			              </div>
			            </Link>
			          </li>
			        </ul>
			      </div>
			      <div className="blankspacearea">
			      </div>
			      <div className="navitems-area bottom-section">
			        <ul>
			        	<li className="halfarea">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <img alt="setting-on" src={SettingOn} />
              			</div>
            					Settings
            			</Link>
          			</li>
			          <li className="halfarea">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <img alt="billing-on" src={BillingOn} />
			              </div>
			              Billing
			            </Link>
			          </li>
			          
			          <li className="halfarea">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <img alt="SupportOn" src={SupportOn} />
			              </div>
			              Support
			            </Link>
			          </li>
			        </ul>
      </div> 
    </div>   
  </aside>
		</>
	);
}
export default BrandAside;