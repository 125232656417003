import React, {useRef, useState, useEffect} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BrandNavigation from "./../../layouts/BrandDashboardHeader";
import BrandAside from "./../../layouts/BrandAside";
import BrandFooter from "./../../layouts/BrandFooter";
import axios from 'axios';
import noBoimage from './../../../assets/images/uploads/no_img.png';
import { API_URL,API_KEY_B, BLOB_URL, BLOB_BOIMAGE_CONTAINER } from './../../../config/constant.js';
import BoimageOne from './../../../assets/images/uploads/boimageone.jpg';
import BrandLogo from './../../../assets/images/uploads/brandlogs.png';
import Tsbrand from './../../../assets/images/uploads/brandlogs.png';
import ProductList from "./product-list";
import {useDropzone} from 'react-dropzone'
import errorWarning from './../../../assets/images/icons-error-48.png';
import errorSuccess from './../../../assets/images/icons8-check-64.png';
import Draggable from 'react-draggable';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadFileToBlob, { isStorageConfigured } from './blob';
import LoaderSvg from './../../../assets/images/loader.svg';
import LoaderIcon from './../../../assets/images/loader-icon.png';
import ForBidden from './../../layouts/denied'
import Vector3 from './../../../assets/images/vector-3.svg';
import Vector28 from './../../../assets/images/vector28.svg';
import Vector75 from './../../../assets/images/vector-75.svg';
import { ExitToApp } from "@mui/icons-material";
const ArrayBeacon =[];
function Canvas(props) {
	document.title = 'BOstagB - Creator Canvas';
	const ref = useRef();
	const imageBoundry = useRef();
	const { BOimageIdData } = useParams();
	const navigate = useNavigate();
	const canvasImageRef = useRef();
	const mobileImageRef = useRef();
	const [elementData, setElementData] = useState("")
	const [lastInsertIdData, setLastInsertIdData] = useState([]);
	const [productVisible, setProductVisible] = useState('none');
	const [files, setFiles] = useState([]);
	const [hideOverlay, setHideOverlay] = useState("");
	const [mousePosition, setMousePostion] = useState([])
	const [divItems, setDivItems] = useState([]);
	const [activeBOtags, setActiveBOtags] = useState(null);
	const [canvasProductList, setCanvasProductList] = useState([]);
	const [canvasProductListNew, setCanvasProductListNew] = useState([]);
	const [linkedListItems, setLinkedListItems] = useState([]);
	const [linkedListItemsNew, setLinkedListItemsNew] = useState([]);
	const [activeProductData, setActiveProductData] = useState(null);
	const [dataForSearch, setDataForSearch] = useState("");
	const [jsonFormData, setJsonFormData] = useState([]);
	const [beaconPosition, setBeaconPosition] = useState([{ beaconXposition: 0, beaconYposition: 0, BotagId: null }])
	const [tagPosition, setTagPosition] = useState([{ BotagId: null }])
	const [mobileDivItems, setMobileDivItems] = useState([]);
	const [mobileTag, setMobileTag] = useState([]);
	const [newMobileTag, setNewMobileTag] = useState([]);
	const [mobData, setMobData] = useState([]);
	const [elementId, setElementId] = useState(0);
	const [hideAlert, setHideAlert] = useState(false)
	const [dontShow, setDontShow] = useState(false)
	const [activePointer, setActivePointer] = useState(false)
	const [isUploaded, setIsUploaded] =useState(0);
	const [brandLogo, setBrandLogo] = useState('');
	const [preLoader, setPreLoader] = useState(true);
	const [onSubmitLoader, setOnSubmitLoader] = useState(false);
	const [buttonGray, setButtonGray] = useState(false);
	const [dataReadonly, setDataReadonly] = useState(false);
	const [denied, setDenied] = useState(false);
	const [imgUrlCk ,setImgUrlCk] =useState(false);
	const [msgs,setMsgs] =useState("BOimage saved");
	const [msgsData,setMsgsData] =useState(1);
	const [buttonName,setButtonName] =useState("NEXT");
	const [editMode,setEditMode] =useState(0);
	const [editModeTrg,setEditMmodeTrg] =useState(0);

	const[editModeBtnFun,setEditModeBtnFun] = useState(0);

    const[apiBrandInfoResp,setApiBrandInfoResp] = useState(0);
    const[apiBrandIdDataResp,setApiBrandIdDataResp] = useState(0);
    const[apiCanvasProdListResp,setApiCanvasProdListResp] = useState(0);
    const[apiSaveListResp,setApiSaveListResp] = useState(0);

	const [updatedDataLinklist,setUpdatedDataLinklist] = useState([]);
    const [updatedDataLinklistSize,setUpdatedDataLinklistSize] = useState([]);
	const [loadOneTime,setLoadOneTime]=useState(1);
	const [loadOneTimeLinkedData,setLoadOneTimeLinkedData]=useState(1);
    const [oneTimeOnBoarding,setOneTimeOnBoarding] = useState(1)

	const [dataColorSize,setDataColorSize] = useState({});

	const addDataIntoDefault = (cacheName, url, response) => {
		setLinkedListItems([]);
		setLinkedListItemsNew([]);
		const data = new Response(JSON.stringify(response));
		if ('caches' in window) {
		  caches.open(cacheName).then((cache) => {
			cache.put(url, data);
		  });
		}
	  };
	

	 
	  const newBoimageFun = () =>{   
		  // console.log("trtrtr",BOimageIdData);
		if(BOimageIdData == undefined){
			//console.log("testtt new boimg");
			localStorage.removeItem('viewMode');
			setEditMode(0);
			setEditModeBtnFun(0);
			getDataOfLid(BOimageIdData);

			setLoadOneTime(1);
			setLoadOneTimeLinkedData(1);
			setOneTimeOnBoarding(1);
			
			setApiBrandInfoResp(0);
			setApiBrandIdDataResp(0);
			setApiCanvasProdListResp(0);
			setApiSaveListResp(0);
            
			setElementData("")
			setLastInsertIdData([]);
			setProductVisible('none');
			setFiles([]);
			setHideOverlay("");
			setMousePostion([])
			setDivItems([]);
			setActiveBOtags(null);
			setCanvasProductList([]);
			setCanvasProductListNew([]);
			setLinkedListItems([]);
			setLinkedListItemsNew([]);
			setActiveProductData(null);
			setDataForSearch("");
			setJsonFormData([]);
			setBeaconPosition([{ beaconXposition: 0, beaconYposition: 0, BotagId: null }])
			setTagPosition([{ BotagId: null }])
			setMobileDivItems([]);
			setMobileTag([]);
			setNewMobileTag([]);
			setMobData([]);
			setElementId(0);
			setHideAlert(false)
			setDontShow(false)
			setActivePointer(false)
			setIsUploaded(0);
			setBrandLogo('');	
			setButtonGray(false);
			setDataReadonly(false);
			setImgUrlCk(false);
			setButtonName("NEXT");
           // navigate("/boimages/canvas");
		}
	 }
	  

     useEffect(()=>{
		if(loadOneTime){
			addDataIntoDefault("linkedDataList", window.location.origin, [])
		}
	 },[loadOneTime])
    

	/* useEffect(()=>{
		if(jsonFormData){
			console.log("jsonFormData",jsonFormData)
		}
	 },[jsonFormData])
    */

   /*******************save popup********************** */
      
   const refWarp = useRef(null);   //new datatttt
   useEffect(() => {
   
	   function handleSaveOutside(event) {
		 // console.log("parentNode",event.target.parentNode);
		   
			 //menu
		   let pclass = event?.target?.parentNode?.parentNode?.parentNode?.parentNode;
		   let pclassimg = event?.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
		   // rightcontrolserch
		   let prClassIcon = event?.target?.parentNode?.parentNode?.parentNode;
		   let prClass = event?.target?.parentNode?.parentNode;
            //center upper btn
		  let pCNClass = event?.target?.parentNode;
			
		   if(editMode && !editModeBtnFun){  
			   //  console.log("test",editMode ,editModeBtnFun);
			 //if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
			 if (refWarp?.current && !refWarp?.current?.contains(event.target) && (event?.target?.classList?.contains("saveIt") || pclass?.classList?.contains("menusections") || pclass?.classList?.contains("navitems-area") || pclassimg?.classList?.contains("navitems-area")|| pclassimg?.classList?.contains("menusections") || prClassIcon?.classList?.contains("saveIt")  || prClass?.classList?.contains("saveIt") || pCNClass?.classList?.contains("saveIt") )  ) {
				 Swal.fire({
					 title: '',
					 text: "Changes will not be saved",
					 icon: 'warning',
					 showDenyButton: true,
					 allowOutsideClick: false,
					 confirmButtonColor: '#205380',
					 denyButtonColor: '#205380',
					 confirmButtonText: 'SAVE NOW',
					 denyButtonText: 'CANCEL',
					 background: '#1d2530',
					 color: '#fff',
					 iconColor: "#FF9900",
					 width: "500px",
					 padding: "10px",
					 reverseButtons: false
				   }).then((result) => {
					 if (result.isConfirmed) {
						 setEditModeBtnFun(1);   
						 saveAndNext();  		
					 }
					 else if(result.isDenied){
						setEditModeBtnFun(1);
					  }
					 
				 })   

			 
		 }
		}
	   }
	   document.addEventListener("mousedown", handleSaveOutside);
	   return () => {
		 document.removeEventListener("mousedown", handleSaveOutside);
	   };
	 }, [refWarp,editMode,editModeBtnFun]);   




   /**************************************************** */

	
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.BOimagesMenu){
					setPreLoader(false);
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }

		
	},[props.compData]);

	useEffect(()=>{
		if(apiBrandInfoResp && apiBrandIdDataResp && apiCanvasProdListResp ){
			setPreLoader(false);    
		}
	 },[apiBrandInfoResp,apiBrandIdDataResp,apiCanvasProdListResp])

    /* *****************cache********************** */
	
	   const [cacheData, setCacheData] = useState([]);
	  
	 /*  useEffect(()=>{
           console.log("cacheData",cacheData,linkedListItems);   
	   },[cacheData,linkedListItems]);  */


	   const getSingleCacheData = async (cacheName, url) => {
	    if (typeof caches === 'undefined') return false;
      	const cacheStorage = await caches.open(cacheName);
      	const cachedResponse = await cacheStorage.match(url);
      	if (!cachedResponse || !cachedResponse.ok) {
      			return [];
      		}
      	return cachedResponse.json().then((item) => {
			return item;
			/*setLinkedListItems(item);
			setLinkedListItemsNew(item);*/	
      	});
  	};
	const getLinkedCacheData = async (cacheName, url) => {
	    if (typeof caches === 'undefined') return false;
      	const cacheStorage = await caches.open(cacheName);
      	const cachedResponse = await cacheStorage.match(url);
      	if (!cachedResponse || !cachedResponse.ok) {
      			return [];
      		}
      	return cachedResponse.json().then((item) => {
      		item.sort(function(a, b) { 
	          return a.BOtagId - b.BOtagId;
	      });
			setLinkedListItems(item);
			setLinkedListItemsNew(item);
      	});
  	};
		
	   const addDataIntoCache = async (cacheName, url, response) => {
		let cData = await getSingleCacheData(cacheName, url)
			cData.push(response)
			setLoadOneTime(0);
			const data = new Response(JSON.stringify(cData));
			if ('caches' in window) {
				caches.open(cacheName).then((cache) => {
					cache.put(url, data);
				});
			}
			cData.sort(function(a, b) { 
          return a.BOtagId - b.BOtagId;
      });
			setLinkedListItems(cData);
			setLinkedListItemsNew(cData);
		};

	                     
   /* ********************************************* */
   useEffect(() => {
	setElementData(ref.current);
	// if(BOimageIdData != undefined){
	// 	console.log("")
		
	// }
	getDataOfLid(BOimageIdData);
	
	getBrandInfo(localStorage.getItem('brandID'));
	//localStorage.setItem("canvasId",BOimageIdData);

},[localStorage.getItem('brandID')])



  
	useEffect(() => { 
		
		 if(localStorage.getItem('BoTagData') && oneTimeOnBoarding && lastInsertIdData?.BOtags?.length == 0){ 
			setOneTimeOnBoarding(0);  
			if(localStorage.getItem("status")!=100 && localStorage.getItem("status")!='Completed'){   
				
           //error sam
			let idata =	JSON.parse(localStorage.getItem('BoTagData')).map(i=>{
						i.isLinked = false;
					    return i;   
					}); 
					
					setDivItems(idata);
					hideOverlayFun();
			}
		 }
		
	},[localStorage.getItem('BoTagData'),oneTimeOnBoarding,lastInsertIdData])

	

       useEffect(()=>{
          if(localStorage.getItem("viewMode")=='editmode')
		  {
			setEditMode(1);
			setEditModeBtnFun(1)
		  }
	   },[localStorage.getItem("viewMode")]) 

	useEffect(()=>{
		if(localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed'){
			setButtonName("SAVE");
		}
	  },[localStorage.getItem('status')])





   /*
	const getLinkedData = async () => {
		await axios.post(API_URL+"saveLinkedProductList"+API_KEY_B, {idData: BOimageIdData}).then((res)=>{
			if(res.data.status==200){
				setLinkedListItems(res?.data?.data);
				setLinkedListItemsNew(res?.data?.data);
				setApiSaveListResp(1);
			}
		})
	}   */

	const [updTag, setUpdTag] = useState([]);
	const unlinkProductList = async (res) => {
		let formData = {
			IdData: BOimageIdData,
			BOtgaId: res.BOtagId,
			type: (res?.type)?res.type:'unlink'
		}
		if(res.type !== 'removeUpd'){
			let d = divItems.map(r => {
				if(r.BotagId == res.BOtagId){
					r.isLinked = false
					r.color = '-'
					r.size = "-"
					r.name = '-'
				}
				return r;
			})
		}

		/*setJsonFormData(jsonFormData.filter(y => {
			return y.tagId !== formData.BOtgaId
		})) */
	   
	if(formData.type === 'removeUpd'){

		let i = beaconPosition.filter(bp => {
			return bp.BotagId !== formData.BOtgaId
		})
		let ir = i.map(oi => {
			if(formData.BOtgaId < oi.BotagId && oi.BotagId){
				oi.BotagId = oi.BotagId - 1;
			}
			return oi;
		})
		setBeaconPosition(ir);
		let it = tagPosition.filter(bp => {
			return bp.BotagId !== formData.BOtgaId
		})
		let itr = it.map(oi => {
			if(formData.BOtgaId < oi.BotagId && oi.BotagId){
				oi.BotagId = oi.BotagId - 1;
			}
			return oi;
		})
		setTagPosition(itr);

	}
		
		
		let cacheData = await getSingleCacheData("linkedDataList",window.location.origin);
		let dCache = cacheData.filter(o => {
			return (o.BOtagId != res.BOtagId)
		})
		if(formData.type === 'removeUpd')
		   {
		     let dataC =	dCache.map((dc)=>{
                 if(dc.BOtagId > res.BOtagId){
					dc.BOtagId = dc.BOtagId - 1;
				 }
				 return dc
			});
			//console.log("dataC",dataC);
			updDataIntoCache("linkedDataList",window.location.origin,dCache);
		   }else{
			updDataIntoCache("linkedDataList",window.location.origin,dCache);
		   }
			
		   if(formData.type === 'removeUpd'){
				let p = jsonFormData.filter(r => {
					return (formData.BOtgaId !== r.tagId);
				})
				let mp = p.map(io => {
					if(formData.BOtgaId < io.tagId){
						io.tagId = io.tagId - 1;
					}
					return io;
				})
				setJsonFormData(mp);
			}else{
             
				let dg = jsonFormData?.map(r => {
					//if(r?.dpost?.BotagId == res.BOtagId ){
					 if(r?.tagId == res.BOtagId ){
						r.dpost.GTINbarcode = "";
						r.dpost.productID = "";
						r.productID ="";
						r.referenceCode = "";
						r.name = "";
						r.size = "";
						r.color = "";

					}
					return r;
				})
				
				//setJsonFormData(dg);

			}

				if(formData.type === 'removeUpd'){
					let mTag = newMobileTag.filter(r => {
						return r.tagId !== formData.BOtgaId
					})
					let fmTag = mTag.map(t => {
						if(formData.BOtgaId < t.tagId){
							t.tagId = t.tagId - 1;
							t.BotagId = t.BotagId - 1;
						}
						return t;
					})
					setUpdTag(fmTag);
				}else{
					let mTag = newMobileTag.map(r => {
						if(r.tagId === formData.BOtgaId){
							r.isLinked = false
						}
						return r;
					})
					setUpdTag(mTag);
				}

				if(editMode){
					setEditModeBtnFun(0);
				  }
		
	}
	const updDataIntoCache = async (cacheName, url, response) => {
		
			setLoadOneTime(0);
			
			const data = new Response(JSON.stringify(response));
			if ('caches' in window) {
				caches.open(cacheName).then((cache) => {
					cache.put(url, data);
				});
			}
			response.sort(function(a, b) { 
          return a.BOtagId - b.BOtagId;
      });
			setLinkedListItems(response);
			setLinkedListItemsNew(response);
			setLoadOneTimeLinkedData(0);
		};

	useEffect(() => {
	  // if(updTag.length){
	 if(updTag){
			setMobileTag(updTag);
			setNewMobileTag(updTag);
		}
		
	},[updTag]);
	//console.log("setProductVisible",productVisible);
	const setShowProductList = (x) => {  
		// console.log("test",x);
		if(x=='block'){
    	setProductVisible('block')
		}else{
		setProductVisible('none')	
		}
    	let tagArray = [1,2,3,4,5,6]		
	  	tagArray.map(r => {
		  	let element = document.getElementById(r);
		  	element?.querySelector('div.notlinked')?.classList?.remove("active");
		  //	element?.querySelector('div.botag-div').classList?.remove("active");
		  	setActiveProductData(null)
	  	});
		setActiveBOtags(null);
 	}
 	const [linkedTabActive, setLinkedTabActive] = useState(false)
	 const isVisibleProductList = (linkedTab = false) => {
		if(files.length && divItems.length > 0){
			setProductVisible('block')
			if(linkedTab){
				setLinkedTabActive(true)
			}else{
				setLinkedTabActive(false)
			}
		}else{
			toast.error("Please click or tap on the image over a product to create a BOtag first.", {
				theme: "dark",
				icon: ({theme, type}) =>  <img src={errorWarning}/>
			});
	  	}
	}
  	const linkedTabActiveFunction = (v) => {
		setLinkedTabActive(v)
		setActivePointer(false)
		let tagArray = [1,2,3,4,5,6]		
	  	tagArray.map(r => {
		  	let element = document.getElementById(r);
		  	element?.querySelector('div.notlinked')?.classList?.remove("active");
		  	//element?.querySelector('div.boxonimg').classList?.remove("active");
		// 	element?.querySelector('div.botag-div').classList?.remove("active");
		  	setActiveProductData(null)
	  	})
		  //added 03-04
		  setActiveBOtags(null);
		
	}
	const getDataOfLid = async(dataLid) =>{
		const formData = {
			"id":dataLid
		}
		await axios.post(API_URL+"get-boimage-id-data-by-id"+API_KEY_B,formData).then((res)=>{
		//await axios.post("http://localhost:7071/api/get-boimage-id-data-by-id",formData).then((res)=>{
			if(res.data.status==200){
				
				//console.log("previous saved data",res.data.resData);
				setLastInsertIdData(res.data.resData);
				if(res.data.resData?.imageURL!=""){ 
					let text = res.data.resData?.imageURL;
						const myArray = text.split("/");
						 let len = myArray.length;
					setFiles([{"name":myArray[len-1],"preview":res.data.resData?.imageURL}]);
					setIsUploaded(true); 
					
				}
				//if(res.data.resData?.BOtags[0]?.GTINbarcode){   
					res.data.resData?.BOtags.map((r,i) =>{
						if(r?.GTINbarcode && r?.productID){
						 	r.isLinked=true;
							r.BotagId=i+1;
						}else{
							r.isLinked=false;
							r.BotagId=i+1;
						}
					});
          if(res.data.resData?.BOtags.length){
          	setDivItems(res.data.resData?.BOtags);
			 
			  setMsgs("BOimage updated");
			  setButtonName("SAVE");
              if(localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed'){
			 	 setEditMode(1);
			  }
          }     
					
					hideOverlayFun();

					setImgUrlCk(true);  	
					
				//}
				if(localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed'){
					
				      //setLastInsertIdData2(res.data.resData);
					  //setImageData(res.data.resData.imageURL);
					setRenderData(res.data.resData.renderedIamge);
					  //if(res.data.resData?.BOtags[0]?.GTINbarcode){ 						
						if(res.data.resData?.BOtags.length){ 						
						 setDataReadonly(true);
						//sam
						
					}
			   }
			  /* if(res?.data?.resData?.imageURL!=""){
				setImgUrlCk(true);     
		      } */   
			}else{
				//navigate("/boimages/creater")
				navigate("/boimages/canvas")
			}
			//setPreLoader(false)
			setApiBrandIdDataResp(1);
		});      
	  
	}
    
	const plotBecons = ()=>{
		let mobArr = []; 
		let BotagsData =   divItems ; 
		//console.log("files",divItems);    
		//console.log("BotagsData",BotagsData,mobileImageRef);    
	 if(BotagsData){
		const opp = [];
		const ttt = [];
		const mPos = [];
		BotagsData.map((r)=>{
			const obj =	{
			color: r?.color,
			isLinked:(r?.productID)?true:false,
			mobileBeaconXposition :(mobileImageRef?.current?.clientWidth/canvasImageRef.current.clientWidth) * r?.beaconXposition,
			mobileBeaconYposition : (mobileImageRef?.current?.clientHeight/canvasImageRef.current.clientHeight) * r?.beaconYposition,				
			name: r?.productName,
			size: r?.size,
			tagId: r?.BotagId
			} 
			mobArr.push(obj);
			//added
			const mJs = {
				x: r?.beaconXposition, 
				y:r?.beaconYposition, 
				boTagMouse: r?.BotagId
			}
			mPos.push(mJs)
			
			//setBeaconPosition([...beaconPosition, {beaconXposition: r?.beaconXposition, beaconYposition: r?.beaconYposition, BotagId: r?.BotagId}])
			const nJson = {
				beaconXposition: r?.beaconXposition, 
				beaconYposition: r?.beaconYposition, 
				BotagId: r?.BotagId
			}
			ttt.push(nJson)
			const jData = {
                referenceCode: "",
                productID: r?.productID,
                name: (r?.productName)?r?.productName:"",
                color: (r?.color)?r?.color:"",
                size: (r?.size)?r?.size:"",
                tagId: r?.BotagId,
                isLinked: true,
                dpost: {
                    beaconXposition:  r?.beaconXposition,
                    beaconYposition: r?.beaconYposition,
                    BotagId: r?.BotagId,
					GTINbarcode: r?.GTINbarcode,
					productID: r?.productID
                }
			}
			
			//if(r?.productID && r?.GTINbarcode ){
				opp.push(jData)
			//}
			
			
			});
			setJsonFormData(opp)
			setBeaconPosition(ttt)
			setMousePostion(mPos);
		setMobileTag(mobArr);
		//added
		setNewMobileTag(mobArr);
        
	
		setMobileDivItems(mobArr);	
	 }	
	}
	useEffect(() => {
		if(jsonFormData.length){
			(jsonFormData).map((r,i)=>{
				(linkedListItems).forEach(k=>{
					if(r.tagId==k.BOtgaId){
						r.referenceCode = k.referenceCode;
					}
				});
			})
		}
		//console.log('dhj',jsonFormData, linkedListItems)
	},[jsonFormData, linkedListItems])

 

	const getBrandInfo = (brandID) => {
      let formData = {
        "id":brandID
      }
      axios.post(API_URL+'get-brand-info'+API_KEY_B,formData).then((res) => {
        if(res.data.status == 200){
                          if(res?.data?.data?.logos?.mainLogoWhiteURL){
                                setBrandLogo(res?.data?.data?.logos?.mainLogoWhiteURL)
                        }else{
                                setBrandLogo(res?.data?.data?.logos?.mainLogoURL)
                        }
          getCanvasProductList(brandID);
		  setApiBrandInfoResp(1);
        }
    })
    }
	const getCanvasProductList = async (brandID) => {
		let formData = {
			"brandID":brandID
		}
		await axios.post(API_URL+"canvas-product-list"+API_KEY_B,formData).then((res)=>{
			if(res.data.status==200){
				let newResponse = [];
				(res.data.resData).map(r => {
					r.isLinked = false
					r.BOtgaId = 0
					newResponse.push(r);
				})
				setCanvasProductList(newResponse);
				setCanvasProductListNew(newResponse)
				setApiCanvasProdListResp(1);
			}
		});
	}
	const fullScreenCheck = (e) => {
		let fulls=localStorage.getItem('fullscreen');
		if(fulls=='full'){
			document.body.requestFullscreen();
		}
	}
	const [dynamicClass, setDynmicClass] = useState("");

    const ConfirmUploadNew = () =>{
         
	    if(divItems.length){	

		Swal.fire({
			title: '',
			text: "You are starting a new BOimage. Save current one?",
			icon: 'warning',
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'Yes',
			denyButtonText: 'No',
			background: '#1d2530',
			color: '#fff',
			iconColor: "#FF9900",
			width: "500px",
			padding: "10px",
			reverseButtons: false
		  }).then((result) => {
			if (result.isDenied) {
				addDataIntoDefault("linkedDataList", window.location.origin, [])  
				open();
			}else{
               
			}
		})
	}else{
            open();  
	}
	

}


const {getRootProps, getInputProps, open} = useDropzone({
	accept: {
		  'image/*': []
	},
	multiple: false,

	onDrop: async acceptedFiles => {
		let randNo = (Math.random() + 1).toString(36).substring(4);
		const renamedAcceptedFiles = acceptedFiles.map((file) => (
			new File([file], `${randNo}_${file.name}`, { type: file.type })
		  ))
		setFiles(renamedAcceptedFiles.map(file => Object.assign(file, {
			preview: URL.createObjectURL(file)
		})));
		renamedAcceptedFiles.map(file => {
		const image = new Image();
		image.addEventListener('load', () => {
			if(image.width > image.height){
				setDynmicClass("dynamic-width")
			}else{
				setDynmicClass("dynamic-height")
			}
		});
		image.src = URL.createObjectURL(file)
		})
		if(BOimageIdData == undefined){
			let formData = {
				"companyID":(typeof props?.compData?.companyDetail != "undefined")?props?.compData?.companyDetail?.ourID:"",
				"brandID":localStorage.getItem('brandID'),
				"pageType": "image",
				"createdBy" : localStorage.getItem('token'),
				"imageURL": BLOB_URL+BLOB_BOIMAGE_CONTAINER+'/'+renamedAcceptedFiles[0].name
			} 
			const blobsInContainer: string[] = await uploadFileToBlob(renamedAcceptedFiles).then(res => {
				axios.post(API_URL+"createNewBOimageData"+API_KEY_B,formData).then((res)=>{
				//axios.post("http://localhost:7071/api/createNewBoimageData",formData).then((res)=>{
					if(res.data.status == 200){
						if(localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed'){
						
							localStorage.setItem("boimageID",res.data.insertedID);
							localStorage.setItem("canvasId",res.data.insertedID);
							let obj = {"ourID":res.data.insertedID};
							setLastInsertIdData(obj);
						}
						let obj = {"ourID":res.data.insertedID};
							setLastInsertIdData(obj);
						navigate('/boimages/canvas/'+res.data.insertedID);
					}
				});
			})
	    }else{
			let formData = {
			"BOid": BOimageIdData,
			"pageType": "image",
			"imageURL": BLOB_URL+BLOB_BOIMAGE_CONTAINER+'/'+renamedAcceptedFiles[0].name
		} 
		const blobsInContainer: string[] = await uploadFileToBlob(renamedAcceptedFiles).then(res => {
			axios.post(API_URL+"update-boimage-target"+API_KEY_B,formData)
		})
			
		}

		fullScreenCheck();
		setHideOverlay("")
		setDivItems([]);
		setMobileDivItems([]);
		setMobileTag([])
		setNewMobileTag([])
		setJsonFormData([])
		setHideAlert(false)
		setDontShow(false)
		setIsUploaded(1);
		}
 });

	const hideOverlayFun = () => {
		setHideOverlay("none");
	}
	
	const handleDrag = (e, ui, BotagId) => { 
		
		//console.log("handleDrag",mousePosition);
		//console.log("handel divItem",divItems)
		//console.log("handel jsonFormData",jsonFormData)
		if(editMode){
            setEditModeBtnFun(0); 
		}
		let newDataDivItem =[];
      
		mousePosition.map(r => {
			// console.log("co-ordinates-",ui);
		 	const x = r.x + ui.x;
		    const y = r.y + ui.y;  
			
			if(BotagId === r.boTagMouse){
			    setBeaconPosition([...beaconPosition, {beaconXposition: x, beaconYposition: y, BotagId: BotagId}])
			    let ne = newMobileTag.map(r => {
			    	if(r.tagId === BotagId){
			    		r.mobileBeaconXposition = (mobileImageRef.current.clientWidth/canvasImageRef.current.clientWidth)*x;
			    		r.mobileBeaconYposition = (mobileImageRef.current.clientHeight/canvasImageRef.current.clientHeight)*y;
			    	}
			    	return r;
			    })

				setMobileDivItems(ne);	
			    setNewMobileTag(ne)		    
			    setMobileTag(ne)
                if(localStorage.getItem("status")!=100 && localStorage.getItem("status")!='Completed'){  
					const divItm = JSON.parse(localStorage.getItem("BoTagData")); 
						newDataDivItem = divItm.map(r => {
							if(r.BotagId === BotagId){
								r.beaconXposition = x;
								r.beaconYposition = y;
							}
							return r;
						})
						localStorage.setItem("BoTagData",JSON.stringify(divItm)); 
			     }
			}			
		})
		// added
		if(localStorage.getItem("status")!=100 && localStorage.getItem("status")!='Completed'  ){
			//console.log("newDataDivItem",newDataDivItem)
		//	localStorage.setItem("BoTagData",JSON.stringify(newDataDivItem));   
		
		}

	}
	/*const handleDragTag = (e, ui, BotagId) =>{
		mousePosition.map(r => {
			if(BotagId === r.boTagMouse){
				setTagPosition([...tagPosition, {BotagId: BotagId}])
				let ne = newMobileTag.map(res => {
			    	
			    	return res;
			    })
			    setMobileDivItems(ne);
			    setNewMobileTag(ne)		    
			    setMobileTag(ne)
			}
		})
	} */
	const onMouseDown = (e) => {
		
		if(divItems.length < 6){
	    
			if(editMode){
				setEditModeBtnFun(0); 
			}
	    let r = e.currentTarget.getBoundingClientRect();
	   		let rx = r.x;
			let ry = r.y;
		let beaconXposition = e.pageX - rx -8;
	    let beaconYposition = e.pageY-ry;
 			const item = divItems;
			item.push({ beaconXposition, beaconYposition, color:'-', size:'-', name:'-', BotagId: divItems.length + 1})
			setDivItems(item)
	    setMousePostion([...mousePosition, {x: beaconXposition, y:beaconYposition, boTagMouse: divItems.length }]);
	    setBeaconPosition([...beaconPosition, {beaconXposition: beaconXposition, beaconYposition: beaconYposition, BotagId: divItems.length}])
	    let mobileBeaconXposition = (mobileImageRef.current.clientWidth/canvasImageRef.current.clientWidth)*beaconXposition;
	    let mobileBeaconYposition = (mobileImageRef.current.clientHeight/canvasImageRef.current.clientHeight)*beaconYposition;
	    const mobileItems = mobileDivItems;
	    mobileItems.push({mobileBeaconXposition, mobileBeaconYposition, tagId: mobileDivItems.length + 1, color:'-', size:'-', name:'-', isLinked:false})
	    setMobileDivItems(mobileItems)
	   	setMobileTag(mobileItems)
	    setNewMobileTag(mobileItems)
			let ids = divItems.length;
			setTimeout(() => {  runFunction(ids); }, 20);
			if(!dontShow){
				setHideAlert(false)
			}
      setJsonFormData([...jsonFormData, {
			    "referenceCode": "",
			    "productID": "",
			    "name": "",
			    "color": "",
			    "size": "",
			    "tagId": divItems.length,
			    "isLinked": false,
			    "dpost": {
			        "GTINbarcode": "",
			        "productID": "",
			        "beaconXposition": "0",
			        "beaconYposition": "0"
			    },
			    "mobileBeaconXposition": 0,
			    "mobileBeaconYposition": 0
			}])    
			if(localStorage.getItem("status")!=100 && localStorage.getItem("status")!='Completed'){
				localStorage.setItem("BoTagData",JSON.stringify(divItems));
			}
			
		}
		let p = canvasProductList.map(r => {
			r.isLinked = false;
			r.BOtgaId = 0
			return r;
		})
		setCanvasProductList(p);
		setLinkedTabActive(false)
		//console.log("onMouseDown",mousePosition);
	};

	const visibilityOfProductList = (isLinked = false)=>{
		//console.log("tet visiblity")
        isVisibleProductList(isLinked);
	}
	

	const runFunction = (elementId, isLinked = false,e) =>{
		setActivePointer(false);
		/**** removeBeaconData assoicated with Botag **** */
		let daE='';
		//removeBeaconData(daE,'mouseout',elementId);
		getEvent(e,elementId);
		let p = canvasProductList.map(r => {
			r.isLinked = false;
			r.BOtgaId = 0
			return r;
		})
		
		setCanvasProductList(p);
		setElementId(elementId);
		let tagArray = [1,2,3,4,5,6]		
		tagArray.map(r => {
			let element = document.getElementById(r);

			let isLinkTag = element?.querySelector('div.custom-className')?.classList?.contains("itslinked");
			if(!isLinkTag){ 
				if(elementId == r){  
					element?.querySelector('div.notlinked')?.classList?.add("active");
					setActiveBOtags(elementId)
					setActiveProductData(null)
					
				}else{  
					element?.querySelector('div.notlinked')?.classList?.remove("active");
				}
				
			}else{ 
				if(elementId != r){
				//	element?.querySelector('div.botag-div').classList?.remove("active");
					//element?.querySelector('div.boxonimg')?.classList?.remove("active");
				}
				setActiveProductData(elementId)
			//	document.getElementById(elementId)?.querySelector('div.botag-div').classList?.add("active");
			}
		})
		setElementId(elementId);
		/*if(localStorage.getItem("status")!=100 && localStorage.getItem("status")!='Completed'){
            isVisibleProductList(isLinked);
		} */
          
		isVisibleProductList(isLinked);
		
	}
	useEffect(() => {
		//console.log("active",activeBOtags)
	},[activeBOtags])
	const removeData = (inx) => {
		 
		Swal.fire({
			title: '',
			text: "Delete BOtag "+(inx)+" ?",
			icon: 'warning',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            background: '#1d2530',
            color: '#fff',
            iconColor: "#FF9900",
            width: "500px",
            padding: "10px",
			reverseButtons: false
		  }).then((result) => {
			if (result.isConfirmed) {
				setActiveBOtags(null)
				
				let d = divItems.filter(r => {
					return r.BotagId !== inx
				})
				let f = d.map(t => {
					if(inx < t.BotagId){
						t.BotagId = t.BotagId - 1;
					}
					return t;
				})
				
				setDivItems(f);

				//added  mousePosition sam
			
				 let msp = mousePosition.filter(r => {
					return r.boTagMouse !== inx;
				});
				let upmps = msp.map(t => {
					if(inx < t.boTagMouse){
						t.boTagMouse = t.boTagMouse - 1;
					}
					return t;
				})
				setMousePostion(upmps);
				//console.log("msp--",msp)
				
				//ls added
				
				
				let md = mobileDivItems.filter(r => {
					return r.tagId !== inx
				});
				 setMobileDivItems(md)    
                 
				 if(localStorage.getItem("status")!=100 && localStorage.getItem("status")!='Completed'){
					localStorage.setItem("BoTagData",JSON.stringify(f));
				}

				//console.log("YYmobileDivItems-yty",md);
			
				let cList = canvasProductList.map(r => {
					if(r.BOtagId == (inx)){
						r.isLinked = false
						r.BOtagId = null
					}
					return r;
				})
				setCanvasProductList(cList)
			  	unlinkProductList({BOtagId: inx, type: "removeUpd"})	
			}
		})
		
	}
	const highlightTagFun = (e, BotagId) => {
		//if (e.target === e.currentTarget) {
			let tagArray = [1,2,3,4,5,6]
			tagArray.map(r => {
				let element = document.getElementById(r);
				if(BotagId == r){
					setActiveProductData(BotagId)
					//element?.querySelector("div.boxonimg").classList?.add("active");
					//element?.querySelector("div.botag-div").classList?.remove("active");
				//	element?.querySelector("div.botag-div").classList?.add("active");
				}else{
					//element?.querySelector("div.boxonimg").classList?.remove("active");
				//	element?.querySelector("div.botag-div").classList?.remove("active");
				}
			})
		//}
	}
	const resetPl = () => {
		let p = canvasProductList.map(r => {
			r.isLinked = false;
			r.BOtgaId = 0
			return r;
		})
		setCanvasProductList(p);
	}

	const hideAlertMsg = () => {
		setHideAlert(true)
	}
	const handleCheckbox = (e) => {
		setDontShow(e.target.checked)
	}
	
    const handleDraging = (event,id) => {

		//var rect = document.getElementById(id).getBoundingClientRect();
		var str = document.getElementById(id).style.transform;
		var mysty = str.split(/[()]/);
		var final =  mysty[1].split(',');
		var datx=parseInt(final[0].replace('px','').trim());
		var daty=parseInt(final[1].replace('px','').trim());
        var dats=0;
		if(datx >=70 ){
			dats =140
		}else{
            dats=0; 
		}
		document.getElementById(id).style.transform = 'translate('+dats+'px ,'+daty+'px)';
		document.getElementById(id).style.transitionDelay = '20ms';
	
     //onMouseMove
	}	
  
		// const beaconDataShows = (hs)=>{
		
		// let data =  <div id="hello1" style={{display:(hs)?"block":"none"}} >
		// 	  <p style={{"color":"red"}}>Hello</p>	 
		//   </div>
		//   return data;   
		// }
	  
	
	
	

const beaconDataShow = (hss,bids,linkedItems) =>{
	  // console.log("bids",activeBOtags,bids,linkedItems);
	if(activeBOtags != bids){

	    let data = linkedItems.filter((d)=>{
			return d.BOtagId == bids
		 });
		 
		//   console.log("data-u",jsonFormData);
		//   let data1 = jsonFormData.filter((d)=>{
		// 	return d.tagId == bids
		//  });
        

		//  let divShow = 0;

		//  if(data1.length){
        //      divShow=hss;
		//  }else{
		// 	divShow = 0; 
		//  }
		 
		//console.log("dws",linkedItems,bids,hss,data,data[0]?.selectedColor);
        let color = (data[0]?.selectedColor != undefined)? data[0]?.gtinData?.color.filter(d => {return d.color == data[0]?.selectedColor}).map(f => {return f.colorCode}) :"2e3137"; 

 let beaconDataSingle = <div className="botag-body botag-body-default product-info-box" onMouseOver={(e)=>{showBeaconData(e,'mouseover',bids)}} onMouseOut={(e)=>{showBeaconData(e,'mouseout',bids)}}  style={{"zIndex":"9","display":(hss)?"flex":"none"}}>
   <div className="flex-row">
      <img className="image-41-1" src={(data[0]?.gtinData?.image[0] != undefined) ? data[0]?.gtinData?.image[0] : noBoimage} />
      <div className="flex-col">
         <div className="brand-product-type"> {localStorage.getItem('brandName')} </div>
         <div className="product-name">
            <div className="dipper-check-s-1 opensans-semi-bold-white-14px">{(data[0]?.name != undefined ) ? data[0]?.name :"-"}</div>
         </div>
         <div className="flex-row-1">
            <div className="size-circle" title={(data[0]?.selectedSize !=undefined ) ? data[0]?.selectedSize : "t" }>{(data[0]?.selectedSize !=undefined ) ? data[0]?.selectedSize : "-"}</div>
            <div className="color-circle" style={{"backgroundColor":"#"+color}}></div>
            <div className="size-circle qty">
               <div className="number opensans-semi-bold-white-14px">1</div>
               {/* <div className="vector-container">
                   <i className="fa-solid fa-caret-up"></i>
                   <i className="fa-solid fa-caret-down"></i>
               </div> */}
            </div>
         </div>
      </div>
   </div>
   <div className="flex-col-1">
      <div className="devider "></div>
      <div className="delivery-slider">
         <div className="delivery-options pick-up ">Pick up</div>
         <div className="delivery-options courier">Courier</div>
         <div className="delivery-options shipping ">Shipping</div>
      </div>
      <div className="flex-row-2">
         {/* <div className="map-icon">
            <div className="overlap-group-2"><i className="fa-light fa-location-dot"></i></div>
         </div> */}
		 <div className="mycustomcloseOpenbx"><span className="NewBoxCompound openNowbox">Open</span></div>
         <div className="flex-col-2">
            <div className="delivery-info">
               <p className="x230m-at-store opensans-medium-white-10px">
               <span className="opensans-medium-stack-10px">(-) from</span><span className="opensans-medium-white-10px-2">&nbsp;</span><span className="opensans-medium-stack-10px">store</span></p>
            </div>
            <div className="store-name">[Store name]</div>
         </div>
      </div>
      <div className="flex-col-3">
         <div className="flex-row-3">
            <div className="devider devider-6"></div>
            <p className="kr-delivery-120">Item -kr / Delivery -kr</p>
            <div className="devider-5 "></div>
         </div>
         <div className="flex-col-4">
            <div className="pricetag ">
               <div className="x1400-kr opensans-bold-white-18px">[-kr]</div>
            </div>
            <div className="flex-row-4">
               <div className="group-3208"><i className="fa-light fa-eye"></i></div>
               <div className="group-3207"><img className="vector-28" src={Vector28} alt="Vector" />
               <img className="vector-29" src={Vector75} alt="Vector" /></div>
               <div className="buy-now-button">Buy now</div>
            </div>
         </div>
      </div>
   </div>
</div> ;

   return beaconDataSingle; 
		}
   }



    const [tim,setTim]=useState("");

	const [hs,setHs] = useState(0);
	const [bid,setBid] =useState("");
    const [timer,setTimer] = useState(0); 

	const showBeaconData = (e,data,BotagId) =>{
		
	// if(data=="mouseover"){ 
	  if(e?.type == "mouseover"){ 
			console.log("show")
			setBid(BotagId)
		 	setHs(1);
			
		} 
      // if(data=="mouseout"){
    if(e?.type == "mouseout" || e?.type == 'click'){
			setBid("");
			setHs(0);
			setTimer(0);
			window.clearTimeout(tim);
			setTim("");
			console.log("hide")
		}        
	}
	
   const removeBeaconData =  (e,data,BotagId)=>{ 
	  console.log("removeBeacon",BotagId);
	  let beaconId =  document.getElementById(BotagId);
         beaconId.removeEventListener("mouseover", showBeaconData(e,data,BotagId));
   }
 
   
  /* useEffect(()=>{
	 console.log(timer,Date.now(),bid);
      if(timer){
		 if(Date.now() > timer)
		 {   let e =1;
			 let ms = "mouseover";
            showBeaconData(e,ms,bid);
		 }
	  }   
   },[timer,Date.now(),bid]);  
 */  
  
   const showOnMOuseHover = (e,timer,BotagId) =>{
	if(timer){
		if(Date.now() > timer)
		{  
			let ms = "mouseover";
		   showBeaconData(e,ms,BotagId);
		}
	 }   
   }
   

   const hoverBeaconData =  (e,data,BotagId)=>{ 
	  let timing = Date.now() ;
	  let tims = window.setTimeout(function(){
		setTimer(timing);
		setBid(BotagId);
		showOnMOuseHover(e,timing,BotagId);
	  }, 2000);
      
	  setTim(tims);
	   
   } 

 const getEvent = (e,botagId) =>{
          //console.log("event type",e.type);
		  if(e?.type == 'click'){
			setTimer(0);
			window.clearTimeout(tim);
			setTim("");
			removeBeaconData(e,'mouseout',botagId)
		  }
		  if(e?.type == 'mouseover'){
			hoverBeaconData(e,'mouseover',botagId) 
		  }
		  if(e?.type == 'mouseout'){
			showBeaconData(e,'mouseout',botagId) 
		  }
 }


 const thumbs = files.map(file => { 
	return(
		<div className="imageupdates" key={file.name}>
			<div className="overlaytext" style={{"display":hideOverlay}}>
				<div className="textoverlay">
				<span><i className="fa-thin fa-hand-back-point-up"></i></span>
				 <p>Click over each item you want to <span className="makeitboldlittle">BO</span>tag.</p>
					<p>Then link each BOtag to an item on the list.</p>
				</div>
			</div>
			<div  className="image-drag">
			   <div ref={imageBoundry} >
				<figure style={{"position":"relative"}}>
					<img src={file.preview} className={dynamicClass} ref={canvasImageRef} onMouseDown={(dataReadonly)?"":onMouseDown} onLoad={() => { URL.revokeObjectURL(file.preview);onImgLoad() }}/>	
						{ 
							divItems?.map((r, i) => {  // console.log("postion:",r)
								return(
									<Draggable defaultClassNameDragging={"draggingit"} handle=".shownumber" onStop={(e, ui) => handleDrag(e, ui, r?.BotagId)}  bounds="parent" key={i} disabled={(dataReadonly)?true:false} onDrag={(e)=>{setShowProductList('none');removeBeaconData(e,'mouseout',r?.BotagId);}}  >
										<div id={r?.BotagId}   onClick={(e) => (dataReadonly)?"":runFunction(r?.BotagId, r?.isLinked,e)} onMouseOver={(e)=>{getEvent(e,r?.BotagId)}} onMouseOut={(e)=>{getEvent(e,r?.BotagId)}}  style={{"position":"absolute","zIndex":"1","left":r?.beaconXposition+"px", "top":r?.beaconYposition+"px"}} >
											<div className={(r?.isLinked)?"custom-className itslinked":"custom-className notlinked"} >
											
											<div className="shownumber"  >{r?.BotagId}
											<span className="closeboximg" onClick={()=> (dataReadonly)?"":removeData(r?.BotagId)} ><Link to="#"><i className="fas fa-times" aria-hidden="true"></i></Link></span></div>
										</div>
										</div>
									</Draggable>
								)
							})
						}
				</figure>
			</div>
			</div>
		</div>
	)
})



 {/*

	const thumbs = files.map(file => { 
		return(
			<div className="imageupdates" key={file.name}>
				<div className="overlaytext" style={{"display":hideOverlay}}>
					<div className="textoverlay">
					<span><i className="fa-thin fa-hand-back-point-up"></i></span>
					 <p>Click over each item you want to <span className="makeitboldlittle">BO</span>tag.</p>
						<p>Then link each BOtag to an item on the list.</p>
					</div>
				</div>
				<div  className="image-drag">
				   <div ref={imageBoundry} >
					<figure style={{"position":"relative"}}>
						<img src={file.preview} className={dynamicClass} ref={canvasImageRef} onMouseDown={(dataReadonly)?"":onMouseDown} onLoad={() => { URL.revokeObjectURL(file.preview);onImgLoad() }}/>	
							{ 
								divItems?.map((r, i) => {  // console.log("postion:",r)
									return(
										<Draggable defaultClassNameDragging={"draggingit"} handle=".shownumber" onStop={(e, ui) => handleDrag(e, ui, r?.BotagId)}  bounds="parent" key={i} disabled={(dataReadonly)?true:false} onDrag={(e)=>{setShowProductList('none');removeBeaconData(e,'mouseout',r?.BotagId);}}  >
											<div id={r?.BotagId}   onClick={() => (dataReadonly)?"":runFunction(r?.BotagId, r?.isLinked)} onMouseOver={(e)=>{hoverBeaconData(e,'mouseover',r?.BotagId)}} onMouseOut={(e)=>{showBeaconData(e,'mouseout',r?.BotagId)}}  style={{"position":"absolute","zIndex":"1","left":r?.beaconXposition+"px", "top":r?.beaconYposition+"px"}} >
												<div className={(r?.isLinked)?"custom-className itslinked":"custom-className notlinked"} >
												
												<div className="shownumber"  >{r?.BotagId}
												<span className="closeboximg" onClick={()=> (dataReadonly)?"":removeData(r?.BotagId)} ><Link to="#"><i className="fas fa-times" aria-hidden="true"></i></Link></span></div>
											</div>
											</div>
										</Draggable>
									)
								})
							}
				    </figure>
				</div>
				</div>
			</div>
		)
	}) */}







//	const preThumb = <div></div>; onDoubleClick={ () =>(dataReadonly)?"":visibilityOfProductList(r?.isLinked)}

	const linkedTagFunction = async(formData, linkedData) => {
		let daat = await getGTINBarcode(formData);
		//console.log("resultof",daat)
		/*******daat is set in getGTINBarcode************ */
		if(daat){ 
		setActiveBOtags(null)
		let d = divItems.map(r => {
			if(r.BotagId === formData.tagId){
				r.color = formData.color;
				r.size = formData.size;
				r.name = formData.name;
				r.isLinked = formData.isLinked;
				//getGTINBarcode(formData)
				linkedData["isLinked"] = formData.isLinked
				linkedData["BOtgaId"] = formData.tagId
				linkedData["selectedColor"] = formData.color
				linkedData["selectedSize"] = formData.size
				linkedData["idData"] = BOimageIdData
				storeLinkedData(linkedData, formData.tagId)
			}
			return r;		
		})
		setDivItems(d);
		let o = newMobileTag.map(p => {
			if(p.tagId === formData.tagId){
				p.color = formData.color;
				p.size = formData.size;
				p.name = formData.name;
				p.isLinked = formData.isLinked;
			}
			return p;
		})
		setMobileTag(o);
		setNewMobileTag(o);
		if(!formData.isLinked){
			let upData = jsonFormData.forEach(r => {
				if(r.tagId != formData.tagId){
					return r;
				}
			})
			setJsonFormData(upData)
		}
	  }else{
		toast.error("No product found for the seleted combination of color and size.", {
			theme: "dark",
			icon: ({theme, type}) =>  <img src={errorWarning}/>
		}); 
	  }
		
	}
	const storeLinkedData = async (d, BotagId) => { 
		d.BOtagId = BotagId
		//console.log(d);
		addDataIntoCache("linkedDataList", window.location.origin,d);
		const ele = document.getElementById(BotagId);

		toast.success("You have successfully created BOtag "+BotagId+" with "+d.referenceCode+". You can check linked products on the 'Linked product list'.", {
			theme: "dark",
			icon: ({theme, type}) =>  <img src={errorSuccess}/>
		}); 
		setActivePointer(true)

	}
	const updateLinkedDataListAllColor = (d) =>{
	    
		/*
		if(!updatedDataLinklist.length && updatedDataLinklist !=undefined){
				updatedDataLinklist.push(d);	
			}else{
   			let newArr =   updatedDataLinklist.filter((k,i)=>{
						if(k.BOtagId!=d.BOtagId && k.idData==d.idData ){
							return k					
						}        
				});
				newArr.push(d);
               setUpdatedDataLinklist(newArr);		
			}  */
			
            updateLinkedColorData(d)
			setEditModeBtnFun(0);
	 }
	 
     const updateLinkedDataListAllSize = (d) =>{
		
            updateLinkedSizeData(d)
			setEditModeBtnFun(0);
	 }

	 const updateLinkedSizeData = async(res) =>{
		let cacheData = await getSingleCacheData("linkedDataList",window.location.origin);     
        
		let dataC =	cacheData.map((dc)=>{
			if(dc.BOtagId  == res.BOtagId){
			   dc.selectedSize = res.selectedSize;
			}
			return dc;
	   });
	   updDataIntoCache("linkedDataList",window.location.origin,dataC);
         
	   let dataU = dataC.filter((item)=>{  
			
		if(item.BOtagId == res.BOtagId)
		{ return item;					
		}
   }).map((item)=>{
	   return  {"color":item.selectedColor,"size":item.selectedSize,"productID":item.productId,"BotagId":res.BOtagId}

   });
   				setDataColorSize({});
   const dat= getGTINBarcodeForUpdate(dataU[0]); 



	 }
     
	 const updateLinkedColorData = async(res) =>{
		let cacheData = await getSingleCacheData("linkedDataList",window.location.origin);     
        
		let dataC =	cacheData.map((dc)=>{
			if(dc.BOtagId  == res.BOtagId){
			   dc.selectedColor = res.selectedColor;
			}
			return dc;
	   }); 
	   updDataIntoCache("linkedDataList",window.location.origin,dataC);

           let dataU = dataC.filter((item)=>{  
			
				 if(item.BOtagId == res.BOtagId)
				 { return item;					
				 }
			}).map((item)=>{
                return  {"color":item.selectedColor,"size":item.selectedSize,"productID":item.productId,"BotagId":res.BOtagId}

			});
			   setDataColorSize({});
            const dat= getGTINBarcodeForUpdate(dataU[0]); 
           

	 }
	

	 useEffect(()=>{
	  
           if(Object.keys(dataColorSize).length != 0 && dataColorSize.GTINbarcode !=undefined){
		              
			let dataM =	jsonFormData?.map((dc)=>{
				

						if(dc?.tagId  == dataColorSize?.BOtagId){ 
						
							dc.dpost.GTINbarcode = dataColorSize.GTINbarcode;
							dc.dpost.productID = dataColorSize.productID;
							dc.color = dataColorSize.selectedColor;
							dc.size = dataColorSize.selectedSize;

							dc.selectedColor = dataColorSize.selectedColor;
							dc.selectedSize = dataColorSize.selectedSize;
					 	}
						return dc
				   }); 
				//   console.log("jsonFormData",jsonFormData);  
		   }
	 },[dataColorSize])

	 const getGTINBarcodeForUpdate = async (data) => {
		 const dataSM = await axios.post(API_URL+"getProductListByColorSizeProductId"+API_KEY_B,data).then((res)=>{
			if(res.data.status==200){
			   if(typeof res.data?.data?.barcode != "undefined" && res.data?.data?.barcode !="-" ){
					let ds = {
						"GTINbarcode": res.data.data.barcode,
						"productID": res.data.data.productID,
						"BOtagId":data.BotagId,
						"selectedColor":data.color,
						"selectedSize":data.size
					}
					setDataColorSize(ds)
				}else{
					toast.error("No product found for the seleted combination of color and size.", {
						theme: "dark",
						icon: ({theme, type}) =>  <img src={errorWarning}/>
					}); 
				}
			
			}

		});
		
	}
	 




/*	useEffect(() => {
		setActiveBOtags(null)
	},[linkedListItems])*/

	useEffect(() => {
		let d = jsonFormData.map((r) => {
			let index = mobileDivItems.findIndex(x => x.BotagId === r.tagId);
			r.mobileBeaconXposition = (mobileDivItems[index]?.mobileBeaconXposition)?mobileDivItems[index]?.mobileBeaconXposition:0;
			r.mobileBeaconYposition = (mobileDivItems[index]?.mobileBeaconYposition)?mobileDivItems[index]?.mobileBeaconYposition:0;
			return r;
		})
	})
	
	/*
	const pagination = (e, type) => {
		e.preventDefault()
		let mData = [];
		if(type === "next"){
			newMobileTag.slice(3, 7).map((item, i) => {
			  mData.push(item);
			});
		}else{
			newMobileTag.slice(0, 3).map((item, i) => {
			  mData.push(item);
			});
		}
		setMobileTag(mData)
	}
       */

  

	const getGTINBarcode = async (data) => {  
		let daat=false;
		
		let dataReturn = await axios.post(API_URL+"getProductListByColorSizeProductId"+API_KEY_B,data).then((res)=>{
			if(res.data.status==200){
			  //  console.log("getGTIN",data);
			
				if(typeof res.data?.data?.barcode != "undefined" && res.data?.data?.barcode !="-" ){
					jsonFormData.map(r =>{
						if(r?.tagId==data.tagId){
						 r.dpost.GTINbarcode= res.data.data.barcode;
						 r.dpost.productID = res.data.data.productID;
						 //sam 
                         r.selectedColor = res.data.data.color;
						 r.selectedSize = res.data.data.size;
						} 	 
				   });
				
				   daat = true;
				}else{
					daat = false;
				}
			}
			else{
				daat = false;

			}

			return daat;
		});
		return dataReturn;
	}

/*	const updateBeaconData = async () => {
		if(divItems.length > 0)
		 { 
			
			const beaconArray = beaconPosition.filter((r) => {
				if(r.BotagId){
					return r;
				}
			})
			var dataArr = beaconArray.map(item=>{
			   return [item?.BotagId,item]
		   });
		   var maparr = new Map(dataArr);
		   var result = [...maparr.values()];		
			let dsPost = [];
			jsonFormData.forEach(r => {
				let index = result.findIndex(x => x.BotagId === r.tagId);
				r.dpost.beaconXposition = (result[index]?.beaconXposition)?result[index]?.beaconXposition:0
				r.dpost.beaconYposition = (result[index]?.beaconYposition)?result[index]?.beaconYposition:0
				r.dpost.BotagId = r.tagId
				dsPost.push(r.dpost)
			})
			let formData = {
			   "BOid": BOimageIdData,
			   "BOtags": dsPost,
			   "pageType": "canvas",
			   "imageURL": BLOB_URL+BLOB_BOIMAGE_CONTAINER+'/'+files[0].name,
			   "renderedIamge":{
				   "height": canvasImageRef.current.clientHeight,
				   "width": canvasImageRef.current.clientWidth
			   }
		   }
		 
			   await axios.post(API_URL+"update-boimage-target"+API_KEY_B,formData).then((res)=>{
				   if(res.data.status == 200){
					//localStorage.removeItem("BoTagData");
					
					
				   }else{
					
				   }
				  
			   })
		   
		 }
		 return true; 	
		} */

	const setDataForLinkProduct = (data, color, size, colorReferenceCode, sizeReferenceCode) => {

		if(!data.isLinked){
			if(activeBOtags){
				if(color && size && (colorReferenceCode == sizeReferenceCode && data.referenceCode == sizeReferenceCode && data.referenceCode == colorReferenceCode)){
					let forData = {
						"referenceCode": data.referenceCode,
						"productID": data.productId,
						"name": data.name,
						"color": color,
						"size": size,
						"tagId": activeBOtags,
						"isLinked": true
					}
					data["BOtgaId"] = activeBOtags
					linkedTagFunction(forData, data);
					if(editMode){
						setEditModeBtnFun(0);
					}
				   
				}else{
					toast.error("Please select color & size", {
						theme: "dark",
						icon: ({theme, type}) =>  <img src={errorWarning}/>
					});
				}
			}else{
				toast.error("Please select a BOtag pin on the image first. A pin is selected when green.", {
					theme: "dark",
					icon: ({theme, type}) =>  <img src={errorWarning}/>
				});
			}

		}else{
			let forData = {
				"referenceCode": data.referenceCode,
				"name": "-",
				"color": "-",
				"size": "-",
				"productID": data.productId,
				"tagId": data.BOtgaId,
				"isLinked": false
			}
			linkedTagFunction(forData, data);
			let dData = canvasProductList.map( r => {
				if(r.referenceCode == data.referenceCode){
					r.isLinked = false
					r.BOtgaId = activeBOtags
					r.selectedColor = color
					r.selectedSize = size
				}
				return r;
			})
			
			let linkedListData = dData.filter( res => {
				return res.isLinked
			})
			setLinkedListItems(linkedListData.sort((a, b) => (a.BOtgaId > b.BOtgaId) ? 1 : -1))
		}
			//updateBeaconData();
			//linkedTabActiveFunction(false);
	 

	}

    
	
	const resetValue = (e) =>{ 
		if(e.target.value==""){
		  setCanvasProductList(canvasProductListNew);   
		  setLinkedListItems(linkedListItemsNew);
		  setDataForSearch(""); 
		}else{
		  setDataForSearch(e.target.value);
		}
	}
	const resetValueByClose = () =>{
		//console.log("canvasProductListNew",canvasProductListNew);
		
		//msgsData(1);
		setTimeout(()=>{
			setCanvasProductList(canvasProductListNew);
			setLinkedListItems(linkedListItemsNew);
			setDataForSearch("");
		},500)
	}
	
	
	 const FiletrData = (e) =>{
		
		//e.preventDefault();
	   	setCanvasProductList(canvasProductListNew);
		if(dataForSearch!=""){   
			if(canvasProductList.length>0){
					const filteredByValue= Object.fromEntries(Object.entries(canvasProductList).filter(([key, value]) => value.name.toUpperCase().includes(dataForSearch.toUpperCase())));
					if(Object.keys(filteredByValue).length!=0){
						let data = Object.keys(filteredByValue).map(function(key){return filteredByValue[key]});	
						setCanvasProductList(data) ;			
					}else{
						const filteredByValue= Object.fromEntries(Object.entries(canvasProductList).filter(([key, value]) => value.referenceCode.toUpperCase().includes(dataForSearch.toUpperCase())));
						if(Object.keys(filteredByValue).length!=0){
							let data = Object.keys(filteredByValue).map(function(key){return filteredByValue[key]});	
							setCanvasProductList(data) ;			
						}else{
						setCanvasProductList([]) ;
						setMsgsData(0);
						}
					}
	    	}

			if(linkedListItems.length>0){
				const filteredByValueNew= Object.fromEntries(Object.entries(linkedListItems).filter(([key, value]) => value.name.toUpperCase().includes(dataForSearch.toUpperCase())));
				if(Object.keys(filteredByValueNew).length!=0){
					let data = Object.keys(filteredByValueNew).map(function(key){return filteredByValueNew[key]});	
					setLinkedListItems(data) ;			
				}else{
					const filteredByValueNew= Object.fromEntries(Object.entries(linkedListItems).filter(([key, value]) => value.referenceCode.toUpperCase().includes(dataForSearch.toUpperCase())));
					if(Object.keys(filteredByValueNew).length!=0){
						let data = Object.keys(filteredByValueNew).map(function(key){return filteredByValueNew[key]});	
						setLinkedListItems(data) ;			
					}else{
						setLinkedListItems([]) ;
					}
				}
			}
		} 		
 	}
	


 	
  const saveAndNext = async () => {
  	if(divItems.length > 0){ 
	  	setOnSubmitLoader(true)
			setButtonGray(true);
 			const beaconArray = beaconPosition.filter((r) => {
	 			if(r.BotagId){
	 				return r;
	 			}
	 		})
	 		var dataArr = beaconArray.map(item=>{
		    return [item?.BotagId,item]
			});
			var maparr = new Map(dataArr);
			var result = [...maparr.values()];		
			
			//console.log("result",result)

 			let dsPost = [];
 			jsonFormData.forEach(r => {
	 			let index = result.findIndex(x => x.BotagId === r.tagId);
				//console.log("removeItem",index);
	 			r.dpost.beaconXposition = (result[index]?.beaconXposition)?result[index]?.beaconXposition:0
	 			r.dpost.beaconYposition = (result[index]?.beaconYposition)?result[index]?.beaconYposition:0
	 			//r.dpost.BotagId = r.tagId
	 			dsPost.push(r.dpost)
 			})
                 
         //   console.log("dsPost--",dsPost);
			 let dsPostData  = dsPost.map(i=>{
               delete i.BotagId;
			   return i;
			 });

			// console.log("dsPostData--",dsPostData);
             
			//console.log("saveAndNext Dpost",dsPost);
	 		let formData = {
				"BOid": BOimageIdData,
				"BOtags": dsPost,
				"pageType": "canvas",
				"imageURL": BLOB_URL+BLOB_BOIMAGE_CONTAINER+'/'+files[0].name,
				"renderedIamge":{
					"height": canvasImageRef.current.clientHeight,
					"width": canvasImageRef.current.clientWidth
				}
			}
			await axios.post(API_URL+"update-boimage-target"+API_KEY_B,formData).then((res)=>{
				if(res.data.status == 200){
					props.loginFun(localStorage.getItem('token'), '70');
					localStorage.removeItem("BoTagData");
                     if(editMode){
						setMsgs("BOimages Updated");
						setEditModeBtnFun(1);
					 }

					toast.success(msgs,
					{
						theme: "dark",
						icon: ({ theme, type }) => <img src={errorSuccess} />,
						onClose: () => {
							if(!editMode){
							navigate('/boimages/target/'+BOimageIdData);
							}else{
								setDataReadonly(false);
								setEditMmodeTrg(1);
								setButtonGray(0);
							}
						}
					}); 
				}else{
					setButtonGray(false);
				}
				setOnSubmitLoader(false)
			})
		//})
	  } 	
 	}
    

     /* const saveWholeData = async()=>{
		if(updatedDataLinklistSize.length || updatedDataLinklist.length){
			updateLinkedSizeAndColor();
		}
		saveAndNext();
	  } */



    

	 const updateColorData = (data, color) => {  
         		
		let formData = {
			"idData": BOimageIdData,
			"BOtagId": data.BOtagId,
			"selectedColor": color,	
		}	
			//updateLinkedData(formData);
			updateLinkedDataListAllColor(formData)
	   }

	   const updateSizeData = (data, size) => {  
		
		let formData = {
			"idData": BOimageIdData,
			"BOtagId": data.BOtagId,
			"selectedSize": size,
			
		}

			updateLinkedDataListAllSize(formData)
	   }








useEffect(() => {
	if(divItems.length === 0){
		//setProductVisible('none')
	}
},[divItems]) 

const targetImageRef = useRef();
const [imageData, setImageData] = useState("");
const onImgLoad = (e) => {
	let height = canvasImageRef.current.clientHeight / renderData?.height;
	let width = canvasImageRef.current.clientWidth / renderData?.width;
	//console.log(canvasImageRef.current.clientHeight, canvasImageRef.current.clientWidth);
	if(canvasImageRef.current.clientWidth > canvasImageRef.current.clientHeight){
		setDynmicClass("dynamic-width")
	}else{
		setDynmicClass("dynamic-height")
	}
	setRatio({ h: height, w: width });
}
const [renderData, setRenderData] = useState("");
const [lastInsertIdData2, setLastInsertIdData2] = useState("");
const [ratio, setRatio] = useState({ h: 0, w: 0 });
let fileName = lastInsertIdData2?.imageURL?.split('/').at(-1);

useEffect(() => {

	if(lastInsertIdData?.BOtags!=undefined && lastInsertIdData?.BOtags.length){
		if(loadOneTimeLinkedData){   
		
      	let liData = [];
		lastInsertIdData?.BOtags.forEach((_p,_i) => {
		
			let fDa = canvasProductList.forEach((v,i) => {	
				  let k = Object.assign({},v);
				
				  if( k?.productId == _p?.productID  ){
				//	k.BOtagId = _p?.BotagId
					k.BOtagId = _i+1
					k.selectedColor = _p?.color
					k.selectedSize = _p?.size
					k.idData = BOimageIdData
					liData.push(k);
				}
				
			}) 
		});

		if(liData.length){
			updDataIntoCache("linkedDataList",window.location.origin,liData);
		}
		
	}
 }
	
},[canvasProductList, lastInsertIdData])
	return(
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData}/>
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper canvasPage">
				{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
				    <div className="controlbar">
				    	<div className="productsearch leftsidetr">
							<Link data-widget="search" className="button-not-active" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
								<span className="searchprodicttext">BOimages</span>
							</Link>
				        </div>
						<div className="centerbtn">
							<Link to={((localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed' ))?"/boimages":"#"} className={(localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed')?"btn btn-success saveIt":" btn btn-success button-not-active"}>Boimages</Link>
							<Link to="#" className={(editMode)?"btn btn-success active ":"btn btn-success button-not-active"}>Edit Boimage</Link>
							{/* <Link to="/boimages/canvas" className={(editMode)?"btn btn-success saveIt":"btn btn-success active"}>New Boimage</Link> */}
							<Link to={((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed' ))?"":"/boimages/canvas"} className={(editMode)?"btn btn-success saveIt":"btn btn-success active"} onClick={((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed' ))?"":newBoimageFun} >New Boimage</Link>
						</div>
                             

						<div className="rightcontrolserch">

							<span className="extraBtns">
									<div className="productsearch rightareas  notactive">
										<Link data-widget="search" to="#" role="button">
											<span className="btn btn-success "><i className="fa-thin fa-list"></i></span>
											<span className="searchprodicttext">List</span>
										</Link>
									</div> 
									<div className="productsearch rightareas notactive">
										<Link to="#" role="button">
											<span className="btn btn-success"><i className="fa-thin fa-grid"></i></span>
											<span className="searchprodicttext">Grid</span>
										</Link>
									</div>    
									
							</span> 

							{/*<div className="productsearch rightareas saveIt">
								 <Link data-widget="search" to={"/boimages/creater/"+BOimageIdData} role="button">
									<span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link> 
							</div>*/}
							<div className="productsearch rightareas saveIt">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-diagram-lean-canvas"></i></span>
									<span className="searchprodicttext">Canvas</span>
								</Link>
							</div> 
							
							<div className={(imgUrlCk || editModeTrg)?"productsearch rightareas saveIt":"productsearch rightareas notactive"}>
								<Link data-widget="search" to={(imgUrlCk || editModeTrg)?(typeof BOimageIdData !== "undefined")?"/boimages/target/"+BOimageIdData:"#":"#"} role="button">
							 {/* <div className={(editMode)?"productsearch rightareas saveIt":"productsearch rightareas notactive"}>
								<Link data-widget="search" to={(editMode)?(typeof BOimageIdData !== "undefined")?"/boimages/target/"+BOimageIdData:"#":"#"} role="button"> */}
									<span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link>
							</div>
						</div>
					</div>
					{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:
                <> <div ref={refWarp}>	
					<div className="filtersarea creators">
						<div className="container-fluid">
							<div className="row">			          
								<div className="col-md-12">
									<div className="filtersection creatorsbtnset ">		
										<div className="nobreadcumbleft">
											<div className="labelgreentext">Creating BOimage: <span className="itvalue">{lastInsertIdData?.name}</span></div>
											<div className="labelgreentext">BOimage ID: <span className="itvalue">{lastInsertIdData?.ourID}</span></div>
										</div>	
										<div className="custom-legends">
											<span className="circlebox"><span className="legend-design redlegends"></span>BOtag without product</span>
											<span className="circlebox"><span className="legend-design greenlegends"></span>BOtag selected</span>
											<span className="circlebox"><span className="legend-design bluelegends"></span>BOtag with product</span>
										</div>            	
										<span className={(jsonFormData.length)?"customeset textinside next-new-btn":"customeset textinside next-new-btn disabledbtn"}>
										{/* <button type="submit" onClick={saveAndNext} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun)?"disabled":((jsonFormData.length)?((buttonGray)?"disabled":""):"disabled")}>{buttonName} {(buttonGray)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-chevron-right"></i>)}</button>                                        */}
											<button type="submit" onClick={saveAndNext} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun)?"disabled":((jsonFormData.length)?((buttonGray)?"disabled":""):"disabled")}>{buttonName} {(buttonGray)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-chevron-right"></i>)}</button>                                       
										</span> 
										<span className="customeset textinside upload-image">
										{/* <button type="submit" onClick={open} className="btn btn-success" disabled={(dataReadonly || (editMode && isUploaded))?"disabled":""} ><i className="fa-thin fa-upload" aria-hidden="true"></i> Upload Image</button>                                        */}
										<button type="submit" onClick={ConfirmUploadNew	} className="btn btn-success" disabled={(dataReadonly || (editMode && isUploaded))?"disabled":""} ><i className="fa-thin fa-upload" aria-hidden="true"></i> Upload Image</button>                                       
										</span>                  
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="canvasarea">			        
	                  	<div className="container-fluid">
					  		<div className="row">
					  			<div className="col-md-12">
					  				<div className="cardsection">
					  					<div className="card-body">
							            	<div className="row">
                                             
                                               <>
								            	<div className="col-md-9">
							            			<div className="form-group">
														<label htmlFor="tobrand">
															<i className="fas fa-info-circle"></i> <span className="boldtitleinline">BO</span>image canvas
														</label>
													</div>
													{/* <div id="hello" style={{display:(hs)?"block":"none"}}>
														<p style={{"color":"red"}}>Hello</p>
													     
													</div> */}
													     
															{/* {beaconDataShows(hs)} */}
															{beaconDataShow(hs,bid,linkedListItems)}
													<div className="custom-upload">
														{(!files.length)?
														<div {...getRootProps({className: 'dropzone'})}>
													    	<input {...getInputProps()} />
													    	<div className="textparar">
													    		<h3><i className="fa-thin fa-upload" aria-hidden="true"></i></h3>
													    		<p>Click UPLOAD IMAGE to upload the image you want to<br />power with the BOtag technology.</p>
													  			<p>Logo is added automatically.</p>													  			
													  		</div>
													  	</div>:""}
													  	<aside onMouseDown={hideOverlayFun} className={(files.length)?"map-height":""}>
															{thumbs}
															
														</aside>
													</div>
													{
														(files[0]?.name)?<span style={{"color":"#A6A6A6"}} className="file-name-canvas">File: {files[0]?.name}</span>:""
													}
													
												</div></> 
												
												<div className="col-md-3">
													<div className="form-group mobile-top-labels">
														<label htmlFor="tobrand">
															<i className="fas fa-info-circle"></i> Preview on mobile
														</label>
													</div>
													<div className="mobilescreens">
													  {(!isUploaded)?<div className="mobile-default-icon" >
													  	<i className="fa-thin fa-image"></i>
													  </div>:
														<div className="leftimageshow">
														
															
															<div className="compundlogoandbrand">
															{
																(files[0]?.preview)?
																<>
																 {/* <img className="brandnamelogo" src={BrandLogo} /> */}
																<img className="brandnamelogo" src={brandLogo} />
																<figure style={{"position":"relative"}}>
																	<img className="leftrealimg" ref={mobileImageRef} src={files[0]?.preview} onLoad={() => { URL.revokeObjectURL(files[0].preview);plotBecons(); }}/>	
																	{   
																		mobileTag?.map((r, i) => {  
																			return(
																				<div id={i+1} className={r.isLinked?"itslinked":"notlinked"} style={{"position":"absolute","zIndex":"1","left":r.mobileBeaconXposition+"px", "top":r.mobileBeaconYposition+"px"}} key={i}>
																					<div className="shownumber">{r.tagId}</div>
																				</div>
																			)
																		})
																	}
																
																</figure>
																</>:""
															}
															</div>
														</div>
														  }
													</div>
												</div>
											</div>
										</div>
									</div>
				            	</div>
				            </div>	
				        </div>
				        <ProductList 
						isVisible={productVisible}
						setShowProductList = {setShowProductList}
						canvasProductList = {canvasProductList}
						setDataForLinkProduct = {setDataForLinkProduct}
						activeBOtags = {activeBOtags}
						linkedListItems = {linkedListItems}
						highlightTagFun = {highlightTagFun}
						activeProductData = {activeProductData}
						resetValue={resetValue}
						resetValueByClose={resetValueByClose}
						FiletrData={FiletrData}
						inputField = {dataForSearch}
						activateFun={runFunction}
						elementId={elementId}
						unlinkProductList = {unlinkProductList}
						resetPl = {resetPl}
						linkedTabActive = {linkedTabActive}
						linkedTabActiveFunction = {linkedTabActiveFunction}
						updateColorData= {updateColorData}
						updateSizeData= {updateSizeData}
						activePointer = {activePointer}
						msg={msgsData}
						/>
				    </div>
					  {/* <div className="opencontrolright">
						<div className="itemspanelblue">
						   <Link to="#" onClick={() => isVisibleProductList(false)} className="btn btn-success">Items Panel <i className="fa-thin fa-chevron-up"></i></Link>
						</div>
					  </div>	 */}
				   </div>
					</>}
				</>}
				</div> 
				<BrandFooter logo={props.imageData.footer_logo}/>
			</div>
			{(onSubmitLoader)?<div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>:""}
		</>
	)
}
export default Canvas;