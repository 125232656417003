import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import { API_URL,API_KEY_B ,BLOB_URL,BLOB_STAFF_CONTAINER} from './../../config/constant.js';
import './../../assets/css/inputTags.css';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import axios from 'axios';
import { useBeforeunload } from 'react-beforeunload';
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning"
import DataTable, { createTheme } from 'react-data-table-component';
import Swal from 'sweetalert2';
import uploadFileToBlob, { isStorageConfigured } from './blob';
import StepCombination from '../layouts/commonLayout';
toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - Invite Staff';
	const ref = useRef();

    let navigate = useNavigate();
    const [status, setStatus] = useState("");
   	const [defaultOption, setDefaultOption] = useState({})
   	const { BOimageIdData } = useParams();
	const [elementData, setElementData] = useState("");
	const [buttonGray, setButtonGray] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
    const [role,setRole] = useState("");
    const [roleOption,setRoleOption] =useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileCode, setMobileCode] = useState({ value: '+47', label: '+47' });
    const [countryMobileCodeOption, setCountryMobileCodeOption] = useState({});
    const [addTo,setAddTo] =useState("");
    const [addToOption, setAddToOption] = useState({});
    const [files, setFiles] = useState("");
	const [uploadFileName, setUploadFileName] = useState({});
  

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
	const [roleError, setRoleError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
	const [mobileCodeError ,setMobileCodeError] = useState(false);
	const [emailError, setEmailError] = useState(false); 
    const [addToError,setAddToError] =useState(false);
	const [imgError,setImgError] =useState(false);

    const [showLoader, setShowLoader] = useState(0);

	const [buttonDisable, setButtonDisable] = useState(false);
	useEffect(() => {
		setStatus(props?.compData?.completePercentage)
	 },[props?.compData]); 

	 

	let {id} = useParams();
	
	useEffect(()=>{
		checkStoreID(id);	
	},[id]); 
     

	const checkStoreID  = (id) => {
	          let formData ={"id":id} 
		
			 axios.post(API_URL+"get-store-info"+API_KEY_B,formData).then((res)=>{
				if(res.data.status != 200){
				   navigate("/store");
				}else{
					const setAddToOption1=[];
					var datas =res.data.data;
					setDefaultOption({ value: datas.ourID, label: datas.name});
					setAddToOption1.push({ value: datas.ourID, label: datas.name});  
					setAddToOption(setAddToOption1);
					setAddTo(datas.ourID)	
				}
		   })  
	} 

    useEffect(() => {
        getMobileCountoryCode();  
		getRoleData();	
	}, [props]);


	

   // const [mobileCode, setMobileCode] = useState({ value: '+47', label: '+47' });	

    


   const colourStyles = {
	control: (styles, state) => ({
		...styles,
		backgroundColor: '#10141B',
		borderColor: "transparent",
		border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
		boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
		"&:hover": {
			border: "1px solid #0B0",
		},
		"&:active": {
			border: "1px solid #0B0",
		}
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: '#205380',
			color: '#a6a6a6',
			cursor: 'pointer',
			border: "1px solid #10141B",
			borderLeft: "1px solid transparent",
			borderRight: "1px solid transparent",
			"&:hover": {
				border: "1px solid #00AEEF",
			},
			"&:active": {
				backgroundColor: "#205380",
			}
		};
	},
	indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
	menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
	menuList: styles => ({...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
			    borderRadius: "10px"
			}
		}),
	singleValue: styles => ({ ...styles, color: '#a6a6a6' }),
	input: styles => ({ ...styles, color: '#a6a6a6' }),
	multiValueLabel: (styles, { data }) => ({
	...styles,
	color: "#fff",
	backgroundColor: "#205380",
	borderRadius: "0px",
  }),
  multiValueRemove: (styles, { data }) => ({
	...styles,
	color: "#fff",
	backgroundColor: "#205380",
	borderRadius: "0px",
':hover': {
  backgroundColor: "#205380",
  color: 'white',
},
}),
};
	



    const phonenumber = (inputtxt) =>
	{
	  var phoneno = /^\d{10}$/;
	  if(inputtxt.match(phoneno))
			{
		  return true;
			}
		  else
			{
			return false;
			}
	}

	function ValidateEmail(mail) 
		{
		  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
			{
				return true;
			}
			else{
				return false;
			}
		}
     


	const handleValidation = () => {
		let formIsValid = true;
		if (firstName == "") {
			setFirstNameError(true)
			formIsValid = false;
		}
       
        if(mobileCode==""){
			setMobileCodeError(true);
				formIsValid = false;
		}else{
			setMobileCodeError(false);
		}
		
		if (role == "") {
			setRoleError(true);
			formIsValid = false;
		}
        if (addTo == "") {
			setAddToError(true);
			formIsValid = false;
		}
		
        if(mobile==""){
			
		//	setMobileError(true);
		//		formIsValid = false;
		}else{
			if(!phonenumber(mobile)){
				setMobileError(true);
				formIsValid = false;
			}
		}
		if (email == "") {
			setEmailError(true);
			formIsValid = false;
		}else{
			if(!ValidateEmail(email)){
				setEmailError(true);
			    formIsValid = false;
			}
		}

		if (files == "") {
		 //	setImgError(true)
		  //	formIsValid = false;
		}

	
		return formIsValid;
	}


 
	
	
	const getRoleData = () =>{
		//setEmail(props.compData?.email);
		const roleOption1=[];
			axios.post(API_URL+"get-store-role"+API_KEY_B).then((res)=>{
			if(res.data.status === 200){
			   let data = res.data.data;
			     data.forEach((da) => {
					
					roleOption1.push({ value: da.name, label: da.name });	
				   }
				 );
				
				setRoleOption(roleOption1);
			}
	   })  
	}


    
    const getMobileCountoryCode = () =>{

		const countryOption1=[];
		const countryMobileCodeOption1=[];
		axios.post(API_URL+'get-country-master-data'+API_KEY_B).then((res)=>{
			if(res.data.status === 200){
			   let data = res.data.data;
			     data.forEach((da) => {
					
                        countryOption1.push({ value: da.code, label: da.name });
						countryMobileCodeOption1.push({value: da.phone, label: da.phone});
				   }
				 );
				
				 setCountryMobileCodeOption(countryMobileCodeOption1);
			}
	   })  
	}


    const handleEmail = (e) => {
		if(e.target.value!=" "){
				setEmailError(false);
				setEmail(e.target.value);
			}
		if(e.target.value &&  firstName  && role  &&  addTo ){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}
	}
    
	const FileUpload = async (e) => {
	
		setUploadFileName(e.target.files[0]);
		
		if(e.target.files[0]!=" "){
          //  setImgError(false);
			setFiles(URL.createObjectURL(e.target.files[0]));
			}
			
	  }; 

    const handleFirstName = (e) => {
		if(e.target.value!=" "){
            setFirstNameError(false);
            setFirstName(e.target.value);
			}
			if(e.target.value &&  role  &&  addTo  && email){
			setButtonGray(true);
			}else{
			setButtonGray(false);
			}
	}

    const handleLastName = (e) => {
		if(e.target.value!=" "){
				//setLastNameError(false);
				setLastName(e.target.value);
			}
		    	
	}
  

    const handleMobile = (e) => {
		if(e.target.value!=" "){
				setMobileError(false);
				setMobile(e.target.value);
			}
			  
	}

    const setRoleH = (e) => {
		
		if(e.value){
			setRoleError(false);
			setRole(e.value);
		}
		if(e.value && firstName &&   addTo  && email){
	  setButtonGray(true);
	}else{
	  setButtonGray(false);
	}
	}

	const setAddToH = (e) => {
		
		if(e.value){
			setAddToError(false);
			setAddTo(e.value);
		}
		if(e.value && firstName &&   role  && email){
	  setButtonGray(true);
	}else{
	  setButtonGray(false);
	}
	}

 


	const handleSubmit = async (e) => {
		e.preventDefault();
		let imageArray = [];     
		if(handleValidation()){
			
            setButtonDisable(true);
				const formData = {
					"firstName":firstName,
					"lastName":lastName,
					"role":role,
					"email":email,
					"mobileNo":(mobile)?mobileCode.value+mobile:"",
					"addTo":addTo,
					"brandID" : (localStorage.getItem('brandID'))?localStorage.getItem('brandID'):"",
					"profileImage" :(uploadFileName.name!=undefined)?BLOB_URL+BLOB_STAFF_CONTAINER+'/'+uploadFileName.name:""
					
				}
                let cname = props?.compData?.Companies[0]?.companyName
				imageArray.push(uploadFileName)
				if(uploadFileName.name!=undefined){	    

				const blobsInContainer = await uploadFileToBlob(imageArray,BLOB_STAFF_CONTAINER).then(res => {
					
					axios.post(API_URL+"invite-staff"+API_KEY_B,formData).then((res)=>{
						if(res.data.status==200){
											
						props.loginFun(localStorage.getItem('token'), '95');

						let timerInterval           
						Swal.fire({
							title: '',
							html: "Staff member invited. As soon as the invitation is accepted, credentials are provided, <br/> and they can login to the account.",
							icon: 'success',
							allowOutsideClick: false,
							confirmButtonColor: '#205380',
							confirmButtonText: 'CONTINUE',
							background: '#1d2530',
							color: '#fff',
							iconColor: "#00BB00",
							width: "500px",
							padding: '3em',
							reverseButtons: true,
							timer: 5000,
							timerProgressBar: false,
							didOpen: () => {
							  Swal.showLoading()
	
							},
							willClose: () => {
							  clearInterval(timerInterval)
							}
						  }).then((result) => {
							
							if (result.dismiss === Swal.DismissReason.timer) {
								
								navigate("/store");
							}
						  })
						  setButtonDisable(false);	
						}else{
							toast.error(res.data.message, {
								theme: "dark",
								icon: ({theme, type}) =>  <img src={errorWarning}/>
							});
							setButtonDisable(false);	
						}
					});
				}) 
			}else{
				axios.post(API_URL+"invite-staff"+API_KEY_B,formData).then((res)=>{
					if(res.data.status==200){
										
					props.loginFun(localStorage.getItem('token'));

					let timerInterval           
					Swal.fire({
						title: '',
						html: "Staff member invited. As soon as the invitation is accepted, credentials are provided, <br/> and they can login to the account.",
						icon: 'success',
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						confirmButtonText: 'CONTINUE',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#00BB00",
						width: "500px",
						padding: '3em',
						reverseButtons: true,
						timer: 5000,
						timerProgressBar: false,
						didOpen: () => {
						  Swal.showLoading()

						},
						willClose: () => {
						  clearInterval(timerInterval)
						}
					  }).then((result) => {
						
						if (result.dismiss === Swal.DismissReason.timer) {
							
							navigate("/store");
						}
					  })
					  setButtonDisable(false);	
					}else{
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({theme, type}) =>  <img src={errorWarning}/>
						});
						setButtonDisable(false);	
					}
				});
			}       


    
         }
		 
	
	}
     
	const callFileUpload = (e) => {
		let checkClass = e.target.classList
		if(checkClass.contains('profile-div') || checkClass.contains('fa-upload') || checkClass.contains('upload-profile-text')){
			document.getElementById('fud').click(); 
		}
	   
	}
	
		const cancelFun1 =(e) => {
			e.preventDefault();
			setFiles("");
			setUploadFileName({});
		}
	

	
	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
				<div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" className="button-not-active" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
								<span className="searchprodicttext text-center">Stores</span>
							</Link>
						</div>
						<div className="centerbtn">
							<Link to={(status === 'Completed')?"/store":"#"} className={(status === 'Completed')?"btn btn-success":"btn btn-success button-not-active"}>Stores</Link>
							<Link to="/store" className="btn btn-success active">New Store</Link>
							<Link to="#" className="btn btn-success">Importer</Link>
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas">
								<Link data-widget="search" to="/store" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link>
							</div>
							<div className="productsearch rightareas">
								<Link to={"/store/open/"+id} role="button">
									<span className="btn btn-success"><i className="fa-thin fa-clock"></i></span>
									<span className="searchprodicttext">Open</span>
								</Link>
							</div>
							<div className="productsearch rightareas">
								<Link data-widget="search" to={"/store/delivery/"+id} role="button">
									<span className="btn btn-success "><i className="fa-thin fa-box"></i></span>
									<span className="searchprodicttext text-center">Delivery</span>
								</Link>
							</div>
							<div className="productsearch rightareas ">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-people-group"></i></span>
									<span className="searchprodicttext text-center">Staff</span>
								</Link>
							</div>
						</div>
					</div>
					<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="filtersection creatorsbtnset enroll-brand ">
										<span className={(buttonGray) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
											<button type="submit" onClick={handleSubmit} className="btn btn-success invite-control" disabled={(buttonDisable)?'disabled':'' } >INVITE {(buttonDisable)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-chevron-right"></i>)}</button>
										</span>
									</div>
									<StepCombination cdata={props.compData}/>

								</div>
							</div>
						</div>
					</div>
				


					<div className="creators-section">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="cardsection">
										<div className="card-header">
											<h3 className="card-title">INVITE STAFF MEMBER<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
                                                    <div className="col-md-9">
                                                        <div className="row">
														<div className="col-md-4">
                                                                <div className="form-group activeborder selectheight" >
                                                                    <label htmlFor="tobrand"><i className="fa fa-info-circle " aria-hidden="true"></i>To Store</label>
                                                                    <Select className={(addToError) ? "selects error-input" : "selects"}
                                                                       options={addToOption}
                                                                       styles={colourStyles}
                                                                       onChange={setAddToH}
                                                                       value={defaultOption}
                                                                        placeholder={<div className="italic-placeholder">Select...</div>}
                                                                    />
                                                                </div>
													        </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group activeborder">
                                                                    <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> First name</label>
                                                                    <input type="text" className={(firstNameError) ? "form-control error-input" : "form-control"} placeholder="" value={firstName} onChange={handleFirstName} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
															   <div className="form-group ">
                                                                    <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Last name</label>
                                                                    <input type="text" className="form-control" placeholder="" value={lastName} onChange={handleLastName} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group activeborder selectheight" >
                                                                    <label htmlFor="tobrand"><i className="fa fa-info-circle " aria-hidden="true"></i> Role</label>
                                                                    <Select className={(roleError) ? "selects error-input" : "selects"}
                                                                        styles={colourStyles}
                                                                        //defaultValue={role}
                                                                        options={roleOption}
                                                                        onChange={setRoleH}
                                                                        //isDisabled
                                                                        placeholder={<div className="italic-placeholder">Select...</div>}
                                                                    />
														        </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                    <div className="form-group activeborder ">
                                                                    <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Email</label>
                                                                    <div className="addonMailleft ">
																	<input type="text" className={(emailError)?"form-control error-input":"form-control"}  value={email} placeholder="" onChange={handleEmail} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
														
                                                                <div className="form-group ">
                                                                    <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Mobile nr.</label>
                                                                     <div className="form-group ">
                                                                        <div className="havesuffixdrop">
                                                                         <Select className="selects"
                                                                            defaultValue={mobileCode}
                                                                            options={countryMobileCodeOption}
                                                                            styles={colourStyles}
                                                                            onChange={setMobileCode}
                                                                            placeholder={<div className="italic-placeholder">Select...</div>}
                                                                            isDisabled
                                                                        />
                                                                        
                                                                        <input type="tel" className={(mobileError)?"form-control error-input":"form-control"}  pattern="[0-9]{10}" maxLength={10} onChange={handleMobile}  value={mobile} />
                                                                        </div>
                                                                    </div> 
                                                         
													            </div>
                                                            </div>
                                                            
                                                        </div>


                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="user-profile-picture">
                                                                    <div className="form-group activeborder"  >
                                                                        <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Profile photo</label>
                                                                        <div className={(imgError)?"profile-div error-input":"profile-div"} onClick={callFileUpload} >
                                                                            <div className="customprofile-pic" style={{"display":(files!="")?"none":"block"}}>
                                                                                <i className="fa fa-upload" aria-hidden="true" ></i>
                                                                                 <span className="upload-profile-text" >Upload</span> 
															                </div>
																			 <input id="fud" type="file" className="upload-profile-text" accept="image/*" onChange={FileUpload} style={{"display":"none"}}/>
																			     {(files)?(<span className="close-image" onClick={cancelFun1}></span>):""}
																			     <img src={files}  style={{"display":(files!="")?"block":"none"}}/>                                                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    
                                                            </div>
                                                        </div>
                                                    </div>  
												</div>

											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					


				</div>

               


				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	);
}
export default Dashboard;