import React from "react";
import FooterLogo from "./../../assets/images/logologinFooter.png";
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
export default function Preloader(){
	return(
		<>
			<div className="wrapper" id="myscreen">
				
				
				
			      	
			      	<div className="pre-loader-img top-content logincenter"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
			   	
			   	
			</div>
		</>
	)
}