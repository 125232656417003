import React, {useState} from "react";
import { Link } from "react-router-dom";
import TermsConditions from "./../static/termsCondition";
import PrivacyPolicy from "./../static/privacyPolicy";
export default function Footer(props) {
  const [termsCondition, setTermsCondtion] = useState('none');
  const [privacyPolicy, setPrivacyPolicy] = useState('none'); 
  const setShowTermsCondition = () => {
    setTermsCondtion('none')
  }
  const isVisibleTermsCondition = () => {
   // setTermsCondtion('block')
  }
  const setShowPrivacyPolicy = () => {
      setPrivacyPolicy('none')
  }
  const isVisiblePrivacyPolicy = () => {
     // setPrivacyPolicy('block')
  }
  return (
    <div>
      <div className="footerarea">
      <TermsConditions setShowTermsCondition={setShowTermsCondition} isVisible={termsCondition}/>
      <PrivacyPolicy setShowPrivacyPolicy={setShowPrivacyPolicy} isVisible={privacyPolicy}/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="ftrlogo">
                <img alt="footer-logo" src={props.logo} />
              </div>
            </div>
            <div className="col-md-8">
              <div className="ftrlinks">
                <ul>
                  <li>
                    <Link to="#">About Us</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={isVisibleTermsCondition}>Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={isVisiblePrivacyPolicy}>Privacy & Security</Link>
                  </li>
                </ul>

                <div className="versiontext">
                  <p>tradE2Simple B.V. © 2022</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
