import React, { useRef, useEffect, useState } from "react";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import { useNavigate } from "react-router-dom";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import Activate_Account from './../../assets/images/activate_account.png';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import axios from 'axios';


import { API_URL,API_KEY_B } from './../../config/constant.js';
import { Link } from "react-router-dom";
import ForBidden from './../layouts/denied'
export default function PreBilling(props){
	document.title = 'BOstageB - Pre-Billing';
	const ref = useRef();
	const navigate = useNavigate();
	const [elementData, setElementData] = useState("");
	const [targetBtb,setTargetBtb] =useState(false);

	const [denied, setDenied] = useState(false);
	const [preLoader, setPreLoader] = useState(true);


	const [companyId, setCompanyId] = useState("");
	const [companyName,setCompanyName] = useState("");
    const [data1,setData1] = useState(0);  

	 
	// useEffect(() => {
	// 	let _data = JSON.parse(localStorage.getItem("__data"));
		
	// 	if(typeof props?.compData?.companyDetail != "undefined"){
	// 		if(_data){	
	// 		// setTimeout((e)=>{
	// 			if(!_data?.BillingMenu){
				
	// 					setPreLoader(false); 
	// 					setDenied(true);     
										
	// 			}else{
	// 				setDenied(false);
	// 				setPreLoader(false);   
	// 			}
	// 	//	},1000);	
	// 	  }
	//    }
		
	// },[props.compData]);

	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.StoresMenu){
				
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }
		
	},[props.compData]);

	useEffect(() => {
		
		if (typeof props?.compData?.Companies != 'undefined') {
			   setCompanyId(props?.compData?.Companies[0]?.companyID);
		}

	}, [props.compData]);
	


   useEffect(() => {
		
	if (typeof props?.compData?.Companies != 'undefined') {
		setCompanyId(localStorage.getItem('brandID'));
		setCompanyName(localStorage.getItem('brandName'));
	}

}, [props.compData]);


// const moveTo = (type) =>{
   
// 	  if(type=="store"){
// 		navigate("/store");
// 	  }
// 	//   if(type=="retailer"){
// 	// 	navigate("/retailer/create")
// 	//   }
// 	//   if(type=='boimage'){
// 	// 	navigate("/boimages/creater")
// 	//   }

// }


useEffect(() => {

	getStoreList();
},[])

const getStoreList = () => {

	axios.post(API_URL+"get-brand-store-list"+API_KEY_B,{brandID:localStorage.getItem("brandID")}).then(res => {
		//console.log(res?.data?.data.length);
		setPreLoader(false)
  if(res?.data?.data.length){
	setData1(1);    
   }
	})
}


// const handelBill = () =>{
// 	setPreLoader(true);
   
// 	let jsonPost = {
	  
// 	  companyId:companyId
//   }
//   axios.post(API_URL + "/create-R-In-B"+API_KEY_B, jsonPost).then((res) => {
// 		 props.loginFun(localStorage.getItem('token'),'100');
// 	  if (res.data.status == 200) {
			
// 			/*res.data.message,*/
// 		  toast.success("Let's proceed with billing plan!",
// 			  {
// 				  theme: "dark",
// 				  icon: ({ theme, type }) => <img src={errorSuccess} />,
// 				  onOpen: () => {
// 						  navigate("/billing-plan");		
// 				  }
// 			  });
// 			  setPreLoader(false);
		  
// 	  } else {
// 		  toast.error(res.data.message, {
// 			  theme: "dark",
// 			  icon: ({ theme, type }) => <img src={errorWarning} />
// 		  })
// 	  }
// 	  setPreLoader(false);
	  
//   })
   

//   // setTimeout(()=>{
//   // 	setPreLoader(false);
//   // 	navigate("/billing-plan");
//   // },2500);
  
// }

const handelBill = () =>{
    navigate("/billing-plan/retailer");			
}

    
	




     


	return(
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
					{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
					<div className="controlbar thankyouwelcomestore">
						<div className="centerwelcome-msg"><span className="brandName-msg">{companyName}</span> is now <span className="grBill">ENROLLED</span></div>	
					</div>
					{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:<>					
					<div className="creators-section billing-page-plans">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="what-to-do-next">
										<h2 className="what-heading">Congratulations! You completed the enrollment of <span className="brandName-msg">{companyName}</span>. <br/>You can now explore your account.</h2>
										<h2 className="what-heading">To start publishing images powered by BOtag technology, you still have to:</h2>
										<div className="row">
											<div className="tManageCenter">
                                            <div className="col-md-3"></div>
											 <div className="col-md-3" onClick={handelBill}>
											{/* <div className="col-md-3" onClick={navigate("/billing-plan")}> */}
												<div className="explore-icons">
													<span className="explore-information-icon"><i className="fa-regular fa-circle-info"></i></span>
													{/* <i className="fa-thin fa-user"></i> */}
													<img src={Activate_Account} />
													<h3>Activate account <br/> Select a billing plan.</h3>		
												</div>
											</div>
											<div className="col-md-3"></div>
											</div>
										</div>
										<h2 className="what-heading">Use menu to explore more</h2>
									</div>
								</div>
							</div>
							
						</div>
					</div></>}
					</> }
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	)
}