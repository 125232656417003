import React, { useRef, useEffect, useState } from "react";
import { Link ,useNavigate, useLocation,useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import { API_URL, API_URL_A, API_KEY_B ,API_KEY_A} from './../../config/constant.js';
import  DatePicker from "react-multi-date-picker";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/icons-error-48.png';
import axios from 'axios';
import Loader from './../../assets/images/loader.gif';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import Swal from 'sweetalert2';
import StepCombination from '../layouts/commonLayout';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import ForBidden from './../layouts/denied';
//import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning"
toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - Account Setup';
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	const [buttonGray, setButtonGray] = useState(false);
	//const [responseData, setResponseData] = useState("");
	const [collection, setCollection] = useState("");
	//const [activePeriod, setActivePeriod] = useState("");
    const [text, setText] = useState("");
    const [nameError, setNameError] = useState(false);
    const [groupError, setGroupError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [regnoError, setRegnoError] = useState(false);
    const [regDateError, setRegDateError] = useState(false);
	const [taxError, setTaxError] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [mobileCodeError ,setMobileCodeError] = useState(false);
	const [btypeError, setBtypeError] = useState(false);
	const [mobileError, setMobileError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [regDate, setRegDate] = useState("");
	const [buttonDisable, setButtonDisable] = useState(false);
	const [countryData, setCountryData] = useState([])
	const [oneTimeMsg, setOneTimeMsg] = useState(true);
	const [taxPrefixName, setTaxPrefixName] = useState("")
	
	const [cName, setCName] =useState("");
    const [group, setGroup] = useState("");
	const [country, setCountry] = useState([]);
	//const [country, setCountry] = useState({ value: 'NO', label: 'Norway' });
	const [regno, setRegno] = useState("");
	const [tax, setTax] = useState("");
	const [email, setEmail] =useState("");
	const [mobile, setMobile] =useState("");
	const [btype, setBtype] = useState({ value: 'Retail', label: 'Retail' });
	const [mainDateRange, setMainDateRange] = useState();
	const [mobileCode, setMobileCode] = useState([]);
	//const [mobileCode, setMobileCode] = useState({ value: '+47', label: '+47' });
	const [dataReadonly, setDataReadonly] = useState(0);
	const [status, setStatus] = useState("");
	const [isPhoneVerified, setIsPhoneVerified] = useState(false);
	const [companyLogo, setCompanyLogo] = useState("");
	const [mobNotVerifyTestShow,setMobNotVerifyTestShow] = useState(false);
	const [preLoader, setPreLoader] = useState(true);
	const [onSubmitLoader, setOnSubmitLoader] = useState(false);
	const [hidePropsLoader, setHidePropsLoader] = useState(false)
	const [hideResLoader, setHideResLoader] = useState(false)
	const [companyVerifiedLabel, setCompanyVerifiedLogo] = useState(false)
 	const {state} = useLocation();
	const [groupValue, setGroupValue] = useState("");
	const [groupSelVal, setGroupSelVal] = useState("");
    const [compCompany,setCompCompany] = useState(false);
	const [denied, setDenied] = useState(false);
	const [registartionNoVerification,setRegistartionNoVerification] = useState(false);
	const [taxVerification,setTaxVerification] = useState(false);
  const [preTaxLable, setPreTaxLable] = useState("") 
	const [gr,setGr] =useState(false);
	const [cn,setCn] =useState(false);
	const [co,setCo] =useState(false);
	const [rg,setRg] =useState(false);
	const [dob,setDob] =useState(false);
	const [tn,setTn] =useState(false);
	const [em,setEm] =useState(false);
	const [mo,setMo] =useState(false);
	const [bt,setBt] =useState(false);
	const [lock,setLock] = useState(0);

	const [buttonName,setButtonName] =useState("NEXT");
	const [editMode,setEditMode] =useState(0);
    const[editModeBtnFun,setEditModeBtnFun] = useState(0);
    
	const { compId } = useParams();





    useEffect(() => {
		if(gr && cn && co && rg && dob && tn && em && mo && bt){
			setHideResLoader(true);
		}
	},[gr,cn,co,rg,dob,tn,em,mo,bt])



	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.AccountMenu){
					setPreLoader(false);
					setDenied(true)
				}else{
					setDenied(false)
					
				}
		  }
	   }
		
	},[props.compData]);

	useEffect(()=>{ 
		
		if(typeof compId != undefined){  
		      if(localStorage.getItem('viewMode')=="editmode"){
					setEditMode(1);
					setEditModeBtnFun(1);
					setButtonName("SAVE");
					//console.log("test");
					
		  }  
		 }else{ 
			localStorage.removeItem('viewMode');
			setEditMode(0)
		}
	},[compId]); 
	
   useEffect(()=>{   
	      if(props?.compData?.companyDetail?.isMobileVerified){
			setOneTimeMsg(false);
		  }
		if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){ 
		       setCompCompany(true);
		}
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){ 
			setCompanyLogo(props?.compData?.companyDetail?.logoURL)
        	setIsPhoneVerified(props?.compData?.companyDetail?.isMobileVerified)
        	setHidePropsLoader(true);
			}else{
				setHidePropsLoader(true);
				
			}
    	}

    	setStatus(props?.compData?.completePercentage)
		getGroupCode();
		getMobileCountoryCode();  
    },[props.compData,editMode]); 

	useEffect(() => {
		if(hidePropsLoader && hideResLoader){
			setPreLoader(false)
		}
	},[hidePropsLoader, hideResLoader,editMode])

   useEffect(() => {
   	if(state?.OTPVerified == true){
		setOneTimeMsg(false);
   		setDataReadonly(1)
   		//setCompanyVerifiedLogo(true)
   		setHideResLoader(true)
   		setHidePropsLoader(true)
   		setOnSubmitLoader(true)
   		props.loginFun(localStorage.getItem('token'), '10')
   		const timer = setTimeout(() => {
		  //  let message = "Good start! First step to create your account is completed. Let's add the company logo now.";
		    let message = "Phone number verified.";
	        toast.success(message, {
	            theme: "dark",
	            icon: ({theme, type}) =>  <img src={errorSuccess}/>,
	            onClose: () => {(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed')?(companyLogo)?navigate('/account/company/logo', { state: { OTPVerified: false} }):navigate('/account/company/pre-company-logo-upload', { state: { OTPVerified: false} }):navigate('/account/company/logo', { state: { OTPVerified: false}})}
	        });
	        setOnSubmitLoader(false)
		}, 5000);
		return () => clearTimeout(timer);
   		state.OTPVerified = false;
   	}
   },[state?.OTPVerified])
  

useEffect(() => { 
if(typeof props?.compData?.companyDetail != "undefined"){

	if(props?.compData?.companyDetail?.registrationNumber){
		//added
		if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){ 
		setDataReadonly(1);
		//setCompanyVerifiedLogo(true)
		setMobNotVerifyTestShow(true);
		setEmail((typeof props?.compData.companyEmail != 'undefined')?props.compData?.companyEmail:"");
		}else{
			setEmail((typeof props?.compData.companyEmail != 'undefined')?props.compData?.companyEmail:"");
		}
	}else{
		
        callOnLoad(cName);

	}
	
}

},[props,cName]);
  


	  /*******************save popup********************** */
      
	  const refWarp = useRef(null);   //new datatttt
	  useEffect(() => {
	  
		  function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode);
			  
				//menu
			  let pclass = event?.target?.parentNode?.parentNode?.parentNode?.parentNode;
			  let pclassimg = event?.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
			  // rightcontrolserch
			  let prClassIcon = event?.target?.parentNode?.parentNode?.parentNode;
			  let prClass = event?.target?.parentNode?.parentNode;
			   //center upper btn
			 let pCNClass = event?.target?.parentNode;
			   
			  if(editMode && !editModeBtnFun){  
				  //  console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp?.current && !refWarp?.current?.contains(event.target) && (event?.target?.classList?.contains("saveIt") || pclass?.classList?.contains("menusections") || pclass?.classList?.contains("navitems-area") || pclassimg?.classList?.contains("navitems-area")|| pclassimg?.classList?.contains("menusections") || prClassIcon?.classList?.contains("saveIt")  || prClass?.classList?.contains("saveIt") || pCNClass?.classList?.contains("saveIt") )  ) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					  }).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);   
							handleSubmit(event); 		
						}
						else if(result.isDenied){
						   setEditModeBtnFun(1);
						 }
						
					})   
   
				
			}
		   }
		  }
		  document.addEventListener("mousedown", handleSaveOutside);
		  return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		  };
		}, [refWarp,editMode,editModeBtnFun]);   
   
   
   
   
	  /**************************************************** */




const callOnLoad = (ciid) =>{
		//console.log("mob",props?.compData);
		//console.log("oneTimeMsg",oneTimeMsg);
	
	if(ciid && oneTimeMsg){	
	setOneTimeMsg(false);
	Swal.fire({		
		title: "",
		text: "",
		html: "<span className='swal-welcome-msg'>Welcome to <b>BO</b>stageB account for "+ ciid + ".<br/> Please provide a few more details about the company.</span>",
		icon: 'info',
		allowOutsideClick: false,
		confirmButtonColor: '#205380',
		confirmButtonText: 'CONTINUE',
		background: '#1d2530',
		color: '#fff',
		iconColor: "#00a4df",
		width: "500px",
 	    padding: '2em',
		reverseButtons: true
	  }).then((result) => {
		
	})
	 
    }
}


const [countryOption, setCountryOption]  = useState({});
const [countryMobileCodeOption, setCountryMobileCodeOption] = useState({});

//console.warn("warn",props.compData);

useEffect(()=>{
	if(mobile && cName && group  &&  country && regno &&  tax && email && btype && mobileCode && mainDateRange){
		setButtonGray(true);
	  }
});


useEffect(()=>{
	if(typeof props?.compData?.companyDetail != 'undefined' && groupValue!=""){
		let da = groupValue?.filter((re) => {					 		
			if(re.value== props?.compData?.companyDetail?.groupID){
				//setHideResLoader(false);
				return re;
			}
		});
		
		if(da.length){
			if(da[0].value=="" || da[0].value =="-" || da[0].value=="No Group" ){	 
				setGroup('-');
				setGroupSelVal({value:"-",label:"No Group"});
				//setHideResLoader(false);
			}else{
				setGroup(da[0].value);
				setGroupSelVal(da[0]);
				//setHideResLoader(false);
			}
		}else{
			setGroup('-');
			setGroupSelVal({value:"-",label:"No Group"});
			//setHideResLoader(false);
		}
	  }
},[groupValue,props?.compData?.companyDetail]);




const getGroupCode =  () =>{
		
	let getGroupCodeArr=[];	
	 
	axios.post(API_URL_A+'get-group-list'+API_KEY_A).then((res)=>{
	   if(res.data.status === 200){
		  let data = res?.data?.data;
		 
			data.forEach((da) => {		
				getGroupCodeArr.push({ value: da.ourID, label: da.name });
			  }
			);
			setGroupValue(getGroupCodeArr);
			if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){
				if(typeof props?.compData?.companyDetail != 'undefined'){
				    if(getGroupCodeArr.length){					
						let da = getGroupCodeArr?.filter((re) => {					 		
							if(re.value== props?.compData?.companyDetail?.groupID){
								setHideResLoader(false);
								return re;
							}
						});
						
						if(da.length){
							if(da[0].value=="" || da[0].value =="-" || da[0].value=="No Group" ){	 
								setGroup('-');
								setGroupSelVal({value:"-",label:"No Group"});
								setHideResLoader(false);
							}else{
								setGroup(da[0].value);
								setGroupSelVal(da[0]);
								setHideResLoader(false);
							}
						}else{
							setGroup('-');
							setGroupSelVal({value:"-",label:"No Group"});
							setHideResLoader(false);
						}
						
					}
				}
		    }else{
				if(editMode){   
					if(typeof props?.compData?.companyDetail != 'undefined'){  
						
						if(getGroupCodeArr.length){					
							let da = getGroupCodeArr?.filter((re) => {					 		
								if(re.value== props?.compData?.companyDetail?.groupID){
									setHideResLoader(false);
									return re;
								}
							});
                               
						
														
							if(da.length){
								if(da[0].value=="" || da[0].value =="-" || da[0].value=="No Group" ){	 
									setGroup('-');
									setGroupSelVal({value:"-",label:"No Group"});
									setHideResLoader(false);
								}else{
									setGroup(da[0].value);
									setGroupSelVal(da[0]);
									setHideResLoader(false);
								}
							}else{
								// setGroup('-');
								// setGroupSelVal({value:"-",label:"No Group"});
								// setHideResLoader(false);
								setHideResLoader(true)
							}
							
						}
					}
				}else{
				setGroup('-');
				setGroupSelVal({value:"-",label:"No Group"});
				setHideResLoader(true)
				}
			   }
			
	   }
  })  
}


	const getMobileCountoryCode = () =>{   
		const countryOption1=[];
		//setEmail(props.compData?.email);
		//console.log(props.compData);
		const countryMobileCodeOption1=[];
		axios.post(API_URL+'get-country-master-data'+API_KEY_B).then((res)=>{
			if(res.data.status === 200){
			   let data = res.data.data;
			    setCountryData(data)
			     data.forEach((da) => {
					//console.log(da.code);
                        countryOption1.push({ value: da.code, label: da.name });
						countryMobileCodeOption1.push({value: da.phone, label: da.name +'   '+ da.phone });
				   }
				 );
				 setCountryOption(countryOption1);
				 setCountryMobileCodeOption(countryMobileCodeOption1);
				//console.log("pro",props?.compData);

               /*************setting value from db for already filled form*************** */
                 
			   if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){

			 
				 setCName((typeof props?.compData?.Companies != 'undefined')?props?.compData?.Companies[0]?.companyName:'');

				 setRegno((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.registrationNumber:'');
				 
				 setTax((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.taxNumber:'');
				 
				 setEmail((typeof props?.compData.companyEmail != 'undefined')?props.compData?.companyEmail:"");
				 
				 setMobile((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.phoneNumber?.split(" ")[1]:'');
				 //setMobile((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.phoneNumber.substr(props?.compData?.companyDetail?.phoneNumber.length - 10):'');
				 
				 //setBtype({ value:(typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.businessType:'' , label: (typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.businessType:'' });
				 
				 setMainDateRange((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.registrationDate:null);
				 
				 setPreTaxLable((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.taxPreLabel:null);

				if(typeof props?.compData?.companyDetail != 'undefined'){
					let cdata =(typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.countryName:""
					if(countryOption1){						
					 	let dat = countryOption1?.filter((re) => {					 		
					 		if(re.value== cdata){
					 			return re;
					 		}
					 	}); 
					 	setCountry(dat[0]);
					 	//setTaxPrefixName(dat[0]?.code)
					}
					setTaxPrefixName(cdata);
					//console.log("countryData:",countryData);
					data.forEach((o) => {  
						if(o.code == cdata){
							setMobileCode({ value: o.phone, label: o.phone })
							
						}
					})
					
				}
				//
				setCompanyVerifiedLogo(false);
				 let regist = (typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.registrationNumber:''
			          if(regist){
						setRegistartionNoVerification(true);
					  }
                   
				   (typeof props?.compData?.Companies != 'undefined')?setCn(true):setCn(false);
				   (typeof props?.compData?.Companies != 'undefined')?setGr(true):setGr(false);
				   (typeof props?.compData?.Companies != 'undefined')?setCo(true):setCo(false);
				   (typeof props?.compData?.Companies != 'undefined')?setRg(true):setRg(false);
				   (typeof props?.compData?.Companies != 'undefined')?setTn(true):setTn(false);
				   (typeof props?.compData?.Companies != 'undefined')?setEm(true):setEm(false);
				   (typeof props?.compData?.Companies != 'undefined')?setMo(true):setMo(false);
				   (typeof props?.compData?.Companies != 'undefined')?setBt(true):setBt(false);
				   (typeof props?.compData?.Companies != 'undefined')?setDob(true):setDob(false);
				  
			   }else{ 
				if(editMode){
                    setCName((typeof props?.compData?.Companies != 'undefined')?props?.compData?.Companies[0]?.companyName:'');

				 setRegno((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.registrationNumber:'');
				 
				 setTax((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.taxNumber:'');
				 
				 setEmail((typeof props?.compData.companyEmail != 'undefined')?props.compData?.companyEmail:"");
				 
				 setMobile((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.phoneNumber?.split(" ")[1]:'');
				 //setMobile((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.phoneNumber.substr(props?.compData?.companyDetail?.phoneNumber.length - 10):'');
				 
				 //setBtype({ value:(typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.businessType:'' , label: (typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.businessType:'' });
				 
				 setMainDateRange((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.registrationDate:null);
				 
				 setPreTaxLable((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.taxPreLabel:null);

				if(typeof props?.compData?.companyDetail != 'undefined'){
					let cdata =(typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.countryName:""
					if(countryOption1){						
					 	let dat = countryOption1?.filter((re) => {					 		
					 		if(re.value== cdata){
					 			return re;
					 		}
					 	}); 
					 	setCountry(dat[0]);
					 	//setTaxPrefixName(dat[0]?.code)
					}
					setTaxPrefixName(cdata);
					//console.log("countryData:",countryData);
					data.forEach((o) => {  
						if(o.code == cdata){
							setMobileCode({ value: o.phone, label: o.phone })
							
						}
					})
					
				}
				//
				setCompanyVerifiedLogo(false);
				 let regist = (typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.registrationNumber:''
			          if(regist){
						setRegistartionNoVerification(true);
					  }
                   
				   (typeof props?.compData?.Companies != 'undefined')?setCn(true):setCn(false);
				   (typeof props?.compData?.Companies != 'undefined')?setGr(true):setGr(false);
				   (typeof props?.compData?.Companies != 'undefined')?setCo(true):setCo(false);
				   (typeof props?.compData?.Companies != 'undefined')?setRg(true):setRg(false);
				   (typeof props?.compData?.Companies != 'undefined')?setTn(true):setTn(false);
				   (typeof props?.compData?.Companies != 'undefined')?setEm(true):setEm(false);
				   (typeof props?.compData?.Companies != 'undefined')?setMo(true):setMo(false);
				   (typeof props?.compData?.Companies != 'undefined')?setBt(true):setBt(false);
				   (typeof props?.compData?.Companies != 'undefined')?setDob(true):setDob(false);

				}else{
                   
				     setMobile((typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.phoneNumber?.split(" ")[1]:''); 
				     if(typeof props?.compData?.phoneNumber != 'undefined'){
                        let pcode = props?.compData?.phoneNumber?.split(" ")[0];
						let contCode =(typeof props?.compData?.companyDetail != 'undefined')?props?.compData?.companyDetail?.countryName:""
						setMobileCode({ value: pcode, label: pcode  }); 
					 }
					 
					 setHideResLoader(true);
					}
			   }	
			 
			}
			
	   })  
            
	  

	}
	
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		noOptionsMessage:(styles) => ({...styles, backgroundColor: '#333F50', color: '#d1d1d1'}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
			    borderRadius: "10px"
			}
		}),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
	    ...styles,
	    color: "#fff",
	    backgroundColor: "#205380",
	    borderRadius: "0px",
	  }),
	  multiValueRemove: (styles, { data }) => ({
    	...styles,
    	color: "#fff",
    	backgroundColor: "#205380",
    	borderRadius: "0px",
    ':hover': {
      backgroundColor: "#205380",
      color: 'white',
    },
  }),
	};


	const phoneCodeStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		noOptionsMessage:(styles) => ({...styles, backgroundColor: '#333F50', color: '#d1d1d1', width:"700px"}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0, width:"250px" }),
		menuList: styles => ({...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
			    borderRadius: "10px"
			}
		}),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
		    ...styles,
		    color: "#fff",
		    backgroundColor: "#205380",
		    borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
	    	...styles,
	    	color: "#fff",
	    	backgroundColor: "#205380",
	    	borderRadius: "0px",
	    	':hover': {
	      	backgroundColor: "#205380",
	      	color: 'white',
	    	},
	  	})
	};
	
    const productOptions = [
		{ value: '1', label: 'Group' },
		
    ];

	const bTypeOptions = [
		{ value: 'Retail', label: 'Retail' },
    ];
     
	
	const handleGroup = (e) => {
		setGroupError(false);
		setGroup(e.value);
		//console.log(e.value);
		setGroupSelVal({value:e.value,label:e.label});
		if(e.value && cName && country  &&  regno &&  tax && email && mobile && btype && mobileCode && mainDateRange){
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
		if(editMode){
			setEditModeBtnFun(0);
		}

	}


	const handleCountry = (e) => { 
		let selectedData = countryData.forEach((res) => {
			if(res.code === e.value){
                 if(props?.compData?.phoneNumber == ""){
					setMobileCode({ value: res.phone, label: res.phone })
				 }
				 if(editMode){
					setMobileCode({ value: res.phone, label: res.phone })
				 }
				setTaxPrefixName(res.code)
				//return res;
			}
		})
		if (e.value === "NO") {
				setPreTaxLable("MVA")
			}else{
				setPreTaxLable("")
			}
		//setResponseData(selectedData[0])
		setCountryError(false);
		setCountry({value:e.value,label:e.label})
		
		if(e.value && group && cName  &&  regno &&  tax && email && mobile && btype && mobileCode && mainDateRange){
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}

         (e.value == "NO" || e.value == "DK")?setLock(1):setLock(0)
        
		 if(editMode){
			setEditModeBtnFun(0);
		}
	}
	
	const handleName = (e) => {
		if(e.target.value!=" ")
		    {
				setNameError(false);
				setCName(e.target.value);	
			}
			if(e.target.value && group && country  &&  regno &&  tax && email && mobile && btype && mobileCode && mainDateRange){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}
		
		if(editMode){
			setEditModeBtnFun(0);
		}

	};

	

	const handelRegNo = (e) => {
		
		if(e.target.value){
				setRegistartionNoVerification(true);
				setRegnoError(false);
				setRegno(e.target.value);
				if(country.value=='NO' || country.value== 'DK' ){
				   setTax(e.target.value)
				  
				}
				 
			}else{
					if(country.value=='NO' || country.value== 'DK' ){
						setTax("");
						
					}
				setRegistartionNoVerification(false);
				setRegno("");
			}
		if(e.target.value && cName && group  &&  country &&  tax && email && mobile && btype && mobileCode && mainDateRange){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}

		if(editMode){
			setEditModeBtnFun(0);
		}
	}
    //const calendarRef = useRef()
	//calendarRef.current.closeCalendar();
	

	const handleTax = (e) => {
		if(e.target.value){
			setTaxVerification(true);
			setTaxError(false);
			setTax(e.target.value);
		}else{
			setTaxVerification(false);
			setTax("");
		}
		
		if(e.target.value && cName && group  &&  country && regno &&  email && mobile && btype && mobileCode && mainDateRange){
	  		setButtonGray(true);
		}else{
		  	setButtonGray(false);
		}

		if(editMode){
			setEditModeBtnFun(0);
		}
		
	}

	const handleMobile = (e) => {
		if(e.target.value){ 
			     setMobNotVerifyTestShow(true);
				setMobileError(false);
				setMobile(e.target.value);
			}else{
				setMobile("");
				setMobNotVerifyTestShow(false);
			}
		//	console.log(e.target.value  + cName + group  +  country + regno + regDate + tax + email + btype +mobileCode + mainDateRange);
			if(e.target.value && cName && group  &&  country && regno &&  tax && email && btype && mobileCode && mainDateRange){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}

		if(editMode){
			setEditModeBtnFun(0);
		}
	}

	

    const handelDate = (e) => {
		if(e!=""){   
				setRegDateError(false);
				setMainDateRange(e.format());
			}
		//	console.log(e.target.value  + cName + group  +  country + regno + regDate + tax + email + btype +mobileCode + mainDateRange);
			if(e && cName && group  &&  country && regno &&  tax && email && btype && mobileCode && mobile && mainDateRange){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}

		if(editMode){
			setEditModeBtnFun(0);
		}
	}

	const phonenumber = (inputtxt) =>
	{
		//var phoneno = /^\d{10}$/;
		var phoneno = "^[0-9]*$";
	  if(inputtxt?.match(phoneno))
			{
		  return true;
			}
		  else
			{
			return false;
			}
	}


    const handleValidation = () =>{  
		let formIsValid = true;
		//console.warn("cvdvd",mobile,country);
		if(cName==""){
			setNameError(true)
		   formIsValid = false;
		} 
		if(group==""){
			setGroupError(true);
			 formIsValid = false;
		  }else{
			setGroupError(false);
			
		  }
         //console.log("country",country);
		if(country==""||country==undefined){
		  setCountryError(true);
			formIsValid = false;
		}else{
			setCountryError(false);
			 
		  }
		if(regno==""){
			setRegnoError(true);
				formIsValid = false;
		}
		if(mainDateRange==undefined || mainDateRange==""){
			setRegDateError(true);
				formIsValid = false;
		}
		if(tax==""){
			setTaxError(true);
				formIsValid = false;
		}
		if(email==""){
			setEmailError(true);
				formIsValid = false;
		}
		if(mobile==""|| mobile==undefined){
			
			setMobileError(true);
				formIsValid = false;
		}else{
			if(!phonenumber(mobile)){
				setMobileError(true);
				formIsValid = false;
			}
		}

		if(mobileCode==""){
			setMobileCodeError(true);
				formIsValid = false;
		}else{
			setMobileCodeError(false);
		}
		
		if(btype==""){
			setBtypeError(true);
				formIsValid = false;
		}else{
			setBtypeError(false);
		}
		  return formIsValid;

	}
  
    
	const handlePhoneCode = (e)=>{
		let phoneLabel = e.label.split(" ");
		let newLabel = phoneLabel.pop();
		let newValue = e.value
		setMobileCode({label: newLabel, value: newValue})
		setMobileCodeError(false);
		if(editMode){
			setEditModeBtnFun(0);
		}
	}



	let navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		
	   if(compCompany){	
			if(isPhoneVerified){
				navigate('/account/company/logo');
				return false;
			}
	    }
		if(handleValidation()){ 
				
			if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){ 
			   setDataReadonly(1);
				setOnSubmitLoader(true)
            	setButtonDisable(true);
				const formData = {
					"groupID":group,
					"countryName":country.value,
					"registrationNumber":regno,
					//"registrationDate":mainDateRange.format("DD-MM-YYYY"),
					"registrationDate":mainDateRange,
					"taxLabel": taxPrefixName,
					"taxNumber":tax,
					"taxPreLabel": preTaxLable,
					"emailID":props.compData?.email,
					"phoneNumber" : mobileCode.value+" "+mobile,
					"businessType" : btype.value,
					"companyID" : props.compData?.Companies[0]?.companyID,
					"companyName" : cName
				}
        
            // console.log("testtsts",formData);
				axios.post(API_URL+"company-enrollment"+API_KEY_B, formData).then((res)=>{
					if(res.data.status==200){
						localStorage.setItem('tel',mobileCode.value+" "+mobile)
						if(res.data?.data?.isMobileVerified){
							navigate('/account/company/logo');
						}else{
				     		navigate('/account/company/phone-verification');
						}
					}else{
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({theme, type}) =>  <img src={errorWarning}/>
						});
						//setButtonDisable(false);	
						setOnSubmitLoader(false);
						setDataReadonly(0);
						setButtonDisable(false);
					}
					setOnSubmitLoader(false)
				});
    
         }else{
			
				 setOnSubmitLoader(true)
				 setButtonDisable(true);
                 if(editMode){

                    const formData = {
						"groupID":group,
						"countryName":country.value,
						"registrationNumber":regno,
						"registrationDate":mainDateRange,
						"taxLabel": taxPrefixName,
						"taxNumber":tax,
						"taxPreLabel": preTaxLable,
						"emailID":email,
						"phoneNumber" : mobileCode.value+" "+mobile,
						"businessType" : btype.value,
						"companyName" : cName,
						"companyID" : compId
					}
				
					axios.post(API_URL+"update-company-info"+API_KEY_B, formData).then((res)=>{
					//	axios.post("http://localhost:7071/api/update-company-info",formData).then((res)=>{
						if(res.data.status==200){
						    
							 localStorage.setItem('tel',mobileCode.value+" "+mobile);
							 localStorage.setItem('lastInsertedID',res.data.data?.lastInsertedID);
							 setEditModeBtnFun(1);
							 props.loginFun(localStorage.getItem('token'),'Completed');
							 if(res.data?.data?.isMobileVerified){
								
								toast.success(res.data.message, {
									theme: "dark",
									icon: ({theme, type}) =>  <img src={errorSuccess}/>
								});
							}else{
								 navigate('/account/company/phone-verification');
							}
								  
						}else{
							toast.error(res.data.message, {
								theme: "dark",
								icon: ({theme, type}) =>  <img src={errorWarning}/>
							});
							//setButtonDisable(false);	
							setOnSubmitLoader(false);
					   
							setButtonDisable(false);
						}
						setOnSubmitLoader(false)
					});

				 }else{

				 const formData = {
					 "groupID":group,
					 "countryName":country.value,
					 "registrationNumber":regno,
					 "registrationDate":mainDateRange,
					 "taxLabel": taxPrefixName,
						"taxNumber":tax,
						"taxPreLabel": preTaxLable,
					 "emailID":email,
					 "phoneNumber" : mobileCode.value+" "+mobile,
					 "businessType" : btype.value,
					 "companyName" : cName
				 }
             
				 axios.post(API_URL+"create-new-company"+API_KEY_B, formData).then((res)=>{
					 if(res.data.status==200){
						//console.log("hfbdhghd",res.data.data);

 						  localStorage.setItem('tel',mobileCode.value+" "+mobile);
						  localStorage.setItem('lastInsertedID',res.data.data?.lastInsertedID);
						 
							//  navigate('/account/company/phone-verification',{state: {"lastInsertedID":res.data.data?.lastInsertedID,"mobileNumber":res.data.data?.mobileNumber}});
							   navigate('/account/company/logo');
						       
					 }else{
						 toast.error(res.data.message, {
							 theme: "dark",
							 icon: ({theme, type}) =>  <img src={errorWarning}/>
						 });
						 //setButtonDisable(false);	
						 setOnSubmitLoader(false);
					
						 setButtonDisable(false);
					 }
					 setOnSubmitLoader(false)
				 });

				}

				 
	 
		  
		}

	  }
	}






	return(
		<>
		<div className="wrapper" ref={ref} id="myscreen">
			<BrandNavigation data={elementData} cdata={props.compData}/>
			<BrandAside cdata={props.compData} />
			
			<div className="conten-wrapper enroll-wrapper">
			  {(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
			    <div className="controlbar">
			    	<div className="productsearch leftsidetr notactive">
                        <Link data-widget="search" className="button-not-active" to="#" onClick={(e) => e.preventDefault()} role="button">
                            <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                            <span className="searchprodicttext text-center">Accounts</span>
                        </Link>
                    </div>
                    <div className="centerbtn">
                        <Link to={(status == 'Completed')?"/account/company/list":"#"} className={(status === 'Completed')?"btn btn-success saveIt":"btn btn-success button-not-active"}>Companies</Link>
						<Link to="#" className={(editMode)?"btn btn-success active saveIt":"btn btn-success button-not-active"}>Edit Companies</Link>
					    <Link to="/account/company" className={(editMode)?"btn btn-success button-not-active saveIt":"btn btn-success active saveIt" } >New Company</Link>
                    </div>
                    <div className="rightcontrolserch ">
                        <div className="productsearch rightareas saveIt">
                            <Link data-widget="search" to="/account/company" role="button">
                                <span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
                                <span className="searchprodicttext">ID</span>
                            </Link>
                        </div>
                        <div className={(isPhoneVerified || editMode)?"productsearch rightareas saveIt":"productsearch rightareas notactive"}>
                            <Link data-widget="search" to={(isPhoneVerified || editMode)?"/account/company/logo":""} role="button">
                                <span className="btn btn-success saveIt"><i className="fa-thin fa-sign-hanging"></i></span>
                                <span className="searchprodicttext saveIt">Logo</span>
                            </Link>
                        </div>
						<div className="productsearch rightareas notactive">
                            <Link data-widget="search" to="#" role="button">
                                <span className="btn btn-success"><i className="fa-thin fa-users"></i></span>
                                <span className="searchprodicttext">Users</span>
                            </Link>
                        </div>
                    </div>
                </div>
                
                {(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:
                <><div ref={refWarp}>	
				<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
								<div className="filtersection creatorsbtnset enroll-brand">
			            			<span className={(buttonGray && !isPhoneVerified)?"customeset textinside next-new-btn":"customeset textinside next-new-btn disabledbtn"}>
                      					<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={(!editMode)?(((buttonDisable || dataReadonly ) && isPhoneVerified)?'disabled':''):(editModeBtnFun)?true:false}>{buttonName} {(buttonDisable)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-chevron-right"></i>)}</button>                                       
                    				</span>                   
			            		</div>
																		
									<StepCombination cdata={props.compData}/>

								</div>
							</div>
						</div>
					</div>
			   
			    <div className="creators-section">
			    	<div className="container-fluid">
			    		<div className="row">
			    			<div className="col-md-12">
			    				<div className="cardsection">
			    					<div className="card-header">
                    					<h3 className="card-title">Company ID <span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
                  					</div>
                  					<div className="card-body">
                  						<form>
                  							<div className="row">
                  								<div className="col-md-3 device-compatabilty">
                  									<div className={(compCompany)?"form-group inActiveborder":(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
					                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Group</label>
					                  					<Select value={groupSelVal} className={(groupError)?"selects error-input":"selects"} 
														  //  defaultValue={group}
					                                        options={groupValue}
					                                        styles={colourStyles}
					                                        onChange={handleGroup}
					                                        placeholder={<div  className="italic-placeholder">Select...</div>}
															isDisabled=	{compCompany}				                                    />
					                  				</div>
                  								</div>
                  								<div className="col-md-3 device-compatabilty">
					                  				<div className={(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
					                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Company name</label>
														<input type="text" name="companyName" className={(nameError)?"form-control error-input":"form-control"} onChange={handleName} value={cName} placeholder="" readOnly={(dataReadonly)?true:false}  />
															{(compCompany)?(companyVerifiedLabel)?<span className="verfiefd-text">Verified</span>:<span className="notverified">Not Verified</span>:""}
													</div>
					                  			</div>
												<div className="col-md-3 device-compatabilty">
													
					                  				<div className={(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
					                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Country </label>
														  <Select value={country} className={(countryError)?"selects error-input":"selects"}
														    // defaultValue={country}
					                                        options={countryOption}
					                                        styles={colourStyles}
					                                     //   onChange={setCountry}
					                                        onChange={handleCountry}
					                                        placeholder={<div className="italic-placeholder">Select...</div>}
														    isDisabled={(dataReadonly)?true:false}
					                                    />
					                  				</div>
					                  			</div>
					                  			<div className="col-md-3 device-compatabilty">
					                  				<div className="row">
					                  					<div className="col-md-6">
							                  				<div className={(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
							                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Registration number</label>
																  	<input type="text" className={(regnoError)?"form-control error-input":"form-control"} onChange={handelRegNo} value={regno} placeholder="" maxLength={10} readOnly={(dataReadonly)?true:false}  />
																	{(compCompany)?(registartionNoVerification)?<span className="notverified">Not Verified</span>:"":""}
							                  				</div>
							                  			</div>

														<div className="col-md-6 date-pickercal">
															<div className={(dataReadonly)?"form-group haveaddon slecticons inActiveborder":"form-group haveaddon slecticons activeborder"}>
																<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Registration date</label>
																<div className={(dataReadonly)?"addonimgleft InActive float-childstwo":(regDateError)?"addonimgleft float-childstwo error-input":"addonimgleft float-childstwo "}>   
																					<DatePicker 
																						value={mainDateRange}
																						onChange={(e) => {handelDate(e)}}
																						numberOfMonths={1}
																						showOtherDays
																						format="DD-MM-YYYY"
																						readOnly={(dataReadonly)?true:false}
																						maxDate={new Date()}
																						/>
																</div>
															</div>
														</div>

					                  				</div>
					                  			</div>
												  
					                  		
												 
					                  			
					                  		</div>
					                  		<div className="row">
											   <div className="col-md-3 device-compatabilty">
					                  				<div className={(dataReadonly || lock)?"form-group inActiveborder vat-input":"form-group activeborder vat-input"}>
					                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Tax number</label>
					                  					<div className="form-group">
					                  						<div className={(taxError)?"havesuffixdrop error-input":"havesuffixdrop"}>
					                  							<span className="selects textbox">{taxPrefixName}</span>
					                  							<input type="text" className="form-control box-pad" onChange={handleTax} value={tax} placeholder="" maxLength={15} readOnly={(dataReadonly || lock )?true:false} />
					                  							<input type="text" className="form-control box-pad-right" placeholder="" value={preTaxLable} disabled={true}/>
					                  							{(compCompany)?(taxVerification)?<span className="notverified">Not Verified</span>:"":""}
																</div>
					                  					</div>
					                  				</div>
					                  			</div>
												  <div className="col-md-3 device-compatabilty">
					                  				<div className="form-group inActiveborder ">
					                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Account email</label>
														  <div className="addonMailleft ">
					                  				     	<input type="text" className={(emailError)?"form-control error-input":"form-control"}  value={props.compData?.companyEmail} placeholder="" readOnly={true} />
					                  				     	{(compCompany)?<span className="verfiefd-text">Verified</span>:""}
														 </div>
													</div>
					                  			</div>
												  <div className="col-md-3 device-compatabilty">
												     <div className={(editMode)?"form-group activeborder":(dataReadonly || localStorage.getItem('status') == 100 || localStorage.getItem('status') =='Completed')?"form-group inActiveborder":"form-group activeborder"}>
					                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Account mobile nr.</label>
					                  				    <div className="form-group ">
															<div className={(mobileError)?"havesuffixdrop error-input":"havesuffixdrop"}>
															
																<Select className={(mobileCodeError)?"selects error-input":"selects"}
																	value={mobileCode}
																	options={countryMobileCodeOption}
																	styles={phoneCodeStyles}
																	//onChange={setMobileCode}
																	onChange={handlePhoneCode}
																	captureMenuScroll = {true}
																	placeholder={<div></div>}
																	isDisabled={(editMode)?false:(dataReadonly || localStorage.getItem('status') == 100 || localStorage.getItem('status') =='Completed')?true:false}
																/>
															 <input type="tel" className="form-control" onChange={handleMobile} value={mobile} maxLength={10} placeholder="" readOnly={(editMode)?false:(dataReadonly || localStorage.getItem('status') == 100 || localStorage.getItem('status') =='Completed')?true:false} />
															 {(compCompany)?<span className={(isPhoneVerified)?"verfiefd-text":"notverified"}>{(isPhoneVerified)?"Verified":(mobNotVerifyTestShow)?"Not Verified":""}</span>:""}
															</div>
													    </div> 
                                                         
													</div>
					                  			</div>
												  <div className="col-md-3 device-compatabilty">
					                  				{/* <div className={(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}> */}
					                  				<div className="form-group inActiveborder">
					                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Business type</label>
														  <Select className={(btypeError)?"selects error-input":"selects"}
					                                        defaultValue={btype}
															options={bTypeOptions}
					                                        styles={colourStyles}
					                                        onChange={setBtype}
					                                        placeholder={<div  className="italic-placeholder">Select...</div>}
															isDisabled
															//isDisabled={(dataReadonly)?true:false}
					                                    />
					                  				</div>
					                  			</div>
					                  		</div>
					                  	</form>
                  					</div>
			    				</div>
			    			</div>
			    		</div>
			    	</div>
			    </div>
				</div>
				</>}
				</>}

			</div>
			<BrandFooter logo={props.imageData.footer_logo}/>
		</div>
		{(onSubmitLoader)?<div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>:""}
		</>
	);
}
export default Dashboard;