import React, {useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
//import 'font-awesome/css/font-awesome.min.css';
import "./assets/css/custom.css";
import Logo from "./assets/images/logologin.png";
import FooterLogo from "./assets/images/logologinFooter.png";
import { CirclesWithBar } from  'react-loader-spinner'
import Preloader from './component/layouts/preLoader';
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
/*const Login = React.lazy(() => import('./component/authentication/login'));
const ForgotCredentialsEmail = React.lazy(() => import('./component/authentication/forgot-credentials-email'));
const ForgotCredentialsPhone = React.lazy(() => import('./component/authentication/forgot-credentials-phone'));
const BrandDashboard = React.lazy(() => import('./component/brand/dashboard/index'));
const Boimages = React.lazy(() => import('./component/brand/boimages/index'));
const Products = React.lazy(() => import('./component/brand/products/index'));
const Bopics = React.lazy(() => import('./component/brand/products/bopics'));
const Importer = React.lazy(() => import('./component/brand/products/importer'));
const PageNotFound = React.lazy(() => import('./component/error/404'));
const Drag = React.lazy(() => import('./component/rnd/drag'));*/
import Login from './component/authentication/login';
import ForgotCredentialsEmail from './component/authentication/forgot-credentials-email';
import ForgotCredentialsPhone from './component/authentication/forgot-credentials-phone';
// import BrandDashboard from './component/brand/dashboard/index';
import Boimages from './component/brand/boimages/index';
// import RetailerBoimages from './component/brand/boimages/retailerBoimagesList';
import BoimagesCreater from './component/brand/boimages/creator';
import BoimagesCreaterCanvas from './component/brand/boimages/canvas';
import BoimagesTarget from './component/brand/boimages/targets';
import Products from './component/brand/products/index';
import Bopics from './component/brand/products/bopics';
import Importer from './component/brand/products/importer';
import PageNotFound from './component/error/404';
import ImageImporter from './component/brand/products/image-importer';
import PublisherURL from './component/brand/publisher/index';
import Drag from './component/rnd/drag';
import Table from './component/rnd/table';
import PreEnrrolment from './component/preenrrolment/index';
import AccountSetup from './component/accounts/index';
import PhoneVerification from './component/accounts/phoneVerification';
import CompanyAdmin from './component/accounts/companyAdmin';
import CompanyAdminRegistration from './component/accounts/companyAdminRegistration';
import PreCompanyLogoUpload from './component/accounts/preCompanyLogoUpload';
import BrandPreRegistrationInvitation from './component/preenrrolment/brandPreRegistrationInvitation';
import PreBrandEnroll from './component/accounts/preBrandEnroll';
import CompanyLogo from './component/accounts/companyLogo';
import Brands from './component/brand/index';
import BrandCreator from './component/brand/creator';
import BrandPreLogoUpload from './component/brand/preLogoUpload';
import BrandLogo from './component/brand/brandLogo';
import PreHashTags from './component/brand/preHashTags';
import TargetsHashtags from './component/brand/targetsHashtags';
import PreBoImage from './component/brand/preBOimage';
import PreStore from './component/store/preStore';
import PreCreateStore from './component/store/preCreateStore';
import BrandList from './component/brand/brandList';
import "./assets/css/bostage_a_custom.css";
import axios from 'axios';
import { API_URL,API_KEY_B } from './config/constant.js';
import CreateStore from './component/store/createStore';
import CreateOpenHours from './component/store/openHours';
import InviteStaff from './component/store/inviteStaffMember';
import StaffPreRegistrationInvitation from './component/preenrrolment/staffPreRegistrationInvitation';
import StoreDelivery from './component/store/delivery'; 
import RetailerCreator from './component/retailer/creator'; 
import BilingPlan from './component/billing/index';
import PreBilling from './component/billing/preBilling';
import PreBillingRetailer from './component/billing/preBillingRetailer';
import NewPreCreateStore from './component/store/newPreStoreCreate';
import RetailerStoreLogo from './component/store/retailerStoreLogo';
import BilingPlanRetailer from './component/billing/billingPlanRetailer';
import ThankyouWelcomeStore from './component/billing/thankyouWelcomeStore';
import ThankyouWelcomeRetailer from './component/billing/thankyouWelcomeRetailer';
import StoreList from './component/store/storeList';
import FFCList from './component/ffc/ffcList';
import CompanyList from "./component/accounts/companyList";
import ReatailerList from './component/retailer/retailerList';
import RetaillerInviteList from './component/retailer/retaillerInviteList'
import Dashboard from './component/dasboard/index'
import PreviewImage from './component/brand/boimages/previewImage'
//import SelectedPlan from './component/billing/selectedPlans';




const imageData = {
  "logo": Logo,
  "footer_logo": FooterLogo
};
function PrivateRoute({ children }) {
  const auth = localStorage.getItem('token');
  return auth ? children : <Navigate to="/" />;
}
function PublicRoute({ children }) {
  const auth = localStorage.getItem('token');
  const status = localStorage.getItem('status');
  if(auth && status === "Completed"){
    return <Navigate to="/account/company/list" />
  }/*else if(auth && status === "0"){
    return <Navigate to="/account/company" />
  }else if(auth && status === "10"){
    return <Navigate to="/account/company/logo" />
  }else if(auth && status === "25"){
    return <Navigate to="/brand/creator" />
  }else if(auth && status === "30"){
    return <Navigate to={"/brand/logo/"+localStorage.getItem("brandID")} />
  }else if(auth && status === "40"){
    return <Navigate to={"/brand/tags-target/"+localStorage.getItem("brandID")} />
  }else if(auth && status === "50"){
    return <Navigate to="/brand/pre-boimages" />
  }else if(auth && (status === "55" || status === "60")){
    return <Navigate to={"/boimages/canvas/"+localStorage.getItem("boimageID")} />
  }else if(auth && status === "70"){
    return <Navigate to={"/boimages/target/"+localStorage.getItem("boimageID")} />
  }else if(auth && status === "75"){
    return <Navigate to="/store/pre-store" />
  }*/else{
    return children;
  }
  //return auth ? <Navigate to="/products" />: children ;
}

const axiosConfig = {
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};


 function App() {
 
 


  const[comapnyDet,setCompanyDet] = useState("");
  const [updData, setUpdData] = useState("");
  const [preLoad, setPreLoad] = useState(true)
  useEffect(()=>{
    if(localStorage.getItem('token')){
     let formData ={
      "userID": localStorage.getItem('token')
     }
    axios.post(API_URL+'get-user-data'+API_KEY_B, formData, {axiosConfig}).then((res)=>{
      if(res.data.status === 200){
        let resData = {
          "status": res.data.data?.status,
          "completePercentage": res.data.data?.completePercentage,
          "AccountMenu": res.data.data?.AccountMenu,
          "DashboardMenu": res.data.data?.DashboardMenu,
          "BOimagesMenu": res.data.data?.BOimagesMenu,
          "FullfillmentCenterMenu": res.data.data?.FullfillmentCenterMenu,
          "StoresMenu": res.data.data?.StoresMenu,
          "RetailersMenu": res.data.data?.RetailersMenu,
          "ProductsMenu": res.data.data?.ProductsMenu,
          "BrandsMenu": res.data.data?.BrandsMenu,
          "PromotersMenu": res.data.data?.PromotersMenu,
          "companyName": (typeof res.data.data?.Companies != 'undefined')?res.data.data?.Companies[0]?.companyName:"",
          "companyLogo":res.data.data?.companyDetail?.logoURL,
          "BillingMenu":res.data.data?.BillingMenu
          }
          localStorage.setItem("__data",JSON.stringify(resData));
          localStorage.setItem("groupName",res.data.data?.groupData);
        /*deleteSpecificCache("MyCache")
        addDataIntoCache("MyCache", window.location.origin, res.data.data)*/
        setCompanyDet(res.data.data);
        if(!localStorage.getItem("brandID") && !localStorage.getItem("brandName")){
          if(res.data.data?.activeBrand){
            localStorage.setItem("brandID",res.data.data?.activeBrand?.brandID);
            localStorage.setItem("brandName",res.data.data?.activeBrand?.brandName);
          }          
        }
      }
    });
    }
  },[]);
  const addDataIntoCache = (cacheName, url, response) => {
    const data = new Response(JSON.stringify(response));
    if ('caches' in window) {
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  };
  const deleteSpecificCache = (cacheName) => {
    if ("caches" in window) {
      caches.delete(cacheName).then(function (res) {
        return res;
      });
    }
  };
  const loginFun = (d, statusPercentage) => {
   // setCompanyDet("");
    let formData ={
      "userID": d,
      "status": statusPercentage
     }
     
     if(localStorage.getItem('status')==100){
      localStorage.removeItem('storeID');
      localStorage.removeItem('boimageID');
    }  

   

    if(parseInt(localStorage.getItem("status")) <= parseInt(statusPercentage) || statusPercentage=="Completed"){
      localStorage.setItem("status", statusPercentage)
      axios.post(API_URL+'updateUserStatus' + API_KEY_B, formData, {axiosConfig}).then(res => {
        axios.post(API_URL+'get-user-data' + API_KEY_B, formData, {axiosConfig}).then((res)=>{
          if(res.data.status === 200){
            /*deleteSpecificCache("MyCache")
            addDataIntoCache("MyCache", window.location.origin, res.data.data)*/
            let resData = {
              "status": res.data.data?.status,
              "completePercentage": res.data.data?.completePercentage,
              "AccountMenu": res.data.data?.AccountMenu,
              "DashboardMenu": res.data.data?.DashboardMenu,
              "BOimagesMenu": res.data.data?.BOimagesMenu,
              "FullfillmentCenterMenu": res.data.data?.FullfillmentCenterMenu,
              "StoresMenu": res.data.data?.StoresMenu,
              "RetailersMenu": res.data.data?.RetailersMenu,
              "ProductsMenu": res.data.data?.ProductsMenu,
              "BrandsMenu": res.data.data?.BrandsMenu,
              "PromotersMenu": res.data.data?.PromotersMenu,
              "companyName": (typeof res.data.data?.Companies != 'undefined')?res.data.data?.Companies[0]?.companyName:"",
              "companyLogo":res.data.data?.companyDetail?.logoURL,
              "BillingMenu":res.data.data?.BillingMenu,
              // "billingPlan":(typeof res.data.data?.companyDetail != 'undefined')?res.data.data?.companyDetail?.billingPlan:"",
            }
            localStorage.setItem("__data",JSON.stringify(resData));
            localStorage.setItem("groupName",res.data.data?.groupData);
            setUpdData(res.data.data);        
            if(res.data?.data?.activeBrand){
                 if(localStorage.getItem('preEnrollData') ==null){
                      localStorage.setItem("brandID",res.data.data?.activeBrand?.brandID);
                       localStorage.setItem("brandName",res.data.data?.activeBrand?.brandName);
                    } 
               
            } 
          
          }
        });
      });
    }else{
      axios.post(API_URL+'get-user-data' + API_KEY_B, formData, {axiosConfig}).then((res)=>{
        if(res.data.status === 200){
          /*addDataIntoCache("MyCache", window.location.origin, res.data.data)*/
          let resData = {
              "status": res.data.data?.status,
              "completePercentage": res.data.data?.completePercentage,
              "AccountMenu": res.data.data?.AccountMenu,
              "DashboardMenu": res.data.data?.DashboardMenu,
              "BOimagesMenu": res.data.data?.BOimagesMenu,
              "FullfillmentCenterMenu": res.data.data?.FullfillmentCenterMenu,
              "StoresMenu": res.data.data?.StoresMenu,
              "RetailersMenu": res.data.data?.RetailersMenu,
              "ProductsMenu": res.data.data?.ProductsMenu,
              "BrandsMenu": res.data.data?.BrandsMenu,
              "PromotersMenu": res.data.data?.PromotersMenu,
              "companyName": (typeof res.data.data?.Companies != 'undefined')?res.data.data?.Companies[0]?.companyName:"",
              "companyLogo":res.data.data?.companyDetail?.logoURL,
              "BillingMenu":res.data.data?.BillingMenu,
              // "billingPlan":(typeof res.data.data?.companyDetail != 'undefined')?res.data.data?.companyDetail?.billingPlan:"",
            }
          localStorage.setItem("__data",JSON.stringify(resData))
          localStorage.setItem("groupName",res.data.data?.groupData);
          setUpdData(res.data.data);        
          if(res.data?.data?.activeBrand){
            if(localStorage.getItem('preEnrollData') ==null){
              localStorage.setItem("brandID",res.data.data?.activeBrand?.brandID);
              localStorage.setItem("brandName",res.data.data?.activeBrand?.brandName);
            } 
       
          }   
         // console.log("tetsss",res.data.data);  
          
        }
      });
    }    
  }
  useEffect(()=>{
    setCompanyDet(updData);
  },[updData])
  return (
    <BrowserRouter>   
      <Routes>
        <Route path="/" element={<PublicRoute><Login loginFun={loginFun} imageData={imageData}/></PublicRoute>} exact={true} />
        <Route path="/forgot-password-email" element={<PublicRoute><ForgotCredentialsEmail  imageData={imageData}/></PublicRoute>} exact={true} />
        <Route path="/forgot-password-phone" element={<PublicRoute><ForgotCredentialsPhone  imageData={imageData}/></PublicRoute>} exact={true} />
        {/* <Route path="/brand-dashboard" element={<PrivateRoute><BrandDashboard compData={comapnyDet} imageData={imageData}/></PrivateRoute>} exact={true} />  */}
        <Route path="/products" element={<PrivateRoute><Products compData={comapnyDet} imageData={imageData}/></PrivateRoute>} exact={true} />
        <Route path="/products/bopics" element={<PrivateRoute><Bopics compData={comapnyDet} imageData={imageData}/></PrivateRoute>} exact={true} />
        <Route path="/products/importer" element={<PrivateRoute><Importer compData={comapnyDet} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} />
        <Route path="/products/image-importer" element={<PrivateRoute><ImageImporter compData={comapnyDet} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} />
        <Route path="/boimages" element={<PrivateRoute><Boimages compData={comapnyDet}  imageData={imageData}/></PrivateRoute>} exact={true} />
        {/* <Route path="/boimages/retailer" element={<PrivateRoute><RetailerBoimages compData={comapnyDet} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} /> */}
        <Route path="/boimages/creater" element={<PrivateRoute><BoimagesCreater compData={comapnyDet} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} />
        <Route path="/boimages/creater/:BOimageIdData" element={<PrivateRoute><BoimagesCreater loginFun={loginFun} compData={comapnyDet} imageData={imageData}/></PrivateRoute>} exact={true} />
        
        <Route path="/boimages/canvas" element={<PrivateRoute><BoimagesCreaterCanvas compData={comapnyDet} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} />
        <Route path="/boimages/canvas/:BOimageIdData" element={<PrivateRoute><BoimagesCreaterCanvas compData={comapnyDet} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} />
        
        <Route path="/boimages/target/:BOimageIdData" element={<PrivateRoute><BoimagesTarget compData={comapnyDet} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} />
        <Route path="/boimages/pre-boimages" element={<PrivateRoute><PreBoImage compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="*" element={<PageNotFound  imageData={imageData}/>} />
        <Route path="/handyad/v/:processId" element={<PublisherURL />} exact={true} />
        <Route path="/drag" element={<Drag />} />
        <Route path="/table" element={<Table />} />
        <Route path="/products-new" element={<PrivateRoute><Products compData={comapnyDet} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/pre-enrrolment/:id" element={<PreEnrrolment loginFun={loginFun}   imageData={imageData}/>} exact={true} /> 
        <Route path="/account/company" element={<PrivateRoute><AccountSetup compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/account/company/:compId" element={<PrivateRoute><AccountSetup compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/account/company/phone-verification" element={<PrivateRoute><PhoneVerification loginFun={loginFun} compData={comapnyDet} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/account/company-admin" element={<PrivateRoute><CompanyAdmin compData={comapnyDet} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/account/company-admin/registration" element={<PrivateRoute><CompanyAdminRegistration loginFun={loginFun} compData={comapnyDet} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/account/company/pre-company-logo-upload" element={<PrivateRoute><PreCompanyLogoUpload loginFun={loginFun} compData={comapnyDet} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/account/company/list" element={<PrivateRoute><CompanyList loginFun={loginFun} compData={comapnyDet} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/register-brand-user/:id" element={<BrandPreRegistrationInvitation  imageData={imageData}/>} exact={true} /> 
        <Route path="/account/company/brand-pre-registration" element={<PrivateRoute><PreBrandEnroll loginFun={loginFun} compData={comapnyDet} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/account/company/logo" element={<PrivateRoute><CompanyLogo compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        {/* <Route path="/brand" element={<PrivateRoute><Brands compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} /> */}
        <Route path="/brand/creator" element={<PrivateRoute><BrandCreator compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/brand/creator/:brId" element={<PrivateRoute><BrandCreator compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/brand/brand-pre-logo-upload/:id" element={<PrivateRoute><BrandPreLogoUpload compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/brand/logo/:id" element={<PrivateRoute><BrandLogo compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/brand/pre-tags-target/:id" element={<PrivateRoute><PreHashTags compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/brand/tags-target/:id" element={<PrivateRoute><TargetsHashtags compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        
        <Route path="/brand" element={<PrivateRoute><BrandList compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/store/pre-store" element={<PrivateRoute><PreStore compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/store/pre-create-new-store" element={<PrivateRoute><NewPreCreateStore compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/store/pre-create-store" element={<PrivateRoute><PreCreateStore compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/store/retail-brand-logo" element={<PrivateRoute><RetailerStoreLogo compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/store" element={<PrivateRoute><CreateStore compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/store/:storeId" element={<PrivateRoute><CreateStore compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/store/open/:id" element={<PrivateRoute><CreateOpenHours compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/store/invite-staff/:id" element={<PrivateRoute><InviteStaff compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/register-staff-member/:id" element={<StaffPreRegistrationInvitation  imageData={imageData}/>} exact={true} /> 
        <Route path="/store/delivery/:id" element={<PrivateRoute><StoreDelivery compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
        <Route path="/retailer/create" element={<PrivateRoute><RetailerCreator compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/billing-plan" element={<PrivateRoute><BilingPlan compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/billing-plan/retailer" element={<PrivateRoute><BilingPlanRetailer compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/billing-plan/pre-billing" element={<PrivateRoute><PreBilling compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/billing-plan/pre-billing-retailer" element={<PrivateRoute><PreBillingRetailer compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/welcome-store" element={<PrivateRoute><ThankyouWelcomeStore compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/welcome-retailer" element={<PrivateRoute><ThankyouWelcomeRetailer compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/store/list" element={<PrivateRoute><StoreList compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/fulfillmentcenter-list" element={<PrivateRoute><FFCList compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/retailer" element={<PrivateRoute><ReatailerList compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/retailer/retailer-invite-list" element={<PrivateRoute><RetaillerInviteList compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} />
        <Route path="/boimages/priview/:BOimageIdData" element={<PrivateRoute><PreviewImage compData={comapnyDet} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} />
        {/* <Route path="/billing-plan/selected-plan" element={<PrivateRoute><SelectedPlan compData={comapnyDet} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} /> */}
      </Routes>
    </BrowserRouter>
  )


}

export default App;
