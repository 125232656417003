import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import axios from 'axios';
import TrunoverImage from './../../assets/images/turnoverboimage.jpg';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import ForBidden from '../layouts/denied';
import { API_URL, API_KEY_B } from './../../config/constant.js';

function Dashboard(props) {
	document.title = 'BOstageB - Brand Dashboard';
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	const [brandCount, setBrandCount] = useState("");
	const [storeCount, setStoreCount] = useState("");
	const [indepentStore, setIndepentStore] = useState("");
	//const [retaillerCount, setRetaillerCount] = useState("");
	const [ffcCount, setFfcCount] = useState("");
	const [campaignsCount, setCampaignsCount] = useState("");
	const [boimage, setBoimage] = useState("");
	const [gtins, setGtins] = useState("");
	const [productCount, setProductCount] = useState("");
	const [activeBoimages, setActiveBoimages] = useState("");
	//const [activeBoads, setActiveBoads] = useState("");
	//const [followers, setFollowers] = useState("");
	//const [shopper, setShopper] = useState("");
	const [totalPurchases, setTotalPurchases] = useState("");
	const [totalItemSold, setTotalItemSold] = useState("");
	const [totalTurnover, setTotalTurnover] = useState("");
	const [bestRetailer, setBestRetailer] = useState("");
	const [bestRetailerValue, setBestRetailerValue] = useState("");
	const [bestStoreName, setBeatStoreName] = useState("");
	const [bestStoreValue, setBestStoreValue] = useState("");
	const [bestCampaign, setBestCampaign] = useState("");
	const [bestCampaignValue, setBestCampaignValue] = useState("");
	const [bestBoimage, setBestBoimage] = useState("");
	const [bestBoimageValue, setBestBoimageValue] = useState("");
	const [bestOfOffline, setBestOfOfline] = useState("");
	const [bestOfOfflineValue, setBestOfOflineValue] = useState("");
	const [bestOfOfflineUnit, setBestOfOflineUnit] = useState("");
	const [bestOfOfflineUnitValue, setBestOfOflineUnitValue] = useState("");
	const [bestInfluencer, setBestInfluencer] = useState("");
	const [bestInfluencerValue, setBestInfluencerValue] = useState("");
	const [bestOnlinePlateForm, setBestOnlinePlateForm] = useState("");
	const [bestOnlinePlateFormValue, setBestOnlinePlateFormValue] = useState("");
	const [preLoader, setPreLoader] = useState(true);
	const [data1, setData1] = useState(0);


	useEffect(() => {
		setElementData(ref.current);

	}, []);

	// useEffect(() => {
	// 	getDashBoardDAta();
	// 	//setPreLoader(false);
	// }, [])

    
	useEffect(() => {
        if(localStorage.getItem('brandID')){
            getDashBoardDAta();
      }else{
        setPreLoader(false);
            } 
      },[localStorage.getItem('brandID')]);



	useEffect(() => {
		if (data1) {
			setPreLoader(false);

		}
	}, [data1,]);

	const getDashBoardDAta = () => {

		axios.post(API_URL + "get-dashboard-data" + API_KEY_B, { brandID: localStorage.getItem("brandID") }).then((res) => {
			//axios.post("http://localhost:7071/api/get-dashboard-data", { brandID: localStorage.getItem("brandID") }).then(res => {

			console.log(res?.data?.data);
			setBrandCount(res?.data?.data.totalBrand)
			setStoreCount(res?.data?.data.totalActiveStore)
			setIndepentStore(res?.data?.data.indepentstore)
			//setRetaillerCount(res?.data?.data.totalRetailler)
			setFfcCount(res?.data?.data.totalFFCStore)
			setCampaignsCount(res?.data?.data.activeCampains)
			setBoimage(res?.data?.data.totalBoimage)
			setGtins(res?.data?.data.totalGTINsData)
			setProductCount(res?.data?.data.totalProuctsData)
			setActiveBoimages(res?.data?.data.activeBoimage)
			//setActiveBoads(res?.data?.data.activeBoads)
			//setFollowers(res?.data?.data.totalFollowers)
			//setShopper(res?.data?.data.aciveShoppers)
			setTotalPurchases(res?.data?.data.totalPurchases)
			setTotalItemSold(res?.data?.data.totalItemsSold)
			setTotalTurnover(res?.data?.data.totalTurnover)
			setBestRetailer(res?.data?.data.bestRetailer)
			setBestRetailerValue(res?.data?.data.bestRetailerValuve)
			setBeatStoreName(res?.data?.data.bestStoreName)
			setBestStoreValue(res?.data?.data.bestStoreValue)
			setBestCampaign(res?.data?.data.bestCampaign)
			setBestCampaignValue(res?.data?.data.bestCampaignValue)
			setBestBoimage(res?.data?.data.bestBoimage)
			setBestBoimageValue(res?.data?.data.bestBoimageValue)
			setBestOfOfline(res?.data?.data.bestOfOffline)
			setBestOfOflineValue(res?.data?.data.bestOfOfflineValue)
			setBestOfOflineUnit(res?.data?.data.bestOfOfflineUnit)
			setBestOfOflineUnitValue(res?.data?.data.bestOfOfflineUnitValue)
			setBestInfluencer(res?.data?.data.bestInfluencer)
			setBestInfluencerValue(res?.data?.data.bestInfluencerValue)
			setBestOnlinePlateForm(res?.data?.data.bestOnlinePlateForm)
			setBestOnlinePlateFormValue(res?.data?.data.bestOnlinePlateForm)



			if (res?.data?.status) {
				setData1(1);
			}




		})
	}



	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
					{(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> :
						<>
							<div className="controlbar">
								<div className="productsearch leftsidetr">
									{/* <Link data-widget="search" to="#" role="button" className="button-not-active">
										<span className="btn btn-success"><i className="fa fa-search" aria-hidden="true"></i></span>
										<span className="searchprodicttext">Products</span>
									</Link> */}
								</div>


								<div className="centerbtn">
									<Link to="#" className="btn btn-success active">All</Link>
									<Link to="#" className="btn btn-success button-not-active">Boimages</Link>
									<Link to="#" className="btn btn-success button-not-active">Stores</Link>
									<Link to="#" className="btn btn-success button-not-active">Products</Link>
								</div>

								{/* <div className="rightcontrolserch">
							<div className="productsearch rightareas">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fa fa-newspaper-o" aria-hidden="true"></i></span>
									<span className="searchprodicttext">Publisher</span>
								</Link>
							</div>
							<div className="productsearch rightareas">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success active"><i className="fa fa-list" aria-hidden="true"></i></span>
									<span className="searchprodicttext">List</span>
								</Link>
							</div>
							<div className="productsearch rightareas">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fa fa-line-chart" aria-hidden="true"></i></span>
									<span className="searchprodicttext">Stats</span>
								</Link>
							</div>

						</div> */}


							</div>

							<div className="dasharea">
								<>
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Brands</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{brandCount}</div>
														<div className="numberstring">Total Brands</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Own stores</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{storeCount}</div>
														<div className="numberstring">Total Stores</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Independent stores</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{indepentStore}</div>
														<div className="numberstring">Total Independent Stores</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Active FFCenters</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{ffcCount}</div>
														<div className="numberstring">Total Active FFCenters</div>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>BOimages</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{boimage}</div>
														<div className="numberstring">Total BOimages</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Active BOimages</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{activeBoimages}</div>
														<div className="numberstring">Total active BOimages</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Products</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{productCount}</div>
														<div className="numberstring">Total Products </div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>GTINs</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{gtins}</div>
														<div className="numberstring">Total GTINs</div>
													</div>
												</div>
											</div>
										</div>

										<div className="row graphical">
											<div className="col-md-12">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Turnover from <strong>BO</strong>images</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="row">
															<div className="col-md-9">
																<div className="showgrapDiv">
																	<img src={TrunoverImage} />
																</div>
															</div>
															<div className="col-md-3">
																<div className="progressbar-Graphs">
																	<div className="right-graphs">
																		<div className="showcount-Graph">{totalPurchases}</div>
																		<div className="graph-totalam">Total purchases in period</div>
																		<div className="progress">
																			<div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ "width": "70%" }}>
																				<span className="showGraphvalueRight">+70%</span>
																			</div>
																		</div>
																	</div>
																	<div className="right-graphs">
																		<div className="showcount-Graph">{totalItemSold}</div>
																		<div className="graph-totalam">Total items sold in period</div>
																		<div className="progress">
																			<div className="progress-bar" role="progressbar" aria-valuenow="12" aria-valuemin="0" aria-valuemax="100" style={{ "width": "12%" }}>
																				<span className="showGraphvalueRight">+12%</span>
																			</div>
																		</div>
																	</div>
																	<div className="right-graphs">
																		<div className="showcount-Graph">Kr {totalTurnover}</div>
																		<div className="graph-totalam">Total turnover in period</div>
																		<div className="progress">
																			<div className="progress-bar" role="progressbar" aria-valuenow="22" aria-valuemin="0" aria-valuemax="100" style={{ "width": "22%" }}>
																				<span className="showGraphvalueRight">+22%</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>
										</div>

										<div className="row downGraphs">
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Best retailer (trunover)</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{bestRetailer}</div>
														<div className="numberstring">Brand: -</div>
														<div className="numberstring rightPost">kr {bestRetailerValue}</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Best store (turnover)</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{bestStoreName}</div>
														<div className="numberstring">Retailer: -</div>
														<div className="numberstring rightPost">kr {bestStoreValue}</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Best campaign (turnover)</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{bestCampaign}</div>
														<div className="numberstring">Brand: -</div>
														<div className="numberstring rightPost">kr {bestCampaignValue}</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Best BOimage</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{bestBoimage}</div>
														<div className="numberstring">Campaign: -</div>
														<div className="numberstring rightPost">kr {bestBoimageValue}</div>
													</div>
												</div>
											</div>
										</div>

										<div className="row downGraphs">
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Best off-line (platform)</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{bestOfOffline}</div>
														<div className="numberstring">Brand: -</div>
														<div className="numberstring rightPost">kr {bestOfOfflineValue}</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Best off-line unit</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{bestOfOfflineUnit}</div>
														<div className="numberstring">Retailer: -</div>
														<div className="numberstring rightPost">kr {bestOfOfflineUnitValue}</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Best influencer</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{bestInfluencer}</div>
														<div className="numberstring">Brand: -</div>
														<div className="numberstring rightPost">kr {bestInfluencerValue}</div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="smallinfobox">
													<div className="smallboxheader">
														<h3>Best online platform</h3>
														<Link to="#">Check</Link>
													</div>
													<div className="smallboxcontent">
														<div className="numberbg">{bestOnlinePlateForm}</div>
														<div className="numberstring">Campaign: -</div>
														<div className="numberstring rightPost">kr {bestOnlinePlateFormValue}</div>
													</div>
												</div>
											</div>
										</div>


									</div>
								</>
							</div>

						</>}
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	);
}
export default Dashboard;