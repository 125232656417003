import React, { useRef, useEffect, useState } from "react";
import { Link ,useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import ExclamtionBlue from './../../assets/images/exclation-blue.png';
import BoimageOne from './../../assets/images/uploads/boimageone.jpg';
import BoimageTwo from './..//../assets/images/uploads/boimagetwo.jpg';
import { API_URL } from './../../config/constant.js';
import  DatePicker from "react-multi-date-picker";
import { Calendar, DateObject } from "react-multi-date-picker"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import axios from 'axios';
import { useBeforeunload } from 'react-beforeunload';
import BlueAccountOn from './../../assets/images/blueaccounton.png';
import BlueBrandOn from './../../assets/images/bluebrandon.png';
import BlueBOimageIsOn from './../../assets/images/blueboimageson.png';
import BlueStoresOn from './../../assets/images/bluestoreson.png';
import CircleTick from './../../assets/images/circle-tick.png';
import GrayBrandoff from './../../assets/images/graybrandoff.png';
import GrayBoimgoff from './../../assets/images/grayboimageson.png';
import Graystoreoff from './../../assets/images/graystoreson.png';
import Swal from 'sweetalert2';
import StepCombination from '../layouts/commonLayout';
import ForBidden from './../layouts/denied';

toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - Create Company Admin ';
	const ref = useRef();
    const [elementData, setElementData] = useState("");
   	const [status, setStatus] = useState("");
	const [isPhoneVerified, setIsPhoneVerified] = useState(false);
	
	useEffect(()=>{
		//localStorage.setItem('tel','');
	});
    const [denied, setDenied] = useState(false)
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		if(!_data?.AccountMenu){
			setDenied(true)
		}else{
			setDenied(false)
		}


	})


    useEffect(()=>{   
     
      if(typeof props?.compData?.companyDetail != "undefined"){
          if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){ 
          setIsPhoneVerified(props?.compData?.companyDetail?.isMobileVerified);
           console.log(props?.compData?.companyDetail?.isMobileVerified);
      }
    }

  },[props.compData]); 
 

    
     

	return(
		<>
		<div className="wrapper" ref={ref} id="myscreen">
			<BrandNavigation data={elementData} cdata={props.compData}/>
			<BrandAside cdata={props.compData} />
			<div className="conten-wrapper enroll-wrapper">
              {(denied)?<ForBidden />:<> 
			    <div className="controlbar">
			    	<div className="productsearch leftsidetr notactive">
                        <Link data-widget="search" className="button-not-active" to="#" onClick={(e) => e.preventDefault()} role="button">
                            <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                            <span className="searchprodicttext text-center">Accounts</span>
                        </Link>
                    </div>
                    <div className="centerbtn">
                        <Link to={(status === 'Completed')?"/account/list":"#"} className={(status === 'Completed')?"btn btn-success":"btn btn-success button-not-active"}>Companies</Link>
                        <Link to="/account/company" className="btn btn-success active">New Company</Link>
                    </div>
                    <div className="rightcontrolserch">
                        <div className="productsearch rightareas">
                            <Link data-widget="search" to="/account/company" role="button">
                                <span className={(isPhoneVerified)?"btn btn-success":"btn btn-success active"}><i className="fa-thin fa-fingerprint"></i></span>
                                <span className="searchprodicttext">ID</span>
                            </Link>
                        </div>
                        <div className={(isPhoneVerified)?"productsearch rightareas":"productsearch rightareas notactive"}>
                            <Link data-widget="search" to={(isPhoneVerified)?"/account/company/logo":""} role="button">
                                <span className={(isPhoneVerified)?"btn btn-success active":"btn btn-success "}><i className="fa-thin fa-sign-hanging"></i></span>
                                <span className="searchprodicttext">Logo</span>
                            </Link>
                        </div>
						<div className="productsearch rightareas notactive">
                            <Link data-widget="search" to="#" role="button">
                                <span className="btn btn-success"><i className="fa-thin fa-users"></i></span>
                                <span className="searchprodicttext">Users</span>
                            </Link>
                        </div>
                    </div>
                </div>
				
				<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									
									<StepCombination cdata={props.compData}/>

								</div>
							</div>
						</div>
					</div>
			   
			    <div className="creators-section">
			    	<div className="container-fluid">
			    		<div className="row">
                            <div className="col-md-12">
                            <div className="small-formas preCompanyLogo" >
                                    <div className="blueicons-area">
                                        <img src={ExclamtionBlue} />
                                    </div>
                                    <p className="verifying-text wt" >Let's upload the company logo now
                                    <br/>
									(not the Brand logo yet)
									<br/><br/>
 									</p>
                                    <p className="wt-color">You can skip adding the logo for now and continue to adding the Brand <br/>to 
                                    the account with the 'ENROLL BRAND' button. <br/>
                                   
									</p>
                                  
                                    <div className="send-blockbtn twobtnsets" >
                                    <Link to={(JSON.parse(localStorage.getItem("__data"))?.BrandsMenu)?"/brand/creator":"#"} className="btn btn-success button-not-active" >ENROLL BRAND</Link> 
                                    <Link to="/account/company/logo" >UPLOAD LOGO</Link>
                                    </div>
                                   
                                    
                                </div>
                            </div>
			    			
			    		</div>
			    	</div>
			    </div>
                </>}
			</div>
			<BrandFooter logo={props.imageData.footer_logo}/>
		</div>
		
		</>
	);
}
export default Dashboard;