import React, {useState} from "react";
import { Link } from "react-router-dom";
import {toast} from 'react-toastify';
import axios from 'axios';
import { API_KEY_B, API_URL } from './../../config/constant.js';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./../layouts/header";
import Footer from "./../layouts/footer";
toast.configure();
function ForgotCredentialEmail(props) {
  document.title = 'BOstageB - Forgot Credentials';
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [btnText, setBtnText] = useState("GO");
  const [buttonDisable, setButtonDisable] = useState(false);
  const handleEmail = (e) => {
    setEmailError(false);
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(handleValidation()){
      setButtonDisable(true);
      setBtnText('');
      const formData = {
        "data":email,
        "type":"email"
      }
      axios.post(API_URL+'forgot-password'+API_KEY_B, formData).then((res)=>{
        if(res.data.status === 200){
          setEmail('');
          toast.success(res.data.success_message)
        }else{
          toast.error(res.data.error_message)
        }
        setBtnText("GO")
        setButtonDisable(false);
      });
    }
  }
  const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }
  const handleValidation = () => {
    let formIsValid = true;
    if(!email){
      formIsValid = false;
      setEmailError(true);
    }else if(!validateEmail(email)){
      formIsValid = false;
      setEmailError(true);
      toast.error('Please enter valid email')
    }
    return formIsValid;
  }
  return (
    <>
      <div className="loginwindow">
        <Header />
        <div className="loginscreens">
          <div className="container">
            <div className="loginwidn">
              <div className="row">
                <div className="col-md-12">
                  <div className="logoineerforgot">
                    <h3>No Worries, Let's Try This</h3>
                    <p>If you know the email address that was used to created the account please insert it down below. Otherwise, use the 'Can't remember' link.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="loginforms forgot-credentials">
                    <form className="form-inline" onSubmit={handleSubmit} autoComplete="off">
                      <p>
                        <i className="fa fa-info-circle" aria-hidden="true"></i> Account email
                      </p>
                      <div className="form-group havewemial">
                        <div className="input-group">
                          <span className="input-group-addon"><i className="fa fa-envelope-open" aria-hidden="true"></i></span>
                           <input type="text" className={(emailError)?"form-control emailfield error-input":"form-control emailfield"} id="email" placeholder="" value={email} onChange={handleEmail}/>                           
                        </div>
                        <p>
                          <Link to="/forgot-password-phone">Can't remember</Link>
                            </p>
                      </div>                      
                      <button type="submit" className="btn btn-default" disabled={(buttonDisable)?'disabled':'' }>
                        {btnText}
                        {(buttonDisable)?<i className="fa fa-spinner fa-spin" style={{"fontSize":"24px"}}></i>:""}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer logo={props.imageData.footer_logo}/>
   </>
  );
}

export default ForgotCredentialEmail;