import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <div className="topheader">
              <h3>The Botag Technology</h3>
              <div className="topheadcontent">
                <p>
                  Turn stores into fulfillment centers. Including any
                  independent stores selling your Brand.
                </p>
                <p>
                  No credentials yet? No worries.
                  <br />
                  Just let us know you want to join.
                </p>
                <div className="enrollband">
                  <Link to="#">Enroll My Brand</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}