import React, { useEffect, useState} from "react";
import { Prompt } from "react-router-dom";
const useUnsavedChangesWarning = (name, caption,genderTarget,ageGroups,lifestyle,majorMarket,hashtag, message = "Are you sure you want to leave this page?") => {
	const [isDirty, setDirty] = useState(false);
	useEffect(() => {
		window.onbeforeunload = isDirty && (() => message)
		return () => {
			window.onbeforeunload = null
		}
	}, [isDirty])
	useEffect(() => {
		if(name || caption ||genderTarget ||ageGroups ||lifestyle || majorMarket || hashtag){
			setDirty(true)
		}else{
			setDirty(false)
		}
	},[name, caption, genderTarget, ageGroups ,lifestyle,majorMarket,hashtag])
	const routerPrompt = <Prompt when={isDirty} message={message} />
	return [routerPrompt, () => isDirty, () => setDirty(false)];
}
export default useUnsavedChangesWarning;