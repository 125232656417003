import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
const sasToken = '?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-01-24T00:00:58Z&st=2022-09-27T16:00:58Z&spr=https&sig=fhQXTT0qn%2F%2Fd3oRJu%2FEhqGcgY9zVHJW7RIlw%2FVSfiV0%3D';
const storageAccountName = 'bo7storage7account'; 
export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}
const getBlobsInContainer = async (containerClient: ContainerClient, containerName) => {
  const returnedBlobUrls: string[] = [];
  for await (const blob of containerClient.listBlobsFlat()) {
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
}
const createBlobInContainer = async (containerClient: ContainerClient, files: File) => {
 
  let res = '';
  files.map( async (file, key) => {
    const blobClient = containerClient.getBlockBlobClient(file.name);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    res = await blobClient.uploadData(file, options);
  })
  
  return true;
}
const uploadFileToBlob = async (file: File | null, containerName): Promise<string[]> => {
  if (!file.length) return [];
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  /*await containerClient.createIfNotExists({
    access: 'container',
  });*/
  let fileUpload = await createBlobInContainer(containerClient, file);
  return fileUpload;//getBlobsInContainer(containerClient, containerName);
};

export default uploadFileToBlob;

