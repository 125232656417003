import React, { useRef, useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import BrandNavigation from "./../../layouts/BrandDashboardHeader";
import BrandAside from "./../../layouts/BrandAside";
import BrandFooter from "./../../layouts/BrandFooter";
import { read, utils } from 'xlsx';
import { Modal } from "react-bootstrap";
import DataTable , { createTheme } from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { Box } from './Box.js'
import { Dustbin } from './Dustbin.js'
import axios from 'axios';
import { API_URL , API_KEY_B } from './../../../config/constant.js';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './../../../assets/css/checkbox.css'
import errorSuccess from './../../../assets/images/icons8-check-64.png'
import errorWarning from './../../../assets/images/icons-error-48.png';
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning"
import StepCombination from '../../layouts/commonLayout';
import LoaderSvg from './../../../assets/images/loader.svg';
import LoaderIcon from './../../../assets/images/loader-icon.png';
import ForBidden from './../../layouts/denied';
toast.configure();
createTheme('solarized', {
  text: {
    primary: '#fff',
    secondary: '#fff',
  },
  background: {
    default: '#10141B',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#333F50',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');
function Dashboard(props) {
  document.title = 'BOstageB - Product Importer';
  const ref = useRef();
  const [elementData, setElementData] = useState("");
  const [progressBar, setProgressBar] = useState(0);
  const [brandID, setBrandID] = useState("");
  const [resPop, setResPop] = useState(false);
  const [preLoader, setPreLoader] = useState(true);
  const [onSubmitLoader, setOnSubmitLoader] = useState(false);
  const [filePreviewButtonDisable, setFilePreviewButtonDisable] = useState(true)
  const [appPreviewButtonDisable, setAppPreviewButtonDisable] = useState(true)
  const [saveButtonDisable, setSaveButtonDisablee] = useState(true)
  const [productList, setProductList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [didectedFields, setDidectedFields] = useState([]);
  const [draggedData, setDraggedData] = useState([]);
  const [draggedDataSecond, setDraggedDataSecond] = useState([]);
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning(fileName)
  const[previuosPosData,setPreviousPosData] = useState([]); 
  const [chevShow, setChevShow] = useState(0);
  const [show, setShow] = useState(false);
  const [opt,setOpt] = useState(0);
  const [temD, setTemD] = useState([]);

  let navigate = useNavigate();
  /* useEffect(() => {
    setElementData(ref.current);
    setBrandID(props?.compData?.activeBrand?.brandID)
  },[props?.compData?.activeBrand?.brandID]); */
  
  const [denied, setDenied] = useState(false)
 
    useEffect(() => {
      let _data = JSON.parse(localStorage.getItem("__data"));
      
      if(typeof props?.compData?.companyDetail != "undefined"){
        if(_data){  
          if(!_data?.ProductsMenu){
            // setPreLoader(false);
            // setDenied(true)

          }else{
            //setDenied(false)
          }
        }
       }
      
    },[props.compData]);


  useEffect(() => {
    setPreLoader(false)
    setElementData(ref.current);
    setBrandID(localStorage.getItem('brandID'));
  },[localStorage.getItem('brandID')]);

 
    
    const handleClose = () => setShow(false);
    const handleShow = () => {
                              setShow(true);
                            //  console.log("data---",data);
                            }
    const fullScreenCheck= (e) => {
      let fulls=localStorage.getItem('fullscreen');
        if(fulls=='full'){
             document.body.requestFullscreen();
      }
    }
    useEffect(() => {
      setPreviousPosData(previuosPosData)
    },[previuosPosData])
    const readImporterFile = (e) => {
        setPreviousPosData([])
        const files = e.target.files;
        if (files.length) {
            setTemD([])

            const file = files[0];
            setFileName(file.name);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const didectedHeaders = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                          header: 1,
                          defval: '',
                          blankrows: false
                    });
                    const didectedData = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                      defval: '-',
                      blankrows: true
                    });
                    let customHeader = [];
                    didectedHeaders[0].map((value, index) => {
                        customHeader.push({"name": value, "type": "text"});
                    }); 
                    setDidectedFields(customHeader);
                    setProductList(didectedData)
                    setFilePreviewButtonDisable(false)
                    setAppPreviewButtonDisable(true)
                    setSaveButtonDisablee(false)
                }
            }
        }
    }
    var data = [];
    productList.map((value, index) => {
        Object.keys(value).forEach((key) => {
           var replacedKey = key.replace(/ /g,'_').replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_');
           if (key !== replacedKey) {
              value[replacedKey] = (typeof value[key] !== "undefined")?value[key]:"-";
              if(typeof value[key] !== "undefined"){
                delete value[key];
              }
            }
        });
        data.push(value);
    })
    //console.log('productList', productList, didectedFields)
    const columns = [];
    didectedFields.map((res) => {
      let selector = (((res.name).toString())?.split(' ').join('_')).replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
      let dataHeader = {
            name: res.name,
            selector: selector,
            sortable: true,
        }
        columns.push(dataHeader)
    })
    
   // console.log("data",data);
  //  console.log("coloumn",columns);

    
    const [droppedBoxNames, setDroppedBoxNames] = useState([]);
    const isDropped = (boxName) => {  
        return droppedBoxNames.indexOf(boxName) > -1
    }
    const [dustbins, setDustbins] = useState([
        { accepts: ['text'], lastDroppedItem: null ,label:"Product Name"}  
    ]);
     const [dustbins1, setDustbins1] = useState([
      { accepts: ['text'], lastDroppedItem: null,label:"Description" }  
  ]);
  const [dustbins2, setDustbins2] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Short Description" }  
  ]);
  const [dustbins3, setDustbins3] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Composition" }  
  ]);
  
  const [dustbins4, setDustbins4] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Maintenance" }  
  ]);
  
  const [dustbins5, setDustbins5] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Country of origin" }  
  ]);
  
  const [dustbins6, setDustbins6] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Color Name" }  
  ]);
  
  const [dustbins7, setDustbins7] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Size" }  
  ]);
  
  const [dustbins8, setDustbins8] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Barcode" }  
  ]);

  const [dustbins9, setDustbins9] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Reference Code" }  
  ]);
  const [dustbins10, setDustbins10] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Main Image" }  
  ]);
  const [dustbins11, setDustbins11] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Eco Statement" }  
  ]);

  const [dustbins12, setDustbins12] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Factory/Origin" }  
  ]);

  const [dustbins13, setDustbins13] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Image 2" }  
  ]);

  const [dustbins14, setDustbins14] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Image 3" }  
  ]);
  
  const [dustbins15, setDustbins15] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Image 4" }  
  ]);
  const [imageFiveDustbin, setImageFiveDustbin] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Image 5" }  
  ]);
  const [productTypeDustbin, setProductTypeDustbin] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Product Type" }  
  ]);
  const [colorCodeDustbin, setColorCodeDustbin] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Color Code" }  
  ]);
  const [noteDustbin, setNoteDustbin] = useState([
    { accepts: ['text'], lastDroppedItem: null,label:"Note" }  
  ]);
   
  //const [dataS,setDataS] =useState([{leftItemIndex:100,boxIndex:100,boxName:"test"}])
  const [dataS,setDataS] =useState([])
  
   
  /*
  const fileList =(index, item,label) =>{
 
    didectedFields.map((e ,i) => {
      if(e.name==item.name){
        console.log(e,i);
         const ty = dataS?.map((m,ii)=>{
              if(m.boxIndex!=index){
                console.log("yyy",m.boxIndex,index);
              let da ={leftItemIndex:i,boxIndex:index,boxName:label};
              setDataS((dataS)=>[...dataS,da]); 
              }
         })
        }   
      
        
  });
  }

  */


//sam
  
  const fileList = (index, item, label) =>{ 
    setTemD(temD => [...temD, {leftItemIndex:index, boxName:label, itemName:item.name}]);

     let filterDataS = dataS.filter(r =>{return r.boxName != label })  
     filterDataS.push({leftItemIndex:index, boxName:label, itemName:item.name});
     setDataS(filterDataS);

  }
  useEffect(() => {
    //console.log("data temp s",temD);
    temD.map((_v,_i) =>{
      let inss = didectedFields.map(e => e.name).indexOf(_v?.itemName);
      _v.leftItemIndex = inss;
      return _v
    })
       
    // dataS.filter(r => { return temD. } )

    //setDataS(temD)
  },[temD])
  const rmvData = (label) =>{ 
    // console.log(label);
    const dat = dataS.filter((e) => {
          if(e.boxName!=label){
            return e;
          }
    });
    setDataS(dat);
  }
 
  /*
  useEffect(()=>{
    console.log("data previous",dataS);
  },[dataS])
 */

  const handleDrop = useCallback(
    (index, item,label) => {
      
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
     fileList(index, item,label);
  //console.log("trtd",item,name);

      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins]
  )



  const handleDrop1 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins1(
        update(dustbins1, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins1],
  )

  const handleDrop2 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins2(
        update(dustbins2, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins2],
  )

  const handleDrop3 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins3(
        update(dustbins3, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins3],
  )
  
  const handleDrop4 = useCallback( (index,item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins4(
        update(dustbins4, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins4],
  )
  
  const handleDrop5 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins5(
        update(dustbins5, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins5],
  )
  
  const handleDrop6 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins6(
        update(dustbins6, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins6],
  )
 
  
  const handleDrop7 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins7(
        update(dustbins7, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins7],
  )
  
  const handleDrop8 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins8(
        update(dustbins8, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins8],
  )
  
  const handleDrop9 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins9(
        update(dustbins9, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins9],
  )
  
  const handleDrop10 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins10(
        update(dustbins10, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins10],
  )
  
  const handleDrop11 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins11(
        update(dustbins11, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins11],
  )

  
  const handleDrop12 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins12(
        update(dustbins12, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins12],
  )

  
  const handleDrop13 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins13(
        update(dustbins13, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins13],
  )

  
  const handleDrop14 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins14(
        update(dustbins14, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins14],
  )

  const handleDrop15 = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setDustbins15(
        update(dustbins15, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins15],
  )

  const handleProductType = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setProductTypeDustbin(
        update(productTypeDustbin, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, productTypeDustbin],
  )

  const handleColorCode = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setColorCodeDustbin(
        update(colorCodeDustbin, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, colorCodeDustbin],
  )
  const handleNote = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setNoteDustbin(
        update(noteDustbin, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, noteDustbin],
  )
  
  const handleImageFive = useCallback(
    (index, item,label) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      fileList(index, item,label);
      setImageFiveDustbin(
        update(imageFiveDustbin, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, imageFiveDustbin],
  )

 let arr=[];
 
  const clear =((ele,lbl)=>{
    setResPop(false);
    let item = ele;
    let index = droppedBoxNames.indexOf(item);
    droppedBoxNames.splice(index, 1);

   setDroppedBoxNames(
    update(droppedBoxNames, item ? { $push:[""] } : { $push: [] }),
  )
 // console.log("previousArray - "+ JSON.stringify(arr));
  let lblRm= lbl.split(' ').join('_');
      lblRm= lblRm.split('/').join('_');

  let inx = arr.findIndex( ar => ar[lblRm] );
  arr.splice(inx, 1);  
  //console.log("AfterDelArray - "+ JSON.stringify(arr));
   
  rmvData(lbl);
  


});





 const arrData = ((inx,val) => {
    //  console.log("arrData",inx,val);
      let ob = cunstObj(inx,val);
      arr.push(ob);
      setDraggedData(JSON.stringify(arr));  
      setResPop(true);       
 })

 const cunstObj =((inx,val) =>{
  //console.log("val",typeof val);
    let value = val?.toString()?.split(' ')?.join('_');  
        value = value?.split('/')?.join('_');  
    let indx = inx?.split(' ')?.join('_');   
        indx = indx?.split('/')?.join('_');   
      return {[indx]:value}
 })

 /**************save data************** */ 
    let objs = {};
  const searchDataInDB = () =>{ 
  if(draggedData.length){
    let jsonObjectArray = JSON.parse(draggedData);
    jsonObjectArray.map((v, i) => {
      let key = Object.keys(v);
      let value = Object.values(v);
      objs[key] = value[0];
    })
  }

    
    let formData = [];
    productList.map((value, index) => {
      
      let postData = {
        "name": (objs.Product_Name)?value[objs.Product_Name].toString():"",
        "productType": (objs.Product_Type)?value[objs.Product_Type].toString():"",
        "description": (objs.Description)?value[objs.Description].toString():"",
        "shortDescription": (objs.Short_Description)?value[objs.Short_Description].toString():"",
        "composition": (objs.Composition)?value[objs.Composition].toString():"",
        "maintenance": (objs.Maintenance)?value[objs.Maintenance].toString():"",
        "countryOfOrigin": (objs.Country_of_origin)?value[objs.Country_of_origin].toString():"",
        "color": (objs.Color_Name)?value[objs.Color_Name].toString():"",
        "colorCode": (objs.Color_Code)?value[objs.Color_Code].toString():"",
        "size": (objs.Size)?value[objs.Size].toString():"",
        "referenceCode": (objs.Reference_Code)?value[objs.Reference_Code].toString():"",
        "barcode": (objs.Barcode)?value[objs.Barcode].toString():"",
        "mainImage": (objs.Main_Image)?value[objs.Main_Image].toString():"",
        "image_1": (objs.Image_2)?value[objs.Image_2].toString():"",
        "image_2": (objs.Image_3)?value[objs.Image_3].toString():"",
        "image_3": (objs.Image_4)?value[objs.Image_4].toString():"",
        "image_4": (objs.Image_5)?value[objs.Image_5].toString():"",
        "notes": (objs.Note)?value[objs.Note].toString():"",
        "ecoStatement": (objs.Eco_Statement)?value[objs.Eco_Statement].toString():"",
        "factoryOrigin": (objs.factoryOrigin)?value[objs.factoryOrigin].toString():""
      }
      formData.push(postData);
    })
    let formJsonData = {
      "brandID": brandID,
      "postData": formData,
     
    }
    if(handleValidation(objs)){
      setFilePreviewButtonDisable(true);
      setAppPreviewButtonDisable(true);
      setSaveButtonDisablee(true);
      setOnSubmitLoader(true);
     axios.post(API_URL+'checkProductDataFromExcelToDb'+API_KEY_B, formJsonData).then((res) => {
     // axios.post("http://localhost:7071/api/checkProductDataFromExcelToDb",formJsonData).then((res) => {
            //console.log("res000",res.data.data);
            if(res.data?.status==200){   
                  setFilePreviewButtonDisable(false);
                  setAppPreviewButtonDisable(true);
                  setSaveButtonDisablee(false);
                  setOnSubmitLoader(false);
                 if(res.data?.dataStatus==true){
                  SaveExistingFileData();  
                 }else{
                  SaveDistribution();
                 }
            }
     })
    }
} 

/**********************get Conformation for fileUpload of existing data*************** */
const SaveExistingFileData = (e) => {

  Swal.fire({
    text: 'One or more items are already saved. Uploading this file will update their data. Do you want to upload the file?',
    icon: 'success',
    showDenyButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#205380',
    denyButtonColor: '#205380',
    confirmButtonText: 'Yes',
    denyButtonText: 'No',
    background: '#1d2530',
    color: '#fff',
    iconColor: "#0B0",
    width: "500px",
    padding: "10px"
  }).then((result) => {
    if (result.isConfirmed) {
       SaveDistribution();
    } 
    else if (result.isDenied) {
         
      setProductList([]);
      setTemD([])
      setDidectedFields([]);
      setFileName("");
      setFilePreviewButtonDisable(true)
      setAppPreviewButtonDisable(true)
      setSaveButtonDisablee(true)
      setDustbins(update(dustbins, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins1(update(dustbins1, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins2(update(dustbins2, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins3(update(dustbins3, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins4(update(dustbins4, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins5(update(dustbins5, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins6(update(dustbins6, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins7(update(dustbins7, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins8(update(dustbins8, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins9(update(dustbins9, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins10(update(dustbins10, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins11(update(dustbins11, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins12(update(dustbins12, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins13(update(dustbins13, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins14(update(dustbins14, {[0]: {lastDroppedItem: {$set: '',}}}))
      setDustbins15(update(dustbins15, {[0]: {lastDroppedItem: {$set: '',}}}))
      setImageFiveDustbin(update(imageFiveDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
      setProductTypeDustbin(update(productTypeDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
      setColorCodeDustbin(update(colorCodeDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
      setNoteDustbin(update(noteDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
      
      
      toast.error("File not uploaded", {
        theme: "dark",
        icon: ({theme, type}) =>  <img src={errorWarning}/>,
        onClose: () => {
         
        }
      });
     
    }
  })

 
} 


/******************************************* */

 const SaveDistribution = (e) => {
 
  if(draggedData.length){
    let jsonObjectArray = JSON.parse(draggedData);
    jsonObjectArray.map((v, i) => {
      let key = Object.keys(v);
      let value = Object.values(v);
      obj[key] = value[0]
    })
  }
  
  if(handleValidation(obj)){

  /**/
  Swal.fire({
    text: 'Next upload should respect this distribution?',
    icon: 'success',
    showDenyButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#205380',
    denyButtonColor: '#205380',
    confirmButtonText: 'Yes',
    denyButtonText: 'No',
    background: '#1d2530',
    color: '#fff',
    iconColor: "#0B0",
    width: "500px",
    padding: "10px"
  }).then((result) => {
    if (result.isConfirmed) {
      
       importData(true);
    } else if (result.isDenied) {
      
       importData(false);
    }
  })
 }
 
} 




 let obj = {}

 const importData = (consent) => {
 
  if(draggedData.length){
    let jsonObjectArray = JSON.parse(draggedData);
    jsonObjectArray.map((v, i) => {
      let key = Object.keys(v);
      let value = Object.values(v);
      obj[key] = value[0]
    })
  }


  //console.log("draggedData",draggedData);
  

    let formData = [];
    //console.log("prod",productList);
    productList.map((value, index) => {
      
      let postData = {
        "name": (obj.Product_Name)?value[obj.Product_Name].toString():"",
        "productType": (obj.Product_Type)?value[obj.Product_Type].toString():"",
        "description": (obj.Description)?value[obj.Description].toString():"",
        "shortDescription": (obj.Short_Description)?value[obj.Short_Description].toString():"",
        "composition": (obj.Composition)?value[obj.Composition].toString():"",
        "maintenance": (obj.Maintenance)?value[obj.Maintenance].toString():"",
        "countryOfOrigin": (obj.Country_of_origin)?value[obj.Country_of_origin].toString():"",
        "color": (obj.Color_Name)?value[obj.Color_Name].toString():"",
        "colorCode": (obj.Color_Code)?value[obj.Color_Code].toString():"",
        "size": (obj.Size)?value[obj.Size].toString():"",
        "referenceCode": (obj.Reference_Code)?value[obj.Reference_Code].toString():"",
        "barcode": (obj.Barcode)?value[obj.Barcode].toString():"",
        "mainImage": (obj.Main_Image)?value[obj.Main_Image].toString():"",
        "image_1": (obj.Image_2)?value[obj.Image_2].toString():"",
        "image_2": (obj.Image_3)?value[obj.Image_3].toString():"",
        "image_3": (obj.Image_4)?value[obj.Image_4].toString():"",
        "image_4": (obj.Image_5)?value[obj.Image_5].toString():"",
        "notes": (obj.Note)?value[obj.Note].toString():"",
        "ecoStatement": (obj.Eco_Statement)?value[obj.Eco_Statement].toString():"",
        "factoryOrigin": (obj.factoryOrigin)?value[obj.factoryOrigin].toString():""
      }
      formData.push(postData);
    })
    let formJsonData = {
      "brandID": brandID,
      "postData": formData,
      "ProductsImporter":(consent)?dataS:""
    }
  //  if(handleValidation(obj)){
    setFilePreviewButtonDisable(true)
    setAppPreviewButtonDisable(true)
    setSaveButtonDisablee(true)
    setOnSubmitLoader(true)
    axios.post(API_URL+'product-importer'+API_KEY_B, formJsonData, {
   // axios.post("http://localhost:7071/api/product-importer", formJsonData, {
      onUploadProgress: progressEvent => {
        let percentComplete = progressEvent.loaded / progressEvent.total
        percentComplete = parseInt(percentComplete * 100);
        setProgressBar(percentComplete);
      }
    }).then((res)=>{
      setFilePreviewButtonDisable(false)
      setAppPreviewButtonDisable(true)
      setSaveButtonDisablee(false)
      if(res.data.status == 200){
        props.loginFun(localStorage.getItem('token'), '60');
        setProductList([]);
        setTemD([])
        setDidectedFields([]);
        setFileName("");
        setFilePreviewButtonDisable(true)
        setAppPreviewButtonDisable(true)
        setSaveButtonDisablee(true)
        setDustbins(update(dustbins, {[0]: {lastDroppedItem: {$set: '',}}}), setNameRequired(false))
        setDustbins1(update(dustbins1, {[0]: {lastDroppedItem: {$set: '',}}}), setDescRequired(false))
        setDustbins2(update(dustbins2, {[0]: {lastDroppedItem: {$set: '',}}}), setShortDescRequired(false))
        setDustbins3(update(dustbins3, {[0]: {lastDroppedItem: {$set: '',}}}), setCompositionRequired(false))
        setDustbins4(update(dustbins4, {[0]: {lastDroppedItem: {$set: '',}}}), setMaintenanceRequired(false))
        setDustbins5(update(dustbins5, {[0]: {lastDroppedItem: {$set: '',}}}), setCOORequired(false))
        setDustbins6(update(dustbins6, {[0]: {lastDroppedItem: {$set: '',}}}), setColorRequired(false))
        setDustbins7(update(dustbins7, {[0]: {lastDroppedItem: {$set: '',}}}), setSizeRequired(false))
        setDustbins8(update(dustbins8, {[0]: {lastDroppedItem: {$set: '',}}}), setBarcodeRequired(false))
        setDustbins9(update(dustbins9, {[0]: {lastDroppedItem: {$set: '',}}}), setReferenceCodeRequired(false))
        setDustbins10(update(dustbins10, {[0]: {lastDroppedItem: {$set: '',}}}), setMainImageRequired(false))
        setDustbins11(update(dustbins11, {[0]: {lastDroppedItem: {$set: '',}}}))
        setDustbins12(update(dustbins12, {[0]: {lastDroppedItem: {$set: '',}}}))
        setDustbins13(update(dustbins13, {[0]: {lastDroppedItem: {$set: '',}}}), setImage2Required(false))
        setDustbins14(update(dustbins14, {[0]: {lastDroppedItem: {$set: '',}}}), setImage3Required(false))
        setDustbins15(update(dustbins15, {[0]: {lastDroppedItem: {$set: '',}}}), setImage4Required(false))
        setImageFiveDustbin(update(imageFiveDustbin, {[0]: {lastDroppedItem: {$set: '',}}}), setImage5Required(false))
        setProductTypeDustbin(update(productTypeDustbin, {[0]: {lastDroppedItem: {$set: '',}}}), setIsProductTypeRequired(false))
        setColorCodeDustbin(update(colorCodeDustbin, {[0]: {lastDroppedItem: {$set: '',}}}), setColorCodeRequired(false))
        setNoteDustbin(update(noteDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
        var r = /\d+/g;
        var s =res.data.message;
       //console.log((s.match(r)==null));
        if(s.match(r)==null){
             /* toast.error(res.data.message, {
                theme: "dark",
                icon: ({theme, type}) =>  <img src={errorWarning}/>,
                onClose: () => {
                  //navigate('/products/image-importer');
                }
              });  */
              toast.success(res.data.message,
                {
                  theme: "dark",
                  icon: ({ theme, type }) => <img src={errorSuccess} />,
                  onClose: () => {
                  
                  }
                });

              setProgressBar(0);
        }else{
          toast.success(res.data.message,
            {
              theme: "dark",
              icon: ({ theme, type }) => <img src={errorSuccess} />,
              onClose: () => {
                navigate('/products/image-importer');
              }
            });
        }
       
     
      }
      setOnSubmitLoader(false)
    }); 
  // }
    //console.log("formData",(formData));
  }

    

   /*************get brand details**************** */
 /*   useEffect(()=>{
      brandInfo();
    },[localStorage.getItem('brandID')]); */
   
 

   const brandInfo = () =>{        
		let formData ={
      "id": localStorage.getItem('brandID')
		}
		 axios.post(API_URL+"get-brand-info"+API_KEY_B,formData).then((res)=>{	   
			if(res.data.status === 200){   
          if(res.data.data?.ProductsImporter != undefined){
              setDustbins(update(dustbins, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins1(update(dustbins1, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins2(update(dustbins2, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins3(update(dustbins3, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins4(update(dustbins4, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins5(update(dustbins5, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins6(update(dustbins6, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins7(update(dustbins7, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins8(update(dustbins8, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins9(update(dustbins9, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins10(update(dustbins10, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins11(update(dustbins11, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins12(update(dustbins12, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins13(update(dustbins13, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins14(update(dustbins14, {[0]: {lastDroppedItem: {$set: '',}}}))
              setDustbins15(update(dustbins15, {[0]: {lastDroppedItem: {$set: '',}}}))
              setImageFiveDustbin(update(imageFiveDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
              setProductTypeDustbin(update(productTypeDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
              setColorCodeDustbin(update(colorCodeDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
              setNoteDustbin(update(noteDustbin, {[0]: {lastDroppedItem: {$set: '',}}}))
             setPreviousPosData(res.data.data?.ProductsImporter); 
             setDataS(res.data.data?.ProductsImporter);
             

               
          let dataArray = [];
          res.data.data?.ProductsImporter.map((_v,_i) => {
           dataArray.push(_v.itemName);
          // console.log(dataArray)
          })
          setDroppedBoxNames(dataArray);

          } 
        

        }
   

      })
    }
    useEffect(() => {
      setDidectedFields(didectedFields);
    },[didectedFields])
    useEffect(()=>{
      if(previuosPosData.length && didectedFields.length){
        previuosPosData.forEach((_v,_i) => {
          let ins = didectedFields.map(f => f?.name).indexOf(_v?.itemName);
          if(_v.itemName == didectedFields[ins]?.name){
            const item = {name:_v?.itemName} 
            setDroppedBoxNames(
              update(droppedBoxNames, _v?.boxName ? { $push: [_v?.boxName] } : { $push: [] }),
            )
            if(_v.boxName  ==  'Product Name'){
              setDustbins(
                update(dustbins, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Description'){
              setDustbins1(
                update(dustbins1, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Product Type'){
              setProductTypeDustbin(
                update(dustbins1, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Short Description'){
              setDustbins2(
                update(dustbins2, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Composition'){
              setDustbins3(
                update(dustbins3, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Maintenance'){
              setDustbins4(
                update(dustbins4, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Country of origin'){
              setDustbins5(
                update(dustbins5, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Color Name'){
              setDustbins6(
                update(dustbins6, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Size'){
              setDustbins7(
                update(dustbins7, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Barcode'){
              setDustbins8(
                update(dustbins8, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Reference Code'){
              setDustbins9(
                update(dustbins9, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Main Image'){
              setDustbins10(
                update(dustbins10, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Eco Statement'){
              setDustbins11(
                update(dustbins11, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Factory/Origin'){
              setDustbins12(
                update(dustbins12, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Image 2'){
              setDustbins13(
                update(dustbins13, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Image 3'){
              setDustbins14(
                update(dustbins14, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Image 4'){
              setDustbins15(
                update(dustbins15, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Image 5'){
              setImageFiveDustbin(
                update(imageFiveDustbin, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Note'){
              setNoteDustbin(
                update(noteDustbin, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
            if(_v.boxName  ==  'Color Code'){
              setColorCodeDustbin(
                update(colorCodeDustbin, {
                  [0]: {
                    lastDroppedItem: {
                      $set: item,
                    },
                    label:{$set:_v.boxName}
                  },
                }),
              )
            }
          }
         // console.log(_v.itemName, didectedFields[ins])
        })
      }
    },[previuosPosData,didectedFields]); 

  const [nameRequired, setNameRequired] = useState(false)
  const [descRequired, setDescRequired] = useState(false)
  const [shortDescRequired, setShortDescRequired] = useState(false)
  const [compositionRequired, setCompositionRequired] = useState(false)
  const [maintenanceRequired, setMaintenanceRequired] = useState(false)
  const [cOORequired, setCOORequired] = useState(false)
  const [colorRequired, setColorRequired] = useState(false)
  const [sizeRequired, setSizeRequired] = useState(false)
  const [isProductTypeRequired, setIsProductTypeRequired] = useState(false)
 const [referenceCodeRequired, setReferenceCodeRequired] = useState(false)
  const [barcodeRequired, setBarcodeRequired] = useState(false)
  const [mainImageRequired, setMainImageRequired] = useState(false)
  const [image2Required, setImage2Required] = useState(false)
  const [image3Required, setImage3Required] = useState(false)
  const [image4Required, setImage4Required] = useState(false)
  const [colorCodeRequired, setColorCodeRequired] = useState(false)
  const [ecoStatementRequired, setEcoStatementRequired] = useState(false)
  const [factoryOriginRequired, setFactoryOriginRequired] = useState(false)
  const [noteRequired, setNoteRequired] = useState(false)
  const [image5Required, setImage5Required] = useState(false)
  const handleValidation = (obj) => {
    let formIsValid = true;
    if(typeof obj.Product_Name == 'undefined'){
      setNameRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Product_Type == 'undefined'){
      setIsProductTypeRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Description == 'undefined'){
      setDescRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Short_Description == 'undefined'){
      setShortDescRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Composition == 'undefined'){
      setCompositionRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Maintenance == 'undefined'){
      setMaintenanceRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Country_of_origin == 'undefined'){
      setCOORequired(true)
       formIsValid = false;
    }
    if(typeof obj.Color_Name == 'undefined'){
      setColorRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Color_Code == 'undefined'){
      setColorCodeRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Size == 'undefined'){
      setSizeRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Reference_Code == 'undefined'){
      setReferenceCodeRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Barcode == 'undefined'){
      setBarcodeRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Main_Image == 'undefined'){
      setMainImageRequired(true)
       formIsValid = false;
    }
    if(typeof obj.Image_2 == 'undefined'){
      setImage2Required(true)
       formIsValid = false;
    }
    if(typeof obj.Image_3 == 'undefined'){
      setImage3Required(true)
       formIsValid = false;
    }
    if(typeof obj.Image_4 == 'undefined'){
      setImage4Required(true)
       formIsValid = false;
    }
    if(typeof obj.Image_5 == 'undefined'){
      setImage5Required(true)
       formIsValid = false;
    }
    return formIsValid;
  }

var percentage = progressBar + '%';

const [currentPage, setCurrentPage] = useState(1);
const [numberOfPage, setNumberOfPage] = useState(30);
const PaginationComponent = (e) => {
  let totalRecord = e.rowCount
  let rowsPerPage = e.rowsPerPage
  let noOfPage = Math.ceil(totalRecord/rowsPerPage);
  let dataPagination = [];
  for(let i = 1; i <= noOfPage; i++){
    dataPagination.push(i)
  }
  return (
    <>
    <div className="paginationfixed">
    <ul className="pagination custompagination">
    <li className="prevnext"><Link to="#" onClick={() => prevPage(currentPage)} className={(e.currentPage === 1)?'disabled':'' }>Prev</Link></li>
      {
        dataPagination.map((v, i) => {
          return(<li key={i}><Link to="#" className={(e.currentPage === v)?'disabled':'' } onClick={() => clickPage(v)}>{v}</Link></li>)
        })
      }
      <li className="prevnext"><Link to="#" onClick={() => nextPage(currentPage)} className={(e.currentPage === noOfPage)?'disabled':'' }>Next</Link></li>
    </ul>
    </div>
    </>
  );
}
const clickPage = (page) =>{
  setCurrentPage(page)
}
const nextPage = (page) => {
  setCurrentPage(page+1)
}
const prevPage = (page) => {
  if(page > 1){
    setCurrentPage(page-1)
  }
}
const changePageNumber = (e) => {
  setNumberOfPage(e.target.value);
}
const tableData = {
    columns,
    data
  };
  let ket = [];
  if(draggedData?.length){
    let dataValidation = JSON.parse(draggedData);
    dataValidation?.forEach(r => {
      let key = Object.keys(r);
      ket.push(key);
    })
  }
 const [dataRowCount,setDataRowCount] = useState(10);
  const getTheNoOfRowSelected = (e) =>{
      // alert(productList.length);
          setDataRowCount(e.target.value);
  }

   useEffect(()=>{
     // console.log(didectedFields);
     // console.log(dataS);
   })



  return(
    <>
<div className="wrapper" ref={ref} id="myscreen">
  <BrandNavigation data={elementData} cdata={props.compData}/>
  <BrandAside cdata={props.compData} />
  <div className="conten-wrapper">
    {(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>   
      <div className="controlbar">
        <div className="productsearch leftsidetr ">
                <Link data-widget="search" className="button-not-active" to="#" role="button">
                  <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                  <span className="searchprodicttext">Products</span>
                </Link>
              </div>
            
            
              <div className="centerbtn">
                <Link to={(props.compData?.completePercentage==="Completed" || props.compData?.status =='100')?"/products":"#"} className={(props.compData?.completePercentage==="Completed" || props.compData?.status =='100')?"btn btn-success":"btn btn-success button-not-active"}>Products</Link>
                <Link to="#" className="btn btn-success button-not-active" >New Product</Link>
                <Link to="#" className="btn btn-success active">Importer</Link>                
                <Link to="#" className="btn btn-success button-not-active" >GTIN<span className="makeitsmaller">s</span></Link>
              </div>
            
            <div className="rightcontrolserch">
              
              <div className="productsearch rightareas">
                <Link data-widget="search"  to="#" role="button">
                  <span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
                  <span className="searchprodicttext">ID</span>
                </Link>
              </div> 
          
                <div className={"productsearch rightareas"}>
                {/* <Link data-widget="search" to={(props.compData?.completePercentage==="Completed")?"/products/image-importer":"#"} role="button"> */}
                  <Link data-widget="search" to={"/products/image-importer"} role="button">
                    <span className="btn btn-success"><i className="fa-thin fa-file-image"></i></span>
                    <span className="searchprodicttext">Images</span>
                  </Link>
                </div>
                         
            </div>
         
        
      </div>
      {(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:
          <>
      <div className="products-listingarea filtersarea images-importer">
        <div className="container-fluid">
          <div className="row"> 
              <div className="col-md-12">
                {/*<div className="custom-breadcrumb">
                <StepCombination cdata={props.compData}/>
                </div>*/}
              <div className="product-importer-filebtn">                
                      <div className="form-group">                                                           
                        <input type="file" name="fileImport" id="fileImport" className="inputfile inputfile-6" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e)=>{readImporterFile(e);fullScreenCheck(e);brandInfo()}} onClick={(e) =>{e.target.value=null}} />
                        <label htmlFor="fileImport"><span>{fileName}</span>
                            
                        </label> 
                        <label htmlFor="fileImport" className={(fileName)?"stromngclass active":"stromngclass active"}>Upload File</label>
                                                 
                    </div>
              </div>
                          
            
              <div className="filtersection">
                  <span className="btnsets">
                      <span className="customeset gobtn">
                        <button type="submit" className="btn btn-success" onClick={handleShow} disabled={(filePreviewButtonDisable)?'disabled':'' }><i className="fa-thin fa-table"></i> FILE PREVIEW</button>
                      </span>
                      
                    <span className="customeset gobtn">
                      <button type="submit" className="btn btn-success save-control" onClick={searchDataInDB} disabled={(saveButtonDisable)?'disabled':'' }> {(!productList.length)?(<i className="fa-thin fa-save"></i>):(saveButtonDisable)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-save"></i>)} {(productList.length)?'SAVE '+productList.length+' ITEMS':'SAVE'}</button>
                   
                      {/* <button type="submit" className="btn btn-success save-control" onClick={SaveDistribution} disabled={(saveButtonDisable)?'disabled':'' }> {(!productList.length)?(<i className="fa-thin fa-save"></i>):(saveButtonDisable)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-save"></i>)} {(productList.length)?'SAVE '+productList.length+' ITEMS':'SAVE'}</button> */}
                    </span>
                     
                  </span>

               
              </div>
            </div> 
            
          </div>
        </div>
      </div>
      

        <div className="product-infosection">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="productinfo productDivimporter">
                            <div className="cardsection prdoctMargin"> 
                                <div className="card-header">
                                  <h4 className="card-title">
                                   
                                      Products File 
                                      <div className="file-include-urls">
                                          <div className="form-group padd-top">
                                          <label className="container-checkbox">
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                          </label>
                                          <span className="file-text"> File includes URLs for product images <i className="fas fa-info-circle"></i> 
                                          </span>
                                          </div>  
                                      </div>
                                      <span className="formcautions">
                                        <ul>
                                            <li><span className="makesqaure mandatorythis"></span>mandatory fields</li>
                                            <li><span className="makesqaure lockedthis"></span>locked fields</li>
                                        </ul>
                                      </span> 
                                     <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"> 
                                      <i className="fa fa-chevron-down pull-right" aria-hidden="true" onClick={()=> setChevShow(1)} style={{"display":(chevShow)?"none":"block"}}></i>
                                      <i className="fa fa-chevron-up pull-right" aria-hidden="true" onClick={() =>setChevShow(0)} style={{"display":(chevShow)?"block":"none"}}></i>
                                    </a>
                                  </h4>
                                 
                                </div>                               
                                  <div className="card-body">
                                    <div className="info-products-form">
                                        <form>
                                            <div className="headpart">
                                                <div className="row">
                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> To Brand</label>
                                                                
                                                                <Select
                                                                    options={statusOptions}
                                                                    styles={colourStyles}
                                                                    placeholder={<div className="italic-placeholder">Brand</div>}
                                                                    isDisabled ={true}
                                                                    defaultValue={{ value: 'holzweiler', label: 'Holzweiler' }}
                                                                  />
                                                          </div>
                                                    </div> */}
                                                    

                                                </div>
                                            </div>

                                            <div className="showprogress">
                                                <div className="progress">
                                                  <div className="progress-bar" role="progressbar" aria-valuenow={progressBar} aria-valuemin="0" aria-valuemax="100"  style={{width: percentage}}>
                                                    <span className="sr-only">{progressBar}% Complete</span>
                                                  </div>
                                                </div>
                                            </div>
                                            <DndProvider backend={HTML5Backend}>
                                            <div className="formdivisions">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="fieldsarea">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Fields detected {didectedFields.length?<span style={{"color":"#00AEEF"}}> {'  '}  Drag fields below to a box on the right</span> : ""}</label>
                                                            </div>
                                                            <div className="fieldfouds">
                                                                {didectedFields?.map(({ name, type }, index) => {
                                                                    return (<Box
                                                                    name={name}
                                                                    type={type}
                                                                    isDropped={isDropped(name)}
                                                                    key={index}
                                                                  />)

                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-3">
                                                        <div className="products-col">
                                                            <h4>Product Info</h4>
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Product Name</label>
                                                                {dustbins.map(({ accepts, lastDroppedItem,label }, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                    clear={clear}
                                                                    arrData={arrData}
                                                                    valids={nameRequired}
                                                                  />
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Product Type</label>
                                                                {productTypeDustbin.map(({ accepts, lastDroppedItem,label }, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleProductType(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                    clear={clear}
                                                                    arrData={arrData}
                                                                    valids={isProductTypeRequired}
                                                                  />
                                                                ))}
                                                            </div>
                                                        </div>
                                                       
                                                        <div className="products-col" >
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Description</label>
                                                                {dustbins1.map(({ accepts, lastDroppedItem,label }, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop1(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={descRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Short Description</label>
                                                                {dustbins2.map(({ accepts, lastDroppedItem,label }, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop2(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={shortDescRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Composition</label>
                                                                {dustbins3.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop3(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={compositionRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Maintenance</label>
                                                                {dustbins4.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop4(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={maintenanceRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Country of origin</label>
                                                                {dustbins5.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop5(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={cOORequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Notes</label>
                                                                {noteDustbin.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleNote(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={noteRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="products-col">
                                                        <h4>Variation</h4>
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Color Name</label>
                                                                {dustbins6.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop6(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={colorRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                          <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Color Code</label>
                                                                {colorCodeDustbin.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleColorCode(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={colorCodeRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Size</label>
                                                                {dustbins7.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop7(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={sizeRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                        <h4 className="seconchild">Product Id</h4>
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Barcode</label>
                                                                {dustbins8.map(({ accepts, lastDroppedItem,label }, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop8(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={barcodeRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Reference Code</label>
                                                                {dustbins9.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop9(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={referenceCodeRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Main Image</label>
                                                                {dustbins10.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop10(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={mainImageRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="products-col">
                                                        <h4>Eco, Transparency & Traceability</h4>
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Eco Statement</label>
                                                                {dustbins11.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop11(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={ecoStatementRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">                             
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Factory/Origin</label>
                                                                {dustbins12.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop12(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     vailds={factoryOriginRequired}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">  
                                                        <h4 className="seconchild">Other Product Images</h4>                           
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Image 2</label>
                                                                {dustbins13.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop13(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={image2Required}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col"> 
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Image 3</label>
                                                                {dustbins14.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop14(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={image3Required}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">                         
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Image 4</label>
                                                                {dustbins15.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleDrop15(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={image4Required}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                        <div className="products-col">                         
                                                            <div className="form-group">
                                                                <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Image 5</label>
                                                                {imageFiveDustbin.map(({ accepts, lastDroppedItem ,label}, index) => (
                                                                  <Dustbin
                                                                    accept={accepts}
                                                                    lastDroppedItem={lastDroppedItem}
                                                                    onDrop={(item) => handleImageFive(index, item,label)}
                                                                    key={index}
                                                                    label={label}
                                                                     clear={clear}
                                                                     arrData={arrData}
                                                                     valids={image5Required}
                                                                  />
                                                                  ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </DndProvider>




                                        </form>
                                    </div>  






                                  </div>
                                
                              
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        </>}
       </>}
</div>
<BrandFooter logo={props.imageData.footer_logo}/>
</div>
{(onSubmitLoader)?<div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>:""}
<Modal backdrop="static" className="filepreviewTable" keyboard={false} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>PRODUCTS FILE PREVIEW</Modal.Title>
          <div className="toppanels">
            <div className="toppadintions">
              <div className="dataTables_length" id="example_length">
                <label>Show 
                <select name="example_length" aria-controls="example" className="custom-select custom-select-sm form-control form-control-sm" onChange={getTheNoOfRowSelected}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                </select> entries
                </label>
              </div>
            </div>
            <div className="toppadintions searchrighrts">
              <div className="dt-buttons">
                 <div className="dt-buttons btn-group">
                    <Link to="#" className="btn btn-default buttons-copy buttons-html5" tabindex="0" aria-controls="example"><span>Copy</span></Link>
                    <Link to="#" className="btn btn-default buttons-csv buttons-html5" tabindex="0" aria-controls="example"><span>CSV</span></Link>
                    <Link to="#" className="btn btn-default buttons-excel buttons-html5" tabindex="0" aria-controls="example"><span>Excel</span></Link>
                    <Link to="#" className="btn btn-default buttons-pdf buttons-html5" tabindex="0" aria-controls="example"><span>PDF</span></Link>
                    <Link to="#" className="btn btn-default buttons-print" tabindex="0" aria-controls="example"><span>Print</span></Link>
                  </div>
              </div>
              <div id="example_filter" className="dataTables_filter">
                <label>Search:<input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example" /></label>
              </div>
            </div>
           <div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 0 to {dataRowCount} of {productList.length} entries</div> 
        </div>
        </Modal.Header>
        <Modal.Body>
        {/*<DataTableExtensions filterPlaceholder="" {...tableData}>*/}
            <DataTable 
                columns={columns} 
                data={data} 
                theme="solarized" 
                sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>} 
                defaultSortFieldId={0} 
                defaultSortAsc={false}
                pagination
                paginationComponent = {PaginationComponent}
                paginationDefaultPage = {currentPage}
            />
        {/*</DataTableExtensions>*/}
        </Modal.Body>
      </Modal>
      {/* {Prompt} */}
</>
  );
}
export default Dashboard;