import React, { useRef, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import BrandNavigation from "./../layouts/BrandDashboardHeaderStatic";
import StepWizard from "react-step-wizard";
import BrandAside from "./../layouts/BrandAsideStatic";
import BrandFooter from "./../layouts/BrandFooter";
import BlueAccountOn from './../../assets/images/blueaccounton.png';
import BlueBrandOn from './../../assets/images/bluebrandon.png';
import BlueBOimageIsOn from './../../assets/images/blueboimageson.png';
import BlueStoresOn from './../../assets/images/bluestoreson.png';
import CircleTick from './../../assets/images/circle-tick.png';
import GrayBrandoff from './../../assets/images/graybrandoff.png';
import GrayBoimgoff from './../../assets/images/grayboimageson.png';
import Graystoreoff from './../../assets/images/graystoreson.png';
import Nav from './Nav';
import axios from 'axios';
import { API_URL,API_URL_A,API_KEY_B,API_KEY_A } from './../../config/constant.js';
import Plugs from './Plugs';
import styles from './wizard.less';
import transitions from './transitions.less';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/error-icon.png';
import errorSuccess from './../../assets/images/icons8-check-64.png'
import Swal from 'sweetalert2';
toast.configure();
function SmallBox(props) {
	const  AutoGenerateUrlId  = useParams();
	document.title = 'BOstageB - PreEnrrolment';
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	useEffect(() => {
		setElementData(ref.current);
        checkAutogeneratedUrl();
		localStorage.clear();
     
	}, []);


	const [addTo,setAddTo] = useState("");
    const [buttonDisable, setButtonDisable] = useState(false);

	let navigate = useNavigate();

    const checkAutogeneratedUrl = () => {
		
		let formData = {
		   "invitedId":AutoGenerateUrlId.id
	   }
	  axios.post(API_URL_A+'validate-url'+API_KEY_A, formData).then((res)=>{
		   if(res.data.status === 200){ 
			 
			   setAddTo(res.data.data.brandName); 
			
			   toast.success(res.data.message, {
				  theme: "dark",
				  icon: ({theme, type}) =>  <img src={errorSuccess}/>
				})
		   }else{
				toast.error(res.data.message, {
				  theme: "dark",
				  icon: ({theme, type}) =>  <img src={errorWarning}/>
				})

				navigate("/");
		   }
	  })
		  
   }

  const confirmation = () => {
	setButtonDisable(true);
	let formData = {
		"uiid":AutoGenerateUrlId.id
	}
   axios.post(API_URL+'accept-invite-request'+API_KEY_B, formData).then((res)=>{
		if(res.data.status === 200){ 
		  					 
			toast.success(res.data.message, {
			   theme: "dark",
			   icon: ({theme, type}) =>  <img src={errorSuccess}/>
			 })

			 navigate("/");

		}else{
			 toast.error(res.data.message, {
			   theme: "dark",
			   icon: ({theme, type}) =>  <img src={errorWarning}/>
			 })
			 setButtonDisable(false);
			 //navigate("/");
		}
   }) 

  }

  const navLogin = () => {
	      navigate("/");
  }


   
	return (
		<>
			<div className="wrapper">
				<BrandNavigation  />
				<BrandAside />
				<div className="conten-wrapper singlepages smallareabox">
					<div className="controlbar">
						<div className="productsearch leftsidetr">
						</div>
						<div className="centerbtn">
							<h3 className="titleonly">PRE-ENROLLMENT</h3>
						</div>
					</div>

					<div className="creators-section">
			    	<div className="container-fluid">
			    		<div className="row">
                            <div className="col-md-12">
                            <div className="small-formas smallbox" >
                                    <div className="blueicons-area">
                                        <img src={CircleTick} />
                                    </div>
                                    <p className="verifying-text dimtext"><span>{addTo} Account Admin</span> invited.<br />
									As soon as the invitation is accepted, credentials are provided and they can login to the account.</p>
                                   <div className="send-blockbtn twobtnsets">
									   <button type="submit" className="btn btn-primary btn" onClick={confirmation} disabled={(buttonDisable)?'disabled':'' } >Yes</button> 
									   <button type="submit" className="btn btn-primary btn-md" onClick={navLogin} >No</button>
									  
                                   </div>
                                    
                                    
                                    
                                    
                                    
                                </div>
                            </div>
			    			
			    		</div>
			    	</div>
			    </div>





				</div>
				
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	);
}
export default SmallBox;