import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";

import { API_URL, API_KEY_B, BLOB_URL, BLOB_COMPANY_IMAGE, BLOB_BRAND_IMAGE } from './../../config/constant.js';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import axios from 'axios';

import Swal from 'sweetalert2';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import uploadFileToBlob, { isStorageConfigured } from './blob';
import StepCombination from '../layouts/commonLayout';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import { param } from "jquery";
import ForBidden from './../layouts/denied';
import Slider from 'react-input-slider';
toast.configure();

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 100,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	)
}

function Dashboard(props) {
	document.title = 'BOstageB - Brand Logo';
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	const [imgSrc, setImgSrc] = useState('')
	const previewCanvasRef = useRef(null)
	const previewWhiteCanvasRef = useRef(null)
	const previewBlackCanvasRef = useRef(null)
	const previewAltCanvasRef = useRef(null)
	const previewAltWhiteCanvasRef = useRef(null)
	const previewAltBlackCanvasRef = useRef(null)
	const imgRef = useRef(null)
	const [crop, setCrop] = useState()
	const [completedCrop, setCompletedCrop] = useState()
	const [completedWhiteCrop, setCompletedWhiteCrop] = useState()
	const [completedBlackCrop, setCompletedBlackCrop] = useState()
	const [completedAltCrop, setCompletedAltCrop] = useState()
	const [completedAltWhiteCrop, setCompletedAltWhiteCrop] = useState()
	const [completedAltBlackCrop, setCompletedAltBlackCrop] = useState()
	//const [cropedData, setCropedData] = useState()
	const [cropedData, setCropedData] = useState({ unit: 'px', x: 0, y: 0, width: 245, height: 90 })
	const [scale, setScale] = useState(1)
	const [rotate, setRotate] = useState(0)
	const [aspect, setAspect] = useState(245 / 90)
	const [isCropped, setIsCropped] = useState(false);
	//	const [fileSrcUrl, setFileSrcUrl] = useState("");
	const [imageName, setImageName] = useState("");
	const [imageNameUpd, setImageNameUpd] = useState("");
	const [saveImage, setSaveImage] = useState([]);
	const [buttonGray, setButtonGray] = useState(false);
	const [logoType, setLogoType] = useState("");
	const [saveMainLogo, setSaveMainLogo] = useState(false);
	const [saveMainWhiteLogo, setSaveMainWhiteLogo] = useState(false);
	const [saveMainBlackLogo, setSaveMainBlackLogo] = useState(false);
	const [saveMainAltLogo, setSaveMainAltLogo] = useState(false);
	const [saveMainAltWhite, setSaveMainAltWhite] = useState(false);
	const [saveMainAltBlack, setSaveMainAltBlack] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [imgError, setImgError] = useState(false);
	const [targetEnable, setTargetEnable] = useState(false);

	const [showPreLoader, setShowPreLoader] = useState(true);
	const [dataReadonly, setDataReadonly] = useState(false);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [denied, setDenied] = useState(false)
	const [sliderInitial, setSliderInitial] = useState(0);
	const [isDisabled, setIsDisabled] = useState(true)
	const [tempScale, setTempScale] = useState(1);
	const [fileSelOpen, setFileSelOpen] = useState(false);

	const [editMode, setEditMode] = useState(0);
	const [editModeBtnFun, setEditModeBtnFun] = useState(0);
	const [buttonName, setButtonName] = useState("NEXT");


	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.BrandsMenu) {
					setShowPreLoader(false);
					setDenied(true)
				} else {
					setDenied(false)
					//setShowPreLoader(false);
				}
			}
		}

	}, [props.compData]);
	let { id } = useParams();
	let navigate = useNavigate();




	const [fileSrcUrl, setFileSrcUrl] = useState("");
	const [fileSrcUrl1, setFileSrcUrl1] = useState("");
	const [fileSrcUrl2, setFileSrcUrl2] = useState("");
	const [fileSrcUrl3, setFileSrcUrl3] = useState("");
	const [fileSrcUrl4, setFileSrcUrl4] = useState("");
	const [fileSrcUrl5, setFileSrcUrl5] = useState("");

	const [wtb, setWtb] = useState(0);


	useEffect(() => {
			getBrandAllData();
	}, [props?.compData]);

	useEffect(()=>{
		if(localStorage.getItem("viewMode")=='editmode')
		{
			getBrandAllData(id);
			setEditMode(1);
			setEditModeBtnFun(1);
			setButtonName("SAVE");
		}
	 },[localStorage.getItem("viewMode")]) 



	const refWarp = useRef(null);   //new datatttt
	useEffect(() => {

		function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode.parentNode.parentNode);
			/*
			 console.log("ref.curren",refWarp.current.contains(event.target)); 
			console.log("eventswq",event.target.value);
			console.log("event",event.target.classList);
			console.log("refWarp.current",refWarp.current); */
			//menu
			let pclass = event.target.parentNode.parentNode.parentNode.parentNode;
			let pclassimg = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
			// rightcontrolserch
			let prClassIcon = event.target.parentNode.parentNode.parentNode;
			let prClass = event.target.parentNode.parentNode;
			//center upper btn
			let pCNClass = event.target.parentNode;

			if (editMode && !editModeBtnFun) {
				// console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || pclass.classList.contains("menusections") || pclass.classList.contains("navitems-area") || pclassimg.classList.contains("navitems-area") || pclassimg.classList.contains("menusections") || prClassIcon.classList.contains("saveIt") || prClass.classList.contains("saveIt") || pCNClass.classList.contains("saveIt"))) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					}).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);
							handleSubmit(event)
						}
						else if (result.isDenied) {
							setEditModeBtnFun(1);
						}

					})


				}
			}
		}
		document.addEventListener("mousedown", handleSaveOutside);
		return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		};
	}, [refWarp, editMode, editModeBtnFun]);

	const getBrandAllData = () => {
		let bid = "";

		if ((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') || (localStorage.getItem('preEnrollData') != null)) {
			bid = localStorage.getItem('brandID')
		} else {
			bid = id;
		}

		let formData = {
			"id": bid
		}

		axios.post(API_URL + "get-brand-info" + API_KEY_B, formData).then((res) => {
			//console.log("test",res.data.data);

			if (res.data.status === 200) {
				if (res.data.data?.logos?.mainLogoURL) {
					if ((localStorage.getItem('status') != "Completed" && localStorage.getItem('status') != '100') || (localStorage.getItem('preEnrollData') != null)) {
						setDataReadonly(true);
					}

				} else {
					setShowPreLoader(false);
				}

				if (res.data.data?.logos?.mainLogoURL) {
					setSaveMainLogo(res.data.data.logos.mainLogoURL);
				}

				setFileSrcUrl(res.data.data?.logos?.mainLogoURL);
				setFileSrcUrl1(res.data.data?.logos?.mainLogoWhiteURL);
				setFileSrcUrl2(res.data.data?.logos?.mainLogoBlackURL);
				setFileSrcUrl3(res.data.data?.logos?.alternativeLogoURL);
				setFileSrcUrl4(res.data.data?.logos?.alternativeLogoWhiteURL);
				setFileSrcUrl5(res.data.data?.logos?.alternativeLogoBlackURL);

				//if(res.data.data?.Targets?.AgeGroups.length){
				if (props.compData?.completePercentage != "Completed" && props.compData?.status != '100' && res.data.data.logos.mainLogoURL) {
					setTargetEnable(true);
					setShowPreLoader(false);
				}


				if (props.compData?.completePercentage == "Completed" || props.compData?.status == '100') {
					if (res.data.data?.Targets?.AgeGroups.length) {
						setTargetEnable(true);
					}
					setShowPreLoader(false);
				}

				if (res.data.data?.logos?.mainLogoURL) {
					setTargetEnable(true);
				}


			} else {
				//setShowPreLoader(false);   
				navigate("/brand/creator");

			}
		})
	}


	const openFileSelector = (type = null) => {
		setLogoType(type)
		setTempScale(1)
		setScale(1)
		setSliderInitial(0)
		if (type == "mainLogo") {
			document.getElementById('companyMainLogo').click();
			setSaveMainLogo(false)
			setWtb(0);
		}
		if (type == "mainWhiteLogo") {
			document.getElementById('companyMainWhiteLogo').click();
			setSaveMainWhiteLogo(false)
			setWtb(0);
		}
		if (type == "mainBlackLogo") {
			document.getElementById('companyMainBlackLogo').click();
			setSaveMainBlackLogo(false)
			setWtb(0);
		}
		if (type == "mainAltLogo") {
			document.getElementById('companyAltLogo').click();
			setSaveMainAltLogo(false)
			setWtb(0);
		}
		if (type == "altWhite") {
			document.getElementById('companyAltWhiteLogo').click();
			setSaveMainAltWhite(false)
			setWtb(0);
		}
		if (type == "altBlack") {
			document.getElementById('companyAltBlackLogo').click();

			setSaveMainAltBlack(false)
			setWtb(0);
		}

	}
	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			setScale(1)
			setCrop(undefined)
			//setCrop({unit: 'px', x: 0, y: 0, width: 245, height: 90});
			setIsCropped(false)
			setIsDisabled(false)
			const reader = new FileReader()
			reader.addEventListener('load', () =>
				setImgSrc(reader.result?.toString() || ''),
			)
			reader.readAsDataURL(e.target.files[0])
			setImageName(e.target.files[0].name)
			setFileSelOpen(true);
		}
	}
	function onImageLoad(e) {
		if (aspect) {
			const { width, height } = e.currentTarget
			setCrop(centerAspectCrop(width, height, aspect))
		}
		cropFunction();
	}
	const [complete, setComplete] = useState(false)
	const onCompleteFun = (e) => {
		setCropedData(e);
		setComplete(true)
	}
	useEffect(() => {
		if (complete) {
			cropFunction();
			setComplete(false)
		}
	}, [complete])
	useDebounceEffect(
		async () => {
			if (logoType === "mainLogo") {
				if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
					canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
				}
			}
		},
		100,
		[completedCrop, scale],
	)
	useDebounceEffect(
		async () => {

			if (logoType === "mainWhiteLogo") {
				if (completedWhiteCrop?.width && completedWhiteCrop?.height && imgRef.current && previewWhiteCanvasRef.current) {
					canvasPreview(imgRef.current, previewWhiteCanvasRef.current, completedWhiteCrop, scale, rotate)
				}
			}
		},
		100,
		[completedWhiteCrop, scale],
	)
	useDebounceEffect(
		async () => {
			if (logoType === "mainBlackLogo") {
				if (completedBlackCrop?.width && completedBlackCrop?.height && imgRef.current && previewBlackCanvasRef.current) {
					canvasPreview(imgRef.current, previewBlackCanvasRef.current, completedBlackCrop, scale, rotate)
				}
			}
		},
		100,
		[completedBlackCrop, scale],
	)
	useDebounceEffect(
		async () => {
			if (logoType === "mainAltLogo") {
				if (completedAltCrop?.width && completedAltCrop?.height && imgRef.current && previewAltCanvasRef.current) {
					canvasPreview(imgRef.current, previewAltCanvasRef.current, completedAltCrop, scale, rotate)
				}
			}
		},
		100,
		[completedAltCrop, scale],
	)
	useDebounceEffect(
		async () => {
			if (logoType === "altWhite") {
				if (completedAltWhiteCrop?.width && completedAltWhiteCrop?.height && imgRef.current && previewAltWhiteCanvasRef.current) {
					canvasPreview(imgRef.current, previewAltWhiteCanvasRef.current, completedAltWhiteCrop, scale, rotate)
				}
			}
		},
		100,
		[completedAltWhiteCrop, scale],
	)
	useDebounceEffect(
		async () => {
			if (logoType === "altBlack") {
				if (completedAltBlackCrop?.width && completedAltBlackCrop?.height && imgRef.current && previewAltBlackCanvasRef.current) {
					canvasPreview(imgRef.current, previewAltBlackCanvasRef.current, completedAltBlackCrop, scale, rotate)
				}
			}

		},
		100,
		[completedAltBlackCrop, scale],
	)

	/*
		const zoomImage = (type) => {
			if(type === "in"){
				setScale(Number(scale+0.1))
			}else{
				if(scale > 1){
					setScale(Number(scale-0.1))
				}  			
			}

		}  */

	const zoomImage = (e, type) => {
		if (type === "in") {
			setSliderInitial(sliderInitial + 0.05)
			//setScale(Number(scale+0.1))
		} else {
			setSliderInitial(sliderInitial - 0.05)
			//setScale(Number(scale-0.1))
		}
	}


	const cancelFun = (type = null) => { 
		
		if(editMode){
			setEditModeBtnFun(0);
		}
		setImgSrc('');
		setIsCropped(false)
		setSliderInitial(0)
		//setSaveImage([]);
		const imgDta = saveImage.filter((img) => {
			if (img.type != type) {
				return img;
			}
		});
		setSaveImage(imgDta);

		setFileSelOpen(false)
		if (type == "mainLogo") {
			setCompletedCrop();
			setSaveMainLogo(false)
			document.getElementById("companyMainLogo").value = null;
			

		}
		if (type == "mainWhiteLogo") {
			setCompletedWhiteCrop();
			setSaveMainWhiteLogo(false)
			document.getElementById("companyMainWhiteLogo").value = null;
			
		}
		if (type == "mainBlackLogo") {
			setCompletedBlackCrop();
			setSaveMainBlackLogo(false)
			document.getElementById("companyMainBlackLogo").value = null;
		}
		if (type == "mainAltLogo") {
			setCompletedAltCrop();
			setSaveMainAltLogo(false)
			document.getElementById("companyAltLogo").value = null;
		}
		if (type == "altWhite") {
			setCompletedAltWhiteCrop();
			setSaveMainAltWhite(false)
			document.getElementById("companyAltWhiteLogo").value = null;
		}
		if (type == "altBlack") {
			setCompletedAltBlackCrop();
			setSaveMainAltBlack(false)
			document.getElementById("companyAltBlackLogo").value = null;
		}  

	} 
	const setCropedDataFun = (d) => {
		setCropedData(d)
		setIsCropped(false)
		setComplete(true)
		if (logoType == "mainLogo") {
			setSaveMainLogo(false)
		}
		if (logoType == "mainWhiteLogo") {
			setSaveMainWhiteLogo(false)
		}
		if (logoType == "mainBlackLogo") {
			setSaveMainBlackLogo(false)
		}
		if (logoType == "mainAltLogo") {
			setSaveMainAltLogo(false)
		}
		if (logoType == "altwhite") {
			setSaveMainAltWhite(false)
		}
		if (logoType == "altBlack") {
			setSaveMainAltBlack(false)
		}


	}
	const cropFunction = () => {
		if (logoType == "mainLogo") {
			setCompletedCrop(cropedData)
			setIsCropped(true)
			let d = saveImage.filter((v, i) => {
				return (v.type != logoType)
			})
			setSaveImage(d);
		}
		if (logoType == "mainWhiteLogo") {
			setCompletedWhiteCrop(cropedData)
			setIsCropped(true)
			saveImage.filter((v, i) => {
				return (v.type != logoType)
			})
			//console.log(saveImage);
		}
		if (logoType == "mainBlackLogo") {
			setCompletedBlackCrop(cropedData)
			setIsCropped(true)
			saveImage.filter((v, i) => {
				return (v.type != logoType)
			})
			//console.log(saveImage);
		}
		if (logoType == "mainAltLogo") {
			setCompletedAltCrop(cropedData)
			setIsCropped(true)
			saveImage.filter((v, i) => {
				return (v.type != logoType)
			})
			//console.log(saveImage);
		}
		if (logoType == "altWhite") {
			setCompletedAltWhiteCrop(cropedData)
			setIsCropped(true)
			saveImage.filter((v, i) => {
				return (v.type != logoType)
			})
			//console.log(saveImage);
		}
		if (logoType == "altBlack") {
			setCompletedAltBlackCrop(cropedData)
			setIsCropped(true)
			saveImage.filter((v, i) => {
				return (v.type != logoType)
			})
			//console.log(saveImage);
		}


	}
	const saveFunction = async () => {
		setImgSrc('');
		setIsCropped(false);
		setCrop({ unit: 'px', x: 0, y: 0, width: 245, height: 90 })
		setIsDisabled(true)
		setSliderInitial(0)
		setFileSelOpen(false)
		if (logoType == "mainLogo") {
			setSaveMainLogo(true)
			let blob = await fetch(previewCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = localStorage.getItem('brandID') + imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, { "data": blob, type: logoType, name: blob.name }])
			if (editMode) {
				//setEditModeBtnFun(0);
			}
		}
		if (logoType == "mainWhiteLogo") {
			setSaveMainWhiteLogo(true)
			let blob = await fetch(previewWhiteCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = localStorage.getItem('brandID') + imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, { "data": blob, type: logoType, name: blob.name }])
			if (editMode) {
				setEditModeBtnFun(0);
			}
		}
		if (logoType == "mainBlackLogo") {
			setSaveMainBlackLogo(true)
			let blob = await fetch(previewBlackCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = localStorage.getItem('brandID') + imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, { "data": blob, type: logoType, name: blob.name }])
			if (editMode) {
				setEditModeBtnFun(0);
			}
		}
		if (logoType == "mainAltLogo") {
			setSaveMainAltLogo(true)
			let blob = await fetch(previewAltCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = localStorage.getItem('brandID') + imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, { "data": blob, type: logoType, name: blob.name }])
			if (editMode) {
				setEditModeBtnFun(0);
				
			}
		}
		if (logoType == "altWhite") {
			setSaveMainAltWhite(true)
			let blob = await fetch(previewAltWhiteCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = localStorage.getItem('brandID') + imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, { "data": blob, type: logoType, name: blob.name }])
			if (editMode) {
				setEditModeBtnFun(0);
				
			}
		}
		if (logoType == "altBlack") {
			setSaveMainAltBlack(true)
			let blob = await fetch(previewAltBlackCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = localStorage.getItem('brandID') + imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, { "data": blob, type: logoType, name: blob.name }])
			if (editMode) {
				setEditModeBtnFun(0);
			}
		}
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (dataReadonly) {
			navigate("/brand/tags-target/" + id);
		} else {

			//console.log("xjcbsx",saveImage);
			if (validateImg(saveImage)) {
				setSubmitLoader(true);
				setDataReadonly(true);
				let imgDatas = [];
				// let brandID = props.compData?.activeBrand?.brandID;
				setButtonDisable(true);
				let brandID = id;

				imgDatas = { ...imgDatas, "brandID": brandID };
				let formArray = [];
				saveImage.forEach((dat) => {
					// console.log("url",dat.name);
					imgDatas = { ...imgDatas, [dat.type]: BLOB_URL + BLOB_BRAND_IMAGE + '/' + dat.name, };
					formArray.push(dat.data);
				});
				// console.log("imgData",imgDatas);

				/******if already have logo******* */
				if (fileSrcUrl) {
					imgDatas = { ...imgDatas, "mainLogo": fileSrcUrl, };
				}
				if (fileSrcUrl1) {
					imgDatas = { ...imgDatas, "mainWhiteLogo": fileSrcUrl1, };
				}
				if (fileSrcUrl2) {
					imgDatas = { ...imgDatas, "mainBlackLogo": fileSrcUrl2, };
				}
				if (fileSrcUrl3) {
					imgDatas = { ...imgDatas, "mainAltLogo": fileSrcUrl3, };
				}
				if (fileSrcUrl4) {
					imgDatas = { ...imgDatas, "altWhite": fileSrcUrl4, };
				}
				if (fileSrcUrl5) {
					imgDatas = { ...imgDatas, "altBlack": fileSrcUrl5, };
				}
				// console.log("imgDataNew",imgDatas);


				let formData = JSON.stringify(imgDatas);
				// console.log("brandLogo",formData);
				// setDataReadonly(true);
				const blobsInContainer = await uploadFileToBlob(formArray, BLOB_BRAND_IMAGE).then(res => {
					//setDataReadonly(true);	 	
					axios.post(API_URL + "update-brand-logo" + API_KEY_B, formData).then((res) => {

						if (res.data.status == 200) {
							setSubmitLoader(false);
							//if(localStorage.getItem('status') != '100' && localStorage.getItem('status') !='Completed'){ 
							setDataReadonly(true);
							// }			
							props.loginFun(localStorage.getItem('token'), '40');

							toast.success("Brand logos saved successfully. Only missing relevant information for shoppers to easily find your brand.",
								{
									theme: "dark",
									icon: ({ theme, type }) => <img src={errorSuccess} />,
									onClose: () => {

										if (!editMode) {
											if (!props.compData.BrandsMenu) {
												//navigate("/brand/pre-tags-target/"+id);
												navigate("/brand/tags-target/" + id);
											} else {
												navigate("/brand/tags-target/" + id);
											}
										} else {
											setEditModeBtnFun(1);
											setDataReadonly(false);
										}
									}
								});

								setButtonDisable(false);

						} else {
							toast.error(res.data.message, {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorWarning} />
							});

							setButtonDisable(false);
							setSubmitLoader(false);
							setDataReadonly(false);

						}
					});
				})
			}
		}
	}

	const validateImg = (savedImages) => { //console.log("tt",savedImages);
		if (Object.keys(savedImages).length) {
			let res = saveImage.filter(obj => {
				if (obj.type == "mainLogo") {
					setImgError(false);
					return true;
				} else {
					setImgError(true);
					return false;
				}
			})
			return res;
		} else {
			if (fileSrcUrl) {
				setImgError(false);
				return true;
			} else {
				setImgError(true);
				return false;
			}

		}

	}


	const resetFun = () => {
		if(editMode){
			setEditModeBtnFun(0);
		}
		 console.log("lklk",fileSelOpen)
		if (!dataReadonly) {
			setFileSrcUrl("");
		}
	}
	const resetFun1 = () => {
		if(editMode){
			setEditModeBtnFun(0);
		}
		if (!dataReadonly) {
			setFileSrcUrl1("");
		}
	}

	const resetFun2 = () => {
		if(editMode){
			setEditModeBtnFun(0);
		}
		if (!dataReadonly) {
			setFileSrcUrl2("");
		}
	}

	const resetFun3 = () => {
		if(editMode){
			setEditModeBtnFun(0);
		}
		if (!dataReadonly) {
			setFileSrcUrl3("");
		}
	}

	const resetFun4 = () => {
		if(editMode){
			setEditModeBtnFun(0);
		}
		if (!dataReadonly) {
			setFileSrcUrl4("");
		}
	}

	const resetFun5 = () => {
		if(editMode){
			setEditModeBtnFun(0);
		}
		if (!dataReadonly) {
			setFileSrcUrl5("");
		}
	}
	const handleOnChange = (e) => {
		setSliderInitial(e.y);
	}
	useEffect(() => {
		setScale(tempScale + sliderInitial)
	}, [sliderInitial])

	return (
		<>
			{(submitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
					{(showPreLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> : (denied) ? <ForBidden /> : <>
						<div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" className="button-not-active" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
									<span className="searchprodicttext text-center">Brands</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to={(props.compData?.completePercentage === "Completed" || props.compData?.status == '100') ? "/brand" : "#"} className={(props.compData?.completePercentage === "Completed" || props.compData?.status == '100') ? "btn btn-success saveIt" : " btn btn-success button-not-active"}>Brands</Link>
								<Link to="#" className={(editMode) ? "btn btn-success active saveIt" : "btn btn-success button-not-active"}>Edit Brand</Link>
								<Link to="/brand/creator" className={(!editMode) ? "btn btn-success active saveIt" : "btn btn-success  saveIt"}>New Brand</Link>
							</div>
							<div className="rightcontrolserch">
								<div className="productsearch rightareas saveIt">
									<Link data-widget="search" to={(editMode)?"/brand/creator/" + id: "/brand/creator"} role="button">
										<span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div>
								<div className="productsearch rightareas saveIt">
									<Link to="#" role="button">
										<span className="btn btn-success active"><i className="fa-thin fa-sign-hanging"></i></span>
										<span className="searchprodicttext">Logos</span>
									</Link>
								</div>
								<div className={(targetEnable) ? "productsearch rightareas saveIt" : "productsearch rightareas notactive"}>
									<Link data-widget="search" to={(targetEnable) ? "/brand/tags-target/" + id : "#"} role="button">
										<span className="btn btn-success"><i className="fa-thin fa-bullseye"></i></span>
										<span className="searchprodicttext">Targets</span>
									</Link>
								</div>
							</div>
						</div>
						{(showPreLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> : <>
							<div ref={refWarp}>
								<div className="filtertopcombo">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="filtersection creatorsbtnset enroll-brand">
													<span className={(saveMainLogo) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
														<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun) ? true : buttonDisable} >{buttonName} {(buttonDisable) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-chevron-right"></i>)}</button>
													</span>
												</div>
												<StepCombination cdata={props.compData} />

											</div>
										</div>
									</div>
								</div>

								<div className="creators-section">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="cardsection">
													<div className="card-header">
														<h3 className="card-title">BRAND LOGOS<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
													</div>
													<div className="card-body">
														<div className="row">
															<div className="col-md-6">
																<div className="uploadimage-area">
																	<div className="crop-section">
																		<div className={(!imgSrc) ? "croped-inner" : ((wtb) ? "croped-inner img-found croped-inner-wb" : "croped-inner img-found")}>
																			{!!imgSrc && (
																				<ReactCrop
																					crop={crop}
																					onChange={(_, percentCrop) => setCrop(percentCrop)}
																					onComplete={(c) => setCropedDataFun(c)}
																					aspect={aspect}
																				>
																					<img
																						ref={imgRef}
																						alt="Crop me"
																						src={imgSrc}
																						style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
																						onLoad={onImageLoad}
																					/>
																				</ReactCrop>
																			)}
																			{
																				(!imgSrc) ? (<div className="croped-message">
																					<h3>LOGO</h3>
																					<p>max 300Kb</p>
																				</div>) : ""
																			}

																		</div>
																		<div className={(isDisabled) ? "zoom-slider" : "zoom-slider active"}>
																			<div className={(isDisabled) ? "slider-sm-btns" : "slider-sm-btns active"}>

																				<Link to="#" onClick={(e) => (isDisabled) ? e.preventDefault() : zoomImage(e, 'in')} className="topicons"><i className="fa-light fa-plus"></i></Link>
																				<Link to="#" onClick={(e) => (isDisabled) ? e.preventDefault() : zoomImage(e, 'out')} className="bottomicons"><i className="fa-light fa-minus"></i></Link>
																			</div>

																			<Slider
																				styles={{
																					track: {
																						backgroundColor: '#4f5b6d',
																						borderRadius: 0,
																						width: 2,
																						height: 220
																					},
																					active: {
																						backgroundColor: '#4f5b6d',
																						borderRadius: 0,
																						width: 2
																					},
																					thumb: {
																						width: 15,
																						height: 15,
																						backgroundColor: '#d1d1d1'
																					}
																				}}
																				axis="y"
																				ymin={1}
																				ymax={-1}
																				ystep={.05}
																				y={sliderInitial}
																				disabled={isDisabled}
																				onChange={handleOnChange}
																			/>
																		</div>
																	</div>
																	<div className="crop-btns">
																		<ul className="multibtns">
																			<li className={(imgSrc) ? "active" : ""}><button className={(imgSrc) ? "btn btn-default tunintoGreen" : "btn btn-default"} onClick={() => cancelFun(logoType)} disabled={!imgSrc}>Reset</button></li>
																			<li className={(isCropped) ? "active" : ""}><button className={(isCropped) ? "btn btn-default tunintoGreen" : "btn btn-default"} onClick={saveFunction} disabled={!isCropped}>Save</button></li>
																		</ul>
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="row">
																	<div className="col-md-6">
																		<div className="company-logo-previewarea">
																			<div className="user-profile-picture">
																				<div className={(completedCrop && !dataReadonly) ? "form-group activeborder blueborder" : (dataReadonly) ? "form-group inActiveborder" : "form-group activeborder"}>
																					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Main logo</label>

																					{(completedCrop) ? (
																						<div className="profile-div">
																							{(saveMainLogo) ? (<span className="close-image" onClick={() => { if (!fileSelOpen) { cancelFun("mainLogo") } }}></span>) : ""}
																							<canvas
																								ref={previewCanvasRef}
																								style={{
																									border: '1px solid black',
																									objectFit: 'contain',
																									width: completedCrop.width,
																									height: completedCrop.height,
																								}}
																							/></div>
																					) : (fileSrcUrl) ? (<div className="profile-div"><span className="close-image" onClick={resetFun}></span> <img src={fileSrcUrl} /> </div>)
																						: (<div className={(imgError) ? "profile-div error-input" : "profile-div"} onClick={() => { if (!fileSelOpen) { openFileSelector("mainLogo") } }}><div className={(dataReadonly || imgSrc) ? "customprofile-pic notactive" : "customprofile-pic "}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																							<span className="upload-profile-text">Upload</span>
																							<input id="companyMainLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{ "display": "none" }} disabled={(dataReadonly) ? true : false} />
																						</div></div>)}
																				</div>
																			</div>
																		</div>
																		<div className="company-logo-previewarea nonmandatory-logo-place small-logo">
																			<div className="user-profile-picture">
																				<div className={(dataReadonly) ? "form-group inActiveClose" : "form-group activeborder"} >
																					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> All white version</label>

																					{(completedWhiteCrop) ? (
																						<div className="profile-div">
																							{(saveMainWhiteLogo) ? (<span className="close-image" onClick={() => { if (!fileSelOpen) { cancelFun("mainWhiteLogo") } }}></span>) : ""}
																							<canvas
																								ref={previewWhiteCanvasRef}
																								style={{
																									border: '1px solid black',
																									objectFit: 'contain',
																									width: completedWhiteCrop.width,
																									height: completedWhiteCrop.height,
																								}}
																							/></div>
																					) : (fileSrcUrl1) ? (<div className="profile-div"><span className="close-image" onClick={resetFun1}></span> <img src={fileSrcUrl1} /> </div>)
																						: (<div className="profile-div" onClick={() => { if (!fileSelOpen) { openFileSelector("mainWhiteLogo") } }}><div className={(dataReadonly || imgSrc) ? "customprofile-pic notactive" : "customprofile-pic"}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																							<span className="upload-profile-text">Upload</span>
																							<input id="companyMainWhiteLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{ "display": "none" }} disabled={(dataReadonly) ? true : false} />
																						</div></div>)}
																				</div>
																			</div>
																		</div>
																		<div className="company-logo-previewarea nonmandatory-logo-place small-logo">
																			<div className="user-profile-picture">
																				<div className={(dataReadonly) ? "form-group inActiveClose" : "form-group activeborder"} >
																					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> All black version</label>

																					{(completedBlackCrop) ? (
																						<div className="profile-div">
																							{(saveMainBlackLogo) ? (<span className="close-image" onClick={() => { if (!fileSelOpen) { cancelFun("mainBlackLogo") } }}></span>) : ""}
																							<canvas
																								ref={previewBlackCanvasRef}
																								style={{
																									border: '1px solid black',
																									objectFit: 'contain',
																									width: completedBlackCrop.width,
																									height: completedBlackCrop.height,
																									backgroundColor: "white",
																								}}
																							/></div>
																					) : (fileSrcUrl2) ? (<div className="profile-div"><span className="close-image" onClick={resetFun2}></span> <img src={fileSrcUrl2} /> </div>)
																						: (<div className="profile-div" onClick={() => { if (!fileSelOpen) { openFileSelector("mainBlackLogo") } }}><div className={(dataReadonly || imgSrc) ? "customprofile-pic notactive" : "customprofile-pic"}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																							<span className="upload-profile-text">Upload</span>
																							<input id="companyMainBlackLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{ "display": "none" }} disabled={(dataReadonly) ? true : false} />
																						</div></div>)}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className="company-logo-previewarea nonmandatory-logo-place">
																			<div className="user-profile-picture">

																				<div className={(dataReadonly) ? "form-group inActiveClose" : "form-group activeborder"} >
																					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Alternative logo</label>

																					{(completedAltCrop) ? (
																						<div className="profile-div">
																							{(saveMainAltLogo) ? (<span className="close-image" onClick={() => { if (!fileSelOpen) { cancelFun("mainAltLogo") } }}></span>) : ""}
																							<canvas
																								ref={previewAltCanvasRef}
																								style={{
																									border: '1px solid black',
																									objectFit: 'contain',
																									width: completedAltCrop.width,
																									height: completedAltCrop.height,
																								}}
																							/></div>
																					) : (fileSrcUrl3) ? (<div className="profile-div"><span className="close-image" onClick={resetFun3}></span> <img src={fileSrcUrl3} /> </div>)
																						: (<div className="profile-div" onClick={() => { if (!fileSelOpen) { openFileSelector("mainAltLogo") } }}><div className={(dataReadonly || imgSrc) ? "customprofile-pic notactive" : "customprofile-pic"}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																							<span className="upload-profile-text">Upload</span>
																							<input id="companyAltLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{ "display": "none" }} disabled={(dataReadonly) ? true : false} />
																						</div></div>)}
																				</div>
																			</div>
																		</div>
																		<div className="company-logo-previewarea nonmandatory-logo-place small-logo">
																			<div className="user-profile-picture">
																				{/* <div className={(completedAltWhiteCrop)?"form-group activeborder blueborder":"form-group activeborder"}> */}
																				<div className={(dataReadonly) ? "form-group inActiveClose" : "form-group activeborder"} >
																					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> All white version</label>

																					{(completedAltWhiteCrop) ? (
																						<div className="profile-div">
																							{(saveMainAltWhite) ? (<span className="close-image" onClick={() => { if (!fileSelOpen) { cancelFun('altWhite') } }}></span>) : ""}
																							<canvas
																								ref={previewAltWhiteCanvasRef}
																								style={{
																									border: '1px solid black',
																									objectFit: 'contain',
																									width: completedAltWhiteCrop.width,
																									height: completedAltWhiteCrop.height,
																								}}
																							/></div>
																					) : (fileSrcUrl4) ? (<div className="profile-div"><span className="close-image" onClick={resetFun4}></span> <img src={fileSrcUrl4} /> </div>)
																						: (<div className="profile-div" onClick={() => { if (!fileSelOpen) { openFileSelector('altWhite') } }}><div className={(dataReadonly || imgSrc) ? "customprofile-pic notactive" : "customprofile-pic"}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																							<span className="upload-profile-text">Upload</span>
																							<input id="companyAltWhiteLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{ "display": "none" }} disabled={(dataReadonly) ? true : false} />
																						</div></div>)}
																				</div>
																			</div>
																		</div>
																		<div className="company-logo-previewarea nonmandatory-logo-place small-logo">
																			<div className="user-profile-picture">
																				{/* <div className={(completedAltBlackCrop)?"form-group activeborder blueborder":"form-group activeborder"}> */}
																				<div className={(dataReadonly) ? "form-group inActiveClose" : "form-group activeborder"} >
																					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> All black version</label>

																					{(completedAltBlackCrop) ? (
																						<div className="profile-div">
																							{(saveMainAltBlack) ? (<span className="close-image" onClick={() => { if (!fileSelOpen) { cancelFun('altBlack') } }}></span>) : ""}
																							<canvas
																								ref={previewAltBlackCanvasRef}
																								style={{
																									border: '1px solid black',
																									objectFit: 'contain',
																									width: completedAltBlackCrop.width,
																									height: completedAltBlackCrop.height,
																									backgroundColor: "white",
																								}}
																							/></div>
																					) : (fileSrcUrl5) ? (<div className="profile-div"><span className="close-image" onClick={resetFun5}></span> <img src={fileSrcUrl5} /> </div>)
																						: (<div className="profile-div" onClick={() => { if (!fileSelOpen) { openFileSelector('altBlack') } }}><div className={(dataReadonly || imgSrc) ? "customprofile-pic notactive" : "customprofile-pic"}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																							<span className="upload-profile-text">Upload</span>
																							<input id="companyAltBlackLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{ "display": "none" }} disabled={(dataReadonly) ? true : false} />
																						</div></div>)}
																				</div>
																			</div>
																		</div>


																	</div>

																</div>

															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>}
					</>}
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>

		</>
	);
}
export default Dashboard;