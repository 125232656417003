import React, { useRef, useEffect, useState, memo, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BrandNavigation from "../../layouts/BrandDashboardHeader";
import BrandAside from "../../layouts/BrandAside";
import BrandFooter from "../../layouts/BrandFooter";
import ProductList from "./product-list";
import BoimageOne from './../../../assets/images/uploads/boimageone.jpg';
import BrandLogo from './../../../assets/images/uploads/brandlogs.png';
import axios from 'axios';
import { API_URL , API_KEY_B } from '../../../config/constant.js';
import CretorImagedrop from './../../../assets/images/creator-imagedrop.jpg';
import Tsbrand from './../../../assets/images/uploads/brandlogs.png';
import './../../../assets/css/inputTags.css';
import InputTag from './TagsInput';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { useBeforeunload } from 'react-beforeunload';
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning"
import Swal from 'sweetalert2';
import StepCombination from '../../layouts/commonLayout';
import errorSuccess from './../../../assets/images/icons8-check-64.png';
import errorWarning from './../../../assets/images/icons-error-48.png';
import LoaderSvg from './../../../assets/images/loader.svg';
import LoaderIcon from './../../../assets/images/loader-icon.png';
import ForBidden from './../../layouts/denied';
import { Calendar, DateObject } from "react-multi-date-picker";
import Select from 'react-select/creatable';
toast.configure();
function Dashboard(props) {
	document.title = 'BOstagB - Creator Target';
	const { BOimageIdData } = useParams();


	const [lastInsertIdData, setLastInsertIdData] = useState("");
	const [genderTarget, setGenderTarget] = useState([]);
	const [ageGroups, setAgeGroups] = useState([]);
	const [lifestyle, setLifestyle] = useState([]);
	const [majorMarket, setMajorMarket] = useState([]);
	const [brandGenderTarget, setBrandGenderTarget] = useState([]);
	const [brandAgeGroups, setBrandAgeGroups] = useState([]);
	const [brandLifestyle, setBrandLifestyle] = useState([]);
	const [brandMajorMarket, setBrandMajorMarket] = useState([]);
	const [brandHashtag, setBrandHashtag] = useState("");
	const [brandGenderTarget1, setBrandGenderTarget1] = useState([]);
	const [brandAgeGroups1, setBrandAgeGroups1] = useState([]);

	const [brandHashtag1, setBrandHashtag1] = useState("");
	const [genderError, setGenderError] = useState(false);
	const [ageGroupError, setAgeGroupError] = useState(false);
	
	const [hashtag, setHashtag] = useState("");
	const [ratio, setRatio] = useState({ h: 0, w: 0 })
	const [renderData, setRenderData] = useState("");
	const [imageData, setImageData] = useState("");
	
	const [preLoader, setPreLoader] = useState(true);
	const [onSubmitLoader, setOnSubmitLoader] = useState(false);
	const [buttonGray, setButtonGray] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [dataReadonly, setDataReadonly] = useState(0);
	const ref = useRef();
	let navigate = useNavigate();
	//let BOid = BOimageIdData;
	const [brandLogo, setBrandLogo] = useState('');
	const [infoLoader, setInfoLoader] = useState(false);
	const [brandLoader, setBrandLoader] = useState(false);
	const [denied, setDenied] = useState(false);
	const [msgs,setMsgs] =useState("Your first BOimage was created successfully. We now need stores to fullfill orders coming from this BOimages.");
	const [buttonName,setButtonName] =useState("NEXT");
	const [removeButtonName, setRemoveButtonName] = useState("REMOVE BRAND TAGS")
	const [btns,setBtns] = useState(0);
	const [editMode,setEditMode] =useState(0);
	const[editModeBtnFun,setEditModeBtnFun] = useState(0);
	

	/******************************************************* */

	const [elementData, setElementData] = useState("");

	const [name, setName] = useState("");
	const [collection, setCollection] = useState("");
	const [caption, setCaption] = useState("");
	const [text, setText] = useState("");
	const [nameError, setNameError] = useState(false);
	const [captionError, setCaptionError] = useState(false);
	const [imgUrlCk ,setImgUrlCk] =useState(true);
	const [collectionVal,setCollectionVal] =useState(null);
	const[brandCollection,setBrandCollection] =useState(true);

   /************************************************* */

	useEffect(() => { 
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.BOimagesMenu){
					setPreLoader(false);
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }
		
	},[props.compData]);
	useEffect(()=>{
		getBrandCollectionData();
	
	  },[])
	
	  const getBrandCollectionData =  () =>{
	
		const brandCollectionOption1=[];
		const formData ={"brandID":localStorage.getItem('brandID')}
	
		 axios.post(API_URL+"get-brand-bo-collection"+API_KEY_B,formData).then((res)=>{
			if(res.data.status === 200){
			   let data = res.data.data;
				 data.forEach((da) => {
					
					brandCollectionOption1.push({ value: da.name, label: da.name });	
				   }
				 );
				
				 setBrandCollection(brandCollectionOption1);
			}
	   })  
	} 
   
	useEffect(()=>{
		if(localStorage.getItem("viewMode")=='editmode')
		{
		  setEditMode(1);
		  setEditModeBtnFun(1)
		}
	 },[localStorage.getItem("viewMode")]) 

	useEffect(()=>{
		if(localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed'){
			setButtonName("SAVE");
		}
	  },[localStorage.getItem('status')])


/*******************************ID data********************************************* */
const colourStyles = {
	control: (styles, state) => ({
		...styles,
		backgroundColor: '#10141B',
		borderColor: "transparent",
		border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
		boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
		"&:hover": {
			border: "1px solid #0B0",
		},
		"&:active": {
			border: "1px solid #0B0",
		}
	}),
	noOptionsMessage:(styles) => ({...styles, backgroundColor: '#333F50', color: '#d1d1d1'}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: '#333F50',
			color: '#d1d1d1',
			cursor: 'pointer',
			border: "1px solid #10141B",
			borderLeft: "1px solid transparent",
			borderRight: "1px solid transparent",
			"&:hover": {
				border: "1px solid #00AEEF",
			},
			"&:active": {
				backgroundColor: "#333F50",
			}
		};
	},
	indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
	menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
	menuList: styles => ({...styles, padding: 0, paddingRight: "2px",
		"::-webkit-scrollbar-thumb": {
			borderRadius: "10px"
		}
	}),
	singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
	input: styles => ({ ...styles, color: '#d1d1d1' }),
	placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
	multiValueLabel: (styles, { data }) => ({
	...styles,
	color: "#fff",
	backgroundColor: "#205380",
	borderRadius: "0px",
  }),
  multiValueRemove: (styles, { data }) => ({
	...styles,
	color: "#fff",
	backgroundColor: "#205380",
	borderRadius: "0px",
':hover': {
  backgroundColor: "#205380",
  color: 'white',
},
}),
};


const [values, setValues] = useState([]);
const [firstValues, setFirstValues] = useState("");
const [secondValues, setSecondValues] = useState("");
const [mainDateRange, setMainDateRange] = useState("");

const changeDate = () =>{
	if((values[0]!=undefined) && (values[1]!=undefined)){
	let firstDate = values[0]?.format("DD-MM-YYYY");
	let secondDate = values[1]?.format("DD-MM-YYYY");
	setFirstValues(firstDate);
	setSecondValues(secondDate);
	let dat = firstDate + " to "+secondDate;
	setMainDateRange(dat);
	setHides(0);
  
		if(editMode){
			setEditModeBtnFun(0);
		} 
	}
}

 
const clearDate = () =>{	
	setMainDateRange("");
	setFirstValues("");
	setSecondValues("");
	setValues([]);
}
	
const handleName = (e) => {
	if(e.target.value!=" ")
		{
			setNameError(false);
			setName(e.target.value);
			if(editMode){
				setEditModeBtnFun(0);
			}	
		}
	/*if(e.target.value && caption){
	  setButtonGray(true);
	}else{
	  setButtonGray(false);
	} */
	 
};
const handleCollection = (e) => {
	if(e){
		setCollectionVal({value:e.value,label:e.label});
		setCollection(e.value);
		if(editMode){
			setEditModeBtnFun(0);
		 } 
	}else{
		setCollectionVal(null);
		setCollection("")
	}
}

/*const handleActivePeriod = (e) => {
	setActivePeriod(e.target.value)
} */
const handleCaption = (e) => {
	if(e.target.value!=" "){
			setCaptionError(false);
			setCaption(e.target.value);
			if(editMode){
				setEditModeBtnFun(0);
			 } 
		}
   /*if(e.target.value && name){
	  setButtonGray(true);
	}else{
	  setButtonGray(false);
	} 
	*/
		//console.log(buttonGray);

}
const handleText = (e,data) => {		
	setText(data);
	const element = document.getElementById("inputData");			
		element.focus();
		window.getSelection().selectAllChildren(element);
		window.getSelection().collapseToEnd();

		if(editMode){
			setEditModeBtnFun(0);
		 }
}

const[hides,setHides] = useState(0);
const handalCal = () =>{
	 if(hides){
		setHides(0);
	 }else{
		setHides(1);
	 }
}

const hideCal = () =>{
	setHides(0);
}

const wrapperRef = useRef(null);
useEffect(() => {

	function handleClickOutside(event) {
		
	  if (wrapperRef.current && !wrapperRef.current.contains(event.target) && wrapperRef.current.contains(event.target.name)!='dateRange') {
		  setHides(0);
	  }
	}
	document.addEventListener("mousedown", handleClickOutside);
	return () => {
	  document.removeEventListener("mousedown", handleClickOutside);
	};
  }, [wrapperRef]);



/*************************************************************************** */

   /*
    useEffect(() =>{
		if((genderTarget.length || brandGenderTarget.length) && (ageGroups.length || brandAgeGroups.length) && (lifestyle.length || brandLifestyle.length) && (majorMarket.length || brandMajorMarket.length)) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	},[genderTarget,ageGroups,lifestyle,majorMarket,brandGenderTarget,brandAgeGroups,brandLifestyle,brandMajorMarket]);
    */

	useEffect(() =>{
		if((genderTarget?.length || brandGenderTarget?.length) && (ageGroups?.length || brandAgeGroups?.length) && name && caption) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	},[genderTarget,ageGroups,brandGenderTarget,brandAgeGroups, name,caption]);

	useEffect(() => {
		getBrandInfo(localStorage.getItem("brandID"));

	}, [localStorage.getItem("brandID")]);

	const targetImageRef = useRef();

	const getBrandInfo = (brandID) => {
		let formData = {
			"id": brandID
		}
		axios.post(API_URL + 'get-brand-info'+API_KEY_B, formData).then((res) => {
			if (res.data.status == 200) {
				let da = res?.data?.data?.Targets;
                  // console.log("Htaa",res?.data?.data.Targets)
                if(res?.data?.data?.Targets?.HashTags){
				setBrandHashtag(res?.data?.data?.Targets?.HashTags?.join(" "))
				setHashtag(res?.data?.data?.Targets?.HashTags?.join(" "))
				setBrandHashtag1(res?.data?.data?.Targets?.HashTags?.join(" "));
				}

				setBrandGenderTarget(res?.data?.data?.Targets?.Genders)
				setBrandAgeGroups(res?.data?.data?.Targets?.AgeGroups)
				
				setGenderTarget(res?.data?.data?.Targets?.Genders)
				setAgeGroups(res?.data?.data?.Targets?.AgeGroups)
				
				setBrandLogo(res?.data?.data?.logos?.mainLogoURL)
				if((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') ){
			    	getDataOfLid(localStorage.getItem("boimageID"));
				}else{
					getDataOfLid(BOimageIdData);
				}
              
				setBrandGenderTarget1(res?.data?.data?.Targets?.Genders);
				setBrandAgeGroups1(res?.data?.data?.Targets?.AgeGroups);
				
				
				
				if (da?.Genders?.length && da?.AgeGroups?.length ) {
					setButtonGray(true);
				}
				

			}
			setBrandLoader(true)
		})
	}
	const getDataOfLid = async (dataLid) => { console.log("call",dataLid);
	
	
	  const formData = {
			"id": dataLid
		}
		await axios.post(API_URL + "get-boimage-id-data-by-id"+API_KEY_B, formData).then((res) => {
		//	await axios.post("http://localhost:7071/api/get-boimage-id-data-by-id",formData).then((res)=>{
			if (res.data.status == 200) {
				
				let da =res.data.resData.Targets;
				setLastInsertIdData(res.data.resData);
				setRenderData(res.data.resData.renderedIamge)
				setImageData(res.data.resData.imageURL)
                 
			
				if (da.Genders.length && da.AgeGroups.length && res?.data?.resData?.name &&  res?.data?.resData?.caption) {
					if((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') ){
						
						setDataReadonly(true);
					}
						setBrandHashtag(da?.HashTags.join(" #"))
						setHashtag(da?.HashTags.join(" #"))

						setBrandGenderTarget(da?.Genders)
						setBrandAgeGroups(da?.AgeGroups)
						
						setGenderTarget(da?.Genders)
						setAgeGroups(da?.AgeGroups)
						
						
						setName(res?.data?.resData?.name)
						setCaption(res?.data?.resData?.caption)
						setText(res?.data?.resData?.description)
						setFirstValues(res?.data?.resData?.activePeriodStart);
						setSecondValues(res?.data?.resData?.activePeriodEnd);
						
						setCollectionVal({value: res.data.resData?.collectionName,label: res.data.resData?.collectionName});
					//added
						if(res?.data?.resData?.activePeriodStart !="" && res?.data?.resData?.activePeriodEnd !=""){
					//
							let dat = res?.data?.resData?.activePeriodStart + " to "+res?.data?.resData?.activePeriodEnd;
							setMainDateRange(dat);

							let startDay = (res?.data?.resData?.activePeriodStart)?.split('-');
							let endDay = (res?.data?.resData?.activePeriodEnd)?.split('-');
							setValues([
								new DateObject().setDay(startDay[0]).setMonth(startDay[1]).setYear(startDay[2]),
								new DateObject().setDay(endDay[0]).setMonth(endDay[1]).setYear(endDay[2])
								]
							)
						}

					
					setMsgs("ID,Targets and Hashtags updated");
					setButtonName("SAVE");
					if(localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed'){
							setEditMode(1);
					}
				}else{
				   if(localStorage.getItem("viewMode")=='editmode')
					 {
					     setEditModeBtnFun(0)
					 }
				}	
              

			} else {
				navigate("/boimages/canvas")
			}
			setInfoLoader(true)
		});
	}
	useEffect(() => {
		if (infoLoader && brandLoader) {
			setPreLoader(false)
		}

	}, [infoLoader, brandLoader])
	const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning(genderTarget, ageGroups,  hashtag);

	const selectedTags = (tags) => {
		      //console.log(genderTarget , ageGroups,lifestyle , majorMarket,tags.genderTarget); 
		if (tags.genderTarget) {
			setGenderTarget(tags.genderTarget);
			setGenderError(false);
		}
		if (tags.ageGroup) {
			setAgeGroups(tags.ageGroup);
			setAgeGroupError(false);
		}
		/*if (tags.lifestyle) {
			setLifestyle(tags.lifestyle);
			setLifestyleError(false);
		}
		if (tags.majorMarket) {
			setMajorMarket(tags.majorMarket);
			setMajorMarketError(false);
		} */

		if(editMode){
			setEditModeBtnFun(0);
		 }
		
	}
	const hashtagOnchange = (e) => {
		setHashtag(e.target.value);
		
		if(editMode){
			setEditModeBtnFun(0);
		 }

	}
	const submitBOimages = (e) => { 
		e.preventDefault();
		if (dataReadonly) {
			navigate("/store/pre-store");
		} else {
			if (handleValidation()) {
                if(localStorage.getItem('status') != 100 &&  localStorage.getItem('status') != 'Completed') {
					/**********enrollment process******************** */
				 setDataReadonly(true);
			   }     
				setButtonDisable(true);
				setOnSubmitLoader(true)
				let formData = {
					"BOid": BOimageIdData,
					"genders": genderTarget,
					"lifestyle": lifestyle,
					"ageGroup": ageGroups,
					"majorMarket": majorMarket,
					"hashtag": hashtag,
					"publisherURL": uuidv4(),
					"name":name,
					"collectionName":collection,
					"description":text,
					"caption":caption,
					"activePeriodStart":firstValues,
					"activePeriodEnd":secondValues,
					"brandID":localStorage.getItem('brandID'),

				}
				//console.log(formData);
				 props.loginFun(localStorage.getItem('token'), '75');
				axios.post(API_URL + "update-boimage-target"+API_KEY_B, formData).then((res) => {
				//axios.post("http://localhost:7071/api/update-boimage-target", formData).then((res) => {
					if (res.data.status == 200) {
						    //props.loginFun(localStorage.getItem('token'), '75');
						   if(editMode){
							 setEditModeBtnFun(1);
						   }	
						  
						if (!props.compData?.storeCount) {
							
							toast.success(msgs,
								{
									theme: "dark",
									icon: ({ theme, type }) => <img src={errorSuccess} />,
									onClose: () => {
                                       if(localStorage.getItem('status')!=100 && localStorage.getItem('status')!='Completed'){
										props.loginFun(localStorage.getItem('token'), '75');
										navigate("/store/pre-store");
									   }else{
										  if(!editMode){
											navigate("/boimages");		
										  }		
									   }
									}
								});
							setOnSubmitLoader(false);
							setButtonDisable(false);

						} else {
							toast.error(res.data.message, {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorWarning} />
							});
							setButtonDisable(false);
							setOnSubmitLoader(false);
						}

					}

				});
			}
		}

	}
	const handleValidation = () => {
		//console.log("dsdsd",genderTarget,ageGroups,lifestyle,majorMarket,brandGenderTarget,brandAgeGroups,brandLifestyle,brandMajorMarket);
		setPristine();
		let formIsValid = true;
		if (!genderTarget.length && !brandGenderTarget.length) {
			formIsValid = false;
			setGenderError(true)
		}
		if (!ageGroups.length && !brandAgeGroups.length) {
			formIsValid = false;
			setAgeGroupError(true)
		}
		/*if (!lifestyle.length && !brandLifestyle.length) {
			formIsValid = false;
			setLifestyleError(true)
		}
		if (!majorMarket.length && !brandMajorMarket.length) {
			formIsValid = false;
			setMajorMarketError(true)
		}  */

		if(name==""){
			setNameError(true)
		   formIsValid = false;
		} 
		if(caption==""){
			setCaptionError(true);
			 formIsValid = false;
		  }
		return formIsValid;
	}
	let fileName = lastInsertIdData?.imageURL?.split('/').at(-1);
	const onImgLoad = (e) => {
		let height = targetImageRef.current.clientHeight / renderData?.height;
		let width = targetImageRef.current.clientWidth / renderData?.width;
		setRatio({ h: height, w: width });
	}
	useBeforeunload((event) => {
		// if (genderTarget || ageGroups || lifestyle || majorMarket || hashtag) {
	 if (genderTarget || ageGroups  || hashtag) {
			event.preventDefault();
		}
	});
	const removeBrandTagFunc = () => {
		
		let genderT = genderTarget.filter(n => !brandGenderTarget.includes(n))
		setGenderTarget(genderT)
		let ageT = ageGroups.filter(n => !brandAgeGroups.includes(n))
		setAgeGroups(ageT)
		/*let lifestyleT = lifestyle.filter(n => !brandLifestyle.includes(n))
		setLifestyle(lifestyleT)
		let majorT = majorMarket.filter(n => !brandMajorMarket.includes(n))
		setMajorMarket(majorT) */
		let hashData = hashtag.replace(brandHashtag, '')
		setHashtag(hashData);

		setBrandGenderTarget([])
		setBrandAgeGroups([])
		//setBrandLifestyle([])
		//setBrandMajorMarket([])
		
		setRemoveButtonName("ADD BRAND TAGS");
		setBtns(1);
		//console.log("remove",genderTarget,ageGroups,lifestyle,majorMarket,brandGenderTarget,brandAgeGroups,brandLifestyle,brandMajorMarket);
	}
   const addBrandTag = () =>{
	//console.log(brandHashtag1);
			setHashtag(brandHashtag1)
			setBrandGenderTarget(brandGenderTarget1)
			setBrandAgeGroups(brandAgeGroups1)
			//setBrandLifestyle(brandLifestyle1)
			//setBrandMajorMarket(brandMajorMarket1)
			setRemoveButtonName("REMOVE BRAND TAGS");
			setBtns(0);
			//if (brandGenderTarget1.length && brandAgeGroups1.length && brandLifestyle1.length && brandMajorMarket1.length) {
			if (brandGenderTarget1.length && brandAgeGroups1.length ) {
				setButtonGray(true);
				setGenderError(false);
				setAgeGroupError(false);
				//setLifestyleError(false);
				//setMajorMarketError(false);
			}
   }




	const actionBtn = (val) =>{
		if(val){
            addBrandTag();
		}else{
            removeBrandTagFunc();
		}
		
	}

const rmBrMMTag = (indx) =>{
       setBrandMajorMarket ([...brandMajorMarket.filter(res=> brandMajorMarket.indexOf(res) !== indx)])
}
const rmBrLsTag = (indx) =>{
	setBrandLifestyle ([...brandLifestyle.filter(res=> brandLifestyle.indexOf(res) !== indx)])
}
const rmBrAgTag = (indx) =>{
	setBrandAgeGroups ([...brandAgeGroups.filter(res=> brandAgeGroups.indexOf(res) !== indx)])
}
const rmBrGnTag = (indx) =>{
	setBrandGenderTarget([...brandGenderTarget.filter(res=> brandGenderTarget.indexOf(res) !== indx)])
}

const refWarp = useRef(null);   //new datatttt
useEffect(() => {

	function handleSaveOutside(event) {
		/*console.log("ref.curren",refWarp.current.contains(event.target));
		console.log("eventswq",event.target.value);
		console.log("event",event.target.classList);
		console.log("refWarp.current",refWarp.current); */
         //map
		let pclass = event.target.parentNode.parentNode.parentNode.parentNode;
		let pclassimg = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
		// rightcontrolserch
		let prClassIcon = event.target.parentNode.parentNode.parentNode;
		let prClass = event.target.parentNode.parentNode;
        //center upper btn
		  let pCNClass = event.target.parentNode;
	  
		if(editMode && !editModeBtnFun){  
			//  console.log("test",editMode ,editModeBtnFun);
			  if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || pclass.classList.contains("menusections") || pclass.classList.contains("navitems-area") || pclassimg.classList.contains("navitems-area")|| pclassimg.classList.contains("menusections") || prClassIcon.classList.contains("saveIt")  || prClass.classList.contains("saveIt") || pCNClass.classList.contains("saveIt") )  ) {
			  Swal.fire({
				  title: '',
				  text: "Changes will not be saved",
				  icon: 'warning',
				  showDenyButton: true,
				  allowOutsideClick: false,
				  confirmButtonColor: '#205380',
				  denyButtonColor: '#205380',
				  confirmButtonText: 'SAVE NOW',
				  denyButtonText: 'CANCEL',
				  background: '#1d2530',
				  color: '#fff',
				  iconColor: "#FF9900",
				  width: "500px",
				  padding: "10px",
				  reverseButtons: false
				}).then((result) => {
				  if (result.isConfirmed) {
					  setEditModeBtnFun(1);   
					  submitBOimages(event);  		
				  }
				  else if(result.isDenied){
					setEditModeBtnFun(1);
				  }
				  
			  })   

		  
	  }
	 }
	}
	document.addEventListener("mousedown", handleSaveOutside);
	return () => {
	  document.removeEventListener("mousedown", handleSaveOutside);
	};
  }, [refWarp,editMode,editModeBtnFun]);   






	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper">
			    	{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
					<div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" to="#" className="button-not-active " role="button">
								<span className="btn btn-success"><i className="fas fa-search"></i></span>
								<span className="searchprodicttext">BOimage</span>
							</Link>
						</div>


						<div className="centerbtn">
							<Link to={(props.compData?.completePercentage === "Completed" || props.compData?.status =='100') ? "/boimages" : "#"} className={(props.compData?.completePercentage === "Completed" || props.compData?.status =='100') ? "btn btn-success saveIt" : " btn btn-success button-not-active"}>Boimages</Link>
							<Link to="#" className={(editMode)?"btn btn-success active saveIt":"btn btn-success button-not-active"}>Edit Boimage</Link>
							{/* <Link to="/boimages/creater" className={(editMode)?"btn btn-success saveIt":"btn btn-success active"}>New Boimage</Link> */}
							<Link to={((localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed' ))?"":"/boimages/canvas"} className={(editMode)?"btn btn-success saveIt":"btn btn-success active"}>New Boimage</Link>
						</div>

						<div className="rightcontrolserch">
							<span className="extraBtns">
										<div className="productsearch rightareas notactive">
											<Link data-widget="search" to="#" role="button">
												<span className="btn btn-success "><i className="fa-thin fa-list"></i></span>
												<span className="searchprodicttext">List</span>
											</Link>
										</div> 
										<div className="productsearch rightareas notactive">
											<Link to="#" role="button">
												<span className="btn btn-success"><i className="fa-thin fa-grid"></i></span>
												<span className="searchprodicttext">Grid</span>
											</Link>
										</div>    
										
								</span> 
								{/* <div className="productsearch rightareas saveIt">
									<Link data-widget="search" to={"/boimages/creater/" + BOimageIdData} role="button">
										<span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div> */}
								<div className="productsearch rightareas saveIt">
									<Link data-widget="search" to={"/boimages/canvas/" + BOimageIdData} role="button">
										<span className="btn btn-success "><i className="fa-thin fa-diagram-lean-canvas"></i></span>
										<span className="searchprodicttext">Canvas</span>
									</Link>
								</div>
								<div className="productsearch rightareas saveIt ">
									<Link data-widget="search" to="#" role="button">
										{/* <span className="btn btn-success active"><i className="fa-thin fa-bullseye"></i></span>
										<span className="searchprodicttext">Targets</span> */}
										<span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div>
						</div>


					</div>
					{(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> :
						<><div ref={refWarp}>
							<div className="filtersarea creators">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="filtersection creatorsbtnset targetpageprogress-bar">
												<div className="nobreadcumbleft">
													<div className="labelgreentext">Creating BOimage: <span className="itvalue">{lastInsertIdData?.name}</span></div>
													<div className="labelgreentext">BOimage ID: <span className="itvalue">{lastInsertIdData?.ourID}</span></div>
												</div>
												{/* <StepCombination cdata={props.compData} /> */}
												<span className={(buttonGray) ? "customeset textinside " : "customeset textinside disabledbtn"}>
													<button type="submit" onClick={submitBOimages} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun)?true:(buttonDisable) ? "disabled" : ""}> {(buttonDisable) ? (<i className="fas fa-spinner fa-spin"></i>) : <i className="fa-thin fa-save" aria-hidden="true"></i>} {buttonName}	</button>
												</span>
												<span className="customeset textinside lesspadding">
													<button type="submit" onClick={()=>{actionBtn(btns)}}  disabled={(dataReadonly)?true:false} className="btn btn-success">{removeButtonName}</button>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
                             
							<div className="creators-section">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="cardsection">
													<div className="card-header">
														<h3 className="card-title">BOIMAGE ID <span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
													</div>
													<div className="card-body" > { /*ref={wrapperRef} */ } 
														<form>
															<div className="row">
																<div className="col-md-3">
																	<div className={(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
																		<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Name (internal)</label>
																		<input type="text" className={(nameError)?"form-control error-input":"form-control"} onChange={handleName} value={name} placeholder="" disabled={dataReadonly} onClick={hideCal} />
																	</div>
																</div>
																<div className="col-md-3">
																	<div className={(dataReadonly)?"form-group lockedNoborder":"form-group"}>
																		<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> To collection</label>
																<Select
																options={brandCollection}
																styles={colourStyles}
																onChange={handleCollection}
																defaultValue = {collectionVal}
																isDisabled={dataReadonly}
																isClearable
																placeholder={<div  className="italic-placeholder">Select...</div>}
																/>
																	</div>
																</div>
																<div className="col-md-3">
																<div  className={(dataReadonly)?"form-group haveaddon slecticons lockcalendar active-period":"form-group haveaddon slecticons active-period"} >
											
																		<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Active Period</label>
																		<div className="addonimgleft ">
																			<input type="text" className="form-control" name="dateRange"  value={mainDateRange}   onClick={(!dataReadonly)?handalCal:""} placeholder="Always Active" disabled={dataReadonly} />
																		</div>
																	</div>
																	<div className="float-containers " ref={wrapperRef} style={{display:(hides)?"block":"none"}}>
																		<div className="float-childs">
																			<div className="inputcontainer">
																				<label >From</label>
																				
																			
																				<input type="text" className="df" name="dateFirst"  value={(values[0]!=undefined)?values[0]?.format("DD-MM-YYYY"):""} placeholder="" />
																			</div>
																			<div className="inputcontainer">
																				<label >To</label>
																				<input type="text" className="df" name="dateSecond"  value={(values[1]!=undefined)?values[1]?.format("DD-MM-YYYY"):""} placeholder="" />
																				
																			</div>
																			<div className="brtninouts">
																			<button type="button" onClick={()=>{clearDate();handalCal()}} className="cancelbtns">Cancel</button>
																			<button type="button" onClick={changeDate} className="aplpybtnd">Apply</button>
																			
																			</div>
																		
																			
																		</div>
																		<div className="float-childstwo">
																		<Calendar
																			value={values}
																			onChange={setValues}
																			range
																			numberOfMonths={2}
																			showOtherDays
																			
																			/> 
																		</div>
																	</div>
																</div>
																<div className="col-md-3">
																	<div className={(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
																		<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Caption (meta data)</label>
																		<input type="text" className={(captionError)?"form-control error-input":"form-control"} onChange={handleCaption} value={caption} placeholder="" disabled={dataReadonly} onClick={hideCal} />
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-12">
																		<div className={(dataReadonly)?"form-group expandHeight lockedNoborder":"form-group expandHeight"}>
																		<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Text</label>
																		{/*<input type="text" className="form-control" onChange={handleText} value={text} placeholder="" disabled={dataReadonly}/>*/}
																		<span id="inputData" spellcheck='false' className={(dataReadonly)?'disableTextArea customspaninput':'customspaninput'} suppressContentEditableWarning={true}  onInput={(e) => {handleText(e,e.currentTarget.innerText)}} contentEditable  >{text}</span>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>



							<div className="canvasarea">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="cardsection">
												<div className="card-header">
													<h3 className="card-title">Targets & Hashtags
														<span className="formcautions">
															<ul>
																<li><span className="makesqaure mandatorythis"></span>mandatory fields</li>
																<li><span className="makesqaure lockedthis"></span>locked fields</li>
															</ul>
														</span>
													</h3>
												</div>
												<div className="card-body">
													<div className="row">
														<div className="col-md-6">
															<div className="imageshowcase target-hashatags-screen">
																<div className="form-group">
																	<label htmlFor="tobrand">
																		<i className="fas fa-info-circle"></i> <span className="boldtitleinline">BO</span>image in progress</label>
																</div>
																<div className="sourceimgandname">

																	<div className="imageupdates">
																		<div className="image-drag">
																			<figure style={{ "position": "relative" }}>
																				<img ref={targetImageRef} src={imageData} onLoad={onImgLoad} />
																				{
																					lastInsertIdData?.BOtags?.map((r, i) => {   
																						 
																						return (
																							<div id={i + 1} style={{ "position": "absolute", "zIndex": "1", "left": (r.beaconXposition * ratio.w) + "px", "top": (r.beaconYposition * ratio.h) + "px" }}>
																								<div className={(r?.productID !="" && r?.productID != undefined)? "custom-className itslinked" : "custom-className targetNotLinked"}>
																									{/* <div className="shownumber">{r.BotagId}</div> */}
																									<div className="shownumber">{i + 1}</div>
																								</div>
																							</div>
																						)
																					})
																				}

																			</figure>
																		</div></div>
																	<span className="printimgname">File: {fileName}</span>
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="brandtagsarea">
																<div className="form-group">
																	<label htmlFor="gendertarget">
																		<i className="fas fa-info-circle"></i> Gender target
																	</label>
																	<div className={(dataReadonly) ? "customtaginput tag-inactive" : "customtaginput"}>
																		<InputTag selectedTags={selectedTags} preData={brandGenderTarget} rmBrTag={rmBrGnTag} tagType="genderTarget" isError={genderError} isDisabled={(dataReadonly)?true:false}/>
																	</div>
																</div>
																<div className="form-group">
																	<label htmlFor="gendertarget">
																		<i className="fas fa-info-circle"></i> Age groups
																	</label>
																	<div className={(dataReadonly) ? "customtaginput tag-inactive" : "customtaginput"}>
																		<InputTag selectedTags={selectedTags} preData={brandAgeGroups} tagType="ageGroup" rmBrTag={rmBrAgTag}  isError={ageGroupError} isDisabled={(dataReadonly)?true:false} />
																	</div>
																</div>
																{/*<div className="form-group">
																	<label htmlFor="gendertarget">
																		<i className="fas fa-info-circle"></i> Lifestyle & Social profile
																	</label>
																	<div className={(dataReadonly) ? "customtaginput tag-inactive" : "customtaginput"}>
																		<InputTag selectedTags={selectedTags} preData={brandLifestyle} tagType="lifestyle" rmBrTag={rmBrLsTag} isError={lifestyleError} isDisabled={(dataReadonly)?true:false} />
																	</div>
																</div>
																<div className="form-group">
																	<label htmlFor="gendertarget">
																		<i className="fas fa-info-circle"></i> Major markets
																	</label>
																	<div className={(dataReadonly) ? "customtaginput tag-inactive" : "customtaginput"}>
																		<InputTag selectedTags={selectedTags} preData={brandMajorMarket} rmBrTag={rmBrMMTag} tagType="majorMarket" isError={majorMarketError} isDisabled={(dataReadonly)?true:false} />
																	</div>
																</div> */}
																
																<div className="form-group">
																	<label htmlFor="gendertarget">
																		<i className="fas fa-info-circle"></i> Hashtags
																	</label>
																	<div className={(dataReadonly) ? "customtaginput tag-inactive" : "customtaginput"}>
																		<textarea className="form-control" onChange={hashtagOnchange} rows="5" value={hashtag}  id="comment" readOnly={(dataReadonly)?true:false}></textarea>
																	</div>
																</div>

															</div>
														</div>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						   </div>	
						</>}
                      </>}
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
			{/* {(!dataReadonly)?Prompt:""} */}
			{(onSubmitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}
		</>
	);
}
export default Dashboard;