import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "../layouts/BrandDashboardHeader";
import BrandAside from "../layouts/BrandAside";
import BrandAsideNew from "../layouts/BrandAsideNew";
import BrandFooter from "../layouts/BrandFooter";
import errorWarning from './../../assets/images/error-icon.png';
import errorSuccess from './../../assets/images/icons8-check-64.png'
import listImage from './../../assets/images/icons8-list-view-48.png';
import DataTable, { createTheme } from 'react-data-table-component';
import './../../assets/css/checkbox.css'
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import { API_URL, API_KEY_B } from '../../config/constant.js';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import ForBidden from '../layouts/denied';
toast.configure();
createTheme('solarized', {
  text: {
    primary: '#fff',
    secondary: '#fff',
  },
  background: {
    default: '#10141B',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#333F50',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');


toast.configure();


function Dashboard(props) {
  document.title = 'BOstageB - Store ';
  const ref = useRef();
  const [elementData, setElementData] = useState("");
  const [denied, setDenied] = useState(false);
  const [preLoader, setPreLoader] = useState(true);
  const [responseArray, setResponseArray] = useState([]);
  const [storeDataList, setStoreDataList] = useState([]);
  const [data1, setData1] = useState(0);

  useEffect(() => {
    let _data = JSON.parse(localStorage.getItem("__data"));

    if (typeof props?.compData?.companyDetail != "undefined") {
      if (_data) {
        if (!_data?.StoresMenu) {
          setPreLoader(false);
          setDenied(true)
        } else {
          setDenied(false)
        }
      }
    }
  }, [props.compData]);

  // props.storeCount(); 

  useEffect(() => {
   //console.log("tee");
    getStoreList();
  }, []);

  useEffect(() => {
    if(localStorage.getItem('brandID')){
       // getStoreList();
      // console.log("test-0");
  }else{
     //setPreLoader(false);
        } 
  },[localStorage.getItem("brandID")]);


  useEffect(() => {
    localStorage.removeItem("viewMode")
  }, []);

  const getStoreList = () => {
    setResponseArray([])
    axios.post(API_URL + "get-brand-store-list" + API_KEY_B, { brandID: localStorage.getItem("brandID") }).then(res => {
      //setResponseArray(res?.data?.data);

      setStoreDataList(res?.data?.data);
      //setPreLoader(false)
      if (res?.data?.status) {
        setData1(1);

      }
    })
  }

  useEffect(() => {
		setElementData(ref.current);
	}, []);

  useEffect(() => {
    if (data1) {
      setPreLoader(false)
    }
  }, [data1]);

  // useEffect(() => {
  //   setStoreDataList(responseArray);
  // }, [responseArray])

  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <label className="container-checkbox">
          <input type="checkbox"
            ref={ref}
            onClick={onClick}
            {...rest}
          />
          <span className="checkmark"></span>
        </label>
      </>
    )
  });
  const productOptions = [

  ];
  const locationOptions = [

  ];
  const statusOptions = [

  ];

  const editStoreData = (list, id) => {
    //console.log(list, id);
  }


  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: '#10141B',
      borderColor: "transparent",
      border: state.isFocused ? "1px solid #0B0" : "1px solid #333F50",
      boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
      "&:hover": {
        border: "1px solid #0B0",
      },
      "&:active": {
        border: "1px solid #0B0",
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#333F50',
        color: '#d1d1d1',
        cursor: 'pointer',
        border: "1px solid #10141B",
        borderLeft: "1px solid transparent",
        borderRight: "1px solid transparent",
        "&:hover": {
          border: "1px solid #00AEEF",
        },
        "&:active": {
          backgroundColor: "#333F50",
        }
      };
    },
    noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
    indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
    menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
    menuList: styles => ({
      ...styles, padding: 0, paddingRight: "2px",
      "::-webkit-scrollbar-thumb": {
        borderRadius: "10px"
      }
    }),
    singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
    input: styles => ({ ...styles, color: '#d1d1d1' }),
    placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#205380",
      borderRadius: "0px",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#205380",
      borderRadius: "0px",
      ':hover': {
        backgroundColor: "#205380",
        color: 'white',
      },
    }),
  };


	const handleDelete = (data) => {
		Swal.fire({
			title: "",
			text: "",
			html: "Are you sure to delete Store ?",
			icon: 'warning',
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'YES',
			denyButtonText: 'NO',
			background: '#1d2530',
			color: '#fff',
			iconColor: "rgb(255, 153, 0)",
			width: "500px",
			padding: '2em',
			reverseButtons: false
		}).then((result)=>{
			if(result.isConfirmed){
        setPreLoader(true);
				let formData = {
					storeID : data
				}
				axios.post(API_URL+'deleteStore'+API_KEY_B, formData).then((res) => {
				//axios.post('http://localhost:7071/api/deleteStore', formData).then((res) => {
					if (res.data.status === 200) {
						toast.success(res.data.message, {
							theme: "dark",
							icon: ({ theme, type }) => <img src={errorSuccess} />,
		          onClose: setPreLoader(false),
						});
             getActiveStoreCount1();
            getStoreList();
           
					} else {
		
					}
				})
			}
		})
	}

  const getActiveStoreCount1 = () => {
		let formData = {

			"brandID": localStorage.getItem("brandID")
		}
	  	axios.post(API_URL+'count-active-store-ffc'+API_KEY_B,formData).then((res) => {
			if(res.data.status === 200){
			//	console.log("dataStore",res.data?.data?.totalActiveStore);
				localStorage.setItem("totalStore",res.data?.data?.totalActiveStore);
				localStorage.setItem("totalFFc",res.data?.data?.totalFFCStore);
			}
		})
	}


  const columns = [
    {
      name: 'Store ID',
      selector: row => row.ourID,
      width: '125px',
      sortable: true,
    },
    {
      name: 'Store Name',
      selector: row => row.name,
      sortable: true,
      width: '215px',
      cell: (row) => <><span className="store-name" title={row.name}>{row.name}</span></>
    },
    {
      name: 'Staff',
      selector: row => row.staffCount,
      sortable: true,
      width: '90px',
      cell: (row) => (row.staffCount) ? <>row.staffCount</> : <><span className="data-table-orng">0</span></>
    },
    {
      name: 'City, Country',
      selector: row => row.city,
      sortable: true,
      width: '150px',
      cell: (row) => row.city + ", " + row.country
    },
    {
      name: 'POS',
      selector: row => (row.POS) ? row.POS : "-",
      sortable: true,
      width: '150px'
    },
    {
      name: 'POS Status',
      selector: row => row.posStatus,
      sortable: true,
      width: '170px',
      cell: (row) => <span className="data-table-orng">Awaiting Connection</span>
    },
    {
      name: 'Last Ping',
      selector: row => row.topLocationName,
      sortable: true,
      cell: (row) => "-",
      width: '90px'
    },
    {
      name: 'Pickup',
      selector: row => (row.pickup) ? row.pickup : "-",
      sortable: true,
      width: '90px'
    },
    {
      name: 'Courier',
      selector: row => (row.courier) ? row.courier : "-",
      sortable: true,
      width: '90px'
    },
    {
      name: 'Shipping',
      selector: row => (row.shipping) ? row.shipping : "-",
      sortable: true,
      width: '90px'
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: '100px',
      //cell: (row) => (row.staffCount)?<>row.staffCount</>:<><span className="data-table-orng">Not active</span></>
      cell: (row) => <><span className="data-table-green">Active</span></>
    },
    {
      name: 'Action',
      selector: row => row.action,
      sortable: false,
      style: {
        justifyContent: 'center',
        flexDirection: 'row'
      },
      center: true,
      width: '250px',
      cell: row => <><Link className="btn btn-success" to={"/store/" + row.ourID} onClick={(e) => { localStorage.setItem("viewMode", "editmode") }} >Edit</Link> <Link className="btn btn-success buttondim button-not-active" to="#" >Suspend</Link><Link className="btn btn-success " onClick={e => { handleDelete(row.ourID); }} to="#">Remove</Link></>
    },
  ];

  return (
    <>
      <div className="wrapper" ref={ref} id="myscreen">
        <BrandNavigation data={elementData} cdata={props.compData} />
        <BrandAside cdata={props.compData} />
        <div className="conten-wrapper">
          {(denied) ? <ForBidden /> : <>
            <div className="controlbar">
              <div className="productsearch leftsidetr">
                <Link data-widget="search" className="button-not-active" to="#" role="button">
                  <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                  <span className="searchprodicttext text-center">Stores</span>
                </Link>
              </div>
              <div className="centerbtn">
                <Link to="/store/list" className="btn btn-success active">Stores</Link>
                <Link to="#" className="btn btn-success button-not-active">Edit Store</Link>
                <Link to="/store" className="btn btn-success  ">New Store</Link>
                {/* <Link to="#" className="btn btn-success button-not-active">Staff</Link> */}
              </div>
              <div className="rightcontrolserch">
                <div className="productsearch rightareas ">
                  <Link data-widget="search" to="#" role="button">
                    <span className="btn btn-success active"><i className="fa-thin fa-list"></i></span>
                    <span className="searchprodicttext">List</span>
                  </Link>
                </div>
                <div className="productsearch rightareas notactive">
                  <Link to="#" role="button">
                    <span className="btn btn-success"><i className="fa-thin fa-grid"></i></span>
                    <span className="searchprodicttext">Grid</span>
                  </Link>
                </div>
                <div className="productsearch rightareas notactive">
                  <Link data-widget="search" to="#" role="button">
                    <span className="btn btn-success"><i className="fa-thin fa-chart-simple"></i></span>
                    <span className="searchprodicttext text-center">Stats</span>
                  </Link>
                </div>

              </div>
            </div>


            {(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> :
              <>
                <div className="filtersarea">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="serchbopicspr">
                          <form className="form-inline">
                            <input className="form-control" type="text" placeholder="Search list:"
                              aria-label="Search" disabled='disabled' />
                            <span className="customeset gobtn button-not-active">
                              <button type="button" className="btn btn-success">
                                <i className="fa-thin fa-arrow-right"></i>
                              </button>
                              <span className="searchprodicttext">Go</span>
                            </span>
                          </form>
                        </div>
                        <div className="filtersection">
                          <form className="form-inline" name="filter">
                            <p className="form-control-static">Filters:</p>
                            <div className="form-group">
                              <label htmlFor="collection" className="sr-only">POS status</label>
                              <Select
                                options={productOptions}
                                styles={colourStyles}
                                placeholder={<div className="italic-placeholder">Select...</div>}
                                isDisabled='true' />
                            </div>
                            <div className="form-group">
                              <label htmlFor="creator" className="sr-only">Country</label>
                              <Select
                                options={locationOptions}
                                styles={colourStyles}
                                placeholder={<div className="italic-placeholder">Select...</div>}
                                isDisabled='true' />
                            </div>
                            <div className="form-group">
                              <label htmlFor="status" className="sr-only">Status</label>
                              <Select
                                options={statusOptions}
                                styles={colourStyles}
                                placeholder={<div className="italic-placeholder">Select...</div>}
                                isDisabled='true' />
                            </div>
                            <span className="btnsets products-fit ">
                              <span className="customeset revertfilters button-not-active">
                                <button type="button" className="btn btn-success"><i className="fa-thin fa-rotate-left"></i></button>
                                <span className="searchprodicttext">Reset</span>
                              </span>
                              <span className="customeset gobtn button-not-active">
                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrow-right"></i></button>
                                <span className="searchprodicttext">Apply</span>
                              </span>
                              <span className="customeset gobtn button-not-active">
                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrows-rotate"></i></button>
                                <span className="searchprodicttext">Refresh</span>
                              </span>
                            </span>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tablestructureser topteninvite"><h3 className="card-title">Stores </h3></div>
                <div className="tablestructure">
                  <DataTable
                    columns={columns}
                    data={storeDataList}
                    theme="solarized"
                    sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                    selectableRows
                    selectableRowsComponent={Checkbox}
                    defaultSortFieldId={1}
                    defaultSortAsc={true}
                    responsive={true}
                  />
                </div>
              </>}
          </>}
        </div>
        <BrandFooter logo={props.imageData.footer_logo} />
      </div>

    </>
  );
}
export default Dashboard;