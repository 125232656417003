import React, { useRef, useEffect, useState } from "react";
import { Link ,useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import ExclamtionBlue from './../../assets/images/exclation-blue.png';
import BoimageOne from './../../assets/images/uploads/boimageone.jpg';
import BoimageTwo from './..//../assets/images/uploads/boimagetwo.jpg';
import { API_URL } from './../../config/constant.js';
import  DatePicker from "react-multi-date-picker";
import { Calendar, DateObject } from "react-multi-date-picker"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import axios from 'axios';
import { useBeforeunload } from 'react-beforeunload';
import BlueAccountOn from './../../assets/images/blueaccounton.png';
import BlueBrandOn from './../../assets/images/bluebrandon.png';
import BlueBOimageIsOn from './../../assets/images/blueboimageson.png';
import BlueStoresOn from './../../assets/images/bluestoreson.png';
import CircleTick from './../../assets/images/circle-tick.png';
import GrayBrandoff from './../../assets/images/graybrandoff.png';
import GrayBoimgoff from './../../assets/images/grayboimageson.png';
import Graystoreoff from './../../assets/images/graystoreson.png';
import Swal from 'sweetalert2';
import StepCombination from '../layouts/commonLayout';

toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - Create Company Admin ';
	const ref = useRef();
    const [elementData, setElementData] = useState("");
   
	
	useEffect(()=>{
		localStorage.setItem('tel','');
	});
    


	return(
		<>
		<div className="wrapper" ref={ref} id="myscreen">
			<BrandNavigation data={elementData} cdata={props.compData}/>
			<BrandAside cdata={props.compData} />
			<div className="conten-wrapper enroll-wrapper">
			    <div className="controlbar">
			    	<div className="productsearch leftsidetr">
			            <Link data-widget="search" to="#" role="button">
			                <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
			                <span className="searchprodicttext text-center">Users</span>
			            </Link>
			        </div>
			        <div className="centerbtn">
			            <Link to="/account/company" className="btn btn-success active">Company</Link>
                        <Link to="/account/company-admin/registration" className="btn btn-success ">Users</Link>
			        </div>
			        <div className="rightcontrolserch">
			            <div className="productsearch rightareas">
			              	<Link data-widget="search" to="/account/company" role="button">
			                	<span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
			                	<span className="searchprodicttext">ID</span>
			              	</Link>
			            </div>
			            {/* <div className="productsearch rightareas notactive"> */}
			            <div className="productsearch rightareas ">
			              	<Link data-widget="search" to="/account/company/logo" role="button">
			                	<span className="btn btn-success"><i className="fa-thin fa-sign-hanging"></i></span>
			                	<span className="searchprodicttext">Logo</span>
			              	</Link>
			            </div>
						<div className="productsearch rightareas notactive">
                            <Link data-widget="search" to="#" role="button">
                                <span className="btn btn-success"><i className="fa-thin fa-users"></i></span>
                                <span className="searchprodicttext">Users</span>
                            </Link>
                        </div>
			        </div>
			    </div>
				
				<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									
									<StepCombination cdata={props.compData}/>
									

								</div>
							</div>
						</div>
					</div>
			   
			    <div className="creators-section">
			    	<div className="container-fluid">
			    		<div className="row">
                            <div className="col-md-12">
                            <div className="small-formas" >
                                    <div className="blueicons-area">
                                        <img src={ExclamtionBlue} />
                                    </div>
                                    <p className="verifying-text wt" >Now let's create the brand-account <b>Admin</b>.
                                    <br/><br/>

                                    The brand-account Admin is the overall manager of the <br/>
                                    BOstageB account for the brand on dispaly in the Control bar <br/>
                                    at the top of the screen.<br/><br/>

                                    If this BOstageB account owner enrolls another brand to this <br/> 
                                    account, a new brand-account Admin for this other brand will <br/>
                                    be requested(can be the same).

                                    </p>
                                    
                                   
                                  
                                    <div className="send-blockbtn twobtnsets" >
                                    <Link to="/account/company-admin/pre-company-logo-upload" >SAME AS OWNER</Link> 
                                    <Link to="/account/company-admin/registration" >CREATE ADMIN</Link>
                                    </div>
                                   
                                    
                                </div>
                            </div>
			    			
			    		</div>
			    	</div>
			    </div>
			</div>
			<BrandFooter logo={props.imageData.footer_logo}/>
		</div>
		
		</>
	);
}
export default Dashboard;