import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL,API_KEY_B } from './../../config/constant.js';
import Header from "./../layouts/header";
import Footer from "./../layouts/footer";
import PreloaderLogin from './../layouts/preLoaderLogin';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
toast.configure();
function Login(props) {
  document.title = 'BOstageB - Login';
  useEffect(() => {
    axios.post(API_URL+'GetThings'+API_KEY_B)
  },[]);
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [btnText, setBtnText] = useState("Login");  
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonGray, setButtonGray] = useState(false);
  const [loader, setLoader] = useState(false);  
  
  const handleEmail = (e) => {
    setEmailError(false);
    let em=e.target.value.trim();
    setEmail(em);
    if(e.target.value && password){
      setButtonGray(true);
    }else{
      setButtonGray(false);
    }
  };
  const handlePassword = (e) => {
    setPasswordError(false);
    let pwd=e.target.value.trim();
    setPassword(pwd);
    if(email && e.target.value){
      setButtonGray(true);
    }else{
      setButtonGray(false);
    }
  };
  const axiosConfig = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  };

   const msgs =  () =>{
    toast.success("You are logged in to your account.", {
    theme: "dark",
    icon: ({theme, type}) =>  <img src={errorSuccess}/>,
    autoClose: 5000
  })
}

  const handleOnClose = (data) => {
    setLoader(false)
    if(data?.completePercentage === 'Completed'){
      navigate('/dashboard');
    }else if(data?.status === '0'){
      navigate('/account/company');
    }else if(data?.status === '10'){
      navigate('/account/company/logo');
    }else if(data?.status === '25'){
      navigate('/brand/creator');
    }else if(data?.status === '30'){
      navigate('/brand/logo/'+data?.brandID);
    }else if(data?.status === '40'){
      navigate('/brand/tags-target/'+data?.brandID);
    }else if(data?.status === '50'){
      navigate('boimages/pre-boimages');
    }else if(data?.status === '55' || data?.status === '60'){
      navigate('/boimages/canvas/'+data?.boiamgeID);
    }else if(data?.status === '70'){
      navigate('/boimages/target/'+data?.boiamgeID);
    }else if(data?.status === '75'){
      navigate('/store/pre-store');
    }else if(data?.status === '80'){
      navigate('/store');
    }else if(data?.status === '85'){
      navigate('/store/open/'+data?.storeID);
    }else if(data?.status === '90'){
      navigate('/store/delivery/'+data?.storeID);
    }
   /*
    else if(data?.status === '95'){
      navigate('/billing-plan/pre-billing');
    }
    */
    else{
      navigate('/account/company/list');
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if(handleValidation()){
      setButtonDisable(true);
      setBtnText('');
      const formData = {
        "username":email.trim(),
        "password":password
      }
      axios.post(API_URL+'brand-login'+API_KEY_B, formData, {axiosConfig}).then((res)=>{
        
        if(res.data.status === 200){
          setLoader(true)
          setEmail("");
          setPassword("");
          localStorage.setItem('token', res.data.data._id);
          localStorage.setItem('name', res.data?.data?.firstName);
          localStorage.setItem('lastname', res.data?.data?.lastName);
          localStorage.setItem('status', res.data.data.status);
          if(res?.data?.data?.storeID){
            localStorage.setItem('storeID', res.data.data.storeID); 
          }
           
          props.loginFun(res.data.data._id)
         
         
         if(res.data.data?.completePercentage !== 'Completed' &&  res.data.data?.status !='100'){
            localStorage.setItem("boimageID",res.data.data?.boiamgeID)
          }
          toast.success("You are successfully logged in.", {
            theme: "dark",
            icon: ({theme, type}) =>  <img  src={errorSuccess} alt="Success" />,
            autoClose: 2000,
            //onOpen: () => handleOpen(resData),
            onClose: () => handleOnClose(res.data.data)
          })
          //console.log('djd',res.data.data?.status);
         
        }else{
          if(res.data.error_type === 'username'){
            setEmailError(true);
          }
          if(res.data.error_type === 'password'){
            setPasswordError(true);
          }
          toast.error(res.data.error_message, {
            theme: "dark",
            width: '390px',
            icon: ({theme, type}) =>  <img src={errorWarning}/>
          })
        }
        setButtonDisable(false);
        setBtnText('Login');
      });
    }
  }
  const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }
  const handleValidation = () => {
    let formIsValid = true;
    if(!email){
      formIsValid = false;
      setEmailError(true);
    }else if(isNaN(email)){
      if(!validateEmail(email)){
        formIsValid = false;
        setEmailError(true);
        //toast.error('Please enter valid email')
        toast.error('Please enter valid email', {
          theme: "dark",
          width: '390px',
          icon: ({theme, type}) =>  <img src={errorWarning}/>
        })
      }
    }else if(!isNaN(email)){
      if(email.length !== 10){
        formIsValid = false;
        setEmailError(true);
       // toast.error('Please enter valid phone number')
        toast.error('Please enter valid phone number', {
          theme: "dark",
          width: '390px',
          icon: ({theme, type}) =>  <img src={errorWarning}/>
        })
      }
    }
    if(!password){
      formIsValid = false;
      setPasswordError(true);
    }
    return formIsValid;
  }
  return (
    <>
    {(loader)?<PreloaderLogin />:
      <><div className="loginwindow">
        <Header />
        <div className="loginscreens">
          <div className="container">
            <div className="loginwidn">
              <div className="row">
                <div className="col-md-12">
                  <div className="logoineer">
                    <img src={props.imageData.logo} alt="LoginScreen" />
                    <p>The Brand Stage For <span>Bo</span>tag Technology</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="loginforms">
                    <form className="form-inline" onSubmit={handleSubmit} autoComplete="off">
                      <p>
                        <i className="fa fa-info-circle" aria-hidden="true"></i> Login with your credentials
                      </p>
                      <div className="form-group havewemial">
                        <label className="sr-only" htmlFor="email">
                          Email address
                        </label>
                        <input type="text" className={(emailError)?"form-control emailfield error-input":"form-control emailfield"} id="email" placeholder="email, phone number" value={email} onChange={handleEmail}/>
                      </div>
                      <div className="form-group havepassword">
                        <label className="sr-only" htmlFor="password">
                          Password
                        </label>
                        <input type="password" className={(passwordError)?"form-control passwordfield error-input":"form-control passwordfield"} id="password" placeholder="password" value={password} onChange={handlePassword}/>
                        <p>
                          <Link to="#">I forgot my credentials</Link>
                        </p>
                      </div>
                      <button type="submit" disabled={(buttonDisable)?'disabled':'' } className={(buttonGray)?"btn btn-default data-entered":"btn btn-default"}>
                        {btnText}
                        {(buttonDisable)?<i className="fas fa-spinner fa-spin" style={{"fontSize":"24px"}}></i>:""}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer logo={props.imageData.footer_logo}/></>
    }
   </>
  );
}

export default Login;