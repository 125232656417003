import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
//import BoimageOne from './../../../assets/images/uploads/boimageone.jpg';
//import BoimageTwo from './../../../assets/images/uploads/boimagetwo.jpg';
import BlueAccountOn from './../../assets/images/blueaccounton.png';
import BlueBrandOn from './../../assets/images/bluebrandon.png';
import GrayBrandoff from './../../assets/images/graybrandoff.png';
import GrayBoimgoff from './../../assets/images/grayboimageson.png';
import Graystoreoff from './../../assets/images/graystoreson.png';
import { API_URL,API_KEY_B ,BLOB_URL,BLOB_ACCOUNT_IMAGE} from './../../config/constant.js';
//import DatePicker from "react-multi-date-picker";
import { Calendar, DateObject } from "react-multi-date-picker"
import DatePicker from "react-multi-date-picker";
import './../../assets/css/inputTags.css';
import InputTag from '../brand/boimages/TagsInput';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import axios from 'axios';
import { useBeforeunload } from 'react-beforeunload';
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning"
import DataTable, { createTheme } from 'react-data-table-component';
import {useDropzone} from 'react-dropzone';
import Swal from 'sweetalert2';
//import uploadFileToBlob, { isStorageConfigured } from './../brand/boimages/blob';
import uploadFileToBlob, { isStorageConfigured } from './blob';
import StepCombination from '../layouts/commonLayout';

toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - Admin Registration';
	const ref = useRef();

    useEffect(() => {
        getUserListData(props);
        getMobileCountoryCode();  
		getRoleData();
		getBrangId(props);
	}, [props]);

     
   



	const { BOimageIdData } = useParams();
	const [elementData, setElementData] = useState("");
	const [buttonGray, setButtonGray] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
    const [role,setRole] = useState("");
    const [roleOption,setRoleOption] =useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileCode, setMobileCode] = useState({ value: '+47', label: '+47' });
    const [countryMobileCodeOption, setCountryMobileCodeOption] = useState({});
    const [addTo,setAddTo] =useState("");
    const [addToOption, setAddToOption] = useState({});
    const [files, setFiles] = useState("");
	const [uploadFileName, setUploadFileName] = useState({});
  

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
	const [roleError, setRoleError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
	const [mobileCodeError ,setMobileCodeError] = useState(false);
	const [emailError, setEmailError] = useState(false); 
    const [addToError,setAddToError] =useState(false);
	const [imgError,setImgError] =useState(false);

    const [showLoader, setShowLoader] = useState(0);

	const [buttonDisable, setButtonDisable] = useState(false);

   // const [mobileCode, setMobileCode] = useState({ value: '+47', label: '+47' });	
	const [dataLoading, setDataLoading] = useState();	
    const [inviteList, setInviteList] = useState([]);
    const [countryOption, setCountryOption]  = useState({});

  /*
   const getBrangId = (props) =>{
	const setAddToOption1=[];
	if(typeof props?.compData?.companyDetail!== 'undefined'){
	const brandDatas = props.compData?.companyDetail[0]?.brands;
	brandDatas.forEach((brandD)=>{
		setAddToOption1.push({ value: brandD.brandID, label: brandD.brandName});  
	});
	  setAddToOption(setAddToOption1);
	}
 }
  */
	
   const getBrangId = (props) =>{
	const setAddToOption1=[];
	if(typeof(props?.compData?.brandData)!== 'undefined'){
	const brandDatas = props.compData?.brandData;
	brandDatas.forEach((brandD)=>{
		setAddToOption1.push({ value: brandD.ourID, label: brandD.name});  
	});
	  setAddToOption(setAddToOption1);
	}
 }


 const colourStyles = {
	control: (styles, state) => ({
		...styles,
		backgroundColor: '#10141B',
		borderColor: "transparent",
		border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
		boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
		"&:hover": {
			border: "1px solid #0B0",
		},
		"&:active": {
			border: "1px solid #0B0",
		}
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: '#205380',
			color: '#a6a6a6',
			cursor: 'pointer',
			border: "1px solid #10141B",
			borderLeft: "1px solid transparent",
			borderRight: "1px solid transparent",
			"&:hover": {
				border: "1px solid #00AEEF",
			},
			"&:active": {
				backgroundColor: "#205380",
			}
		};
	},
	indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
	menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
	menuList: styles => ({...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
			    borderRadius: "10px"
			}
		}),
	singleValue: styles => ({ ...styles, color: '#a6a6a6' }),
	input: styles => ({ ...styles, color: '#a6a6a6' }),
	multiValueLabel: (styles, { data }) => ({
	...styles,
	color: "#fff",
	backgroundColor: "#205380",
	borderRadius: "0px",
  }),
  multiValueRemove: (styles, { data }) => ({
	...styles,
	color: "#fff",
	backgroundColor: "#205380",
	borderRadius: "0px",
':hover': {
  backgroundColor: "#205380",
  color: 'white',
},
}),
};

    const phonenumber = (inputtxt) =>
	{
	  var phoneno = /^\d{10}$/;
	  if(inputtxt.match(phoneno))
			{
		  return true;
			}
		  else
			{
			return false;
			}
	}

	function ValidateEmail(mail) 
		{
		  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
			{
				return true;
			}
			else{
				return false;
			}
		}
     


	const handleValidation = () => {
		let formIsValid = true;
		if (firstName == "") {
			setFirstNameError(true)
			formIsValid = false;
		}
        if (lastName == "") {
			setLastNameError(true)
			formIsValid = false;
		}
        if(mobileCode==""){
			setMobileCodeError(true);
				formIsValid = false;
		}else{
			setMobileCodeError(false);
		}
		
		if (role == "") {
			setRoleError(true);
			formIsValid = false;
		}
        if (addTo == "") {
			setAddToError(true);
			formIsValid = false;
		}
		
        if(mobile==""){
			
			setMobileError(true);
				formIsValid = false;
		}else{
			if(!phonenumber(mobile)){
				setMobileError(true);
				formIsValid = false;
			}
		}
		if (email == "") {
			setEmailError(true);
			formIsValid = false;
		}else{
			if(!ValidateEmail(email)){
				setEmailError(true);
			    formIsValid = false;
			}
		}

		// if (files == "") {
		// 	setImgError(true)
		// 	formIsValid = false;
		// }

	
		return formIsValid;
	}

	const getUserListData = async (props) => {
		if(typeof props?.compData?.companyDetail !== 'undefined'){
        let formData = {
            "companyID": props.compData?.Companies[0].companyID
        }
		await axios.post(API_URL+"get-company-user-list"+API_KEY_B,formData).then((res)=>{
			
			if(res.data.status==200){
                //props.loginFun(localStorage.getItem('token'));
			     setShowLoader(1);
				setInviteList(res.data.data);
				
			}
		});
	  }	
	}
 
	
	
	const getRoleData = () =>{

		//setEmail(props.compData?.email);
		const roleOption1=[];
	
		axios.post(API_URL+"get-role-data"+API_KEY_B).then((res)=>{
			if(res.data.status === 200){
			   let data = res.data.data;
			     data.forEach((da) => {
					
					roleOption1.push({ value: da.name, label: da.name });	
				   }
				 );
				
				setRoleOption(roleOption1);
			}
	   })  
	}


    
    const getMobileCountoryCode = () =>{

		//setEmail(props.compData?.email);
		const countryOption1=[];
		const countryMobileCodeOption1=[];
		axios.post(API_URL+'get-country-master-data'+API_KEY_B).then((res)=>{
			if(res.data.status === 200){
			   let data = res.data.data;
			     data.forEach((da) => {
					
                        countryOption1.push({ value: da.code, label: da.name });
						countryMobileCodeOption1.push({value: da.phone, label: da.phone});
				   }
				 );
				// setCountryOption(countryOption1);
				 setCountryMobileCodeOption(countryMobileCodeOption1);
			}
	   })  
	}


    const handleEmail = (e) => {
		if(e.target.value!=" "){
				setEmailError(false);
				setEmail(e.target.value);
			}
	   if(e.target.value && files && firstName && lastName && role  &&  addTo && mobile && mobileCode){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}
	}


	const FileUpload = async (e) => {
		setUploadFileName(e.target.files[0]);
		//setFiles(URL.createObjectURL(e.target.files[0]));
		if(e.target.files[0]!=" "){
          //  setImgError(false);
			setFiles(URL.createObjectURL(e.target.files[0]));
			}
		
	  }; 
    
	

    const handleFirstName = (e) => {
		if(e.target.value!=" "){
            setFirstNameError(false);
            setFirstName(e.target.value);
			}
			if(e.target.value && lastName && role  &&  addTo && mobile && mobileCode  && email){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}
	}

    const handleLastName = (e) => {
		if(e.target.value!=" "){
				setLastNameError(false);
				setLastName(e.target.value);
			}
			if(e.target.value && firstName && role  &&  addTo && mobile && mobileCode  &&  email){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}
	}
  

    const handleMobile = (e) => {
		if(e.target.value!=" "){
				setMobileError(false);
				setMobile(e.target.value);
			}
			if(e.target.value && firstName && role  &&  addTo && lastName && mobileCode  && email){
		  setButtonGray(true);
		}else{
		  setButtonGray(false);
		}
	}

    const setRoleH = (e) => {
		//setRole(e.value);
		if(e.value){
			setRoleError(false);
			setRole(e.value);
		}
		if(e.value && firstName &&  mobile &&  addTo && lastName && mobileCode  && email){
	  setButtonGray(true);
	}else{
	  setButtonGray(false);
	}
	}

	const setAddToH = (e) => {
		//setRole(e.value);
		if(e.value){
			setAddToError(false);
			setAddTo(e.value);
		}
		if(e.value && firstName &&  mobile &&  role && lastName && mobileCode  && email){
	  setButtonGray(true);
	}else{
	  setButtonGray(false);
	}
	}

 
	let navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		let imageArray = [];     
		if(handleValidation()){
			//console.warn("length",Object.keys(uploadFileName).length)
			//console.warn("file",uploadFileName.name)

            setButtonDisable(true);
				const formData = {
					"firstName":firstName,
					"lastName":lastName,
					"role":role,
					"email":email,
					"mobileNo": mobileCode.value+mobile,
					"addTo":addTo,
					"companyID" : props.compData?.Companies[0].companyID,
					"profileImage" :(uploadFileName.name!=undefined)?BLOB_URL+BLOB_ACCOUNT_IMAGE+'/'+uploadFileName.name:""
					
				} 
				 
                let cname = props?.compData?.Companies[0]?.companyName
				imageArray.push(uploadFileName)
                 
		      	  //	const blobsInContainer =  await uploadFileToBlob(imageArray);
			   
			 if(uploadFileName.name!=undefined){	  
				  
				const blobsInContainer = await uploadFileToBlob(imageArray,BLOB_ACCOUNT_IMAGE).then(res => {
					
					axios.post(API_URL+"invite-brand-user"+API_KEY_B,formData).then((res)=>{
						if(res.data.status==200){
											
						props.loginFun(localStorage.getItem('token'));

						let timerInterval           
						Swal.fire({
							title: '',
							html: "<b>"+cname+" Account Admin invited</b>.As soon as the invitation is accepted,credentials are provided <br/> and they can login to the account.",
							icon: 'success',
							allowOutsideClick: false,
							confirmButtonColor: '#205380',
							confirmButtonText: 'CONTINUE',
							background: '#1d2530',
							color: '#fff',
							iconColor: "#00BB00",
							width: "500px",
							padding: '3em',
							reverseButtons: true,
							timer: 5000,
							timerProgressBar: false,
							didOpen: () => {
							  Swal.showLoading()
	
							},
							willClose: () => {
							  clearInterval(timerInterval)
							}
						  }).then((result) => {
							
							if (result.dismiss === Swal.DismissReason.timer) {
								
								navigate("/account/company-admin/pre-company-logo-upload");
							}
						  })
						  setButtonDisable(false);	
						}else{
							toast.error(res.data.message, {
								theme: "dark",
								icon: ({theme, type}) =>  <img src={errorWarning}/>
							});
							setButtonDisable(false);	
						}
					});
				})    

			}else{
				axios.post(API_URL+"invite-brand-user"+API_KEY_B,formData).then((res)=>{
					if(res.data.status==200){
										
					props.loginFun(localStorage.getItem('token'));

					let timerInterval           
					Swal.fire({
						title: '',
						html: "<b>"+cname+" Account Admin invited</b>.As soon as the invitation is accepted,credentials are provided <br/> and they can login to the account.",
						icon: 'success',
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						confirmButtonText: 'CONTINUE',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#00BB00",
						width: "500px",
						padding: '3em',
						reverseButtons: true,
						timer: 5000,
						timerProgressBar: false,
						didOpen: () => {
						  Swal.showLoading()

						},
						willClose: () => {
						  clearInterval(timerInterval)
						}
					  }).then((result) => {
						
						if (result.dismiss === Swal.DismissReason.timer) {
							
							navigate("/account/company-admin/pre-company-logo-upload");
						}
					  })
					  setButtonDisable(false);	
					}else{
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({theme, type}) =>  <img src={errorWarning}/>
						});
						setButtonDisable(false);	
					}
				});
			}  

         }
	}
     
	const callFileUpload = (e) => {
		let checkClass = e.target.classList
		if(checkClass.contains('profile-div') || checkClass.contains('fa-upload') || checkClass.contains('upload-profile-text')){
			document.getElementById('fud').click(); 
		}
	     
	}
	const cancelFun1 =(e) => {
		e.preventDefault();
		setFiles("");
		setUploadFileName({});
	}


	const userStatusChange  = async (type,id,props) => {
              
		
			let formData = {
				"id": id,
				"status":type
			}
			await axios.post(API_URL+"change-brand-user-status"+API_KEY_B,formData).then((res)=>{
				
				if(res.data.status==200){
					toast.success(res.data.message, {
						theme: "dark",
						icon: ({theme, type}) =>  <img src={errorSuccess}/>
					});
                    

					 setShowLoader(1);
					getUserListData(props);
					
				}else{
					toast.error(res.data.message, {
						theme: "dark",
						icon: ({theme, type}) =>  <img src={errorWarning}/>
					});
				}
			});
		

	} 

    
	const columns = [

		{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
			width: '180px'
		},
		{
			name: 'Company',
			selector: row => row.comapnyName ,
			sortable: true,
			width: '180px'
		},

		{
			name: 'Brand',
			selector: row => row.brand,
			sortable: true,
			width: '160px'
		},
		{
			name: 'Role',
			selector: row => row.role,
			sortable: true,
			//width: '160px'
		},
		{
			name: 'E-mail',
			selector: row => row.emailID,
			sortable: true,
			width: '350px',
            cell:row=>(row.isEmailVerified== true)?<span> {row.emailID} <i className="fas fa-check" aria-hidden="true" style={{"color":"#0B0","padding-left":"5px"}}></i></span>:<span style={{"color":"#0B0"}} >{row.emailID}</span>
		},

		{
			name: 'Mobile',
			selector: row => row.phoneNumber,
			sortable: true,
			width: "200px",
            cell:row=>(row.isPhoneVerified== true)?<span> {row.phoneNumber} <i className="fas fa-check" aria-hidden="true" style={{"color":"#0B0","padding-left":"5px"}}></i></span>:<span style={{"color":"#0B0"}} >{row.phoneNumber}</span>
		},
        {
			name: 'Status',
			selector: row => row.status,
			sortable: true,
			width: "100px",
			cell: row => (row.status == "Active")?<span style={{"color":"#0B0"}}>{row.status}</span>:(row.status=="Suspend")?<span style={{"color":"#00aeef"}} >{row.status}</span>:(row.status=="Delete")?<span style={{"color":"Red"}} >{row.status}</span>:<span style={{"color":"#0B0"}}>{row.status}</span>

		},
		{
			name: 'Action',
			selector: row => row._id,
			sortable: false,
			cell: (row) => <div className="last-sell-btns"><Link className="btn btn-success" to="#">Edit</Link> {(row.role!="Owner")?<><Link className="btn btn-success" to="#" onClick={() =>{userStatusChange((row.status == "Suspend")?"Active":"Suspend",row._id,props)}}>{(row.status == "Suspend")?"Active":"Suspend"}</Link> <Link className="btn btn-success" to="#" onClick={() => {userStatusChange('Delete',row._id,props)}}>Delete</Link></>:""}</div>,
			//cell: (row) => <><Link className="btn btn-success" to="#">Edit</Link> </>,
			width: '250px',
			style: {
				justifyContent: 'center',
				flexDirection: 'row'
			},
			center: true,

		},
	];

    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
		return (
			<>
				<label className="container-checkbox">
					<input type="checkbox"
						ref={ref}
						onClick={onClick}
						{...rest}
					/>
					<span className="checkmark"></span>
				</label>
			</>
		)
	})
    const CustomLoader = () => (<div className="loaderclass"><i className="fa fa-spinner fa-spin"></i></div>)
	


	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
					<div className="controlbar">
                        <div className="productsearch leftsidetr">
                            <Link data-widget="search" to="#" role="button">
                                <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                <span className="searchprodicttext text-center">Users</span>
                            </Link>
                        </div>
						<div className="centerbtn">
							<Link to="/account/company" className="btn btn-success">Company</Link>
							<Link to="/account/company-admin/registration" className="btn btn-success active">USERS</Link>
             
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-list" aria-hidden="true"></i></span>
									<span className="searchprodicttext">List</span>
								</Link>
							</div>
							<div className="productsearch rightareas notactive">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-users"></i></span>
									<span className="searchprodicttext">User</span>
								</Link>
                           </div>
							
						</div>
					</div>
					<div className="filtertopcombo">
                      <div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
								<div className="filtersection creatorsbtnset enroll-brand">
			            			<span className={(buttonGray)?"customeset textinside ":"customeset textinside disabledbtn"}>
                                    <button type="submit" onClick={handleSubmit} className="btn btn-success invite-control" disabled={(buttonDisable)?'disabled':'' }><i className="fas fa-envelope-open-text"></i> INVITE </button>                                         
                    				</span>                   
			            		</div>
									
									<StepCombination cdata={props.compData}/>
									

								</div>
							</div>
						</div>
					</div>
				


					<div className="creators-section">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="cardsection">
										<div className="card-header">
											<h3 className="card-title">INVITE USER <span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
                                                    <div className="col-md-9">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group activeborder">
                                                                    <label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> First name</label>
                                                                    <input type="text" className={(firstNameError) ? "form-control error-input" : "form-control"} placeholder="" value={firstName} onChange={handleFirstName} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group activeborder">
                                                                    <label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Last name</label>
                                                                    <input type="text" className={(lastNameError) ? "form-control error-input" : "form-control"} placeholder="" value={lastName} onChange={handleLastName} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group activeborder selectheight" >
                                                                    <label htmlFor="tobrand"><i className="fas fa-info-circle " aria-hidden="true"></i> Role</label>
                                                                    <Select className={(roleError) ? "selects error-input" : "selects"}
                                                                        styles={colourStyles}
                                                                        //defaultValue={role}
                                                                        options={roleOption}
                                                                        onChange={setRoleH}
                                                                        //isDisabled
                                                                        placeholder={<div className="italic-placeholder">Select...</div>}
                                                                    />
														        </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                    <div className="form-group activeborder ">
                                                                    <label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Email</label>
                                                                    <div className="addonMailleft ">
																	<input type="text" className={(emailError)?"form-control error-input":"form-control"}  value={email} placeholder="" onChange={handleEmail} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group activeborder">
                                                                    <label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Mobile nr.</label>
                                                                    {/* { <input type="text" className={(mobileError)?"form-control error-input":"form-control"} onChange={handleMobile} value={mobile} placeholder="" />} */}
                                                                    <div className="form-group ">
                                                                        <div className="havesuffixdrop">
                                                                        
                                                                        <Select className={(mobileCodeError)?"selects error-input":"selects"}
                                                                            defaultValue={mobileCode}
                                                                            options={countryMobileCodeOption}
                                                                            styles={colourStyles}
                                                                            onChange={setMobileCode}
                                                                            placeholder={<div  className="italic-placeholder">Select...</div>}
                                                                            isDisabled
                                                                        />
                                                                        
                                                                        <input type="tel" className={(mobileError)?"form-control error-input":"form-control"}  pattern="[0-9]{10}" maxLength={10} onChange={handleMobile}  value={mobile} />
                                                                        </div>
                                                                    </div> 
                                                         
													            </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group activeborder selectheight" >
                                                                    <label htmlFor="tobrand"><i className="fas fa-info-circle " aria-hidden="true"></i> Add to</label>
                                                                    <Select className={(addToError) ? "selects error-input" : "selects"}
                                                                       options={addToOption}
                                                                       styles={colourStyles}
                                                                       onChange={setAddToH}
                                                                        placeholder={<div  className="italic-placeholder">Select...</div>}
                                                                    />
                                                                </div>
													        </div>
                                                        </div>


                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="user-profile-picture">
                                                                    <div className="form-group activeborder"  >
                                                                        <label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Profile photo</label>
                                                                        <div className={(imgError)?"profile-div error-input":"profile-div"} onClick={callFileUpload} >
                                                                            <div className="customprofile-pic" style={{"display":(files!="")?"none":"block"}}>
                                                                                <i className="fas fa-upload" aria-hidden="true" ></i>
                                                                                 <span className="upload-profile-text" >Upload</span> 
															                </div>
																			 <input id="fud" type="file" className="upload-profile-text" onChange={FileUpload} accept="image/*" style={{"display":"none"}}/>
																		    	 {(files)?(<span className="close-image" onClick={cancelFun1}></span>):""}
																				 <img src={files}  style={{"display":(files!="")?"block":"none"}}/>                                                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    
                                                            </div>
                                                        </div>
                                                    </div>  
												</div>

											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="loaderclassNew" style={{"display":(showLoader)?"none":"block"}}>
										<i className="fa fa-spinner fa-spin fa-3x"></i>
									</div>
					
					<div style={{ "display": (inviteList.length) ? "block" : "none" }} >
                   
                    
					<div className="tablestructureser" >
						<h3 className="sepraerte-titleunderline">Last 10 User List<span></span></h3>
     				</div>

                    <div className="filtersarea-search">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="serchbopicspr">
										<form className="form-inline">
											<input className="form-control" type="text" placeholder="Search list:"
												aria-label="Search" />
											<span className="customeset gobtn">
												<button type="submit" className="btn btn-success">
													<i className="fa-thin fa-arrow-right" aria-hidden="true"></i>
												</button>
												<span className="searchprodicttext">Go</span>
											</span>
										</form>
									  </div>
									</div>
								</div>

							</div>

						</div> 
                           

										<div className="tablestructure">
											<DataTable
												columns={columns}
												data={inviteList}
												theme="solarized"
												sortIcon={<i className="fas fa-sort-amount-asc" aria-hidden="true"></i>}
												progressPending={dataLoading}
												selectableRows
												selectableRowsComponent={Checkbox}
												defaultSortFieldId={1}
												defaultSortAsc={true}
												responsive={true}
												expandableRows={false}
												progressComponent={<CustomLoader />}
												
											/>
										</div>
					</div>
				</div>

               


				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	);
}
export default Dashboard;