import React, { useRef, useEffect, useState } from "react";
import { Link ,useNavigate, useParams } from "react-router-dom";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import StepCombination from '../layouts/commonLayout';
import TimeKeeper from 'react-timekeeper';
import { API_URL,API_KEY_B } from './../../config/constant.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import ForBidden from './../layouts/denied';
import Swal from 'sweetalert2';
toast.configure();
function useOutsideAlerter(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
      	return false;
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function OpenHours(props) {
	const storeID = useParams();
	const ref = useRef();
	let navigate = useNavigate();
	const wrapperRef = useRef(null);
	const [elementData, setElementData] = useState("");
	const [buttonGray, setButtonGray] = useState(false);
	const [openTimePicker, setOpenTimePicker] = useState(false)
	const [weekdaysFrom, setWeekdaysFrom] = useState(null);
	const [weekdaysTo, setWeekdaysTo] = useState(null);
	const [saturdayFrom, setSaturdayFrom] = useState(null);
	const [saturdayTo, setSaturdayTo] = useState(null);
	const [sundayFrom, setSundayFrom] = useState(null);
	const [sundayTo, setSundayTo] = useState(null);
	const [publicHolidaysFrom, setPublicHolidaysFrom] = useState(null);
	const [publicHolidaysTo, setPublicHolidaysTo] = useState(null);
	const [weekdaysBreakFrom, setWeekdaysBreakFrom] = useState(null);
	const [weekdaysBreakTo, setWeekdaysBreakTo] = useState(null);
	const [saturdayBreakFrom, setSaturdayBreakFrom] = useState(null);
	const [saturdayBreakTo, setSaturdayBreakTo] = useState(null);
	const [sundayBreakFrom, setSundayBreakFrom] = useState(null);
	const [sundayBreakTo, setSundayBreakTo] = useState(null);
	const [publicHolidayBreakFrom, setPublicHolidayBreakFrom] = useState(null);
	const [publicHolidayBreakTo, setPublicHolidayBreakTo] = useState(null);
	const [disabledSaturday, setDisabledSaturday] = useState(false)
	const [disabledSunday, setDisabledSunday] = useState(false)
	const [disabledPublicHolidays, setDisabledPublicHolidays] = useState(false)
	const [disabledSaturdayClose, setDisabledSaturdayClose] = useState(false)
	const [disabledSameWeek, setDisabledSameWeek] = useState(false)
	const [disabledSundayClose, setDisabledSundayClose] = useState(false)
	const [disabledSameSaturday, setDisabledSameSaturday] = useState(false)
	const [disabledPublicClose, setDisabledPublicClose] = useState(false)
	const [disabledSameSunday, setDisabledSameSunday] = useState(false)
	const [disabledWeekdayBreak, setDisabledWeekdayBreak] = useState(false)
	const [disabledSaturdayBreak, setDisabledSaturdayBreak] = useState(false)
	const [disabledSundayBreak, setDisabledSundayBreak] = useState(false)
	const [disabledPublicBreak, setDisabledPublicBreak] = useState(false)
	const [disableSaturdayNoBreak, setDisableSaturdayNoBreak] = useState(false)
	const [disableSundayNoBreak, setDisableSundayNoBreak] = useState(false)
	const [disablePublicNoBreak, setDisablePublicNoBreak] = useState(false)
	const [disableSaturdaySameBreak, setDisableSaturdaySameBreak] = useState(false)
	const [disableSundaySameBreak, setDisableSundaySameBreak] = useState(false)
	const [disablePublicSameBreak, setDisablePublicSameBreak] = useState(false)
	const [isSaturdayCloseAllDay, setIsSaturdayCloseAllDay] = useState(false)
	const [isSaturdaySameAsWeek, setIsSaturdaySameAsWeek] = useState(false)
	const [isSaturdayNoBreak, setIsSaturdayNoBreak] = useState(false)
	const [isSaturdayNoBreakWeek, setIsSaturdayNoBreakWeek] = useState(false)
	const [isSundayCloseAllDay, setIsSundayCloseAllDay] = useState(false)
	const [isSundaySameAsWeek, setIsSundaySameAsWeek] = useState(false)
	const [isSundayNoBreak, setIsSundayNoBreak] = useState(false)
	const [isSundayNoBreakWeek, setIsSundayNoBreakWeek] = useState(false)
	const [isPublicCloseAllDay, setIsPublicCloseAllDay] = useState(false)
	const [isPublicSameAsWeek, setIsPublicSameAsWeek] = useState(false)
	const [isPublicNoBreak, setIsPublicNoBreak] = useState(false)
	const [isPublicNoBreakWeek, setIsPublicNoBreakWeek] = useState(false)
	const [isWeekdayNoBreak, setIsWeekdayNoBreak] = useState(false)
	const [errorWeekdays, setErrorWeekdays] = useState(false);
	const [errorSaturdays, setErrorSaturdays] = useState(false);
	const [errorSundays, setErrorSundays] = useState(false);
	const [errorPublic, setErrorPublic] = useState(false);
	const [errorWeekdaysBreak, setErrorWeekdaysBreak] = useState(false);
	const [errorSaturdayBreak, setErrorSaturdayBreak] = useState(false);
	const [errorSundayBreak, setErrorSundayBreak] = useState(false);
	const [errorPublicBreak, setErrorPublicBreak] = useState(false);
	const [disabledbtn, setDisabledbtn] = useState(false);
	const [location, setLocation] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false)
	const [preLoader, setPreLoader] = useState(true);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [dataReadonly, setDataReadonly] = useState(0);
	
	const [denied, setDenied] = useState(false)
	
	const [buttonName,setButtonName] =useState("NEXT");
	const [editMode,setEditMode] =useState(0);
    const[editModeBtnFun,setEditModeBtnFun] = useState(0);

	useEffect(()=>{
		if(localStorage.getItem("viewMode")=='editmode')
		{
		  setEditMode(1);
		  setEditModeBtnFun(1);
		  setButtonName("SAVE");
		}
	 },[localStorage.getItem("viewMode")]) 


	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.StoresMenu){
					setPreLoader(false);
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }
		
	},[props.compData]);


	document.title = 'BOstageB - Store Open Hours ';


	useEffect(() => {
		setElementData(ref.current);
		//setStatus(props?.compData?.completePercentage)
		setStatus(localStorage.getItem('status'));
	}, [props.compData]);
	useEffect(()=>{
		console.log("open hrs",storeID)
		checkStoreID(storeID.id);	
	},[storeID]);
	useEffect(() => {
	    function handleClickOutside(event) {
	      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
	      	setOpenTimePicker(false)
	      }
	    }
	    document.addEventListener("mousedown", handleClickOutside);
	    return () => {
	      document.removeEventListener("mousedown", handleClickOutside);
	    };
	  }, [wrapperRef]);
	  
    // useEffect(()=>{
	// 	if(){
	// 		setButtonGray(true);
	// 	} else {
	// 		setButtonGray(false);
	// 	}
	// },[]);
      

    useEffect(() => {
		if(weekdaysFrom && weekdaysTo && (isSaturdayCloseAllDay || isSaturdaySameAsWeek || (saturdayFrom && saturdayTo)) && (isSundayCloseAllDay || isSundaySameAsWeek || (sundayFrom && sundayTo)) && (isPublicCloseAllDay || isPublicSameAsWeek || (publicHolidaysFrom && publicHolidaysTo)) && (isWeekdayNoBreak || (weekdaysBreakFrom && weekdaysBreakTo)) && (isSaturdayNoBreak || isSaturdayNoBreakWeek || (saturdayBreakFrom && saturdayBreakTo)) && (isSundayNoBreak || isSundayNoBreakWeek || (sundayBreakFrom && sundayBreakTo)) && (isPublicNoBreak || isPublicNoBreakWeek || (publicHolidayBreakFrom && publicHolidayBreakTo))){
			setButtonGray(true)
		}else{
			setButtonGray(false)
		}
	},[weekdaysFrom, weekdaysTo, isSaturdayCloseAllDay, isSaturdaySameAsWeek, saturdayFrom, saturdayTo, isSundayCloseAllDay, isSundaySameAsWeek, sundayFrom, sundayTo, isPublicCloseAllDay, isPublicSameAsWeek, publicHolidaysFrom, publicHolidaysTo, isWeekdayNoBreak, weekdaysBreakFrom, weekdaysBreakTo, isSaturdayNoBreak, isSaturdayNoBreakWeek, saturdayBreakFrom, saturdayBreakTo, isSundayNoBreak, isSundayNoBreakWeek, sundayBreakFrom, sundayBreakTo, isPublicNoBreak, isPublicNoBreakWeek, publicHolidayBreakFrom, publicHolidayBreakTo])


     
	  /*******************save popup********************** */
      
	  const refWarp = useRef(null);   //new datatttt
	  useEffect(() => {
	  
		  function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode);
			  
				//menu
			  let pclass = event?.target?.parentNode?.parentNode?.parentNode?.parentNode;
			  let pclassimg = event?.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
			  // rightcontrolserch
			  let prClassIcon = event?.target?.parentNode?.parentNode?.parentNode;
			  let prClass = event?.target?.parentNode?.parentNode;
			   //center upper btn
			 let pCNClass = event?.target?.parentNode;
			   
			  if(editMode && !editModeBtnFun){  
				  //  console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp?.current && !refWarp?.current?.contains(event.target) && (event?.target?.classList?.contains("saveIt") || pclass?.classList?.contains("menusections") || pclass?.classList?.contains("navitems-area") || pclassimg?.classList?.contains("navitems-area")|| pclassimg?.classList?.contains("menusections") || prClassIcon?.classList?.contains("saveIt")  || prClass?.classList?.contains("saveIt") || pCNClass?.classList?.contains("saveIt") )  ) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					  }).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);   
							handleSubmit(event); 		
						}
						else if(result.isDenied){
						   setEditModeBtnFun(1);
						 }
						
					})   
   
				
			}
		   }
		  }
		  document.addEventListener("mousedown", handleSaveOutside);
		  return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		  };
		}, [refWarp,editMode,editModeBtnFun]);   
   
   
   
   
	  /**************************************************** */







	const checkStoreID  = (id) => {
		let formData ={"id":id} 
		axios.post(API_URL+"get-store-info"+API_KEY_B,formData).then((res)=>{
			if(res.data.status != 200){
				navigate("/store");
			}else{
				setPreLoader(false);
				//console.log("open hours",res.data?.data?.openHours);
				let dat = res.data?.data?.openHours;
				setWeekdaysFrom(res.data?.data?.openHours?.Weekdays?.hourStart)
				setWeekdaysTo(res.data?.data?.openHours?.Weekdays?.hourEnd)
				setSaturdayFrom(res.data?.data?.openHours?.Saturdays?.hourStart)
				setSaturdayTo(res.data?.data?.openHours?.Saturdays?.hourEnd)
				setIsSaturdayCloseAllDay(res.data?.data?.openHours?.Saturdays?.closeAllDay)
				setIsSaturdaySameAsWeek(res.data?.data?.openHours?.Saturdays?.sameAsWeekdays)
				setSundayFrom(res.data?.data?.openHours?.Sundays?.hourStart)
				setSundayTo(res.data?.data?.openHours?.Sundays?.hourEnd)
				setIsSundayCloseAllDay(res.data?.data?.openHours?.Sundays?.closeAllDay)
				setIsSundaySameAsWeek(res.data?.data?.openHours?.Sundays?.sameAsSaturdays)
				setPublicHolidaysFrom(res.data?.data?.openHours?.PublicHolidays?.hourStart)
				setPublicHolidaysTo(res.data?.data?.openHours?.PublicHolidays?.hourEnd)
				setIsPublicCloseAllDay(res.data?.data?.openHours?.PublicHolidays?.closeAllDay)
				setIsPublicSameAsWeek(res.data?.data?.openHours?.PublicHolidays?.sameAsSaturdays)
				setWeekdaysBreakFrom(res.data?.data?.openHours?.WeekdaysBreak?.hourStart)
				setWeekdaysBreakTo(res.data?.data?.openHours?.WeekdaysBreak?.hourEnd)
				setIsWeekdayNoBreak(res.data?.data?.openHours?.WeekdaysBreak?.noBreak)
				setSaturdayBreakFrom(res.data?.data?.openHours?.SaturdaysBreak?.hourStart)
				setSaturdayBreakTo(res.data?.data?.openHours?.SaturdaysBreak?.hourEnd)
				setIsSaturdayNoBreak(res.data?.data?.openHours?.SaturdaysBreak?.noBreak)
				setIsSaturdaySameAsWeek(res.data?.data?.openHours?.SaturdaysBreak?.sameAsWeekdays)
                 
				setIsSaturdayNoBreakWeek(res.data?.data?.openHours?.SaturdaysBreak?.sameAsWeekdays)

				setSundayBreakFrom(res.data?.data?.openHours?.SundaysBreak?.hourStart)
				setSundayBreakTo(res.data?.data?.openHours?.SundaysBreak?.hourEnd)

				setIsSundayNoBreak(res.data?.data?.openHours?.SundaysBreak?.noBreak)
				setIsSundayNoBreakWeek(res.data?.data?.openHours?.SundaysBreak?.sameAsSaturdays)

				setPublicHolidayBreakFrom(res.data?.data?.openHours?.PublicHolidaysBreak?.hourStart)
				setPublicHolidayBreakTo(res.data?.data?.openHours?.PublicHolidaysBreak?.hourEnd)
				setIsPublicNoBreak(res.data?.data?.openHours?.PublicHolidaysBreak?.noBreak)
				setIsPublicNoBreakWeek(res.data?.data?.openHours?.PublicHolidaysBreak?.sameAsSaturdays)
			}
			if(res.data?.data?.openHours?.Weekdays?.hourStart && res.data?.data?.openHours?.Weekdays?.hourEnd ){
				if(localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed'){
					setDataReadonly(1);
				   }
			}
			
		}) 
	}
  const handleOpenTimePicker = (type) => {
  	setOpenTimePicker(true)
  	setLocation(type)
	//sam editmode 
	  if(editMode){
		setEditModeBtnFun(0);
	  }
  }
  const handleWeekdayFrom = (e) => {
	setErrorWeekdays(false)
	let t1From = e.formatted24.split(":");
	let updT1From = t1From[0].toString().padStart(2, '0')+":"+t1From[1]
	setWeekdaysFrom(updT1From)
}
const handleWeekdayTo = (e) => {
	setErrorWeekdays(false)
	let t1To = e.formatted24.split(":");
	let updT1To = t1To[0].toString().padStart(2, '0')+":"+t1To[1]
	setWeekdaysTo(updT1To)
}
const handleSaturdayFrom = (e) => {
	setErrorSaturdays(false)
	let SatFrom = e.formatted24.split(":");
	let updSatFrom = SatFrom[0].toString().padStart(2, '0')+":"+SatFrom[1]
	setSaturdayFrom(updSatFrom)
}
const handleSaturdayTo = (e) => {
	setErrorSaturdays(false)
	let SatTo = e.formatted24.split(":");
	let updSatTo = SatTo[0].toString().padStart(2, '0')+":"+SatTo[1]
	setSaturdayTo(updSatTo)
}
const handleSundayFrom = (e) => {
	setErrorSundays(false)
	let SunFrom = e.formatted24.split(":");
	let updSunFrom = SunFrom[0].toString().padStart(2, '0')+":"+SunFrom[1]
	setSundayFrom(updSunFrom)
}
const handleSundayTo = (e) => {
	setErrorSundays(false)
	let SunTo = e.formatted24.split(":");
	let updSunTo = SunTo[0].toString().padStart(2, '0')+":"+SunTo[1]
	setSundayTo(updSunTo)
}
const handlePublicHolidaysFrom = (e) => {
	setErrorPublic(false)
	let PFrom = e.formatted24.split(":");
	let updPFrom = PFrom[0].toString().padStart(2, '0')+":"+PFrom[1]
	setPublicHolidaysFrom(updPFrom)
}
const handlePublicHolidaysTo = (e) => {
	setErrorPublic(false)
	let PTo = e.formatted24.split(":");
	let updPTo = PTo[0].toString().padStart(2, '0')+":"+PTo[1]
	setPublicHolidaysTo(updPTo)
}
const handleweekdaysBreakFrom = (e) => {
	setErrorWeekdaysBreak(false)
	let WeekBFrom = e.formatted24.split(":");
	let updWeekBFrom = WeekBFrom[0].toString().padStart(2, '0')+":"+WeekBFrom[1]
	setWeekdaysBreakFrom(updWeekBFrom)
}
const handleweekdaysBreakTo = (e) => {
	setErrorWeekdaysBreak(false)
	let WeekBTo = e.formatted24.split(":");
	let updWeekBTo = WeekBTo[0].toString().padStart(2, '0')+":"+WeekBTo[1]
	setWeekdaysBreakTo(updWeekBTo)
}
const handleSaturdayBreakFrom = (e) => {
	setErrorSaturdayBreak(false)
	let satBFrom = e.formatted24.split(":");
	let updsatBFrom = satBFrom[0].toString().padStart(2, '0')+":"+satBFrom[1]
	setSaturdayBreakFrom(updsatBFrom)
}
const handleSaturdayBreakTo = (e) => {
	setErrorSaturdayBreak(false)
	let satBTo = e.formatted24.split(":");
	let updsatBTo = satBTo[0].toString().padStart(2, '0')+":"+satBTo[1]
	setSaturdayBreakTo(updsatBTo)
}
const handleSundayBreakFrom = (e) => {
	setErrorSundayBreak(false)
	let sunBFrom = e.formatted24.split(":");
	let updsunBFrom = sunBFrom[0].toString().padStart(2, '0')+":"+sunBFrom[1]
	setSundayBreakFrom(updsunBFrom)
}
const handleSundayBreakTo = (e) => {
	setErrorSundayBreak(false)
	let sunBTo = e.formatted24.split(":");
	let updsunBTo = sunBTo[0].toString().padStart(2, '0')+":"+sunBTo[1]
	setSundayBreakTo(updsunBTo)
}
const handlePublicHolidayBreakFrom = (e) => {
	setErrorPublicBreak(false)
	let PHBFrom = e.formatted24.split(":");
	let updPHBFrom = PHBFrom[0].toString().padStart(2, '0')+":"+PHBFrom[1]
	setPublicHolidayBreakFrom(updPHBFrom)
}
const handlePublicHolidayBreakTo = (e) => {
	setErrorPublicBreak(false)
	let PHBTo = e.formatted24.split(":");
	let updPHBTo = PHBTo[0].toString().padStart(2, '0')+":"+PHBTo[1]
	setPublicHolidayBreakTo(updPHBTo)
}
  const closeAllDay = (e, type) => {
  	setOpenTimePicker(false)
     //sam editmode 
	  if(editMode){
		setEditModeBtnFun(0);
	  }

  	if(type == 'saturday'){
  		setErrorSaturdays(false)
  		if(e.target.checked){
  			setSaturdayFrom(null)
			setSaturdayTo(null)
  		}
  		setIsSaturdayCloseAllDay(e.target.checked)
  		setDisabledSameWeek(e.target.checked)
  		setDisabledSaturday(e.target.checked)		
  	}
  	if(type == 'sunday'){
  		setErrorSundays(false)
  		setIsSundayCloseAllDay(e.target.checked)
  		setDisabledSameSaturday(e.target.checked)
  		setDisabledSunday(e.target.checked)
  		if(e.target.checked){
  			setSundayFrom(null)
			setSundayTo(null)
  		}		
  	}
  	if(type == 'publicHoliday'){
  		setErrorPublic(false)
  		setIsPublicCloseAllDay(e.target.checked)
  		setDisabledSameSunday(e.target.checked)
  		setDisabledPublicHolidays(e.target.checked)
  		if(e.target.checked){
  			setPublicHolidaysFrom(null)
			setPublicHolidaysTo(null)
  		}
  	}
  }
  const sameAsFunc = (e, type) => {
  	setOpenTimePicker(false);
    //sam edit
	if(editMode){
		setEditModeBtnFun(0);
	  }

  	if(type == 'saturday'){
  		if(e.target.checked){
  			setSaturdayFrom(weekdaysFrom)
			setSaturdayTo(weekdaysTo)
  		}
  		setErrorSaturdays(false)
  		setIsSaturdaySameAsWeek(e.target.checked)
  		setDisabledSaturdayClose(e.target.checked)
  		setDisabledSaturday(e.target.checked)		
  	}
  	if(type == 'sunday'){
  		setErrorSundays(false)
  		setDisabledSundayClose(e.target.checked)
  		setDisabledSunday(e.target.checked)
  		setIsSundaySameAsWeek(e.target.checked)
  		if(e.target.checked){
  			setSundayFrom(saturdayFrom)
			setSundayTo(saturdayTo)
  		}		
  	}
  	if(type == 'publicHoliday'){
  		setErrorPublic(false)
  		setIsPublicSameAsWeek(e.target.checked)
  		setDisabledPublicClose(e.target.checked)
  		setDisabledPublicHolidays(e.target.checked)
  		if(e.target.checked){
  			setPublicHolidaysFrom(saturdayFrom)
			setPublicHolidaysTo(saturdayTo)
  		}		
  	}
  }
  	const nobreakFunc = (e, type) => {
  		setOpenTimePicker(false);
		//sam editmode
		if(editMode){
			setEditModeBtnFun(0);
		  }
  		if(type == 'weekday'){
  			if(e.target.checked){
	  			setWeekdaysBreakFrom(null)
	  			setWeekdaysBreakTo(null)
	  		}
	  		setErrorWeekdaysBreak(false)
	  		setIsWeekdayNoBreak(e.target.checked)
	  		setDisabledWeekdayBreak(e.target.checked)
  		}
  		if(type == 'saturday'){
  			if(e.target.checked){
	  			setSaturdayBreakFrom(null)
	  			setSaturdayBreakTo(null)
	  		}
	  		setErrorSaturdayBreak(false)
	  		setIsSaturdayNoBreak(e.target.checked)
	  		setDisableSaturdaySameBreak(e.target.checked)
	  		setDisabledSaturdayBreak(e.target.checked)
  		}
  		if(type == 'sunday'){
  			if(e.target.checked){
	  			setSundayBreakFrom(null)
	  			setSundayBreakTo(null)
	  		}
	  		setErrorSundayBreak(false)
	  		setIsSundayNoBreak(e.target.checked)
	  		setDisableSundaySameBreak(e.target.checked)
	  		setDisabledSundayBreak(e.target.checked)
  		}
  		if(type == 'publicHoliday'){
  			if(e.target.checked){
	  			setPublicHolidayBreakFrom(null)
	  			setPublicHolidayBreakTo(null)
	  		}
	  		setErrorPublicBreak(false)
	  		setIsPublicNoBreak(e.target.checked)
	  		setDisablePublicSameBreak(e.target.checked)
	  		setDisabledPublicBreak(e.target.checked)
  		}
  	}
  	const sameasBreakFunc = (e, type) => {
  		setOpenTimePicker(false)
  		if(type == 'saturday'){
  			if(e.target.checked){
	  			setSaturdayBreakFrom(weekdaysBreakFrom)
	  			setSaturdayBreakTo(weekdaysBreakTo)
	  		}
	  		setErrorSaturdayBreak(false)
	  		setIsSaturdayNoBreakWeek(e.target.checked)
	  		setDisableSaturdayNoBreak(e.target.checked)
	  		setDisabledSaturdayBreak(e.target.checked)
  		}
  		if(type == 'sunday'){
  			if(e.target.checked){
	  			setSundayBreakFrom(saturdayBreakFrom)
	  			setSundayBreakTo(saturdayBreakTo)
	  		}
	  		setErrorSundayBreak(false)
	  		setIsSundayNoBreakWeek(e.target.checked)
	  		setDisableSundayNoBreak(e.target.checked)
	  		setDisabledSundayBreak(e.target.checked)
  		}
  		if(type == 'publicHoliday'){
  			if(e.target.checked){
	  			setPublicHolidayBreakFrom(saturdayBreakFrom)
	  			setPublicHolidayBreakTo(saturdayBreakTo)
	  		}
	  		setErrorPublicBreak(false)
	  		setIsPublicNoBreakWeek(e.target.checked)
	  		setDisablePublicNoBreak(e.target.checked)
	  		setDisabledPublicBreak(e.target.checked)
  		}
  	}
  	const handleValidation = () => {
		let formIsValid = true;
		if (!weekdaysFrom || !weekdaysTo) {
			setErrorWeekdays(true)
			formIsValid = false;
		}
		if (!isSaturdayCloseAllDay && !isSaturdaySameAsWeek && (!saturdayFrom || !saturdayTo)) {
			setErrorSaturdays(true)
			formIsValid = false;
		}
		if (!isSundayCloseAllDay && !isSundaySameAsWeek && (!sundayFrom || !sundayTo)) {
			setErrorSundays(true)
			formIsValid = false;
		}
		if (!isPublicCloseAllDay && !isPublicSameAsWeek && (!publicHolidaysFrom || !publicHolidaysTo)) {
			setErrorPublic(true)
			formIsValid = false;
		}
		if (!isWeekdayNoBreak && (!weekdaysBreakFrom || !weekdaysBreakTo)) {
			setErrorWeekdaysBreak(true)
			formIsValid = false;
		}
		if (!isSaturdayNoBreak && !isSaturdayNoBreakWeek && (!saturdayBreakFrom || !saturdayBreakTo)) {
			setErrorSaturdayBreak(true)
			formIsValid = false;
		}
		if (!isSundayNoBreak && !isSundayNoBreakWeek && (!sundayBreakFrom || !sundayBreakTo)) {
			setErrorSundayBreak(true)
			formIsValid = false;
		}
		if (!isPublicNoBreak && !isPublicNoBreakWeek && (!publicHolidayBreakFrom || !publicHolidayBreakTo)) {
			setErrorPublicBreak(true)
			formIsValid = false;
		}
		return formIsValid;
	}
	const handleSubmit = async (e) => {
         if(dataReadonly){
			navigate("/store/delivery/"+storeID.id);
		 }else{

		e.preventDefault();
		if(handleValidation()){
			if(localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed'){
				setDataReadonly(1);
			}
			setSubmitLoader(true);
			setDisabledbtn(true)
			let formJson = {
				"storeID": storeID.id,
				"type": 'openHours',
				"openHours":{
					"Weekdays":{
						"hourStart":weekdaysFrom,
						"hourEnd":weekdaysTo
					},
					"WeekdaysBreak":{
						"noBreak":isWeekdayNoBreak,
						"hourStart":weekdaysBreakFrom,
						"hourEnd":weekdaysBreakTo
					},
					"Saturdays":{
						"closeAllDay":isSaturdayCloseAllDay,
						"hourStart":saturdayFrom,
						"hourEnd":saturdayTo,
						"sameAsWeekdays":isSaturdaySameAsWeek
					},
					"SaturdaysBreak":{
						"noBreak":isSaturdayNoBreak,
						"hourStart":saturdayBreakFrom,
						"hourEnd":saturdayBreakTo,
						"sameAsWeekdays":isSaturdayNoBreakWeek
					},
					"Sundays":{
						"closeAllDay":isSundayCloseAllDay,
						"hourStart":sundayFrom,
						"hourEnd":sundayTo,
						"sameAsSaturdays":isSundaySameAsWeek
					},
					"SundaysBreak":{
						"noBreak":isSundayNoBreak,
						"hourStart":sundayBreakFrom,
						"hourEnd":sundayBreakTo,
						"sameAsSaturdays":isSundayNoBreakWeek
					},
					"PublicHolidays":{
						"closeAllDay":isPublicCloseAllDay,
						"hourStart":publicHolidaysFrom,
						"hourEnd":publicHolidaysTo,
						"sameAsSaturdays":isPublicSameAsWeek
					},
					"PublicHolidaysBreak":{
						"noBreak":isPublicNoBreak,
						"hourStart":publicHolidayBreakFrom,
						"hourEnd":publicHolidayBreakTo,
						"sameAsSaturdays":isPublicNoBreakWeek
					}
				}
			}
			axios.post(API_URL+"/update-store-info"+API_KEY_B,formJson).then((res)=>{
				setDisabledbtn(false)
				
				if(res.data.status == 200){
					props.loginFun(localStorage.getItem('token') , '90');
					 if(editMode){
							setEditModeBtnFun(1);
					  }            
					toast.success(res.data.message,
					{
						theme: "dark",
						icon: ({ theme, type }) => <img src={errorSuccess} />,
						onClose: () => {
							if(!editMode){
								navigate("/store/delivery/"+storeID.id);
							 }
						}
					});
					setSubmitLoader(false);
				}else{
					toast.error(res.data.message, {
			            theme: "dark",
			            icon: ({theme, type}) =>  <img src={errorWarning}/>
			        })
				}
				
				setSubmitLoader(false);
			})
		}
	  }	
	}
	return(
		<>
			{(submitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}

			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
				{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
					<div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" className="button-not-active" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
								<span className="searchprodicttext text-center">Stores</span>
							</Link>
						</div>
						<div className="centerbtn">
							{/* <Link to={(status != 100 || status !='Completed') ?"/store":"#"} className={(status !=100 || status !='Completed')?"btn btn-success":"btn btn-success button-not-active"}>Stores</Link> */}
							<Link to={(status ==100 || status == 'Completed') ? "/store/list" : "#"} className={(status ==100 || status == 'Completed') ? "btn btn-success saveIt" : "btn btn-success button-not-active"}>Stores</Link> 
							<Link to="#" className={(editMode)?"btn btn-success active saveIt":"btn btn-success button-not-active"}>Edit Store</Link>
							<Link to="/store" className={(editMode)?"btn btn-success saveIt":"btn btn-success active saveIt" }   >New Store</Link>
							{/* <Link to="#" className="btn btn-success">Importer</Link> */}
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas saveIt">
								<Link data-widget="search" to={(editMode)?"/store/"+storeID.id:"/store"} role="button">
									<span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link>
							</div>
							<div className="productsearch rightareas saveIt">
								<Link to="#" role="button" onClick={(e) =>{e.preventDefault()}} >
									<span className="btn btn-success active"><i className="fa-thin fa-clock"></i></span>
									<span className="searchprodicttext">Open</span>
								</Link>
							</div>
							<div className={(dataReadonly)?"productsearch rightareas saveIt":(editMode)?"productsearch rightareas saveIt":"productsearch rightareas notactive"}>
								<Link data-widget="search" to={(dataReadonly)?"/store/delivery/"+localStorage.getItem('storeID'):(editMode)?"/store/delivery/"+storeID.id:"#"} role="button" onClick={(dataReadonly || editMode)?"":(e) => {e.preventDefault()}} >
									<span className="btn btn-success"><i className="fa-thin fa-box"></i></span>
									<span className="searchprodicttext text-center">Delivery</span>
								</Link>
							</div>
							<div className="productsearch rightareas notactive">
								<Link data-widget="search" to="#" role="button" onClick={(e) =>{e.preventDefault()}} >
									<span className="btn btn-success"><i className="fa-thin fa-people-group"></i></span>
									<span className="searchprodicttext text-center">Staff</span>
								</Link>
							</div>
						</div>
					</div>
					{(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> :
					<><div ref={refWarp}>	
					<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="filtersection creatorsbtnset enroll-brand ">
										<span className={(buttonGray)?"customeset textinside next-new-btn":"customeset textinside next-new-btn disabledbtn"}>
											<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun)?true:disabledbtn} >{buttonName}{(disabledbtn)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-chevron-right"></i>)}</button>
										</span>
									</div>
									<StepCombination cdata={props.compData}/>

								</div>
							</div>
						</div>
					</div>
					<div className="creators-section">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="cardsection">
										<div className="card-header">
											<h3 className="card-title">OPEN HOURS<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
										</div>
										<div className="card-body" ref={wrapperRef}>
											<div className="row">
												<div className="col-md-3">
													<div className="form-group activeborder multiple-inputs">
														<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Weekdays</label>
														<div className={(dataReadonly)?"addonclockeft clock-inactive":(errorWeekdays)?"addonclockeft error-input":"addonclockeft"}>
															<span className="fifty-width" >
																<input type="text" placeholder="--:--" value={weekdaysFrom} className="form-control" onClick={() => handleOpenTimePicker('weekdaysFrom')}  disabled={(dataReadonly)?true:false} />
																{openTimePicker && (location=='weekdaysFrom') && <TimeKeeper time={weekdaysFrom} onChange={handleWeekdayFrom} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
																</span>
															
															<span className="fifty-width right-side">
																<input type="text" placeholder="--:--" value={weekdaysTo} className="form-control" onClick={() => handleOpenTimePicker('weekdaysTo')} disabled={(dataReadonly)?true:false}  />
																{openTimePicker && (location=='weekdaysTo') && <TimeKeeper time={weekdaysTo} onChange={handleWeekdayTo} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>															
														</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group activeborder multiple-inputs">
														<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Saturdays</label>
														<span className="same-as-weekdays close-allday">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isSaturdayCloseAllDay} disabled={(dataReadonly)?true:(disabledSaturdayClose)?true:false} onChange={(e) => closeAllDay(e, 'saturday')}/>
												                <span className="checkmark"></span>
												            </label> Close all day
												        </span>
														<div className={(dataReadonly)?"addonclockeft clock-inactive":(errorSaturdays)?"addonclockeft error-input":"addonclockeft"}>
															<span className="fifty-width">
																<input type="text" className="form-control" value={(saturdayFrom)?saturdayFrom:""} disabled={(dataReadonly)?true:(disabledSaturday)?true:false} placeholder="--:--"  onClick={() => handleOpenTimePicker('saturdayFrom')} />
																{openTimePicker && (location=='saturdayFrom') && <TimeKeeper time={saturdayFrom} onChange={handleSaturdayFrom} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>
															<span className="fifty-width right-side">
																<input type="text" disabled={(dataReadonly)?true:(disabledSaturday)?true:false} value={(saturdayTo)?saturdayTo:""} placeholder="--:--" onClick={() => handleOpenTimePicker('saturdayTo')} className="form-control" />
																{openTimePicker && (location=='saturdayTo') && <TimeKeeper time={saturdayTo} onChange={handleSaturdayTo} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>															
														</div>
														<span className="same-as-weekdays bottom-show">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isSaturdaySameAsWeek} disabled={(dataReadonly)?true:(disabledSameWeek)?true:false} onChange={(e) => sameAsFunc(e, 'saturday')}/>
												                <span className="checkmark"></span>
												            </label> Same as weekdays
												        </span>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group activeborder multiple-inputs">
														<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Sunday</label>
														<span className="same-as-weekdays close-allday">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isSundayCloseAllDay} disabled={(dataReadonly)?true:(disabledSundayClose)?true:false} onChange={(e) => closeAllDay(e, 'sunday')}/>
												                <span className="checkmark"></span>
												            </label> Close all day
												        </span>
														<div className={(dataReadonly)?"addonclockeft clock-inactive":(errorSundays)?"addonclockeft error-input":"addonclockeft"}>
															<span className="fifty-width">
																<input type="text" disabled={(dataReadonly)?true:(disabledSunday)?true:false} value={(sundayFrom)?sundayFrom:""} placeholder="--:--" onClick={() => handleOpenTimePicker('sundayFrom')} className="form-control" />
																{openTimePicker && (location=='sundayFrom') && <TimeKeeper time={sundayFrom} onChange={handleSundayFrom} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>
															<span className="fifty-width right-side">
																<input type="text" disabled={(dataReadonly)?true:(disabledSunday)?true:false} value={(sundayTo)?sundayTo:""} placeholder="--:--" onClick={() => handleOpenTimePicker('sundayTo')} className="form-control" />
																{openTimePicker && (location=='sundayTo') && <TimeKeeper time={sundayTo} onChange={handleSundayTo} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>															
														</div>
														<span className="same-as-weekdays bottom-show">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isSundaySameAsWeek} disabled={(dataReadonly)?true:(disabledSameSaturday)?true:false} onChange={(e) => sameAsFunc(e, 'sunday')}/>
												                <span className="checkmark"></span>
												            </label> Same as saturdays
												        </span>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group activeborder multiple-inputs">
														<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Public holidays</label>
														<span className="same-as-weekdays close-allday">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isPublicCloseAllDay} disabled={(dataReadonly)?true:(disabledPublicClose)?true:false} onChange={(e) => closeAllDay(e, 'publicHoliday')}/>
												                <span className="checkmark"></span>
												            </label> Close all day
												        </span>
														<div className={(dataReadonly)?"addonclockeft clock-inactive":(errorPublic)?"addonclockeft error-input":"addonclockeft"}>
															<span className="fifty-width">
																<input type="text" disabled={(dataReadonly)?true:(disabledPublicHolidays)?true:false} value={(publicHolidaysFrom)?publicHolidaysFrom:""} placeholder="--:--" onClick={() => handleOpenTimePicker('publicHolidaysFrom')} className="form-control" />
																{openTimePicker && (location=='publicHolidaysFrom') && <TimeKeeper time={publicHolidaysFrom} onChange={handlePublicHolidaysFrom} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>
															<span className="fifty-width right-side">
																<input type="text" disabled={(dataReadonly)?true:(disabledPublicHolidays)?true:false} value={(publicHolidaysTo)?publicHolidaysTo:""} placeholder="--:--" onClick={() => handleOpenTimePicker('publicHolidaysTo')} className="form-control" />
																{openTimePicker && (location=='publicHolidaysTo') && <TimeKeeper time={publicHolidaysTo} onChange={handlePublicHolidaysTo} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>															
														</div>
														<span className="same-as-weekdays bottom-show">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isPublicSameAsWeek} disabled={(dataReadonly)?true:(disabledSameSunday)?true:false} onChange={(e) => sameAsFunc(e, 'publicHoliday')}/>
												                <span className="checkmark"></span>
												            </label> Same as saturdays
												        </span>
													</div>
												</div>
											</div>
											<div className="row">								
												<div className="col-md-3">
													<div className="form-group activeborder multiple-inputs">
														<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Weekdays break</label>
														<span className="same-as-weekdays close-allday">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isWeekdayNoBreak} onChange={(e) => nobreakFunc(e, 'weekday')} disabled={(dataReadonly)?true:false}/>
												                <span className="checkmark"></span>
												            </label> No break
												        </span>
														<div className={(dataReadonly)?"addonclockeft clock-inactive":(errorWeekdaysBreak)?"addonclockeft error-input":"addonclockeft"}>
															<span className="fifty-width">
																<input type="text" disabled={(dataReadonly)?true:(disabledWeekdayBreak)?true:false} value={(weekdaysBreakFrom)?weekdaysBreakFrom:""} placeholder="--:--" onClick={() => handleOpenTimePicker('weekdaysBreakFrom')} className="form-control" />
																{openTimePicker && (location=='weekdaysBreakFrom') && <TimeKeeper time={weekdaysBreakFrom} onChange={handleweekdaysBreakFrom} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>
															<span className="fifty-width right-side">
																<input type="text" disabled={(dataReadonly)?true:(disabledWeekdayBreak)?true:false} value={(weekdaysBreakTo)?weekdaysBreakTo:""} placeholder="--:--" onClick={() => handleOpenTimePicker('weekdaysBreakTo')} className="form-control" />
																{openTimePicker && (location=='weekdaysBreakTo') && <TimeKeeper time={weekdaysBreakTo} onChange={handleweekdaysBreakTo} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>															
														</div>
														
													</div>
												</div>
													<div className="col-md-3">
													<div className="form-group activeborder multiple-inputs">
														<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Saturdays break</label>
														<span className="same-as-weekdays close-allday">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isSaturdayNoBreak} disabled={(dataReadonly)?true:(disableSaturdayNoBreak)?true:false} onChange={(e) => nobreakFunc(e, 'saturday')} />
												                <span className="checkmark"></span>
												            </label> No break
												        </span>
														<div className={(dataReadonly)?"addonclockeft clock-inactive":(errorSaturdayBreak)?"addonclockeft error-input":"addonclockeft"}>
															<span className="fifty-width">
																<input type="text" disabled={(dataReadonly)?true:(disabledSaturdayBreak)?true:false} value={(saturdayBreakFrom)?saturdayBreakFrom:""} placeholder="--:--" onClick={() => handleOpenTimePicker('saturdayBreakFrom')} className="form-control" />
																{openTimePicker && (location=='saturdayBreakFrom') && <TimeKeeper time={saturdayBreakFrom} onChange={handleSaturdayBreakFrom} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>
															<span className="fifty-width right-side">
																<input type="text" disabled={(dataReadonly)?true:(disabledSaturdayBreak)?true:false} value={(saturdayBreakTo)?saturdayBreakTo:""} placeholder="--:--" onClick={() => handleOpenTimePicker('saturdayBreakTo')} className="form-control" />
																{openTimePicker && (location=='saturdayBreakTo') && <TimeKeeper time={saturdayBreakTo} onChange={handleSaturdayBreakTo} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>															
														</div>
														<span className="same-as-weekdays bottom-show">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isSaturdayNoBreakWeek} disabled={(dataReadonly)?true:(disableSaturdaySameBreak)?true:false} onChange={(e) => sameasBreakFunc(e, 'saturday')}/>
												                <span className="checkmark"></span>
												            </label> Same as weekdays
												        </span>
													</div>
												</div>
													<div className="col-md-3">
													<div className="form-group activeborder multiple-inputs">
														<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Sundays break</label>
														<span className="same-as-weekdays close-allday">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isSundayNoBreak} disabled={(dataReadonly)?true:(disableSundayNoBreak)?true:false} onChange={(e) => nobreakFunc(e, 'sunday')}/>
												                <span className="checkmark"></span>
												            </label> No break
												        </span>
														<div className={(dataReadonly)?"addonclockeft clock-inactive":(errorSundayBreak)?"addonclockeft error-input":"addonclockeft"}>
															<span className="fifty-width">
																<input type="text" disabled={(dataReadonly)?true:(disabledSundayBreak)?true:false} value={(sundayBreakFrom)?sundayBreakFrom:""} placeholder="--:--" onClick={() => handleOpenTimePicker('sundayBreakFrom')} className="form-control" />
																{openTimePicker && (location=='sundayBreakFrom') && <TimeKeeper time={sundayBreakFrom} onChange={handleSundayBreakFrom} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>
															<span className="fifty-width right-side">
																<input type="text" disabled={(dataReadonly)?true:(disabledSundayBreak)?true:false} value={(sundayBreakTo)?sundayBreakTo:""} placeholder="--:--" onClick={() => handleOpenTimePicker('sundayBreakTo')} className="form-control" />
																{openTimePicker && (location=='sundayBreakTo') && <TimeKeeper time={sundayBreakTo} onChange={handleSundayBreakTo} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>															
														</div>
														<span className="same-as-weekdays bottom-show">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isSundayNoBreakWeek} disabled={(dataReadonly)?true:(disableSundaySameBreak)?true:false} onChange={(e) => sameasBreakFunc(e, 'sunday')}/>
												                <span className="checkmark"></span>
												            </label> Same as saturdays
												        </span>
													</div>
												</div>
													<div className="col-md-3">
													<div className="form-group activeborder multiple-inputs">
														<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Public holidays break</label>
														<span className="same-as-weekdays close-allday">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isPublicNoBreak} disabled={(dataReadonly)?true:(disablePublicNoBreak)?true:false} onChange={(e) => nobreakFunc(e, 'publicHoliday')}/>
												                <span className="checkmark"></span>
												            </label> No break
												        </span>
														<div className={(dataReadonly)?"addonclockeft clock-inactive":(errorPublicBreak)?"addonclockeft error-input":"addonclockeft"}>
															<span className="fifty-width">
																<input type="text" disabled={(dataReadonly)?true:(disabledPublicBreak)?true:false} value={(publicHolidayBreakFrom)?publicHolidayBreakFrom:""} placeholder="--:--" onClick={() => handleOpenTimePicker('publicHolidayBreakFrom')} className="form-control" />
																{openTimePicker && (location=='publicHolidayBreakFrom') && <TimeKeeper time={publicHolidayBreakFrom} onChange={handlePublicHolidayBreakFrom} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>
															<span className="fifty-width right-side">
																<input type="text" disabled={(dataReadonly)?true:(disabledPublicBreak)?true:false} value={(publicHolidayBreakTo)?publicHolidayBreakTo:""} placeholder="--:--" onClick={() => handleOpenTimePicker('publicHolidayBreakTo')} className="form-control" />
																{openTimePicker && (location=='publicHolidayBreakTo') && <TimeKeeper time={publicHolidayBreakTo} onChange={handlePublicHolidayBreakTo} hour24Mode={true} switchToMinuteOnHourSelect={true} closeOnMinuteSelect={false} onDoneClick={() => setOpenTimePicker(false)} closeOnMinuteSelect={true}/>}
															</span>															
														</div>
														<span className="same-as-weekdays bottom-show">
															<label className="container-checkbox">
												                <input type="checkbox" checked={isPublicNoBreakWeek} disabled={(dataReadonly)?true:(disablePublicSameBreak)?true:false} onChange={(e) => sameasBreakFunc(e, 'publicHoliday')}/>
												                <span className="checkmark"></span>
												            </label> Same as sundays
												        </span>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div></div></>}
						</>}
					</div>
				<BrandFooter logo={props.imageData.footer_logo}/>
			</div>
		</>
	)
}
export default OpenHours;
