import { data } from "jquery";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
function BrandNavigation(props) {
  let navigate = useNavigate();
  const expandCollapse = (e) => {
    e.preventDefault();
    if(IsFullScreenCurrently()){
        GoOutFullscreen();
      }else{
        openFullscreen();
      }
  }

 // console.log("brandAside",props.cdata?.brandData);
 //console.log("brandAside",props.cdata);
 //console.log("brandAside lc",(JSON.parse(localStorage.getItem("__data"))));

 

  const IsFullScreenCurrently = () => {
    
    //var full_screen_element = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || null;
    
   if((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)){
      localStorage.setItem('fullscreen','full');
      return false;
     }else{
      localStorage.setItem('fullscreen','exit');
         return true;
     }
   
  }
  const GoOutFullscreen = () => {
    document.getElementById("expand-button").classList.remove("fa-compress");
      document.getElementById("expand-button").classList.add("fa-expand");
      if(document.exitFullscreen)
        document.exitFullscreen();
      else if(document.mozCancelFullScreen)
        document.mozCancelFullScreen();
      else if(document.webkitExitFullscreen)
        document.webkitExitFullscreen();
      else if(document.msExitFullscreen)
        document.msExitFullscreen();
  }
  //let elem = props.data;
  let elem = document.body;
  const openFullscreen = () => {
      document.getElementById("expand-button").classList.remove("fa-expand");
      document.getElementById("expand-button").classList.add("fa-compress");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
  }
  const logOut = (e) => {
    e.preventDefault();
    /**/
    Swal.fire({
      text: 'Are you sure want to logout?',
      icon: 'success',
      showDenyButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#205380',
      denyButtonColor: '#205380',
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      background: '#1d2530',
      color: '#fff',
      iconColor: "#0B0",
      width: "500px",
      padding: "10px"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate('/');
      } else if (result.isDenied) {
         Swal.close()
      }
    })
  }

document.addEventListener('fullscreenchange', exitHandler);
document.addEventListener('webkitfullscreenchange', exitHandler);
document.addEventListener('mozfullscreenchange', exitHandler);
document.addEventListener('MSFullscreenChange', exitHandler);

function exitHandler() {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      // console.log("ESC");
       document.getElementById("expand-button").classList.add("fa-expand");
      document.getElementById("expand-button").classList.remove("fa-compress");
    }
} 

 const handleBrandData = (brandName, brandID) => {
  if(typeof props.dataReloadfunction != 'undefined'){
    props.dataReloadfunction(true);
  }
  
  localStorage.setItem("brandID",brandID);
  localStorage.setItem("brandName",brandName);  
 }

 const moveTo = (type) =>{ 
   if(JSON.parse(localStorage.getItem("__data"))?.completePercentage == 'Completed'){
      if(type=="retailer"){
      navigate("/retailer/create")
      }
      if(type=='boimage'){
      navigate("/boimages/creater")
      }

    }

}





  return(  
    <>
    <nav className="main-header navbar navbar-expand navbar-fixed-top">
        <div className="previosnext">
            <ul>
              <li><Link to="#" onClick={(e) => {e.preventDefault();}}><i className="fa-thin fa-chevron-left"></i></Link></li> &nbsp;
              <li><Link to="#" onClick={(e) => {e.preventDefault();}}><i className="fa-thin fa-chevron-right"></i></Link></li>
            </ul>
            <li className="nav-item dropdown profileopen active-brand-names">
              <Link className="nav-link" data-toggle="dropdown" to="#" onClick={(e) => e.preventDefault()} aria-expanded="true">
                <div className="profiletext">                  
              
                <span className="branddisplayname"> {(localStorage.getItem('status') =='Completed') ?"Active Brand":((localStorage.getItem('status') == '100')?"Enrolled Brand":"Enrolling Brand")} :<span className={(localStorage.getItem('status') == 'Completed')?"blueColor":"brandgreenname"}>{(localStorage.getItem("brandName")?localStorage.getItem("brandName"):((typeof props?.cdata?.activeBrand != "undefined")?(props?.cdata?.activeBrand?.brandName):"-"))} {(typeof props?.cdata?.companyDetail?.brands != 'undefined' && props?.cdata?.companyDetail?.brands.length > 1 && props?.cdata?.activeBrand?.brandName)?<i className="fa-thin fa-chevron-down" aria-hidden="true"></i>:""}</span></span>
               
                </div>
              </Link>
              {(typeof props.cdata?.companyDetail?.brands !== 'undefined' && props.cdata?.companyDetail?.brands.length > 1)?
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{"left": "inherit", "right": "0px"}}>  
                   {props.cdata?.companyDetail?.brands?.map((dat)=>{ 
                     return(<> 
                      {/* <Link to="#" className="dropdown-item" onChange={handelBrandData(dat.brandName,dat.brandID)}> */}
                    <Link to="#" onClick={() => handleBrandData(dat.brandName,dat.brandID)} className="dropdown-item" > 
                        <div className="role-define">{dat.brandName}</div>                                     
                    </Link>
                    <div className="dropdown-divider"></div>
                    </>);
                   })}
                                
                </div>:""} 
            </li>    
             
          </div>
          
        
          <div className="centerbtn ">
            <Link to={(JSON.parse(localStorage.getItem("__data"))?.completePercentage == 'Completed')?"/boimages/canvas":"#"} className={(JSON.parse(localStorage.getItem("__data"))?.completePercentage == 'Completed')?"btn btn-success saveIt":"btn btn-success button-not-active"} >New <span className="boldtitleinline">Bo</span>image</Link>
            <Link to={(JSON.parse(localStorage.getItem("__data"))?.completePercentage == 'Completed')?"/retailer/create":"#"} className={(JSON.parse(localStorage.getItem("__data"))?.completePercentage == 'Completed')?"btn btn-success saveIt":"btn btn-success button-not-active"} >New Retailer</Link>
            <Link to="#" className={(JSON.parse(localStorage.getItem("__data"))?.completePercentage == 'Completed')?"btn btn-success button-not-active saveIt":"btn btn-success button-not-active"} onClick={(e) => e.preventDefault()}>New Invite</Link>
          </div>
       
        
          <ul className="navbar-nav ml-auto"> 
            <li className="nav-item dropdown profileopen">
              <Link className="nav-link" data-toggle="dropdown" to="#" onClick={(e) => e.preventDefault()} aria-expanded="true">
                <div className="profiletext">
                  <span className="username">{localStorage.getItem('name')} <i className="fa-thin fa-chevron-down" aria-hidden="true"></i></span>
                  <span className="userdesignation include-email">{props.cdata?.email}</span>
                  <span className="userdesignation">Account Owner</span>
                  
                  
                </div>
              </Link>
                {/*<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{"left": "inherit", "right": "0px"}}>   
                  <span className="dropdown-item dropdown-header">Users</span>
                  <Link to="#" className="dropdown-item">
                    <div className="adminrole-change">Test</div>  
                    <div className="role-define">Owner</div>                  
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link to="#" className="dropdown-item">
                    <div className="adminrole-change">Test</div>  
                    <div className="role-define">Admin</div>                    
                  </Link>                
              </div> */}
            </li>           
            <li className="nav-item dropdown notificatonbell ">
              <Link className="nav-link" data-toggle="dropdown" to="#" onClick={(e) => e.preventDefault()} aria-expanded="true">
              <i className="fa-thin fa-bell button-not-active"></i>
              {/* <span className="badge badge-warning navbar-badge">1</span> */}
              </Link>
                {/* <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{"left": "inherit", "right": "0px"}}>
                  <span className="dropdown-item dropdown-header">15 Notifications</span>
                  <div className="dropdown-divider"></div>
                  <Link to="#" className="dropdown-item">
                    4 new messages
                    <span className="float-right text-muted text-sm">3 mins</span>
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link to="#" className="dropdown-item">
                   8 friend requests
                   <span className="float-right text-muted text-sm">12 hours</span>
                  </Link>                                 
                  <div className="dropdown-divider"></div>
                  <Link to="#" className="dropdown-item dropdown-footer">See All Notifications</Link>
                </div>   */}
            </li>
            <li className="nav-item" id="">
              <Link className="nav-link btn btn-success button-not-active" data-widget="search" to="#" onClick={(e) => e.preventDefault()} role="button">
                  <i className="fa-thin fa-magnifying-glass"></i>
              </Link>
            </li>
            <li className="nav-item">
              <Link id="" className="nav-link btn btn-success" to="#" onClick={expandCollapse} role="button">                  
                  <i className="fa-thin fa-expand" id="expand-button"></i>
              </Link>
            </li>
            <li className="nav-item logoutbtn">
              <Link className="nav-link btn btn-success saveIt" data-widget="logout" to="#" onClick={logOut} role="button">
              <i className="fa-thin fa-power-off saveIt"></i>
              </Link>
            </li>
          </ul>
      </nav>
    </>
  );
}
export default BrandNavigation;