import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "../layouts/BrandDashboardHeader";
import BrandAside from "../layouts/BrandAside";
import BrandAsideNew from "../layouts/BrandAsideNew";
import BrandFooter from "../layouts/BrandFooter";
import errorWarning from './../../assets/images/error-icon.png';
import errorSuccess from './../../assets/images/icons8-check-64.png'
import listImage from './../../assets/images/icons8-list-view-48.png';
import DataTable, { createTheme } from 'react-data-table-component';
import './../../assets/css/checkbox.css'
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import { API_URL, API_KEY_B } from '../../config/constant.js';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import ForBidden from '../layouts/denied';
toast.configure();
createTheme('solarized', {
    text: {
        primary: '#fff',
        secondary: '#fff',
    },
    background: {
        default: '#10141B',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333F50',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


toast.configure();


function Dashboard(props) {
    document.title = 'BOstageB - retailer Invite List';
    const ref = useRef();
    const [elementData, setElementData] = useState("");
    const [denied, setDenied] = useState(false);
    const [preLoader, setPreLoader] = useState(true);
    const [responseArray, setResponseArray] = useState([]);
    const [storeDataList, setStoreDataList] = useState([]);
    const [data1, setData1] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        let _data = JSON.parse(localStorage.getItem("__data"));

        if (typeof props?.compData?.companyDetail != "undefined") {
            if (_data) {
                if (!_data?.StoresMenu) {
                    setPreLoader(false);
                    setDenied(true)
                } else {
                    setDenied(false)
                }
            }
        }

    }, [props.compData]);

    // useEffect(() => {
    //     getStoreList();

    // }, [])

    useEffect(() => {
        if(localStorage.getItem('brandID')){
            getStoreList();
      }else{
        setPreLoader(false);
            } 
      },[localStorage.getItem('brandID')]);



    const timeConvert = (data) => {
        var date = new Date(data);
        return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
    }


    const getStoreList = () => {
        setResponseArray([])
       // axios.post("http://localhost:7071/api/retailer-invite-list", { brandID: localStorage.getItem("brandID"), numOfData: 'all' }).then(res => {
            axios.post(API_URL+"retailer-invite-list"+API_KEY_B, { brandID: localStorage.getItem("brandID"), numOfData: 'all' }).then(res => {
            setResponseArray(res?.data?.data)
            if (res?.data?.status) {
                setData1(1);
            }
            console.log("dfgddf", res?.data?.data);
            // askQ();
        })
    }



    const resendEmail = (email) => {
        let formData = {
            "type": "resend",
            "contactEmail": email,
        }
        axios.post(API_URL + 'inviteRetailer' + API_KEY_B, formData).then((res) => {
            // axios.post('http://localhost:7071/api/inviteRetailer', formData).then((res) => {

            if (res.data.status === 200) {
                //getinvitationList();
                toast.success(res.data.message, {
                    theme: "dark",
                    icon: ({ theme, type }) => <img src={errorSuccess} />

                })
            } else {
                toast.error(res.data.message, {
                    theme: "dark",
                    icon: ({ theme, type }) => <img src={errorWarning} />

                })
            }
        });
    }


    useEffect(() => {

        setStoreDataList(responseArray);
    }, [responseArray])

    useEffect(() => {
        if (data1) {
            setPreLoader(false);
            // if (!responseArray.length) {
            //     // console.log(responseArray.length);
            //     askQ();
            // }

        }
    }, [data1,]);

    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox"
                        ref={ref}
                        onClick={onClick}
                        {...rest}
                    />
                    <span className="checkmark"></span>
                </label>
            </>
        )
    });
    const retailerOptions = [

    ];
    const retailBrandOptions = [

    ];
    const statusOptions = [

    ];
    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: "transparent",
            border: state.isFocused ? "1px solid #0B0" : "1px solid #333F50",
            boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid #0B0",
            },
            "&:active": {
                border: "1px solid #0B0",
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#333F50',
                color: '#d1d1d1',
                cursor: 'pointer',
                border: "1px solid #10141B",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                "&:hover": {
                    border: "1px solid #00AEEF",
                },
                "&:active": {
                    backgroundColor: "#333F50",
                }
            };
        },
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
        input: styles => ({ ...styles, color: '#d1d1d1' }),
        placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
            ':hover': {
                backgroundColor: "#205380",
                color: 'white',
            },
        }),
    };
    const columns = [
        {
            name: 'Invited Retail Brand',
            selector: row => row.retailBrandName,
            sortable: true,
            width: '142px',
            cell: (row) => <><span className="store-name" title={(row.retailBrandName) ? row.retailBrandName : "-"}>{(row.retailBrandName) ? row.retailBrandName : "-"}</span></>
        },
        {
            name: 'Company',
            selector: row => row.companyName
            ,
            sortable: true,
            width: '142px',
            cell: (row) => <><span className="store-name" title={(row.companyName) ? row.companyName : "-"}>{(row.companyName) ? row.companyName : "-"}</span></>
        },

        {
            name: 'Contact person',
            selector: row => row.contactName,
            sortable: true,
            width: '140px',
            cell: (row) => <><span className="store-name" title={(row.contactName) ? row.contactName : "-"}>{(row.contactName) ? row.contactName : "-"}</span></>
        },
        {
            name: 'Contact email',
            selector: row => row.conatctEmail,
            sortable: true,
            width: '180px'
        },
        {
            name: 'Invited on ',
            selector: row => row.invitedOn,
            sortable: true,
            width: '200px',
            //cell: (row) => <span className={(row.posStatus == 'Active') ? "data-table-green" : "data-table-red"}>{row.posStatus}</span>
        },
        {
            name: 'Invited by',
            selector: row => row.invitedBy,
            sortable: true,
            width: '120px',
            //cell: (row) => <span className={(row.posStatus == 'Active') ? "data-table-green" : "data-table-red"}>{row.posStatus}</span>
        },
        {
            name: 'Max Stores',
            selector: row => row.maxStore,
            sortable: true,
            width: '120px',
            // cell: (row) => (row.activeGtins) ? row.activeGtins : "-"
        },
        {
            name: 'Re-sent on',
            selector: row => row.resentOn,
            sortable: true,
            width: '200px',
            //cell: (row) => (row.lastUpdate) ? timeConvert(row.lastUpdate) : "-"
        },

        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '142px',
            // cell: (row) => (row.status)?row.status:<span className="data-table-orng">{row.status}</span>
            cell: (row) => <span className={(row.status == "Invited") ? "data-table-orng" : "data-table-green"}>{row.status}</span>
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            style: {
                justifyContent: 'center',
                flexDirection: 'row'
            },
            center: true,
            width: '250px',
            cell: row => <><Link className="btn btn-success buttondim button-not-active" to="#">Update</Link><Link className="btn btn-success " to="#" onClick={e => { resendEmail(row.conatctEmail) }}>Resend</Link><Link className="btn btn-success buttondim button-not-active" to="#">Cancel</Link></>
        },
    ];



    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="conten-wrapper">
                    {(denied) ? <ForBidden /> : <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                    <span className="searchprodicttext text-center">Retailers</span>
                                </Link>
                            </div>
                            <div className="centerbtn">
                                <Link to={(localStorage.getItem('status') === 'Completed') ? "/retailer" : ""} className={(localStorage.getItem('status') === 'Completed') ? "btn btn-success" : "btn btn-success button-not-active"}>Retailers</Link>
                                <Link to="#" className="btn btn-success active">New Retailer</Link>
                                {/* <Link to="#" className="btn btn-success">IMPORTER</Link> */}
                            </div>
                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/retailer/create" role="button">
                                        <span className="btn btn-success "><i className="fa-thin fa-envelope-open-text"></i></span>
                                        <span className="searchprodicttext">Invite</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas">
                                    <Link to="#" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-list"></i></span>
                                        <span className="searchprodicttext">List</span>
                                    </Link>
                                </div>
                            </div>
                        </div>


                        {(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> :
                            <>
                                <div className="filtersarea">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="serchbopicspr">
                                                    <form className="form-inline">
                                                        <input className="form-control" type="text" placeholder="Search list:"
                                                            aria-label="Search" disabled='disabled' />
                                                        <span className="customeset gobtn button-not-active">
                                                            <button type="button" className="btn btn-success">
                                                                <i className="fa-thin fa-arrow-right"></i>
                                                            </button>
                                                            <span className="searchprodicttext">Go</span>
                                                        </span>
                                                    </form>
                                                </div>
                                                <div className="filtersection">
                                                    <form className="form-inline" name="filter">
                                                        <p className="form-control-static">Filters:</p>
                                                        <div className="form-group">
                                                            <label htmlFor="collection" className="sr-only">Retailer</label>
                                                            <Select
                                                                options={retailerOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled='true' />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="creator" className="sr-only">Retail Brand</label>
                                                            <Select
                                                                options={retailBrandOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled='true' />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="status" className="sr-only">Status</label>
                                                            <Select
                                                                options={statusOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled='true' />
                                                        </div>
                                                        <span className="btnsets products-fit">
                                                            <span className="customeset revertfilters button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-rotate-left"></i></button>
                                                                <span className="searchprodicttext">Reset</span>
                                                            </span>
                                                            <span className="customeset gobtn button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrow-right"></i></button>
                                                                <span className="searchprodicttext">Apply</span>
                                                            </span>
                                                            <span className="customeset gobtn button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrows-rotate"></i></button>
                                                                <span className="searchprodicttext">Refresh</span>
                                                            </span>
                                                        </span>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tablestructureser topteninvite"><h3 className="card-title">RETAILERS</h3></div>
                                <div className="tablestructure">
                                    <DataTable
                                        columns={columns}
                                        data={storeDataList}
                                        theme="solarized"
                                        sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                                        selectableRows
                                        selectableRowsComponent={Checkbox}
                                        defaultSortFieldId={1}
                                        defaultSortAsc={true}
                                        responsive={true}
                                        //noDataComponent={false}
                                        persistTableHead={true}
                                    //  expandableRows = {true}
                                    //   expandableRowsComponent={ExpandedComponent}
                                    />
                                </div>
                            </>}
                    </>}
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>

        </>
    );
}
export default Dashboard;