import React, { useRef, useEffect, useState } from "react";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import { useNavigate } from "react-router-dom";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import { API_URL, API_KEY_B } from './../../config/constant.js';
import StepCombination from '../layouts/commonLayout';
import { Link } from "react-router-dom";
//import Select  from 'react-select';
import Select from 'react-select/creatable';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import Swal from 'sweetalert2';
import DataTable, { createTheme } from 'react-data-table-component';
import ForBidden from './../layouts/denied'
toast.configure();
createTheme('solarized', {
	text: {
		primary: '#fff',
		secondary: '#fff',
	},
	background: {
		default: '#10141B',
	},
	context: {
		background: '#cb4b16',
		text: '#FFFFFF',
	},
	divider: {
		default: '#333F50',
	},
	action: {
		button: 'rgba(0,0,0,.54)',
		hover: 'rgba(0,0,0,.08)',
		disabled: 'rgba(0,0,0,.12)',
	},
}, 'dark');


toast.configure();
export default function Creator(props) {
	document.title = 'BOstageB - Invite Retailer';
	const ref = useRef();
	const navigate = useNavigate();
	const [elementData, setElementData] = useState("");
	const [status, setStatus] = useState("");
	const [buttonGray, setButtonGray] = useState(false)
	const [buttonDisable, setButtonDisable] = useState(false)
	const [brandOption, setBrandOption] = useState([])
	const [countryOption, setCountryOption] = useState([])
	const [toBrand, setToBrand] = useState('');
	const [brandName, setBrandName] = useState('');
	const [retailer, setRetailer] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [country, setCountry] = useState('');
	const [vatNumber, setVatNumber] = useState('');
	const [maxStore, setMaxStore] = useState('');
	const [firstNameError, setFirstNameError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [countryError, setCountryError] = useState(false)
	const [maxStoreError, setMaxStoreError] = useState(false)
	const [denied, setDenied] = useState(false);
	const [preLoader, setPreLoader] = useState(true);
	const [onSubmitLoader, setOnSubmitLoader] = useState(false);
	const [taxPrefixName, setTaxPrefixName] = useState("");
	const [brandVal, setBrandVal] = useState(null);
	const [companyName, setCompanyName] = useState('');
	const [collectionVal, setCollectionVal] = useState(null);
	const [collection, setCollection] = useState("");
	const [brandCollection, setBrandCollection] = useState(true);
	const [newOld, setNewOld] = useState(false);
	const [retailBrandId, setRetailBrandId] = useState('');
	const [responseArray, setResponseArray] = useState([]);
	const [data1, setData1] = useState(0);
	const [storeDataList, setStoreDataList] = useState([]);
	//RetailersMenu
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {

			if (_data) {
				//setPreLoader(false);
				if (!_data?.StoresMenu) {
					setPreLoader(false);
					setDenied(true)
				} else {
					setDenied(false)
					//setPreLoader(false);
				}
			}
		}

	}, [props.compData]);

	useEffect(() => {

		setStatus(props?.compData?.completePercentage)
		if (typeof props?.compData?.Companies != 'undefined') {
			let brandList = props?.compData?.Companies[0]?.Brands;
			var resultArray = brandList.map(function (elm) {
				return { value: elm.brandID, label: elm.brandName };
			});
			setToBrand(resultArray[0]?.value)

			setBrandName(resultArray[0]?.label)
			//setBrandOption(resultArray)
			//setBrandVal({value:resultArray[0]?.value,label:resultArray[0]?.label});
		}
	}, [props?.compData]);

	// useEffect(() => {
	// 	//setPreLoader(true);
	// 	getStoreList();
	// }, [])

	 
    useEffect(() => {
        if(localStorage.getItem('brandID')){
            getStoreList();
      }else{
        setPreLoader(false);
            } 
      },[localStorage.getItem('brandID')]);


	useEffect(() => {
		setElementData(ref.current);
		//getCountry()
	}, [])
	function ValidateEmail(mail) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		} else {
			return false;
		}
	}
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({
			...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
				borderRadius: "10px"
			}
		}),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};
	useEffect(() => {
		if (firstName && email && maxStore) {
			setButtonGray(true)
		} else {
			setButtonGray(false)
		}
	}, [firstName, email, maxStore])

	/*const getCountry = () => {
		let countryArray = [];
		axios.post(API_URL+'get-country-master-data'+API_KEY_B).then((res)=>{
			if(res.data.status === 200){
			   let data = res.data.data;
				 data.forEach((da) => {		
						countryArray.push({ value: da.code, label: da.name });
				   }
				 );
				 setCountryOption(countryArray);
			}
	   })  
	} */
	const handleRetailer = (e) => {
		//console.log("test onchange 178",e);
		if (e) {
			setCollectionVal({ value: e.value, label: e.label });
			setRetailer(e.label);
			getTheRetailBrandInfo(e.value);
		} else {
			setCollectionVal(null);
			setRetailer("")
		}


	}

	const handleCompany = (e) => {
		setCompanyName(e.target.value)
	}

	const handleFirstName = (e) => {
		setFirstNameError(false);
		setFirstName(e.target.value)
	}
	const handleLastName = (e) => {
		setLastName(e.target.value)
	}
	const handleEmail = (e) => {
		setEmailError(false);
		setEmail(e.target.value)
	}
	const handleCountry = (e) => {
		setCountryError(false);
		setCountry(e.value);
		setTaxPrefixName(e.value);
	}
	const handleVatNumber = (e) => {
		setVatNumber(e.target.value)
	}
	const handleMaxStore = (e) => {
		setMaxStoreError(false)
		setMaxStore(e.target.value)
	}
	const handleValidation = () => {
		let formIsValid = true;
		if (!firstName) {
			setFirstNameError(true)
			formIsValid = false;
		}
		if (!email) {
			setEmailError(true)
			formIsValid = false;
		}
		if (email && !ValidateEmail(email)) {
			setEmailError(true)
			formIsValid = false;
		}
		// if(!country){
		// 	setCountryError(true)
		// 	 formIsValid = false;
		// }
		if (!maxStore) {
			setMaxStoreError(true)
			formIsValid = false;
		}
		return formIsValid;
	}
	const handleSubmit = (e) => {

		e.preventDefault();
		if (handleValidation()) {
			setButtonDisable(true);
			setOnSubmitLoader(true);
			let jsonFormData = {
				addTo: localStorage.getItem('brandID'),
				//addTo: toBrand,
				brandName: localStorage.getItem('brandName'),
				//brandName: brandName,
				retailer: retailer,
				firstName: firstName,
				lastName: lastName,
				contactEmail: email,
				company: companyName,
				//country: country,
				vatNumber: vatNumber,
				maxStore: parseInt(maxStore),
				invitedBy: localStorage.getItem("token"),
				inviteType: "Retailer",
				invitedByName: localStorage.getItem('name') + " " + localStorage.getItem('lastname'),
				existingBR: newOld,
				retailBrandId: retailBrandId

			}
			axios.post(API_URL + 'inviteRetailer' + API_KEY_B, jsonFormData).then((res) => {
				//console.log("shfhsfhsf", res);
				//axios.post("http://localhost:7071/api/inviteRetailer", jsonFormData).then((res) => {
				if (res.data.status === 200) {
					setOnSubmitLoader(false);
					props.loginFun(localStorage.getItem('token'), '100')
					toast.success(res.data.message, {
						theme: "dark",
						icon: ({ theme, type }) => <img src={errorSuccess} />,
						onClose: () => {
							
						//	{ (localStorage.getItem('status') === "Completed") ? navigate("/fulfillmentcenter-list") : (navigate("/billing-plan/pre-billing-retailer", { state: { "brandName": brandName } })) }
							{ (localStorage.getItem('status') === "Completed") ? navigate("/retailer") : (navigate("/billing-plan/pre-billing-retailer", { state: { "brandName": brandName } })) }


						}
					});

				} else {
					toast.error(res.data.message, {
						theme: "dark",
						icon: ({ theme, type }) => <img src={errorWarning} />
					});

				}
				setButtonDisable(false);
				setOnSubmitLoader(false);
			});

		}
	}

	const resendEmail = (email) => {
		let formData = {
			"type": "resend",
			"contactEmail": email,
		}
		axios.post(API_URL + 'inviteRetailer' + API_KEY_B, formData).then((res) => {
		//axios.post('http://localhost:7071/api/inviteRetailer', formData).then((res) => {

			if (res.data.status === 200) {
				//getinvitationList();
				toast.success(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={errorSuccess} />

				})
			} else {
				toast.error(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={errorWarning} />

				})
			}
		});
	}

	// const cancelFun = (email) => {
	// 	let formData = {

	// 		"contactEmail": email,
	// 	}
	// 	//axios.post(API_URL + 'deleteRetailerList' + API_KEY_B, formData).then((res) => {
	// 	axios.post('http://localhost:7071/api/deleteRetailerList', formData).then((res) => {

	// 		if (res.data.status === 200) {
	// 			//getinvitationList();
	// 			toast.success(res.data.message, {
	// 				theme: "dark",
	// 				icon: ({ theme, type }) => <img src={errorSuccess} />

	// 			})
	// 		} else {
	// 			toast.error(res.data.message, {
	// 				theme: "dark",
	// 				icon: ({ theme, type }) => <img src={errorWarning} />

	// 			})
	// 		}
	// 	});
	// }



	const changeHandel = (e) => {
		setToBrand(e.value);
		setBrandName(e.label);
		setBrandVal({ value: e.value, label: e.label });
	}


	useEffect(() => {
		getBrandCollectionData();
	}, [])



	const getBrandCollectionData = () => {
		const brandCollectionOption1 = [];
		brandCollectionOption1.push({ value: "", label: "search or enter" });
		setBrandCollection(brandCollectionOption1);
	}



	const getTheRetailBrandInfo = (cid) => {
		setOnSubmitLoader(true);
		setRetailBrandId(cid);
		const formData = { "RetailerBrandID": cid }
		axios.post(API_URL + "get-retailer-brand-detail-search" + API_KEY_B, formData).then((res) => {
			//axios.post("http://localhost:7071/api/get-retailer-brand-detail-search", formData).then((res) => {

			if (res.data.status === 200) {

				//console.log("company data retail", res.data?.data[0]);
				let data = res.data?.data[0];
				if (data != undefined) {
					setCompanyName(data.Companies[0].companyName);
					setEmail(data?.email);
					setFirstName(data?.firstName);
					setLastName(data?.lastName);
					setVatNumber(data?.CompanyAccountMatchData[0]?.taxNumber);
					setNewOld(true);
				} else {
					setCompanyName("");
					setEmail("");
					setFirstName("");
					setLastName("");
					setVatNumber("");
					setMaxStore("");
					setNewOld(false);
				}

				setOnSubmitLoader(false);
			}
			else {
				setCompanyName("");
				setEmail("");
				setFirstName("");
				setLastName("");
				setVatNumber("");
				setMaxStore("");
				setNewOld(false);
				setOnSubmitLoader(false);
			}
		}
		)
	}




	const searchRetailBrand = (e) => {
		if (e.length > 0) {
			setOnSubmitLoader(true);
			const brandCollectionOption1 = [];
			const formData = { "retailBrandName": e }

			axios.post(API_URL + "search-retail-brand" + API_KEY_B, formData).then((res) => {
				//axios.post("http://localhost:7071/api/search-retail-brand", formData).then((res) => {
				if (res.data.status === 200) {

					let data = res.data.data;
					console.log("tetsts dtd", data)
					data.forEach((da) => {

						brandCollectionOption1.push({ value: da.brandID, label: da.name });
					}
					);

					setBrandCollection(brandCollectionOption1);
					setOnSubmitLoader(false);
				}
			})
		}
	}
	const columns = [
		{
			name: 'Invited Retail Brand',
			selector: row => row.retailBrandName,
			sortable: true,
			width: '200px',
			cell: (row) => <><span className="store-name" title={row.retailBrandName}>{row.retailBrandName}</span></>
		},
		{
			name: 'Company',
			selector: row => row.companyName,
			sortable: true,
			width: '142px',
			cell: (row) => <><span className="store-name" title={(row.companyName) ? row.companyName : "-"}>{(row.companyName) ? row.companyName : "-"}</span></>
		},

		{
			name: 'Contact person',
			selector: row => row.contactName,
			sortable: true,
			width: '140px',
			cell: (row) => <><span className="store-name" title={(row.contactName) ? row.contactName : "-"}>{(row.contactName) ? row.contactName : "-"}</span></>
		},
		{
			name: 'Contact email',
			selector: row => row.conatctEmail,
			sortable: true,
			width: '222px'
		},
		{
			name: 'Invited on ',
			selector: row => row.invitedOn,
			sortable: true,
			width: '170px',
			//cell: (row) => <span className={(row.posStatus == 'Active') ? "data-table-green" : "data-table-red"}>{row.posStatus}</span>
		},
		{
			name: 'Invited by',
			selector: row => row.invitedBy,
			//selector: row => "-",
			sortable: true,
			width: '120px',
			//cell: (row) => <span className={(row.posStatus == 'Active') ? "data-table-green" : "data-table-red"}>{row.posStatus}</span>
		},
		{
			name: 'Max Stores',
			selector: row => row.maxStore,
			sortable: true,
			width: '120px',
			// cell: (row) => (row.activeGtins) ? row.activeGtins : "-"
		},
		{
			name: 'Re-sent on',
			selector: row => row.resentOn,
			sortable: true,
			width: '132px',
			//cell: (row) => (row.lastUpdate) ? timeConvert(row.lastUpdate) : "-"
		},

		{
			name: 'Status',
			selector: row => row.status,
			sortable: true,
			width: '102px',
			//  cell: (row) => (row.status)?row.status:<span className="data-table-orng">Not Active</span>
			//cell: (row) => <span className={(row.status == 'Invited') ? "data-table-orng" : "data-table-green"}>Invited</span>
			cell: row => (row.status == "Active") ? <span style={{ "color": "#00BB00" }}>{row.status}</span> : (row.status == "Invited" || row.status == "Re-sent") ? <span style={{ "color": "#ff9900" }} >{row.status}</span> : <span style={{ "color": "#0B0" }} >{row.status}</span>
		},
		{
			name: 'Action',
			selector: row => row.action,
			sortable: false,
			style: {
				justifyContent: 'center',
				flexDirection: 'row'
			},
			center: true,
			width: '250px',
			cell: row => <><Link className="btn btn-success buttondim button-not-active" to="#">Update</Link><Link className="btn btn-success  " to="#" onClick={e => { resendEmail(row.conatctEmail) }}>Resend</Link><Link className="btn btn-success buttondim button-not-active " to="#" >Cancel</Link></>
		},
	];
	const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
		return (
			<>
				<label className="container-checkbox">
					<input type="checkbox"
						ref={ref}
						onClick={onClick}
						{...rest}
					/>
					<span className="checkmark"></span>
				</label>
			</>
		)
	});
	const getStoreList = () => {

		setResponseArray([]);
		axios.post(API_URL + "retailer-invite-list" + API_KEY_B, { brandID: localStorage.getItem("brandID"), numOfData: 'limit' }).then(res => {
		//axios.post("http://localhost:7071/api/retailer-invite-list", { brandID: localStorage.getItem("brandID"), numOfData: 'limit' }).then(res => {

			setResponseArray(res?.data?.data)
			//console.log("jhsgtet", res?.data?.data);
			if (res?.data?.status) {
				setData1(1);
				setPreLoader(false);
			}
			console.log("dshgfjhdsfgjs", res?.data?.data);
			// askQ();
		})
	}


	// const getStoreList = () => {
	//     setResponseArray([]);
	//     axios.post(API_URL+"retailer-invite-list"+API_KEY_B,{ brandID: localStorage.getItem("brandID"),numOfData: 'limit' }).then((res)=>{
	//     //axios.post(" http://localhost:7071/api/get-retailler-list-active" , { brandID: localStorage.getItem("brandID") }).then(res => {
	//         setResponseArray(res?.data?.data)
	//         if (res?.data?.status) {
	//             setData1(1);
	// 			setPreLoader(false);
	//         }

	//         const dataA = res?.data?.data?.filter((ret) => {

	// 			if(ret?.status=='Inactive'){
	//                 return ret;
	//              }
	//         });

	// 		const fitData = dataA.reverse().filter((re,i)=>{
	//                if(i<10){
	// 				return re;
	// 			   }
	// 		})

	//         //console.log("dataAAAA",dataA.reverse());
	//        // console.log("fit",fitData);
	//         setResponseArray(fitData);

	//     })
	// }

	useEffect(() => {

		setStoreDataList(responseArray);
	}, [responseArray])



	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
					{(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> : (denied) ? <ForBidden /> :
						<><div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" className={(status === 'Completed') ? "button-not-active" : " button-not-active"} to='#' role="button">
									<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
									<span className="searchprodicttext text-center">Retailers</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to={(status == 'Completed') ? "/retailer" : ""} className={(status == 'Completed') ? "btn btn-success" : "btn btn-success button-not-active"}>Retailers</Link>
								<Link to="#" className="btn btn-success active">New Retailer</Link>
								{/* <Link to="#" className="btn btn-success">IMPORTER</Link> */}
							</div>
							<div className="rightcontrolserch">
								<div className="productsearch rightareas">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success active"><i className="fa-thin fa-envelope-open-text"></i></span>
										<span className="searchprodicttext">Invite</span>
									</Link>
								</div>
								<div className="productsearch rightareas ">
									<Link to="/retailer/retailer-invite-list" role="button">
										<span className="btn btn-success"><i className="fa-thin fa-list"></i></span>
										<span className="searchprodicttext">List</span>
									</Link>
								</div>
							</div>
						</div>
							<div className="filtertopcombo">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="filtersection creatorsbtnset enroll-brand">
												<span className={(buttonGray) ? "customeset textinside " : "customeset textinside disabledbtn"}>
													<button type="submit" onClick={handleSubmit} className="btn btn-success invite-control" disabled={(buttonDisable) ? 'disabled' : ''}>{(buttonDisable) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-paper-plane"></i>)} INVITE </button>
												</span>
											</div>
											<StepCombination cdata={props.compData} />

										</div>
									</div>
								</div>
							</div>
							<div className="creators-section">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="cardsection">
												<div className="card-header">
													<h3 className="card-title">Invite Retailer <span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
												</div>
												<div className="card-body">
													<div className="row">
														{/*<div className="col-md-3">
													<div className={(localStorage.getItem('status') !='Completed')?"form-group inActiveborder selectheight":"form-group activeborder selectheight"}>
															<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> To brand</label>
															
															<Select className="selects"
																styles={colourStyles}
																options={brandOption}
																value={brandVal}
																isDisabled={(status == 'Completed')?false:true}
																placeholder={<div className="italic-placeholder">Select...</div>} 
																onChange={changeHandel}
																
															/>
															<span className="verfiefd-text">Verified</span>
															
														</div>
													</div>  */}

														<div className="col-md-3">
															<div className="form-group" >
																<label htmlFor="tobrand"><i className="fas fa-info-circle " aria-hidden="true"></i> Retail Brand</label>
																{/* <input type="text" onChange={handleRetailer} className="form-control" placeholder="" /> */}
																<Select
																	options={brandCollection}
																	styles={colourStyles}
																	onChange={handleRetailer}
																	onInputChange={searchRetailBrand}
																	defaultValue={collectionVal}
																	// isDisabled={dataReadonly}
																	isClearable
																	placeholder={<div className="italic-placeholder">Select...</div>}
																/>
															</div>
														</div>
														<div className="col-md-3">
															<div className="form-group">

																<div className="form-group ">
																	<label for="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> VAT/Tax number</label>
																	<div className={(newOld) ? "form-group lockedNoborder" : "form-group"}>
																		<div className="havesuffixdrop">
																			<span className="selects textbox">{taxPrefixName}</span>
																			<input type="text" className="form-control box-pad" placeholder="" value={vatNumber} maxlength="15" onChange={handleVatNumber} readOnly={(newOld) ? true : false} />
																		</div>
																	</div>
																</div>

															</div>
														</div>
														<div className="col-md-3">
															<div className={(newOld) ? "form-group lockedNoborder" : "form-group"} >
																<label htmlFor="tobrand"><i className="fas fa-info-circle " aria-hidden="true"></i>Company</label>
																<input type="text" onChange={handleCompany} className="form-control" placeholder="" value={companyName} readOnly={(newOld) ? true : false} />
															</div>
														</div>
														<div className="col-md-3">
															<div className="form-group activeborder">
																<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Max stores</label>
																<input type="number" name="points" onChange={handleMaxStore} min="1" step="1" value={maxStore} className={(maxStoreError) ? "form-control error-input" : "form-control"} placeholder="All" />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-3">
															<div className={(newOld) ? "form-group inActiveborder selectheight" : "form-group activeborder selectheight"}>
																<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Contact 1st name</label>
																<input type="text" onChange={handleFirstName} value={firstName} className={(firstNameError) ? "form-control error-input" : "form-control"} placeholder="" readOnly={(newOld) ? true : false} />
															</div>
														</div>
														<div className="col-md-3">
															<div className={(newOld) ? "form-group lockedNoborder" : "form-group"}>
																<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Contact last name</label>
																<input type="text" onChange={handleLastName} value={lastName} className="form-control" placeholder="" readOnly={(newOld) ? true : false} />
															</div>
														</div>

														<div className="col-md-3">
															<div className={(newOld) ? "form-group inActiveborder " : "form-group activeborder "}>
																<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Contact email</label>
																<div className="addonMailleft ">
																	<input type="text" onChange={handleEmail} value={email} className={(emailError) ? "form-control error-input" : "form-control"} placeholder="" readOnly={(newOld) ? true : false} />
																</div>
															</div>
														</div>

														{/* <div className="col-md-3">
														<div className="form-group activeborder selectheight">
															<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Country</label>
															<Select className={(countryError)?"selects error-input":"selects"} 
																styles={colourStyles}
																options={countryOption}
																onChange={handleCountry}
																placeholder={<div className="italic-placeholder">Select...</div>} 
															/>
														</div>
													</div> */}

													</div>
												</div>
											</div>
											<div className="tablestructureser topteninvite"><h3 className="card-title">LAST 10 INVITED</h3></div>
											<div className="tablestructure">
												<DataTable
													columns={columns}
													title={true}
													data={storeDataList}
													theme="solarized"
													sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
													selectableRows
													selectableRowsComponent={Checkbox}
													defaultSortFieldId={0}
													defaultSortAsc={true}
													responsive={true}
													persistTableHead={true}
												//expandableRows = {false}
												//noDataComponent={true}
												//   expandableRowsComponent={ExpandedComponent}
												/>
											</div>


										</div>
									</div>
								</div>
							</div></>
					}
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
			{(onSubmitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}
		</>
	)
}