import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import StepCombination from '../layouts/commonLayout';
import { API_URL,API_KEY_B } from './../../config/constant.js';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import ForBidden from './../layouts/denied';
import Swal from 'sweetalert2';
toast.configure();
export default function Delivery(props) {
	document.title = 'BOstageB - Store Delivery';
	const storeID = useParams();
	const ref = useRef();
	let navigate = useNavigate();
	const wrapperRef = useRef(null);
	const [elementData, setElementData] = useState("");
	const [openHours, setOpenHours] = useState("");
	const [buttonGray, setButtonGray] = useState(true);
	const [disabledbtn, setDisabledbtn] = useState(false);
	const [pickup, setPickup] = useState("No")
	const [pickupWhen, setPickupWhen] = useState("")
	const [courier, setCourier] = useState("No")
	const [courierWhen, setCourierWhen] = useState("")
	const [freeCourier, setFreeCourier] = useState("No")
	const [freeCourierFrom, setFreeCourierFrom] = useState("")
	const [shipping, setShipping] = useState("No")
	const [shippingWhen, setShippingWhen] = useState("")
	const [freeShipping, setFreeShipping] = useState("No")
	const [freeShippingFrom, setFreeShippingFrom] = useState("")
	const [isFreeCourier, setIsFreeCourier] = useState(true);
	const [isFreeShipping, setIsFreeShipping] = useState(true);
	const [status, setStatus] = useState("");
	const [defaultPickupValue, setDefaultPickupValue] = useState("");
	const [defaultCourierValue, setDefaultCourierValue] = useState("");
	const [defaultShippingValue, setDefaultShippingValue] = useState("");
	const [preLoader, setPreLoader] = useState(true);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [dataReadonly, setDataReadonly] = useState(0);
	const [denied, setDenied] = useState(false);
	const [companyId, setCompanyId] = useState("");

	const [pickupError, setPickUpError] = useState(false);
	const [locationError, setLocationError] = useState(false);
	const [addressError, setAddressError] = useState(false);

	
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.StoresMenu){
					setPreLoader(false);
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }
		
	},[props.compData]);

	const [buttonName,setButtonName] =useState("NEXT");
	const [editMode,setEditMode] =useState(0);
    const[editModeBtnFun,setEditModeBtnFun] = useState(0);

	useEffect(()=>{
		if(localStorage.getItem("viewMode")=='editmode')
		{
		  setEditMode(1);
		  setEditModeBtnFun(1);
		  setButtonName("SAVE");
		}
	 },[localStorage.getItem("viewMode")]) 
     
    
     
	  /*******************save popup********************** */
      
	  const refWarp = useRef(null);   //new datatttt
	  useEffect(() => {
	  
		  function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode);
			  
				//menu
			  let pclass = event?.target?.parentNode?.parentNode?.parentNode?.parentNode;
			  let pclassimg = event?.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
			  // rightcontrolserch
			  let prClassIcon = event?.target?.parentNode?.parentNode?.parentNode;
			  let prClass = event?.target?.parentNode?.parentNode;
			   //center upper btn
			 let pCNClass = event?.target?.parentNode;
			   
			  if(editMode && !editModeBtnFun){  
				  //  console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp?.current && !refWarp?.current?.contains(event.target) && (event?.target?.classList?.contains("saveIt") || pclass?.classList?.contains("menusections") || pclass?.classList?.contains("navitems-area") || pclassimg?.classList?.contains("navitems-area")|| pclassimg?.classList?.contains("menusections") || prClassIcon?.classList?.contains("saveIt")  || prClass?.classList?.contains("saveIt") || pCNClass?.classList?.contains("saveIt") )  ) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					  }).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);   
							//handleSubmit(event);
							document.getElementById("submitBtnC").click(); 		
						}
						else if(result.isDenied){
						   setEditModeBtnFun(1);
						 }
						
					})   
   
				
			}
		   }
		  }
		  document.addEventListener("mousedown", handleSaveOutside);
		  return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		  };
		}, [refWarp,editMode,editModeBtnFun]);   
   
   
   
   
	  /**************************************************** */

	 
	useEffect(() => {
		if (typeof props?.compData?.Companies != 'undefined') {
			   setCompanyId(props?.compData?.Companies[0]?.companyID);
		}
	},[props.compData]);
	
	useEffect(() => {
		setStatus(props?.compData?.completePercentage)
	}, [props?.compData]);
	
    
	useEffect(() => {
		//if (pickupWhen && courierWhen && shippingWhen) {
		if (pickupWhen || courierWhen || shippingWhen) {
			setButtonGray(true)
		} else {
			setButtonGray(false)
		}
		
	}, [pickupWhen , courierWhen , shippingWhen]);



	useEffect(() => {
		checkStoreID(storeID.id);
	}, [storeID]);
	const checkStoreID = (id) => {
		let formData = { "id": id }
		axios.post(API_URL + "get-store-info"+API_KEY_B, formData).then((res) => {
			if (res.data.status != 200) {
				navigate("/store");
			} else {
				setPreLoader(false);
				//console.log(res.data?.data);
				setOpenHours(res.data?.data?.openHours)
				setPickup(res.data?.data?.pickup)
				setDefaultPickupValue({ value: res.data?.data?.pickupWhen, label: res.data?.data?.pickupWhen })
				setPickupWhen(res.data?.data?.pickupWhen)
				setCourier(res.data?.data?.courier)
				setDefaultCourierValue({ value: res.data?.data?.courierWhen, label: res.data?.data?.courierWhen })
				setCourierWhen(res.data?.data?.courierWhen);
				if (res.data?.data?.courier == 'Yes') {
					setIsFreeCourier(false)
				} else {
					setIsFreeCourier(true)
				}
				setFreeCourier(res.data?.data?.freeCourier)
				setFreeCourierFrom(res.data?.data?.freeCourierFrom)

				if (res.data?.data?.shipping == 'Yes') {
					setIsFreeShipping(false)
				} else {
					setIsFreeShipping(true)
				}
				setFreeShipping(res.data?.data?.freeShipping)
				setFreeShippingFrom(res.data?.data?.freeShippingFrom)
				setShipping(res.data?.data?.shipping)
				setDefaultShippingValue({ value: res.data?.data?.shippingWhen, label: res.data?.data?.shippingWhen })
				setShippingWhen(res.data?.data?.shippingWhen)
				
				if(res.data.data?.pickupWhen &&  res.data.data?.courierWhen &&  res.data.data?.shippingWhen){
					  if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){
					   setDataReadonly(1);
					  }
				 }

			}
		})
	}
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		noOptionsMessage:(styles) => ({...styles, backgroundColor: '#333F50', color: '#d1d1d1'}),
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
			    borderRadius: "10px"
			}
		}),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};
	const options = [
		{ value: 'During open hours', label: 'During open hours' },
		{ value: 'Always', label: 'Always' }
	];
	const handleShippingWhen = (e) => {
		//sam editMode
		if(editMode){
			setEditModeBtnFun(0);
		  }
		setDefaultShippingValue(e);
		setShippingWhen(e.value);
        setAddressError(false);

		setLocationError(false);
		setPickUpError(false);
		if (e.value) {
			setShipping("Yes")
			setIsFreeShipping(false)
		} else {
			setShipping("No")
			setIsFreeShipping(true)
		}
	}
	const handlePickupWhen = (e) => { 
		//sam editMode
		if(editMode){
			setEditModeBtnFun(0);
		  }
		setDefaultPickupValue(e);
		setPickupWhen(e.value);
		setPickUpError(false);

		setLocationError(false);
		setAddressError(false);
		if (e.value) {
			setPickup("Yes")
		} else {
			setPickup("No")
		}
	}
	const handleCourierWhen = (e) => {
		//sam editMode
		if(editMode){
			setEditModeBtnFun(0);
		  }
		setDefaultCourierValue(e);
		setCourierWhen(e.value);
		setLocationError(false);
        
		setPickUpError(false);  
		setAddressError(false);

		if (e.value) {
			setCourier("Yes")
			setIsFreeCourier(false)
		} else {
			setCourier("No")
			setIsFreeCourier(true)
		}
	}
	const handleCheckBoxes = (e, type) => {
		//sam editMode
		if(editMode){
			setEditModeBtnFun(0);
		  }
		if (type == 'pickup') {
			if (e.target.checked) {
				setPickup("Yes")
				setDefaultPickupValue(options[0]);
				setPickupWhen(options[0].value);
				setPickUpError(false);
			} else {
				setPickup("No")
				setDefaultPickupValue(""); 
        		setPickupWhen("");
				setPickUpError(false);
			
			}
		}
		if (type == 'courier') {
			if (e.target.checked) {
				setCourier("Yes")
				setIsFreeCourier(false);
				setDefaultCourierValue(options[0]);
       			setCourierWhen(options[0].value);
				setLocationError(false);
			} else {
				setCourier("No")
				setIsFreeCourier(true)
				setDefaultCourierValue("");
        		setCourierWhen("");
				setFreeCourier("No");
				setLocationError(false);
			}
		}
		if (type == 'shipping') {
			if (e.target.checked) {
				setShipping("Yes")
				setIsFreeShipping(false)
				setDefaultShippingValue(options[0]);
				setShippingWhen(options[0].value);
				setAddressError(false);
			} else {
				setShipping("No")
				setIsFreeShipping(true)
				setDefaultShippingValue("");
        		setShippingWhen("");
				setFreeShipping("No")
				setAddressError(false);

			}
		}
		if (type == 'courierFree') {
			if (e.target.checked) {
				setFreeCourier("Yes")
			} else {
				setFreeCourier("No")
			}
		}
		if (type == 'shippingFree') {
			if (e.target.checked) {
				setFreeShipping("Yes")
			} else {
				setFreeShipping("No")
				
			}
		}
	}
	const handleFreeCourierFrom = (e) => {
		//sam editMode
		if(editMode){
			setEditModeBtnFun(0);
		  }
		setFreeCourierFrom(e.target.value)
	}
	const handleFreeShippingFrom = (e) => {
		//sam editMode
		if(editMode){
			setEditModeBtnFun(0);
		  }
		setFreeShippingFrom(e.target.value)
	}

	 const handleValidation = () => {
		
	 	let formIsValid = true;
        if(pickupWhen || courierWhen || shippingWhen){
			setPickUpError(false);
			setLocationError(false);
			setAddressError(false);
	 		formIsValid = true;
		}else{
			setPickUpError(true);
			setLocationError(true);
			setAddressError(true);
	 		formIsValid = false;
		}
       
		
	 	return formIsValid;
	 }   


	 const getActiveStoreCount = () => {
		let formData = {

			"brandID": localStorage.getItem("brandID")
		}
	  	axios.post(API_URL+'count-active-store-ffc'+API_KEY_B,formData).then((res) => {
			if(res.data.status === 200){
			//	console.log("dataStore",res.data?.data?.totalActiveStore);
				localStorage.setItem("totalStore",res.data?.data?.totalActiveStore);
				localStorage.setItem("totalFFc",res.data?.data?.totalFFCStore);
			}
		})
	}

  
const handleCreateRInB = () =>{
	    //  setPreLoader(true);
         
		  let jsonPost = {
			
			companyId:companyId
		}
		axios.post(API_URL + "/create-R-In-B"+API_KEY_B, jsonPost).then((res) => {
		      if (res.data.status == 200) {
				 	return true;			
			} else {
				return false;
			}
		})
		return true;
		
}





	const handleSubmit = (e) => {
		e.preventDefault()
		if (dataReadonly) {
			navigate("/billing-plan/pre-billing");
		} else {
	  if (handleValidation()){ 	
		setSubmitLoader(true);
		setDisabledbtn(true);
         //sam editMode
        if(!editMode){
			setDataReadonly(1)
		}  
		     
		let jsonPost = {
			"storeID": storeID.id,
			"type": "delivery",
			"pickup": (pickup != null)?pickup:"No",
			"pickupWhen": pickupWhen,
			"courier": (courier != null)?courier:"No",
			"courierWhen": courierWhen,
			"freeCourier": freeCourier,
			"freeCourierFrom": freeCourierFrom,
			"shipping": (shipping != null)?shipping:"No",
			"shippingWhen": shippingWhen,
			"freeShipping": freeShipping,
			"freeShippingFrom": freeShippingFrom,
			"companyId":companyId
		}
		axios.post(API_URL + "/update-store-info"+API_KEY_B, jsonPost).then((res) => {
			setDisabledbtn(false)
			if (res.data.status == 200) {
				 props.loginFun(localStorage.getItem('token'),'100');
				  
				  if(localStorage.getItem('status')!="Completed"){
					handleCreateRInB()
					getActiveStoreCount();
				 }

				  /*res.data.message,*/
				toast.success("Delivery data has been saved successfully",
					{
						theme: "dark",
						icon: ({ theme, type }) => <img src={errorSuccess} />,
						onClose: () => {
							if(!editMode){
								if(localStorage.getItem('status')!="Completed"){
									navigate("/billing-plan/pre-billing");
								}else{
									navigate("/store/list");
									
								}
							 }else{
								setDataReadonly(0)  
								
							 }
								
						}
					});
					setSubmitLoader(false);
				
			} else {
				toast.error(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={errorWarning} />
				})
			}
			if(editMode){
				setEditModeBtnFun(1);
		     } 
			setSubmitLoader(false);
		})
    	}
      } 
	}
	return (
		<>
			{(submitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
				 {(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
					<div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" className="button-not-active" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
								<span className="searchprodicttext text-center">Stores</span>
							</Link>
						</div>
						<div className="centerbtn">
							<Link to={(status ==100 || status == 'Completed') ? "/store/list" : "#"} className={(status ==100 || status == 'Completed') ? "btn btn-success saveIt" : "btn btn-success button-not-active"}>Stores</Link> 
							{/* <Link to={(status === 'Completed' || localStorage.getItem('status')  ==100) ? "/store" : "#"} className={(status === 'Completed' || localStorage.getItem('status')  ==100) ? "btn btn-success" : "btn btn-success button-not-active"}>Stores</Link> */}
							<Link to="#" className={(editMode)?"btn btn-success active saveIt":"btn btn-success button-not-active"}>Edit Store</Link>
							<Link to="/store" className={(editMode)?"btn btn-success saveIt":"btn btn-success active saveIt" }   >New Store</Link>
							{/* <Link to="#" className="btn btn-success">Importer</Link> */}
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas saveIt">
								<Link data-widget="search" to={(editMode)?"/store/"+storeID.id:"/store"} role="button">
									<span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link>
							</div>
							<div className="productsearch rightareas saveIt">
								<Link to={"/store/open/" + storeID?.id} role="button">
									<span className="btn btn-success"><i className="fa-thin fa-clock"></i></span>
									<span className="searchprodicttext">Open</span>
								</Link>
							</div>
							<div className="productsearch rightareas saveIt">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-box"></i></span>
									<span className="searchprodicttext text-center">Delivery</span>
								</Link>
							</div>
							<div className="productsearch rightareas notactive">
								<Link data-widget="search" to="#" role="button" onClick={(e) =>{e.preventDefault()}} >
									<span className="btn btn-success"><i className="fa-thin fa-people-group"></i></span>
									<span className="searchprodicttext text-center">Staff</span>
								</Link>
							</div>
						</div>
					</div>
					{(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> :
						<><div ref={refWarp}>	
							<div className="filtertopcombo">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="filtersection creatorsbtnset enroll-brand ">
												<span className={(buttonGray) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
													<button type="submit" id="submitBtnC" onClick={handleSubmit} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun)?true:disabledbtn} >{buttonName} {(disabledbtn) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-chevron-right"></i>)}</button>
												</span>
											</div>
											<StepCombination cdata={props.compData} />

										</div>
									</div>
								</div>
							</div>
							<div className="creators-section">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="cardsection">
												<div className="card-header">
													<h3 className="card-title">Delivery Options Available<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
												</div>
												<div className="card-body">
													<form>
														<div className="row">
															<div className="col-md-3">
																<div className={(dataReadonly)?"form-group inActiveborder selectheight":"form-group activeborder"}>
																	<label htmlFor="tobrand"><Tooltip title="Select this option to make pick-up in store available to your shoppers" arrow placement={"top-start"}><i className="fas fa-info-circle" aria-hidden="true"></i></Tooltip> Pick-up in store</label>
																	<div className="checkbox-select">
																		<label className="container-checkbox"><input type="checkbox" checked={(pickup == 'Yes') ? true : false} onClick={(e) => handleCheckBoxes(e, 'pickup')} disabled={(dataReadonly)?true:false} /><span className="checkmark"></span></label>
																		<Select className={(pickupError) ? "selects error-input" : "selects"}
																		 value={defaultPickupValue}
																		  onChange={handlePickupWhen}
																		   options={options}
																		    styles={colourStyles}
																			 isDisabled={(dataReadonly)?true:false}
																			 placeholder={<div className="italic-placeholder">Select...</div>}
																		/>
																	</div>
																</div>
																<div className="open-close-timing">
																	<h5>Open hours</h5>
																	<ul>
																		<li>Week days: <span className="green-text">{openHours?.Weekdays?.hourStart} to {openHours?.Weekdays?.hourEnd}</span></li>
																		<li>Saturdays: {(openHours?.Saturdays?.hourStart && openHours?.Saturdays?.hourEnd) ? (<span className="green-text">{openHours?.Saturdays?.hourStart} to {openHours?.Saturdays?.hourEnd}</span>) : (<span className="red-text">Closed</span>)}</li>
																		<li>Sundays: {(openHours?.Sundays?.hourStart && openHours?.Sundays?.hourEnd) ? (<span className="green-text">{openHours?.Sundays?.hourStart} to {openHours?.Sundays?.hourEnd}</span>) : (<span className="red-text">Closed</span>)}</li>
																		<li>P.Holidays: {(openHours?.PublicHolidays?.hourStart && openHours?.PublicHolidays?.hourEnd) ? (<span className="green-text">{openHours?.PublicHolidays?.hourStart} to {openHours?.PublicHolidays?.hourEnd}</span>) : (<span className="red-text">Closed</span>)}</li>
																	</ul>
																</div>
															</div>
															<div className="col-md-3">
																<div className={(dataReadonly)?"form-group inActiveborder selectheight":"form-group activeborder"}>
																	<label htmlFor="tobrand"><Tooltip title="Select this option to make courier-to-a-location service available to your shoppers. If you select this option, BOtag will provide the service through a courier partner. No workload to your side." arrow placement={"top-start"}><i className="fas fa-info-circle" aria-hidden="true"></i></Tooltip> Courier to a location</label>
																	<div className="checkbox-select">
																		<label className="container-checkbox"><input type="checkbox" checked={(courier == 'Yes') ? true : false} onClick={(e) => handleCheckBoxes(e, 'courier')}  disabled={(dataReadonly)?true:false} /><span className="checkmark"></span></label>
																		<Select className={(locationError) ? "selects error-input" : "selects"}
																			value={defaultCourierValue}
																			onChange={handleCourierWhen}
																			options={options}
																			styles={colourStyles}
																			isDisabled={(dataReadonly)?true:false}
																			placeholder={<div className="italic-placeholder">Select...</div>}
																		/>
																	</div>
																</div>
																<div className={isFreeCourier ? "free-courier-blocks field-locked" : "free-courier-blocks"}>
																	<label className="container-checkbox"><input type="checkbox" checked={(freeCourier == 'Yes') ? true : false} onClick={(e) => handleCheckBoxes(e, 'courierFree')} disabled={(dataReadonly)?true:(isFreeCourier)?true:false} /><span className="checkmark"></span></label>
																	<span className="custom-courier-label">
																		Free courier from: <input type="text" className="form-control" placeholder="-" value={freeCourierFrom} onChange={handleFreeCourierFrom} disabled={(dataReadonly)?true:isFreeCourier} /> NOK
																	</span>
																</div>

															</div>
															<div className="col-md-3">
																<div className={(dataReadonly)?"form-group inActiveborder selectheight":"form-group activeborder"}>
																	<label htmlFor="tobrand"><Tooltip title="Select this option to make shipping-to-an-address service available to your shoppers. If you select this option, BOtag will provide the service through a courier partner. No workload to your side." arrow placement={"top-start"}><i className="fas fa-info-circle" aria-hidden="true"></i></Tooltip> Shipping to an address</label>
																	<div className="checkbox-select">
																		<label className="container-checkbox"><input type="checkbox" checked={(shipping == 'Yes') ? true : false} onClick={(e) => handleCheckBoxes(e, 'shipping')}  disabled={(dataReadonly)?true:false} /><span className="checkmark" ></span></label>
																		<Select className={(addressError) ? "selects error-input" : "selects"}
																		 onChange={handleShippingWhen}
																		  value={defaultShippingValue} 
																		   options={options} 
																		   styles={colourStyles}
																		   placeholder={<div className="italic-placeholder">Select...</div>}
																		     isDisabled={(dataReadonly)?true:false} />
																	</div>
																</div>
																<div className={isFreeShipping ? "free-courier-blocks field-locked" : "free-courier-blocks"}>
																	<label className="container-checkbox"><input type="checkbox" checked={(freeShipping == 'Yes') ? true : false} disabled={(dataReadonly)?true:(isFreeShipping)?true:false} onClick={(e) => handleCheckBoxes(e, 'shippingFree')} /><span className="checkmark"></span></label>
																	<span className="custom-courier-label">
																		Free shipping from: <input type="text" className="form-control" placeholder="-" value={freeShippingFrom} onChange={handleFreeShippingFrom} disabled={(dataReadonly)?true:isFreeShipping} /> NOK
																	</span>
																</div>
															</div>
														</div>
													</form>
												</div>
											</div>

										</div>
									</div>
								</div>
								</div>
							</div></>}
							</>}
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	)
}