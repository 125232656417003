import React, { useRef, useEffect, useState } from "react";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import { useNavigate } from "react-router-dom";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import PilotIcon from './../../assets/images/pilot.png';
import BoimagePlan from './../../assets/images/boimage-plan.png';
import InfluencerPlan from './../../assets/images/influencer-plan.png';
import BusinessPlan from './../../assets/images/business-plan.png';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import axios from 'axios';

import { API_URL,API_KEY_B } from './../../config/constant.js';
import { Link } from "react-router-dom";
import ForBidden from './../layouts/denied'
export default function Billing(props){
	document.title = 'BOstageB - Subscription Plans';
	const ref = useRef();
	const navigate = useNavigate();
	const [elementData, setElementData] = useState("");
	const [targetBtb,setTargetBtb] =useState(false);
	const [planType, setPlanType] = useState("pilot")
	const [denied, setDenied] = useState(false);
	const [preLoader, setPreLoader] = useState(true);
	
	const [btnPilot, setBtnPilot] = useState(false);
	const [btnBoimage, setBtnBoimage] = useState(false);
	const [btnInfulence, setBtnInfulence] = useState(false);
	const [btnBusiness, setBtnBusiness] = useState(false);

	const [companyId, setCompanyId] = useState("");
	const [companyName,setCompanyName] = useState("");

	const [companyEmail, setCompanyEmail] = useState("");
	const [mobile, setMobile] = useState("");
    const [mainDateRange, setMainDateRange] = useState("");
	const [country, setCountry] = useState("");
      
      

	 
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
			// setTimeout((e)=>{
				if(!_data?.BillingMenu){
				
						setPreLoader(false); 
						setDenied(true);     
										
				}else{
					setDenied(false);
					//setPreLoader(false);   
				}
		//	},1000);	
		  }
	   }
		
	},[props.compData]);
	

   useEffect(()=>{
	if(Object.keys(JSON.parse(localStorage.getItem("__data")).status).length != 0){
			
		//setPreLoader(false);      
	
      }  
		
   });

   const [stripData ,setStripData] = useState(0);

   useEffect(() => {
		// console.log("company data",Date.now())
	if (typeof props?.compData?.Companies != 'undefined') {
		setCompanyId(props?.compData?.Companies[0]?.companyID);
		setCompanyName(props?.compData?.Companies[0]?.companyName);

       /************added********* */
	    
	    setCompanyEmail(props.compData?.companyEmail);
		setMobile(props?.compData?.companyDetail?.phoneNumber?.split(" ")[1]);
		setMainDateRange(props?.compData?.companyDetail?.registrationDate);
		//setMainDateRange("20/01/2000");
		setCountry(props?.compData?.companyDetail?.countryName);
		

	}
}, [props.compData]);

 


	const handleChoosePlane = (e, type) => {
		setPlanType(type)
	}
    
	const handleCheckBoxes = (e,plan) =>{
		if (plan == 'Pilot') {
			if (e.target.checked) {
				setBtnPilot(true);
				setBtnBoimage(false);
				setBtnInfulence(false);
				setBtnBusiness(false);
			} else {
				setBtnPilot(false);
			}
		}

		if (plan == 'BOimage') {
			if (e.target.checked) {
				setBtnPilot(false);
				setBtnBoimage(true);
				setBtnInfulence(false);
				setBtnBusiness(false);
			} else {
				setBtnBoimage(false);
			}
		}

		if (plan == 'Influencers') {
			if (e.target.checked) {
				setBtnPilot(false);
				setBtnBoimage(false);
				setBtnInfulence(true);
				setBtnBusiness(false);
			} else {
				setBtnInfulence(false);
			}
		}

		if (plan == 'Business') {
			if (e.target.checked) {
				setBtnPilot(false);
				setBtnBoimage(false);
				setBtnInfulence(false);
				setBtnBusiness(true);
			} else {
				setBtnBusiness(false);
			}
		}
	}
  
/*	 
const buyPlan = (plan) =>{
	setPreLoader(true);
   
	let jsonPost = {  
	  companyId:companyId,
	  billingPlan: plan,
  }
  axios.post(API_URL + "/billing-plan"+API_KEY_B, jsonPost).then((res) => {
  
	  if (res.data.status == 200) {
		      props.loginFun(localStorage.getItem('token'),'Completed'); 
			
		  toast.success(res.data.message,
			  {  
				  theme: "dark",
				  icon: ({ theme, type }) => <img src={errorSuccess} />,
				  onClose: () => {
						  navigate("/welcome-store");	
						 // navigate("/billing-plan/selected-plan");			
				  }
			  });
			  setPreLoader(false);
		  
	  } else {
		  toast.error(res.data.message, {
			  theme: "dark",
			  icon: ({ theme, type }) => <img src={errorWarning} />
		  })
	  }
	  setPreLoader(false);
	  
  })
   
  // setTimeout(()=>{
  // 	setPreLoader(false);
  // 	navigate("/billing-plan");
  // },2500);
  
}
  */


const [ip, setIP] = useState("");

const getData = async () => {
  const res = await axios.get("https://api.ipify.org/?format=json");
  //console.log(res.data);
  setIP(res.data.ip);
  setPreLoader(false);   
};

useEffect(() => {
   getData();
}, []);




const buyPlan = (plan) =>{
	
	setPreLoader(true);

   
	let jsonPost = {  
	  companyId:companyId,
	  billingPlan: plan,
	  stripAccountId:props?.compData?.companyDetail?.stripeAccountID,
	  stripPlan : {
	  type : 'custom',
	  country : country,
	  email : companyEmail,
	  business_type :"individual",
	  individual : {	
	  first_name : companyName,
	  last_name : "Lda",
	  phone : mobile,
	  email : companyEmail,
	  dob :{day:mainDateRange?.split('-')[0],month:mainDateRange?.split('-')[1],year:mainDateRange?.split('-')[2]},
	  address : {line1:"Test Address",
	  			city:"Oslo",
				postal_code :"0154",
			},
	   },
	  business_profile: {   
		  mcc : "7296",
		  url : "www"+companyName.replace(/ /g, '')+".com",
	  },
	  external_account: {
	  object : "bank_account",
	  country : country,
	  currency : "NOK",
	 // account_number: "00001111222233334444",
	  account_number : "NO9386011117947",
  		},
	  capabilities : {
		  card_payments : { requested: true },
		  transfers : { requested: true },
	  },
	  tos_acceptance :{
		date : Math.floor(Date.now() / 1000),
		ip : ip,

	  },
     }  
	}	   
  
  axios.post(API_URL + "/billingPlanStripePG"+API_KEY_B, jsonPost).then((res) => {
  //axios.post("http://localhost:7071/api/billingPlanStripePG", jsonPost).then((res) => {
  
	  if (res.data.status == 200) {
		      props.loginFun(localStorage.getItem('token'),'Completed'); 
			let msg = (res.data?.message?.acknowledged!=undefined)?"Successfully activated your plan.":res.data?.message;
		  toast.success(msg,
			  {  
				  theme: "dark",
				  icon: ({ theme, type }) => <img src={errorSuccess} />,
				  onClose: () => {
						  navigate("/welcome-store");	
						 // navigate("/billing-plan/selected-plan");			
				  }
			  });
			  setPreLoader(false);
		  
	  }else{ 
		 //res.data.message.raw.message
		  toast.error(res.data.message.raw.message, {
			  theme: "dark",
			  icon: ({ theme, type }) => <img src={errorWarning} />
		  })
	  }
	  setPreLoader(false);
	  
  })

  
}






const [fplanPriceA,setFplanePriceA] = useState("0");
const [fplanPriceB,setFplanePriceB] = useState("1.020");
const [fplanCommA,setFplanCommA] = useState("5,5%");
const [fplanCommB,setFplanCommB] = useState("8,75%");

const [splanPriceA,setSplanePriceA] = useState("1.020");
const [splanCommA,setSplanCommA] = useState("8,75%");

const [tplanPriceA,setTplanePriceA] = useState("1.920");
const [tplanCommA,setTplanCommA] = useState("8,75%");

const [foplanPriceA,setFoplanePriceA] = useState("3.960");
const [foplanCommA,setFoplanCommA] = useState("8,75%");


const [ptype,setPtype] = useState("ANNUAL FEE");
const [pstype,setPstype] = useState(true);

const [fmonthCostA,setFmonthCostA]=useState("€0/month");
const [fmonthCostB,setFmonthCostB]=useState("€85/month");
const [smonthCostA,setSmonthCostA]=useState("€85/month");
const [tmonthCostA,setTmonthCostA]=useState("€160/month");
const [fomonthCostA,setFomonthCostA]=useState("€330/month");



const handlePlanTypeCheckBoxes = (e)=>{
    if(e.target.checked) {
		 //monthly
	     setPtype("MONTHLY FEE");
		 
		 setPstype(false);
		 setFplanePriceA('0');
		 setFplanePriceB('100');
		 setFplanCommA('5,5%');
		 setFplanCommB('8,75%');

		 setSplanePriceA('100');
		 setSplanCommA('8,75%');
		 
		 setTplanePriceA('190');
		 setTplanCommA('8,75%');

		 setFoplanePriceA('390');
		 setFoplanCommA('8,75%');

		}else{
			//Anaully
			setPtype("ANNUAL FEE");	
			setPstype(true);

			setFplanePriceA('0');
			setFplanePriceB('1.020');
			setFplanCommA('5,5%');
			setFplanCommB('8,75%');
   
			setSplanePriceA('1.020');
			setSplanCommA('8,75%');
			
			setTplanePriceA('1.920');
			setTplanCommA('8,75%');
   
			setFoplanePriceA('3.960');
			setFoplanCommA('8,75%');
	}
}


     


	return(
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
					{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
					<div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" className="button-not-active" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
								<span className="searchprodicttext text-center">Invoices</span>
							</Link>
						</div>
						<div className="centerbtn">							
							<Link to="#" className="btn btn-success active">BILLING</Link>						
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-file-invoice-dollar"></i></span>
									<span className="searchprodicttext">Plans</span>
								</Link>
							</div>
							<div className={(targetBtb)?"productsearch rightareas":"productsearch rightareas notactive"}>
								<Link to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-file-invoice"></i></span>
									<span className="searchprodicttext">Invoices</span>
								</Link>
							</div>							
						</div>
					</div>
					{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:<>					
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-3">
								<div className="labelgreentext billing-for">Applying to company: <span className="itvalue">{companyName}</span></div>
							</div>
						</div>
					</div>
					<div className="creators-section billing-page-plans">
						<div className="container-fluid">
							
							<div className="row">
								<div className="col-md-12">
									<div className="cardsection prdoctMargin billingScroll">
										<div className="card-header">
											<h3 className="card-title">Subscription plans
												<span className="formcautions">
													<ul>
														<li><span className="makesqaure mandatorythis"></span>mandatory fields</li>
														<li><span className="makesqaure lockedthis"></span>locked fields</li>
													</ul>
												</span>
											</h3>
											<div className="control-switch card-header-slot">
												<div className="controlftrbtn tooltipson">
													<span className="control-item">Annual charges</span>
													<label className="switch"><input type="checkbox" onClick={(e) => handlePlanTypeCheckBoxes(e)} /><span className="slider round"></span></label>
													<span className="control-item">Monthly charges</span>
												</div>
											</div>

										</div>
										<div className="card-body">	
																				
											<div className="row">
												<div className="col-md-3">
													<div className="billing-plans-section">
														<div className="plan-header">
															<h3 className="plan-name"><img className="brand-icon-img" src={PilotIcon} alt="Pilot Plan" />Pilot Plan<i className="fa-light fa-circle-info"></i></h3>
															<div className="plan-points">
																<ul>
																	<li><i className="fa-regular fa-check"></i>Create <span className="makeitbo">BO</span>images</li>
																	<li><i className="fa-regular fa-check"></i>Create a Fulfillment Centers Network</li>
																	<li><i className="fa-regular fa-xmark"></i>Create an Influencers Network</li>
																	<li><i className="fa-regular fa-xmark"></i>Create a Business Network</li>
																</ul>
															</div>
														</div>
														<div className="plan-pricing">
																<span className="twoplans">
																	<h3 className="fee-structre">{ptype}<i className="fa-light fa-circle-info"></i></h3>
																	<p className="fee-structre-subtext">Charged to the brand</p>
																	<h2>&euro;{fplanPriceA}</h2>
																	<span className="one-line-plan">{(pstype)? fmonthCostA:""}  <br />per enrolled brand<br />(1st 12 months)</span>
																</span>
																<span className="twoplans">
																	<h3 className="fee-structre">{ptype}<i className="fa-light fa-circle-info"></i></h3>
																	<p className="fee-structre-subtext">Charged to the brand</p>
																	<h2>&euro;{fplanPriceB}</h2>
																	<span className="one-line-plan">{(pstype)? fmonthCostB:""}<br />per enrolled brand<br />(after 12 months)</span>
																</span>	
														</div>
														<div className="enroll-as-retailer">
															<h4>Commission Fee<i className="fa-light fa-circle-info"></i></h4>
															<p className="fee-structre-subtext">Charged to the brand</p>
															<span className="twoplans">
																<h3 className="plan-percentage">{fplanCommA}</h3>
																<span className="commision-sale">Pilot Program: 1st 12 months</span>
															</span>
															<span className="twoplans">
																<h3 className="plan-percentage">{fplanCommB}</h3>
																<span className="commision-sale">After Pilot Program</span>
															</span>															
														</div>
														<div className="plan-footer">
															<div className="yes-checkbox">
																<label className="container-checkbox">
																	<input type="checkbox" onClick={(e) => handleCheckBoxes(e, 'Pilot')} checked={(btnPilot) ? true : false} />
																	<span className="checkmark"></span>
																</label><span className="label-yes">I agree with the terms and conditions</span>
															</div>
															<button className={(btnPilot)?"choosebtns selected":"choosebtns"} disabled={(btnPilot)?"":"disabled"} onClick={e => buyPlan('Pilot')} >Choose Plan<i className="fas fa-angle-right" aria-hidden="true" ></i></button>
														</div>

													</div>
												</div>


												<div className="col-md-3">
													<div className="billing-plans-section">
														<div className="plan-header">
															<h3 className="plan-name"><img className="brand-icon-img" src={BoimagePlan} alt="BOimage PLan" /><span className="makeitbo">BO</span>image Plan<i className="fa-light fa-circle-info"></i></h3>
															<div className="plan-points">
																<ul>
																	<li><i className="fa-regular fa-check"></i>Create <span className="makeitbo">BO</span>images</li>
																	<li><i className="fa-regular fa-check"></i>Create a Fulfillment Centers Network</li>
																	<li><i className="fa-regular fa-xmark"></i>Create an Influencers Network</li>
																	<li><i className="fa-regular fa-xmark"></i>Create a Business Network</li>
																</ul>
															</div>
														</div>
														<div className="plan-pricing">
																
																	<h3 className="fee-structre">{ptype}<i className="fa-light fa-circle-info"></i></h3>
																	<p className="fee-structre-subtext">Charged to the brand</p>
																	<h2>&euro;{splanPriceA}</h2>
																	<span className="one-line-plan">{(pstype)? smonthCostA:""}<br />per enrolled brand</span>
																
																
														</div>
														<div className="enroll-as-retailer">
															<h4>Commission Fee<i className="fa-light fa-circle-info"></i></h4>
															<p className="fee-structre-subtext">Charged to the brand</p>
															<span className="one-line-plan">
																<h3 className="plan-percentage">{splanCommA}</h3>
															</span>
																													
														</div>
														<div className="plan-footer">
															<div className="yes-checkbox">
																<label className="container-checkbox">
																	<input type="checkbox" onClick={(e) => handleCheckBoxes(e, 'BOimage')} checked={(btnBoimage) ? true : false} />
																	<span className="checkmark"></span>
																</label><span className="label-yes">I agree with the terms and conditions</span>
															</div>
															<button className={(btnBoimage)?"choosebtns selected":"choosebtns"} disabled={(btnBoimage)?"":"disabled"} onClick={e => buyPlan('BOimage')} >Choose Plan<i className="fas fa-angle-right" aria-hidden="true" ></i></button>
														</div>

													</div>
												</div>

												<div className="col-md-3">
													<div className="billing-plans-section ">
														<div className="plan-header">
															<h3 className="plan-name"><img className="brand-icon-img" src={InfluencerPlan} alt="Influencers Plan" />Influencers plan<i className="fa-light fa-circle-info"></i></h3>
															<div className="plan-points">
																<ul>
																	<li><i className="fa-regular fa-check"></i>Create <span className="makeitbo">BO</span>images</li>
																	<li><i className="fa-regular fa-check"></i>Create a Fulfillment Centers Network</li>
																	<li><i className="fa-regular fa-check"></i>Create an Influencers Network</li>
																	<li><i className="fa-regular fa-xmark"></i>Create a Business Network</li>
																</ul>
															</div>
														</div>
														<div className="plan-pricing">
																
																	<h3 className="fee-structre">{ptype}<i className="fa-light fa-circle-info"></i></h3>
																	<p className="fee-structre-subtext">Charged to the brand</p>
																	<h2>&euro;{tplanPriceA}</h2>
																	<span className="one-line-plan">{(pstype)? tmonthCostA:""}<br />per enrolled brand</span>
																
																
														</div>
														<div className="enroll-as-retailer">
															<h4>Commission Fee<i className="fa-light fa-circle-info"></i></h4>
															<p className="fee-structre-subtext">Charged to the brand</p>
															<span className="one-line-plan">
																<h3 className="plan-percentage">{tplanCommA}</h3>
															</span>
																													
														</div>
														<div className="plan-footer">
															<div className="yes-checkbox">
																<label className="container-checkbox">
																	<input type="checkbox" onClick={(e) => handleCheckBoxes(e, 'Influencers')} checked={(btnInfulence) ? true : false} />
																	<span className="checkmark"></span>
																</label><span className="label-yes">I agree with the terms and conditions</span>
															</div>
															<button className={(btnInfulence)?"choosebtns selected":"choosebtns"} disabled={(btnInfulence)?"":"disabled"} onClick={e => buyPlan('Influencers')} >Choose Plan<i className="fas fa-angle-right" aria-hidden="true" ></i></button>
														</div>

													</div>
												</div>

												<div className="col-md-3">
													<div className="billing-plans-section">
														<div className="plan-header">
															<h3 className="plan-name"><img className="brand-icon-img" src={BusinessPlan} alt="Business Plan" />Business plan<i className="fa-light fa-circle-info"></i></h3>
															<div className="plan-points">
																<ul>
																	<li><i className="fa-regular fa-check"></i>Create <span className="makeitbo">BO</span>images</li>
																	<li><i className="fa-regular fa-check"></i>Create a Fulfillment Centers Network</li>
																	<li><i className="fa-regular fa-check"></i>Create an Influencers Network</li>
																	<li><i className="fa-regular fa-check"></i>Create a Business Network</li>
																</ul>
															</div>
														</div>
														<div className="plan-pricing">
																
																	<h3 className="fee-structre">{ptype}<i className="fa-light fa-circle-info"></i></h3>
																	<p className="fee-structre-subtext">Charged to the brand</p>
																	<h2>&euro;{foplanPriceA}</h2>
																	<span className="one-line-plan">{(pstype)? fomonthCostA:""}<br />per enrolled brand</span>
																
																
														</div>
														<div className="enroll-as-retailer">
															<h4>Commission Fee<i className="fa-light fa-circle-info"></i></h4>
															<p className="fee-structre-subtext">Charged to the brand</p>
															<span className="one-line-plan">
																<h3 className="plan-percentage">{foplanCommA}</h3>
															</span>
																													
														</div>
														<div className="plan-footer">
															<div className="yes-checkbox">
																<label className="container-checkbox">
																	<input type="checkbox" onClick={(e) => handleCheckBoxes(e, 'Business')} checked={(btnBusiness) ? true : false} />
																	<span className="checkmark"></span>
																</label><span className="label-yes">I agree with the terms and conditions</span>
															</div>
															<button className={(btnBusiness)?"choosebtns selected":"choosebtns"} disabled={(btnBusiness)?"":"disabled"} onClick={e => buyPlan('Business')} >Choose Plan<i className="fas fa-angle-right" aria-hidden="true" ></i></button>
														</div>

													</div>
												</div>

												

												

												

											</div>
											
										</div>
									</div>						

								</div>
							</div>
						</div>
					</div></>}
					</> }
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	)
}