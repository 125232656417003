import React, { useRef, useEffect, useState } from "react";
import { Link ,useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import ExclamtionBlue from './../../assets/images/exclation-blue.png';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import ForBidden from './../layouts/denied';
import Swal from 'sweetalert2';
import StepCombination from '../layouts/commonLayout';

toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - Create Tags    ';
	const ref = useRef();
    const [elementData, setElementData] = useState("");
	const [showPreLoader, setShowPreLoader] = useState(true) ;
	
	let navigate = useNavigate(); 
	useEffect(() => {
		setTimeout(()=>{
			setShowPreLoader(false);
		},1000)
		
	 },[props?.compData]); 
    
	 const [denied, setDenied] = useState(false)
	 useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.BrandsMenu){
					
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }
		
	},[props.compData]);

const incPerBoimage =()=>{ 
	console.log("test Pre boimage");
	props.loginFun(localStorage.getItem('token'), '55');
	navigate('/boimages/canvas');
}


	return(
		<>
		<div className="wrapper" ref={ref} id="myscreen">
			<BrandNavigation data={elementData} cdata={props.compData}/>
			<BrandAside cdata={props.compData} />
			<div className="conten-wrapper enroll-wrapper">
			{(denied)?<ForBidden />:<>
			<div className="controlbar">
			    	<div className="productsearch leftsidetr">
			            <Link data-widget="search" className="button-not-active" to="#" role="button">
			                <span className="btn btn-success"><i className="fas fa-search"></i></span>
			                <span className="searchprodicttext">BOimages</span>
			            </Link>
			        </div>
			        <div className="centerbtn">
						<Link to="#" className=" btn btn-success button-not-active">Boimages</Link>
                        <Link to="/boimages/creater" className="btn btn-success active">New Boimage</Link>
			        </div>
			        <div className="rightcontrolserch">
			            <div className="productsearch rightareas ">
			              	<Link data-widget="search" to="/boimages/creater" role="button">
			                	<span className="btn btn-success active"><i className="fas fa-fingerprint"></i></span>
			                	<span className="searchprodicttext">ID</span>
			              	</Link>
			            </div>
			            <div className="productsearch rightareas notactive">
			              	<Link data-widget="search"  to="#" role="button">
			                	<span className="btn btn-success"><i className="fas fa-chalkboard"></i></span>
			                	<span className="searchprodicttext">Canvas</span>
			              	</Link>
			            </div> 
			            <div className="productsearch rightareas notactive">
			              	<Link data-widget="search" to="#" role="button">
			                	<span className="btn btn-success"><i className="fas fa-bullseye"></i></span>
			                	<span className="searchprodicttext">Targets</span>
			              	</Link>
			            </div>
			        </div>
			    </div>  
			{(showPreLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:<>
				<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									
									<StepCombination cdata={props.compData}/>

								</div>
							</div>
						</div>
					</div>
			   
                    <div className="creators-section">
			    	<div className="container-fluid">
			    		<div className="row">
                            <div className="col-md-12">
                            <div className="small-formas" >
                                    <div className="blueicons-area">
                                        <img src={ExclamtionBlue} />
                                    </div>
                                    <p className="verifying-text wt">Ready to create your first <b>BO</b>image?</p>
                                    <p className="verifying-text wt senduty"> Get one of your images ready to upload. Can be any image you use to promote your products such as an advertising image, a catalogue image, a product image, etc. We  
                                    recommend an image with more than one product.</p>
									
									<p className="wt-color">The image should be a PNG or JPEG file, high quality, max 1Mb.</p>                                  
 									
                                    <div className="send-blockbtn twobtnsets" >
                                    <button className="btn btn-success remanchor" onClick={incPerBoimage} >LET'S GO</button> 
                                  
                                    </div>
                                   
                                    
                                </div>
                            </div>
			    			
			    		</div>
			    	</div>
			    </div>
				</>}
				</>}
			</div>
			<BrandFooter logo={props.imageData.footer_logo}/>
		</div>
		
		</>
	);
}
export default Dashboard;