import React, { useRef, useEffect, useState } from "react";
import { Link ,useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import ExclamtionBlue from './../../assets/images/exclation-blue.png';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import StepCombination from './../layouts/commonLayout';
import ForBidden from './../layouts/denied';
toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - Store ';
	const ref = useRef();
    const [elementData, setElementData] = useState("");
	const [denied, setDenied] = useState(false)
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.StoresMenu){
					
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }
		
	},[props.compData]);
	
	let navigate = useNavigate(); 
	useEffect(() => {
		if(localStorage.getItem('status') > 75){
			//navigate("/store");
		}
	 });
	 
	 
	 const handelStore = () =>{

			 navigate("/store/pre-create-new-store");

   }	 
    
   const handelRetailer = () =>{
    	
		let data = JSON.parse(localStorage.getItem("__data"));
		   data.RetailersMenu=true;

		   localStorage.setItem("__data",JSON.stringify(data));
			 navigate("/retailer/create");

   }

	return(
		<>
		<div className="wrapper" ref={ref} id="myscreen">
			<BrandNavigation data={elementData} cdata={props.compData}/>
			<BrandAside cdata={props.compData} />
			<div className="conten-wrapper enroll-wrapper">
            {(denied)?<ForBidden />:<>
            <div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" className="button-not-active" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
								<span className="searchprodicttext text-center">Stores</span>
							</Link>
						</div>
						<div className="centerbtn">
							<Link to={(props.compData?.completePercentage==="Completed")?"#":"#"} className={(props.compData?.completePercentage==="Completed")?"btn btn-success":"btn btn-success button-not-active "}>Stores</Link>
							<Link to="#" className="btn btn-success active ">New Store</Link>
							{/* <Link to="#" className="btn btn-success button-not-active">Staff</Link> */}
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas ">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link>
							</div>
							<div className="productsearch rightareas notactive">
								<Link to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-clock"></i></span>
									<span className="searchprodicttext">Open</span>
								</Link>
							</div>
							<div className="productsearch rightareas notactive">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-box"></i></span>
									<span className="searchprodicttext text-center">Delivery</span>
								</Link>
							</div>
							<div className="productsearch rightareas notactive">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-people-group"></i></span>
									<span className="searchprodicttext text-center">Staff</span>
								</Link>
							</div>
						</div>
					</div>
				
				<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									
									<StepCombination cdata={props.compData}/>

								</div>
							</div>
						</div>
					</div>
			   
			    <div className="creators-section">
			    	<div className="container-fluid">
			    		<div className="row">
                            <div className="col-md-12">
                            <div className="small-formas pre-storebox" >
                                    <div className="blueicons-area">
                                        <img src={ExclamtionBlue} />
                                    </div>
                                    <p className="verifying-text wt" >If the company {(typeof props?.compData?.Companies != 'undefined')?props?.compData?.Companies[0]?.companyName:''} has physical stores selling the {props?.compData?.activeBrand?.brandName} brand, select ENROLL STORES. Otherwise select INVITE RETAILERS to start affiliating independent stores as fulfillment centers for {props?.compData?.activeBrand?.brandName}.
 									</p>

                                  
                                    <div className="send-blockbtn twobtnsets similarsize" >
                                    {/* <Link to="/store/pre-create-new-store" >ENROLL STORES</Link>  */}
                                    {/* <Link to="/retailer/create" onClick={handelRetailer} >ENROLL RETAILERS</Link> */}
									<button type="submit" className="btn btn-primary pre-store" onClick={handelStore} >ENROLL STORES</button>
									<button type="submit" className="btn btn-primary pre-store" onClick={handelRetailer} >ENROLL RETAILERS</button>
                                    </div>
                                   
                                    
                                </div>
                            </div>
			    			
			    		</div>
			    	</div>
			    </div>
				</>}
			</div>
			<BrandFooter logo={props.imageData.footer_logo}/>
		</div>
		
		</>
	);
}
export default Dashboard;