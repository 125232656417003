import React from "react";
import { Link } from "react-router-dom";
import Header from "./../layouts/header";
import Footer from "./../layouts/footer";
function notFound(props) {
  document.title = 'BOstageB - Page Not Found';
  return (
    <>
      <div className="loginwindow">
        <Header />
        <div className="loginscreens">
          <div className="container">
            <div className="loginwidn">
              <div className="row">
                <div className="col-md-12">
                  <div className="logoineer">
                    <img src={props.imageData.logo} alt="LoginScreen" />
                    <p>The Brand Stage For Botag Technology</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                    <h1 className="page-not-found">Page Not Found <Link to="/">Go Back</Link></h1>

                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer logo={props.imageData.footer_logo}/>
    </>
  );
}

export default notFound;