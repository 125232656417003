import React from "react";
const TagsInput = (props) => {
    const [tags, setTags] = React.useState([]);
    const [preTags, setPreTags] = React.useState([]);
    const [tTag, setTTags] = React.useState("");
    const addTags = event => {
        //if (event.key === "Enter" && event.target.value !== "") {
        if (event.target.value !== "") {
            setTags([...tags, event.target.value]);
            let arrayData = [...tags, event.target.value];
            var a = new Array();
            arrayData = preTags.concat(arrayData);
            a[event.target.id] = arrayData;
            props.selectedTags(a);
            event.target.value = "";
        }
    };
    const addTagsOnPres = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setTags([...tags, event.target.value]);
            let arrayData = [...tags, event.target.value];
            var a = new Array();
            arrayData = preTags.concat(arrayData);
            a[event.target.id] = arrayData;
            props.selectedTags(a);
            event.target.value = "";
        }
    }
    const removeTags = (index, targetTag) => { 
        setTags([...tags.filter(tag => tags.indexOf(tag) !== index)]);
        setTTags(targetTag)
    };
    const removeBrandTags = (index, targetTag) => {
        setPreTags([...preTags.filter(tag => preTags.indexOf(tag) !== index)]);
        setTTags(targetTag)
    };
    React.useEffect(() => {
        if(tTag){
            var a = new Array();
            let updTag = preTags.concat(tags)
            a[tTag] = updTag;
            props.selectedTags(a);
        }
    }, [tags, preTags]);
    React.useEffect(() => {
        if(typeof props.preData != 'undefined'){
            setPreTags(props.preData);
        }
    }, [props.preData]);
    return (
        <div className={props.isError?"tags-input error-input":"tags-input"}>
                {preTags.map((tag, index) => (
                    <div className="tag" key={index}>
                        {tag}
                        <button onClick={() => {removeBrandTags(index, props.tagType); props.rmBrTag(index);}}   disabled={props.isDisabled}>x</button>
                      </div>
                ))}
                {tags.map((tag, index) => (
                    <div className="brand-tag" key={index}>
                        {tag}
                        <button onClick={() => removeTags(index, props.tagType)}   disabled={props.isDisabled}>x</button>
                      </div>
                ))}
            <input
                type="text"
                onKeyUp={event => addTagsOnPres(event)}
                onBlur={event => addTags(event)}
                id={props.tagType}
                placeholder="Press enter to add tags"
                disabled={props.isDisabled}
            />
        </div>
    );
};
export default TagsInput;