import React, { useRef, useEffect, useState } from "react";
import { Link ,useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";

import { API_URL, API_KEY_B ,BLOB_URL, BLOB_COMPANY_IMAGE,BLOB_BRAND_IMAGE} from './../../config/constant.js';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import axios from 'axios';

import Swal from 'sweetalert2';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import uploadFileToBlob, { isStorageConfigured } from './blob';
import StepCombination from '../layouts/commonLayout';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import { param } from "jquery";
import ForBidden from './../layouts/denied';
import Slider from 'react-input-slider';
toast.configure();

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  	return centerCrop(
    	makeAspectCrop(
	      	{
	        	unit: '%',
	        	width: 100,
	      	},
	      	aspect,
	      	mediaWidth,
	      	mediaHeight,
    	),
    	mediaWidth,
    	mediaHeight,
  	)
}

function Dashboard(props) {
	document.title = 'BOstageB -Retail Brand Logo';
	const ref = useRef();
    const [elementData, setElementData] = useState("");
   	const [imgSrc, setImgSrc] = useState('')
  	const previewCanvasRef = useRef(null)
  	const previewWhiteCanvasRef = useRef(null)
  	const previewBlackCanvasRef = useRef(null)
  	const previewAltCanvasRef = useRef(null)
	const previewAltWhiteCanvasRef = useRef(null)
	const previewAltBlackCanvasRef = useRef(null)
  	const imgRef = useRef(null)
  	const [crop, setCrop] = useState()
  	const [completedCrop, setCompletedCrop] = useState()
  	const [completedWhiteCrop, setCompletedWhiteCrop] = useState()
  	const [completedBlackCrop, setCompletedBlackCrop] = useState()
  	const [completedAltCrop, setCompletedAltCrop] = useState()
  	const [completedAltWhiteCrop, setCompletedAltWhiteCrop] = useState()
  	const [completedAltBlackCrop, setCompletedAltBlackCrop] = useState()
  	const [cropedData, setCropedData] = useState({unit: 'px', x: 0, y: 0, width: 245, height: 90})
  	const [scale, setScale] = useState(1)
  	const [rotate, setRotate] = useState(0)
  	const [aspect, setAspect] = useState(245/90)
  	const [isCropped, setIsCropped] = useState(false);
  //	const [fileSrcUrl, setFileSrcUrl] = useState("");
  	const [imageName, setImageName] = useState("");
  	const [imageNameUpd, setImageNameUpd] = useState("");
	const [saveImage, setSaveImage] = useState([]);
	const [buttonGray, setButtonGray] = useState(false);
	const [logoType, setLogoType] = useState("");
	const [saveMainLogo, setSaveMainLogo] = useState(false);
	const [saveMainWhiteLogo, setSaveMainWhiteLogo] = useState(false);
	const [saveMainBlackLogo, setSaveMainBlackLogo] = useState(false);
	const [saveMainAltLogo, setSaveMainAltLogo] = useState(false);
	const [saveMainAltWhite, setSaveMainAltWhite] = useState(false);
	const [saveMainAltBlack, setSaveMainAltBlack] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false); 
	const [imgError,setImgError] =useState(false);   
	const [targetEnable,setTargetEnable] = useState(false);

	const [showPreLoader, setShowPreLoader] = useState(true); 
	//const [showPreLoader, setShowPreLoader] = useState(false); 
	const [dataReadonly, setDataReadonly] = useState(false);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [denied, setDenied] = useState(false);
    const [country,setCountry] = useState("");
	const [getCountryLoader,setGetCountryLoader] = useState(false);
	const [getCompanyLoader,setGetCompanyLoader] = useState(false);
    
	const [retailerName, setRetailerName] = useState("");
	const [retailerOneError, setRetailerOneError] = useState(false);
	const [companyName, setCompanyName] = useState("");
	const [companyId, setCompanyId] = useState("");
	const [grayButton,setGrayButton] = useState(false);


	const [companyOption,setCompanyOption] = useState("");
    const [companyError, setCompanyError] = useState(false); 
    const [companyDefault,setCompanyDefault] = useState(0);
    const [companyLoader,setCompanyLoader] = useState(false);
	const [companyVal,setCompanyVal] = useState(null);


	const [sliderInitial, setSliderInitial] = useState(0);
	const [isDisabled, setIsDisabled] = useState(true)
	const [tempScale, setTempScale] = useState(1)

	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.BrandsMenu){
					setShowPreLoader(false);
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }
		
	},[props.compData]);
    let {id} = useParams();
	let navigate = useNavigate();
	

	useEffect(()=>{
		     if(localStorage.getItem('status') >= 80){
				 navigate("/store");
			 }
	});

	useEffect(() => {
        if(retailerName && companyVal && saveMainLogo)
        {
        setGrayButton(true);
        } else{
            setGrayButton(false);
        }
    },[retailerName,companyVal,saveMainLogo]);

    
	const [fileSrcUrl, setFileSrcUrl] = useState("");
	const [fileSrcUrl1, setFileSrcUrl1] = useState("");
	const [fileSrcUrl2, setFileSrcUrl2] = useState("");
	const [fileSrcUrl3, setFileSrcUrl3] = useState("");
	const [fileSrcUrl4, setFileSrcUrl4] = useState("");
	const [fileSrcUrl5, setFileSrcUrl5] = useState("");
    const [fileSelOpen, setFileSelOpen] = useState(false);
	const [wtb,setWtb] = useState(0);

    useEffect(()=>{
		if(getCountryLoader && getCompanyLoader ){
			setShowPreLoader(false);
		}
   },[getCountryLoader, getCompanyLoader]);
 
	useEffect(()=>{
		if (typeof props?.compData?.Companies != 'undefined') {
			   //  setGetCompanyLoader(true);
				//setCompanyName(props?.compData?.Companies[0]?.companyName);
				//setCompanyId(props?.compData?.Companies[0]?.companyID);
			}
			getCompanyId();
			getBrandCountryData();
	},[props?.compData]);  

  /*
	const getBrandAllData =  () =>{
        let bid="";

		if(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed'){ 
			bid = localStorage.getItem('brandID')
	     }else{
			bid=id;
		 }

		 let formData ={
			"id":bid
		}
			
		 axios.post(API_URL+"get-brand-info?code=5fH0lRGIZoSf4PRfSeBX9dEy2Q7v5JfsGxwjk1_-acqMAzFu51pV_w==",formData).then((res)=>{
			    //console.log("test",res.data.data);
			
			if(res.data.status === 200){
				 if(res.data.data?.logos?.mainLogoURL){
					if(localStorage.getItem('status')!="Completed" && localStorage.getItem('status')!='100'){
						setDataReadonly(true);
					}
					
				}
				
				if(res.data.data?.logos?.mainLogoURL){
					setSaveMainLogo(res.data.data.logos.mainLogoURL);
				}
			
				setFileSrcUrl(res.data.data?.logos?.mainLogoURL);
				setFileSrcUrl1(res.data.data?.logos?.mainLogoWhiteURL);
				setFileSrcUrl2(res.data.data?.logos?.mainLogoBlackURL);
				setFileSrcUrl3(res.data.data?.logos?.alternativeLogoURL);
				setFileSrcUrl4(res.data.data?.logos?.alternativeLogoWhiteURL);
				setFileSrcUrl5(res.data.data?.logos?.alternativeLogoBlackURL);
               
				
				
				 setShowPreLoader(false);   
			}else{
				//setShowPreLoader(false);   
				navigate("/brand/creator"); 

			}
	   })  
	}
    
*/

const handleCompany = (e) =>{
      setCompanyError(false);
	  setCompanyId(e.value);
	  setCompanyName(e.label);
		
		setCompanyVal({value:e.value,label:e.label});
}



const getBrandCountryData =  () =>{
	let bid = localStorage.getItem('brandID');

		   
	let formData ={

		"id": bid
	}	
	 axios.post(API_URL+"get-brand-info"+API_KEY_B,formData).then((res)=>{
		   // console.log("test",res.data.data?.Targets?.AgeGroups.length);
		   if (res.data.status === 200) {
			setCountry(res.data.data?.country);
		   }
			setGetCountryLoader(true);
			
		})
}

const getCompanyId =  () =>{
			   
	let formData ={
		"userID": localStorage.getItem('token')
	   }
	   const array = [];	
	 axios.post(API_URL+"get-user-data"+API_KEY_B,formData).then((res)=>{
		if (res.data.status === 200) {
			//console.log("get-brand-info",res.data.data?.Companies);
		     let data = res.data.data?.Companies ;

              data.forEach((res)=>{
				array.push({ value: res.companyID, label: res.companyName });
			  });
			  setCompanyOption(array);

              if(localStorage.getItem('status')!=100 && localStorage.getItem('status')!="Completed")
				   {
					setCompanyVal(array[0]);
					setCompanyId(array[0]?.value);
					setCompanyName(array[0]?.label);
					setCompanyDefault(1);
				   }


			}

			setGetCompanyLoader(true);
			
		})
}





const handleRetailer = (e) => {
	//console.log(e.target.value);
	setRetailerOneError(false);
	setRetailerName(e.target.value);
	// if(e.target.value){
	// 	setGrayButton(true);
	// }else{
	// 	setGrayButton(false);
	// }
}
 
	
	const openFileSelector = (type = null) => {
    	setLogoType(type)
    	setTempScale(1)
    	setScale(1)
    	setSliderInitial(0)
    	if(type == "mainLogo"){
    		document.getElementById('companyMainLogo').click();
    		setSaveMainLogo(false);
			setWtb(0);
    	}
    	if(type == "mainWhiteLogo"){
    		document.getElementById('companyMainWhiteLogo').click(); 
    		setSaveMainWhiteLogo(false);
			setWtb(0);
    	}
    	if(type == "mainBlackLogo"){
    		document.getElementById('companyMainBlackLogo').click();
    		setSaveMainBlackLogo(false);
			setWtb(1);
    	}
		if(type == "mainAltLogo"){
			document.getElementById('companyAltLogo').click();
    		setSaveMainAltLogo(false);
			setWtb(0);
		}
		if(type == "altWhite"){
			document.getElementById('companyAltWhiteLogo').click();
    		setSaveMainAltWhite(false);
			setWtb(0);
		}
		if(type == "altBlack"){
			document.getElementById('companyAltBlackLogo').click();
    		setSaveMainAltBlack(false);
			setWtb(1);
		}
		
    }
    function onSelectFile(e) {
    	if (e.target.files && e.target.files.length > 0) {
    		setScale(1)
    		setCrop(undefined)
			//setCrop({unit: 'px', x: 0, y: 0, width: 245, height: 90});
      		setIsCropped(false)
      		setIsDisabled(false)
      		const reader = new FileReader()
      		reader.addEventListener('load', () =>
        		setImgSrc(reader.result?.toString() || ''),
      		)
      		reader.readAsDataURL(e.target.files[0])
      		setImageName(e.target.files[0].name)
			  setFileSelOpen(true);
    	}
  	}
  	function onImageLoad(e) {
    	if (aspect) {
      		const { width, height } = e.currentTarget
      		setCrop(centerAspectCrop(width, height, aspect))
    	}
		cropFunction();
  	}
	  const [complete, setComplete] = useState(false)
  	const onCompleteFun = (e) => {
  		setCropedData(e);
  		setComplete(true)
  	}
  	useEffect(() => {
  		if(complete){
  			cropFunction();
  			setComplete(false)
  		}
  	},[complete])
  	useDebounceEffect(
    	async () => {
    		if(logoType === "mainLogo") {
				if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
	        	canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
	      	}	}      	
    	},
    	100,
    	[completedCrop, scale, rotate],
  	)
  	useDebounceEffect(
    	async () => {
			if(logoType === "mainWhiteLogo"){
    		if (completedWhiteCrop?.width && completedWhiteCrop?.height && imgRef.current && previewWhiteCanvasRef.current) {
	        	canvasPreview(imgRef.current, previewWhiteCanvasRef.current, completedWhiteCrop, scale, rotate)
	      	}}	      	
    	},
    	100,
    	[completedWhiteCrop, scale, rotate],
  	)
  	useDebounceEffect(
    	async () => {
			if(logoType === "mainBlackLogo"){
    		if (completedBlackCrop?.width && completedBlackCrop?.height && imgRef.current && previewBlackCanvasRef.current) {
	        	canvasPreview(imgRef.current, previewBlackCanvasRef.current, completedBlackCrop, scale, rotate)
	      	}}	      	
    	},
    	100,
    	[completedBlackCrop, scale, rotate],
  	)
	  useDebounceEffect(
    	async () => {
			if(logoType === "mainAltLogo"){
    		if (completedAltCrop?.width && completedAltCrop?.height && imgRef.current && previewAltCanvasRef.current) {
	        	canvasPreview(imgRef.current, previewAltCanvasRef.current, completedAltCrop, scale, rotate)
	      	}}	      	
    	},
    	100,
    	[completedAltCrop, scale, rotate],
  	)

	  useDebounceEffect(
    	async () => {
			if(logoType === "altWhite"){
    		if (completedAltWhiteCrop?.width && completedAltWhiteCrop?.height && imgRef.current && previewAltWhiteCanvasRef.current) {
	        	canvasPreview(imgRef.current, previewAltWhiteCanvasRef.current, completedAltWhiteCrop, scale, rotate)
	      	}}	      	
    	},
    	100,
    	[completedAltWhiteCrop, scale, rotate],
  	)

	  useDebounceEffect(
    	async () => {
			if(logoType === "altBlack"){
    		if (completedAltBlackCrop?.width && completedAltBlackCrop?.height && imgRef.current && previewAltBlackCanvasRef.current) {
	        	canvasPreview(imgRef.current, previewAltBlackCanvasRef.current, completedAltBlackCrop, scale, rotate)
	      	}}	      	
    	},
    	100,
    	[completedAltBlackCrop, scale, rotate],
  	)

	  const zoomImage = (e, type) => {
		if(type === "in"){
			setSliderInitial(sliderInitial+0.05)
			//setScale(Number(scale+0.1))
		}else{
			setSliderInitial(sliderInitial-0.05)
			//setScale(Number(scale-0.1))
		}
	}
	
  	const cancelFun = (type = null) => {
		setImgSrc('');
  		setIsCropped(false)
  		setSliderInitial(0)
  		//setSaveImage([]);
		
		const imgDta =  saveImage.filter((img)=>{
			if(img.type!=type){
		       return img;
			} 	
  		 });
       setSaveImage(imgDta);
		  setFileSelOpen(false);
  		if(type == "mainLogo"){
  			setCompletedCrop();
  			setSaveMainLogo(false)
  			document.getElementById("companyMainLogo").value = null;
  		}
  		if(type == "mainWhiteLogo"){
  			setCompletedWhiteCrop();
  			setSaveMainWhiteLogo(false)
  			document.getElementById("companyMainWhiteLogo").value = null;
  		}
  		if(type == "mainBlackLogo"){
  			setCompletedBlackCrop();
  			setSaveMainBlackLogo(false)
  			document.getElementById("companyMainBlackLogo").value = null;
  		}
		  if(type == "mainAltLogo"){
			setCompletedAltCrop();
			setSaveMainAltLogo(false)
			document.getElementById("companyAltLogo").value = null;
		} 
		if(type == "altWhite"){
			setCompletedAltWhiteCrop();
			setSaveMainAltWhite(false)
			document.getElementById("companyAltWhiteLogo").value = null;
		}
		if(type == "altBlack"){
			setCompletedAltBlackCrop();
			setSaveMainAltBlack(false)
			document.getElementById("companyAltBlackLogo").value = null;
		}  
  		
  	}
  	const setCropedDataFun = (d) => {
		setCropedData(d)
		setIsCropped(false)
		setComplete(true)
  		if(logoType == "mainLogo"){
  			setSaveMainLogo(false)
  		}
  		if(logoType == "mainWhiteLogo"){
  			setSaveMainWhiteLogo(false)
  		}
  		if(logoType == "mainBlackLogo"){
  			setSaveMainBlackLogo(false)
  		}
		if(logoType == "mainAltLogo"){
			setSaveMainAltLogo(false)
		}
		if(logoType == "altwhite"){
			setSaveMainAltWhite(false)
		}
		if(logoType == "altBlack"){
			setSaveMainAltBlack(false)
		}

  		
  	}
  	const cropFunction = () => {
	
  		if(logoType == "mainLogo"){
  			setCompletedCrop(cropedData)
  			setIsCropped(true)
  			let d = saveImage.filter((v,i) => {
  				return (v.type != logoType)
  			})
  			setSaveImage(d);
  		}
  		if(logoType == "mainWhiteLogo"){
  			setCompletedWhiteCrop(cropedData)
  			setIsCropped(true)
  			saveImage.filter((v,i) => {
  				return (v.type != logoType)
  			})
  			//console.log(saveImage);
  		}
  		if(logoType == "mainBlackLogo"){
  			setCompletedBlackCrop(cropedData)
  			setIsCropped(true)
  			saveImage.filter((v,i) => {
  				return (v.type != logoType)
  			})
  			//console.log(saveImage);
  		}
		  if(logoType == "mainAltLogo"){
			setCompletedAltCrop(cropedData)
			setIsCropped(true)
			saveImage.filter((v,i) => {
				return (v.type != logoType)
			})
			//console.log(saveImage);
		}
		if(logoType == "altWhite"){
			setCompletedAltWhiteCrop(cropedData)
			setIsCropped(true)
			saveImage.filter((v,i) => {
				return (v.type != logoType)
			})
			//console.log(saveImage);
		}
		if(logoType == "altBlack"){
			setCompletedAltBlackCrop(cropedData)
			setIsCropped(true)
			saveImage.filter((v,i) => {
				return (v.type != logoType)
			})
			//console.log(saveImage);
		}
     
		  
  	}
  	const saveFunction = async () => {
		setImgSrc('');
		setIsCropped(false);
		setCrop({unit: 'px', x: 0, y: 0, width: 245, height: 90})
		setIsDisabled(true)
		setSliderInitial(0)
		setFileSelOpen(false)
  		if(logoType == "mainLogo"){
  			setSaveMainLogo(true)  			
	  		let blob = await fetch(previewCanvasRef.current.toDataURL()).then((r) => r.blob());
	  		blob.name = Math.floor(Math.random() * 1000000000)+imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
	  		setSaveImage([...saveImage, {"data":blob, type: logoType, name: blob.name}])
  		}
  		if(logoType == "mainWhiteLogo"){
  			setSaveMainWhiteLogo(true)
  			let blob = await fetch(previewWhiteCanvasRef.current.toDataURL()).then((r) => r.blob());
	  		blob.name = Math.floor(Math.random() * 1000000000)+imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
	  		setSaveImage([...saveImage, {"data":blob, type: logoType, name: blob.name}])
  		}
  		if(logoType == "mainBlackLogo"){
  			setSaveMainBlackLogo(true)
  			let blob = await fetch(previewBlackCanvasRef.current.toDataURL()).then((r) => r.blob());
	  		blob.name = Math.floor(Math.random() * 1000000000)+imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
	  		setSaveImage([...saveImage, {"data":blob, type: logoType, name: blob.name}])
  		}
		if(logoType == "mainAltLogo"){
			setSaveMainAltLogo(true)
			let blob = await fetch(previewAltCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = Math.floor(Math.random() * 1000000000)+imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, {"data":blob, type: logoType, name: blob.name}])
		}
		if(logoType == "altWhite"){
			setSaveMainAltWhite(true)
			let blob = await fetch(previewAltWhiteCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = Math.floor(Math.random() * 1000000000)+imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, {"data":blob, type: logoType, name: blob.name}])
		}
		if(logoType == "altBlack"){
			setSaveMainAltBlack(true)
			let blob = await fetch(previewAltBlackCanvasRef.current.toDataURL()).then((r) => r.blob());
			blob.name = Math.floor(Math.random() * 1000000000)+imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
			setSaveImage([...saveImage, {"data":blob, type: logoType, name: blob.name}])
		}
  	}
  	const handleSubmit = async (e) => {
  		e.preventDefault();
		  if(dataReadonly){
			navigate("/store");
	      }else{
        
  		//console.log("xjcbsx",saveImage);
		if(validateImg(saveImage) && validateInput()){
			setSubmitLoader(true);
			setDataReadonly(true);
		  let imgDatas = []	;
		 // let brandID = props.compData?.activeBrand?.brandID;
		 setButtonDisable(true);
		  //let brandID = id;
          
		  imgDatas={...imgDatas,"retailerName":retailerName};
		  imgDatas={...imgDatas,"companyName":companyName};
		  imgDatas={...imgDatas,"country":country};
		  imgDatas={...imgDatas,"comapnyID":companyId};
		  imgDatas={...imgDatas,"brandID":localStorage.getItem('brandID')};
         let formArray=[];
		  saveImage.forEach((dat)=>{
			// console.log("url",dat.name);
			imgDatas={...imgDatas,[dat.type] : BLOB_URL+BLOB_BRAND_IMAGE+'/'+dat.name,};
			formArray.push(dat.data);
		   });
		  //  console.log("imgData",imgDatas);
    
		  /******if already have logo******* */
		  if(fileSrcUrl){
			imgDatas={...imgDatas,"mainLogo" : fileSrcUrl,};
		   }
		   if(fileSrcUrl1){
			imgDatas={...imgDatas,"mainWhiteLogo" : fileSrcUrl1,};
		   }
		   if(fileSrcUrl2){
			imgDatas={...imgDatas,"mainBlackLogo" : fileSrcUrl2,};
		   }
		   if(fileSrcUrl3){
			imgDatas={...imgDatas,"mainAltLogo" : fileSrcUrl3,};
		   }
		   if(fileSrcUrl4){
			imgDatas={...imgDatas,"altWhite" : fileSrcUrl4,};
		   }
		   if(fileSrcUrl5){
			imgDatas={...imgDatas,"altBlack" : fileSrcUrl5,};
		   }
		  // console.log("imgDataNew",imgDatas);

		  
		   let formData= JSON.stringify(imgDatas);
          // console.log("brandLogo",formData);
		  // setDataReadonly(true);
           const blobsInContainer = await uploadFileToBlob(formArray, BLOB_BRAND_IMAGE).then(res => {
			//setDataReadonly(true);	 	
			axios.post(API_URL+"insert-retail-brand-no"+API_KEY_B,formData).then((res)=>{
	
				
				if(res.data.status==200){

					setSubmitLoader(false);	
					if(localStorage.getItem('status') != '100' && localStorage.getItem('status') !='Completed'){ 
						setDataReadonly(true);
					 }			
				     props.loginFun(localStorage.getItem('token'), '80');
				
				toast.success(res.data.message, 
				{
					theme: "dark",
					icon: ({theme, type}) =>  <img src={errorSuccess}/>,			
					onClose: () => {	
						navigate("/store");					
					}
				});	
				navigate("/store");	
								 
				}else{
					toast.error(res.data.message, {
						theme: "dark",
						icon: ({theme, type}) =>  <img src={errorWarning}/>
					});

					setButtonDisable(false);
					setSubmitLoader(false);
					setDataReadonly(false);
					
				}
			});  
		})
    	}
	  }
	}

	const validateImg = (savedImages) =>{ //console.log("tt",savedImages);
		if(Object.keys(savedImages).length ){
		   let res =  saveImage.filter(obj =>{
				 if(obj.type=="mainLogo"){
				  setImgError(false);
				  return true;
				 }else{
				  setImgError(true);
				  return false;
				 }
			 })
		   return res;  
		}else{
		  if(fileSrcUrl){
			  setImgError(false);
			  return true;  
		  }else{
			  setImgError(true);
			  return false;
		  }
		  
		}
		   
	 }

	const validateInput = () => {   
    let 	formIsValid =  true;  
	if(!retailerName){
			setRetailerOneError(true);
			formIsValid =  false;  
		} 
	if(!companyId){
			setCompanyError(true);
			formIsValid =  false;
	}	

	 return  formIsValid;

	}

    
	const resetFun =()=>{
        if(!dataReadonly){
	    	setFileSrcUrl("");
		}
	}
	const resetFun1 =()=>{
		if(!dataReadonly){
      		setFileSrcUrl1("");
		}
	}

	const resetFun2 =()=>{
	  if(!dataReadonly){	
        setFileSrcUrl2("");
	  }
	}

	const resetFun3 =()=>{
		if(!dataReadonly){
           setFileSrcUrl3("");
		}
	}

	const resetFun4 =()=>{
	  if(!dataReadonly){
        setFileSrcUrl4("");
	  }
	}
   
	const resetFun5 =()=>{
	  if(!dataReadonly){
        setFileSrcUrl5("");
	  }
	}

	const handleOnChange = (e) => {
		setSliderInitial(e.y);
	}
	useEffect(() => {
		setScale(tempScale+sliderInitial)
	},[sliderInitial])
    
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		noOptionsMessage:(styles) => ({...styles, backgroundColor: '#333F50', color: '#d1d1d1'}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({ ...styles, padding: 0 }),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};
	
	return(
		<>
		{(submitLoader)? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>:"" }
		<div className="wrapper" ref={ref} id="myscreen">
			<BrandNavigation data={elementData} cdata={props.compData} />
			<BrandAside cdata={props.compData} />
			<div className="conten-wrapper enroll-wrapper">
			{(showPreLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
					<div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" className="button-not-active" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
								<span className="searchprodicttext text-center">Stores</span>
							</Link>
						</div>
						<div className="centerbtn">
						
							<Link to="/brand/creator" className="btn btn-success active">New Retail</Link>
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas">
								<Link data-widget="search" to="/brand/creator" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link>
							</div>
							
						</div>
					</div>
					{(showPreLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:<>
					<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="filtersection creatorsbtnset enroll-brand">
										<span className={(grayButton && saveMainLogo) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
											<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={(dataReadonly)?true:buttonDisable} >Create {(buttonDisable)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-chevron-right"></i>)}</button>
										</span>
									</div>
									<StepCombination cdata={props.compData}/>

								</div>
							</div>
						</div>
					</div>
			   	
					<div className="creators-section">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="cardsection">
										<div className="card-header">
											<h3 className="card-title">RETAIL BRAND ID<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
										</div>
										<div className="card-body">
											<div className="row">
												<div className="col-md-3">
													<div className={(companyDefault || dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
													<label for="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> To company</label>
													<Select value={companyVal} className={(companyError) ? "selects error-input" : "selects"}
																				styles={colourStyles}
																				onChange={handleCompany}
																				options={companyOption}
																				placeholder={<div className="italic-placeholder">Select...</div>}
																				isDisabled ={(companyDefault || dataReadonly)?true:false}
																			/>
													<span className="verfiefd-text">Verified</span>
													</div>

												</div>
												<div className="col-md-3">
													<div className="form-group activeborder">
													<label for="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Retail brand</label>
													<input type="text" value={retailerName} onChange={handleRetailer}  className={(retailerOneError) ? "form-control error-input" : "form-control"} readOnly={(dataReadonly)?true:false}/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="cardsection secondchild">
										<div className="card-header">
											<h3 className="card-title">RETAIL BRAND LOGOS<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
										</div>
										<div className="card-body">
											<div className="row">
												<div className="col-md-6">
													<div className="uploadimage-area">
														<div className="crop-section">
															<div className={(!imgSrc)?"croped-inner":((wtb)?"croped-inner img-found croped-inner-wb":"croped-inner img-found")}>
																{!!imgSrc && (
														        <ReactCrop
														          crop={crop}
														          onChange={(_, percentCrop) => setCrop(percentCrop)}
														          onComplete={(c) => setCropedDataFun(c)}
														          aspect={aspect}
														        >
														          <img
														            ref={imgRef}
														            alt="Crop me"
														            src={imgSrc}
														            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
														            onLoad={onImageLoad}
														          />
														        </ReactCrop>
														      )}
														      {
														      	(!imgSrc)?(<div className="croped-message">
																	<h3>LOGO</h3>
																	<p>max 200x90px 300Kb</p>
																</div>):""
														      }
																
															</div>
															<div className={(isDisabled)?"zoom-slider":"zoom-slider active"}>
															<div className={(isDisabled)?"slider-sm-btns":"slider-sm-btns active"}>
															
																<Link to="#" onClick={(e) => (isDisabled)?e.preventDefault():zoomImage(e, 'in')} className="topicons"><i className="fa-light fa-plus"></i></Link>
																<Link to="#" onClick={(e) => (isDisabled)?e.preventDefault():zoomImage(e, 'out')} className="bottomicons "><i className="fa-light fa-minus"></i></Link>
															</div>
															{/* <div className="form-group zoom-label">
																<label for="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Main logo</label>
															</div>	 */}
															<Slider
																styles={{
																    track: {
																      backgroundColor: '#4f5b6d',
																      borderRadius: 0,
																      width: 2,
																      height: 220
																    },
																    active: {
																      backgroundColor: '#4f5b6d',
																      borderRadius: 0,
																      width: 2
																    },
																    thumb: {
																      width: 15,
																      height: 15,
																      backgroundColor: '#d1d1d1'
																    }
																  }}
														        axis="y"
														        ymin={1}
														        ymax={-1}
														        ystep={.05}
														        y={sliderInitial}
														        disabled={isDisabled}
														        onChange={handleOnChange}
														    />
														</div>

														</div>
														<div className="crop-btns">
														<ul className="multibtns">
																<li className={(imgSrc)?"active":""}><button className={(imgSrc)?"btn btn-default turnintoYellow":"btn btn-default"} onClick={() => cancelFun(logoType)} disabled={!imgSrc}>Reset</button></li>
																<li className={(isCropped)?"active":""}><button className={(isCropped)?"btn btn-default turnintoYellow":"btn btn-default"} onClick={saveFunction} disabled={!isCropped}>Save</button></li>
															</ul>
															{/* <div className="crop-guideline">1) Upload the logo; 2) Adjust cropping; 3) Click 'Crop'; 4) Click 'Save'</div> */}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="row">
														<div className="col-md-6">
															<div className="company-logo-previewarea">
																<div className="user-profile-picture">
																	<div className={(completedCrop && !dataReadonly)?"form-group activeborder blueborder":(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
																		<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Main logo</label>
																		
																			{(completedCrop)? (
																	          <div className="profile-div">
																	          {(saveMainLogo)?(<span className="close-image" onClick={() => {if(!fileSelOpen){cancelFun("mainLogo")}}}></span>):""}
																	          	<canvas
																	            ref={previewCanvasRef}
																	            style={{
																	              border: '1px solid black',
																	              objectFit: 'contain',
																	              width: completedCrop.width,
																	              height: completedCrop.height,
																	            }}
																	          /></div>
																	        ):(fileSrcUrl)?(<div className="profile-div"><span className="close-image" onClick={resetFun}></span> <img src={fileSrcUrl} /> </div> ) 
																			:(<div className={(imgError)?"profile-div error-input":"profile-div"} onClick={() => {if( !fileSelOpen){ 
																				openFileSelector("mainLogo")}}}><div className={(dataReadonly || imgSrc)?"customprofile-pic notactive":"customprofile-pic "}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																				<span className="upload-profile-text">Upload</span>
																				<input id="companyMainLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{"display":"none"}} disabled={(dataReadonly)?true:false} />
																			</div></div>)}
																	</div>
																</div>
															</div>
															<div className="company-logo-previewarea nonmandatory-logo-place small-logo">
																<div className="user-profile-picture">
																	<div className={(dataReadonly)?"form-group inActiveClose":"form-group activeborder"} >
																		<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> All white version</label>
																		
																			{(completedWhiteCrop)? (
																	          <div className="profile-div">
																	          {(saveMainWhiteLogo)?(<span className="close-image" onClick={() => {if(!fileSelOpen){cancelFun("mainWhiteLogo")}}}></span>):""}
																	          	<canvas
																	            ref={previewWhiteCanvasRef}
																	            style={{
																	              border: '1px solid black',
																	              objectFit: 'contain',
																	              width: completedWhiteCrop.width,
																	              height: completedWhiteCrop.height,
																	            }}
																	          /></div>
																	        ):(fileSrcUrl1)?(<div className="profile-div"><span className="close-image" onClick={resetFun1}></span> <img src={fileSrcUrl1} /> </div> ) 
																			 :(<div className="profile-div" onClick={() =>{if( !fileSelOpen){ 
																				openFileSelector("mainWhiteLogo")}}}><div className={(dataReadonly || imgSrc)?"customprofile-pic notactive":"customprofile-pic "}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																				<span className="upload-profile-text">Upload</span>
																				<input id="companyMainWhiteLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{"display":"none"}} disabled={(dataReadonly)?true:false} />
																			</div></div>)}
																	</div>
																</div>
															</div>
															<div className="company-logo-previewarea nonmandatory-logo-place small-logo">
																<div className="user-profile-picture">
																	<div className={(dataReadonly)?"form-group inActiveClose":"form-group activeborder"} >
																		<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> All black version</label>
																		
																			{(completedBlackCrop)? (
																	          <div className="profile-div">
																	          {(saveMainBlackLogo)?(<span className="close-image" onClick={() => {if(!fileSelOpen){cancelFun("mainBlackLogo")}}}></span>):""}
																	          	<canvas
																	            ref={previewBlackCanvasRef}
																	            style={{
																	              border: '1px solid black',
																	              objectFit: 'contain',
																	              width: completedBlackCrop.width,
																	              height: completedBlackCrop.height,
																				  backgroundColor: "white",
																	            }}
																	          /></div>
																	        ):(fileSrcUrl2)?(<div className="profile-div"><span className="close-image" onClick={resetFun2}></span> <img src={fileSrcUrl2} /> </div> ) 
																			:(<div className="profile-div" onClick={() => {if( !fileSelOpen){ 
																				openFileSelector("mainBlackLogo")}}}><div className={(dataReadonly || imgSrc)?"customprofile-pic notactive":"customprofile-pic "}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																				<span className="upload-profile-text">Upload</span>
																				<input id="companyMainBlackLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{"display":"none"}} disabled={(dataReadonly)?true:false} />
																			</div></div>)}
																	</div>
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="company-logo-previewarea nonmandatory-logo-place">
																<div className="user-profile-picture">
																	
																	<div className={(dataReadonly)?"form-group inActiveClose":"form-group activeborder"} >
																		<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Alternative logo</label>
																		
																			{(completedAltCrop)? (
																	          <div className="profile-div">
																	          {(saveMainAltLogo)?(<span className="close-image" onClick={() =>{if(!fileSelOpen){cancelFun("mainAltLogo")}}}></span>):""}
																	          	<canvas
																	            ref={previewAltCanvasRef}
																	            style={{
																	              border: '1px solid black',
																	              objectFit: 'contain',
																	              width: completedAltCrop.width,
																	              height: completedAltCrop.height,
																	            }}
																	          /></div>
																	        ):(fileSrcUrl3)?(<div className="profile-div"><span className="close-image" onClick={resetFun3}></span> <img src={fileSrcUrl3} /> </div> ) 
																			:(<div className="profile-div" onClick={() => {if( !fileSelOpen){ 
																				openFileSelector("mainAltLogo")}}}><div className={(dataReadonly || imgSrc)?"customprofile-pic notactive":"customprofile-pic "}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																				<span className="upload-profile-text">Upload</span>
																				<input id="companyAltLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{"display":"none"}} disabled={(dataReadonly)?true:false} />
																			</div></div>)}
																	</div>
																</div>
															</div>
															<div className="company-logo-previewarea nonmandatory-logo-place small-logo">
																<div className="user-profile-picture">
																	{/* <div className={(completedAltWhiteCrop)?"form-group activeborder blueborder":"form-group activeborder"}> */}
																	<div className={(dataReadonly)?"form-group inActiveClose":"form-group activeborder"} >
																		<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> All white version</label>
																		
																			{(completedAltWhiteCrop)? (
																	          <div className="profile-div">
																	          {(saveMainAltWhite)?(<span className="close-image" onClick={() =>{if(!fileSelOpen){cancelFun('altWhite')}}}></span>):""}
																	          	<canvas
																	            ref={previewAltWhiteCanvasRef}
																	            style={{
																	              border: '1px solid black',
																	              objectFit: 'contain',
																	              width: completedAltWhiteCrop.width,
																	              height: completedAltWhiteCrop.height,
																	            }}
																	          /></div>
																	        ):(fileSrcUrl4)?(<div className="profile-div"><span className="close-image" onClick={resetFun4}></span> <img src={fileSrcUrl4} /> </div> ) 
																			:(<div className="profile-div" onClick={()=>{if( !fileSelOpen){ 
																				openFileSelector("altWhite")}}}><div className={(dataReadonly || imgSrc)?"customprofile-pic notactive":"customprofile-pic "}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																				<span className="upload-profile-text">Upload</span>
																				<input id="companyAltWhiteLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{"display":"none"}} disabled={(dataReadonly)?true:false} />
																			</div></div>)}
																	</div>
																</div>
															</div>
															<div className="company-logo-previewarea nonmandatory-logo-place small-logo">
																<div className="user-profile-picture">
																	{/* <div className={(completedAltBlackCrop)?"form-group activeborder blueborder":"form-group activeborder"}> */}
																	<div className={(dataReadonly)?"form-group inActiveClose":"form-group activeborder"} >
																		<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> All black version</label>
																		
																			{(completedAltBlackCrop)? (
																	          <div className="profile-div">
																	          {(saveMainAltBlack)?(<span className="close-image" onClick={()=>{if(!fileSelOpen){cancelFun('altBlack')}}}></span>):""}
																	          	<canvas
																	            ref={previewAltBlackCanvasRef}
																	            style={{
																	              border: '1px solid black',
																	              objectFit: 'contain',
																	              width: completedAltBlackCrop.width,
																	              height: completedAltBlackCrop.height,
																				  backgroundColor: "white",
																	            }}
																	          /></div>
																	        ):(fileSrcUrl5)?(<div className="profile-div"><span className="close-image" onClick={resetFun5}></span> <img src={fileSrcUrl5} /> </div> ) 
																			:(<div className="profile-div" onClick={()=>{if( !fileSelOpen){ 
																				openFileSelector("altBlack")}}}><div className={(dataReadonly || imgSrc)?"customprofile-pic notactive":"customprofile-pic "}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																				<span className="upload-profile-text">Upload</span>
																				<input id="companyAltBlackLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{"display":"none"}} disabled={(dataReadonly)?true:false} />
																			</div></div>)}
																	</div>
																</div>
															</div>


														</div>

													</div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				    	</>}
					</>}
			</div>
			<BrandFooter logo={props.imageData.footer_logo}/>
		</div>
		
		</>
	);
}
export default Dashboard;