import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandAsideNew from "./../layouts/BrandAsideNew";
import BrandFooter from "./../layouts/BrandFooter";
import DataTable, { createTheme } from 'react-data-table-component';
import listImage from './../../assets/images/icons8-list-view-48.png';
import axios from 'axios';
import { API_URL, API_KEY_B } from './../../config/constant.js';
import './../../assets/css/checkbox.css'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/error-icon.png';
import errorSuccess from './../../assets/images/icons8-check-64.png'
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import ForBidden from './../layouts/denied';
toast.configure();
createTheme('solarized', {
	text: {
		primary: '#fff',
		secondary: '#fff',
	},
	background: {
		default: '#10141B',
	},
	context: {
		background: '#cb4b16',
		text: '#FFFFFF',
	},
	divider: {
		default: '#333F50',
	},
	action: {
		button: 'rgba(0,0,0,.54)',
		hover: 'rgba(0,0,0,.08)',
		disabled: 'rgba(0,0,0,.12)',
	},
}, 'dark');


function Dashboard(props) {
	document.title = 'BOstageB - Brand List';
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	const [disabledbtn, setDisabledbtn] = useState(true);
	const [disabledbt, setDisabledbt] = useState(false);
	const [tooltipImg, setTooltip] = useState(true);
	const [tooltipText, setTooltipText] = useState(true);
	const [inviteList, setInviteList] = useState([]);
	const [dataLoading, setDataLoading] = useState();
	const [gtStatus, setGtStatus] = useState([]);
	const [Tops, setTops] = useState(10);
	const [nodata, setNodat] = useState(0);
	const [showLoader, setShowLoader] = useState(0);
	const [showPreLoader, setShowPreLoader] = useState(true);
	let navigate = useNavigate();
	const [denied, setDenied] = useState(false)


	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.BrandsMenu) {
					setShowPreLoader(false);
					setDenied(true)
				} else {
					setDenied(false)
				}
			}
		}

	}, [props.compData]);

	useEffect(() => {
		localStorage.removeItem("viewMode")
	}, []);

	useEffect(() => {
		setElementData(ref.current);
	}, []);


	useEffect(() => {
		getinvitationList(props?.compData);
	}, [props?.compData]);



	const getinvitationList = (comp) => {
		if (typeof comp?.Companies != 'undefined') {
			let formData = {
				"companyID": (typeof comp?.Companies != 'undefined') ? comp?.Companies[0]?.companyID : ""
			}
			axios.post(API_URL+'get-brand-list'+API_KEY_B, formData).then((res) => {
				//console.log(comp?.Companies[0]?.companyID);
				if (res.data.status == 200) {
					let data = res.data.data;
					//console.log(data);
					setTops(data.length);
					setInviteList(res.data.data);
					setShowLoader(1);
					setDataLoading(false);
					//  console.log(data.length)
					if (data?.length) {
						setNodat(0);
					} else {
						setNodat(1);
					}
					setShowPreLoader(false);
				} else {
					setDataLoading(false);
					setNodat(1);
					setShowPreLoader(false);
				}
			})
		}
	}

	
	const getActiveStoreCount = async() => {
        
		let formData = {
    
			"brandID": localStorage.getItem("brandID")
		}
	      await	axios.post(API_URL+'count-active-store-ffc'+API_KEY_B,formData).then((res) => {
			if(res.data.status === 200){
			//	console.log("dataStore",res.data?.data?.totalActiveStore);
				localStorage.setItem("totalStore",res.data?.data?.totalActiveStore);
				localStorage.setItem("totalFFc",res.data?.data?.totalFFCStore);

				localStorage.removeItem("brandID");
				localStorage.removeItem("brandName");
			}
		})
	}

	const handleDelete = (data,dataName) => {
		Swal.fire({
			title: "",
			text: "",
			html: "Are you sure to delete Brand ?",
			icon: 'warning',
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'YES',
			denyButtonText: 'NO',
			background: '#1d2530',
			color: '#fff',
			iconColor: "rgb(255, 153, 0)",
			width: "500px",
			padding: '2em',
			reverseButtons: false
		}).then((result)=>{
			if(result.isConfirmed){
				setShowPreLoader(true);
				let formData = {
					brandID : data,
					bname:dataName
				}
				axios.post(API_URL+'deleteLebalBrand'+API_KEY_B, formData).then((res) => {
				//axios.post('http://localhost:7071/api/deleteLebalBrand', formData).then((res) => {
					if (res.data.status === 200) {
						 if(localStorage.getItem('brandID')==data){
							getActiveStoreCount();

							/* localStorage.removeItem("brandID");
							localStorage.removeItem("brandName"); */

						 }
					
						toast.success(res.data.message, {
							theme: "dark",
							icon: ({ theme, type }) => <img src={errorSuccess} />,
							onClose:setShowPreLoader(false),
		
						})
						props.loginFun(localStorage.getItem('token'),'Completed');
						getinvitationList(props?.compData);
					} else {
		               	props.loginFun(localStorage.getItem('token'),'Completed');
					}

					
				})
			}
		})
		


	}

	const columns = [
		{
			name: 'ID',
			selector: row => row.ourID,
			sortable: true,
			// width: '130px',
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
			width: '200px',
			cell: (row) => <><span className="store-name" title={row.name}>{row.name}</span></>
		},
		{
			name: 'Country',
			selector: row => row.countryName,
			sortable: true,
			width: '130px'
		},
		{
			name: 'Industry',
			selector: row => row.industry,
			sortable: true,
			width: '130px',

		},
		{
			name: 'Design/Lifestyle',
			selector: row => row.lifestyle,
			sortable: true,
			// cell: (row) => "-",
			width: '140px'
		},
		{
			name: 'Main product category',
			selector: row => row.main_category,
			sortable: true,
			width: '200px'
		},
		{
			name: 'Active stores',
			selector: row => row.activeStore,
			sortable: true,
			width: '200px'
		},
		{
			name: 'Active FFC',
			selector: row => row.activeFFC,
			sortable: true,
			width: '100px'
		},
		{
			name: 'Status',
			selector: row => row.status,
			sortable: true,
			width: '110px',
			cell: (row) => <span className="data-table-green">Active</span>
		},
		{
			name: 'Action',
			selector: row => row.action,
			sortable: false,
			style: {
				justifyContent: 'center',
				flexDirection: 'row'
			},
			center: true,
			width: '250px',
			cell: row => <><Link className="btn btn-success " to={"/brand/creator/" + row.ourID} onClick={(e) => { localStorage.setItem("viewMode", "editmode") }} >Edit</Link><Link className="btn btn-success buttondim button-not-active" to="#" >Suspend</Link><Link className="btn btn-success " onClick={e => { handleDelete(row.ourID,row.name); }} to="#">Remove</Link></>
		},
	];



	const locationOptions = [
		{ value: 'Location-1', label: 'Location 1' },
		{ value: 'Location-2', label: 'Location 2' },
		{ value: 'Location-3', label: 'Location-3' },
	];




	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid #333F50",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({ ...styles, padding: 0 }),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};


	const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
		return (
			<>
				<label className="container-checkbox">
					<input type="checkbox"
						ref={ref}
						onClick={onClick}
						{...rest}
					/>
					<span className="checkmark"></span>
				</label>
			</>
		)
	})

	{/* //pagination */ }


	const CustomLoader = () => (<div className="loaderclass"><i className="fa fa-spinner fa-spin"></i></div>)



	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />

				<div className="conten-wrapper">
					{(denied) ? <ForBidden /> : <>
						<div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" to="#" role="button" className="button-not-active">
									<span className="btn btn-success "><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
									<span className="searchprodicttext text-center">Brands</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to="#" className="btn btn-success active">Brands</Link>
								<Link to="#" className="btn btn-success button-not-active">Edit Brand</Link>
								<Link to="/brand/creator" className="btn btn-success ">New Brand</Link>
							</div>
							<div className="rightcontrolserch">
								<div className="productsearch rightareas">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success active"><i className="fa-thin fa-list"></i></span>
										<span className="searchprodicttext">List</span>
									</Link>
								</div>

							</div>
						</div>
						{(showPreLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> : <>

							<div className="filtersarea">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="serchbopicspr">
												<form className="form-inline">
													<input className="form-control" type="text" placeholder="Search list:"
														aria-label="Search" disabled='disabled' />
													<span className="customeset gobtn button-not-active">
														<button type="button" className="btn btn-success" disabled='disabled'>
															<i className="fa-thin fa-arrow-right"></i>
														</button>
														<span className="searchprodicttext">Go</span>
													</span>
												</form>
											</div>
											<div className="filtersection">
												<form className="form-inline" name="filter">
													<p className="form-control-static">Filters:</p>
													<div className="form-group">
														<label htmlFor="collection" className="sr-only">Collection</label>
														<Select
															//options={retailerOptions}
															styles={colourStyles}
															placeholder={<div className="italic-placeholder">POS status</div>}
															isDisabled='true'/>
													</div>
													<div className="form-group">
														<label htmlFor="creator" className="sr-only">CreatorCreator</label>
														<Select
															// options={retailBrandOptions}
															styles={colourStyles}
															placeholder={<div className="italic-placeholder">Country</div>}
															isDisabled='true'/>
													</div>
													<div className="form-group">
														<label htmlFor="status" className="sr-only">Status</label>
														<Select
															//options={statusOptions}
															styles={colourStyles}
															placeholder={<div className="italic-placeholder">Status</div>}
															isDisabled='true'/>
													</div>
													<span className="btnsets products-fit">
														<span className="customeset revertfilters button-not-active">
															<button type="button" className="btn btn-success"><i className="fa-thin fa-rotate-left"></i></button>
															<span className="searchprodicttext">Reset</span>
														</span>
														<span className="customeset gobtn button-not-active">
															<button type="button" className="btn btn-success"><i className="fa-thin fa-arrow-right"></i></button>
															<span className="searchprodicttext">Apply</span>
														</span>
														<span className="customeset gobtn button-not-active">
															<button type="button" className="btn btn-success"><i className="fa-thin fa-arrows-rotate"></i></button>
															<span className="searchprodicttext">Refresh</span>
														</span>
													</span>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="noDataFound" style={{ "display": (!inviteList.length) ? "block" : "none", "color": "#D1D1D1" }}  >There are no records to display</div>
							<div style={{ "display": (inviteList.length) ? "block" : "none" }}>
								{/* <div className="tablestructureser topteninvite" >
							<h3 className="card-title">Last {Tops} Brands </h3>

						</div> */}
								<div className="tablestructureser topteninvite"><h3 className="card-title">Brands</h3></div>
								<div className="tablestructure">
									<DataTable
										columns={columns}
										data={inviteList}
										theme="solarized"
										sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
										progressPending={dataLoading}
										selectableRows
										selectableRowsComponent={Checkbox}
										defaultSortFieldId={1}
										defaultSortAsc={true}
										responsive={true}
										expandableRows={false}
										progressComponent={<CustomLoader />}

									/>
								</div>
							</div>
						</>}
					</>}
				</div>

				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	);
}
export default Dashboard;