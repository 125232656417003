import React from "react";
import { Link } from "react-router-dom";
import AccountOff from './../../assets/images/accountoff.png';
function BrandAside(props) {

	function capitalize(s)
	{
	   return s && s[0].toUpperCase() + s.slice(1);
	}
   
	const dataFilter = (data) =>{
	   let arrOfPlan = ['Pilot','BOimage','Influencers','Business'];
		 let billPlan = capitalize(data)
	   let res =	arrOfPlan.includes(billPlan);
		  if(res){
		   return data;    
		  }else{
		   return "-";    
		  }
	}


	return(
		<>
			<aside className="main-sidebar pre-enroll" onClick={props.preHide}>
			<div className="user-profile-height stageb">
			    <div className="custom-logo withoutlogo">
			      <div className="logoname">
			        <span className="maincompany">Company Logo</span>
			        <small>Max 200x90px 100kb</small>
			      </div>
			      <div className="itemsas">Company name</div>
			    </div>
			    <div className="itemscenter">
			      <ul>

			        <li className="notactivated">Current status: <span className="orng">0% complete</span> </li>
			        <li className="notactivated">Billing plan: <span className="orng">{dataFilter(props?.bdata)}</span></li>
			        <li className="gogrn">Group plan: <span className="orng">{(props?.gdata)?props?.gdata:"-"}</span></li>
			        <li>Active stores: <span className="orng"> 0 </span></li>
					<li>Active FFcenters: <span className="orng">0</span></li>
			      </ul>
			    </div>
				</div>
			    <div className="menusections">
			      <div className="navitems-area">
			        <ul>			          
			          <li className="fullarea menuisonstate">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
			                  <img alt="menuison" className="menuison" src={AccountOff} />
			                </div>
			                Account
			              </div>
			            </Link>
			          </li>
			        </ul>
			      </div>
			      
			      
        </div>   
  </aside>
		</>
	);
}
export default BrandAside;