import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandAside from "./../layouts/BrandAside";
import BrandFooter from "./../layouts/BrandFooter";
import StepCombination from '../layouts/commonLayout';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import { API_URL,API_KEY_B, BLOB_URL, BLOB_STORE_CONTAINER, MAP_KEY } from './../../config/constant.js';
import uploadFileToBlob, { isStorageConfigured } from './blob';
import Select from 'react-select';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, InfoBox } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { toast } from 'react-toastify';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import ForBidden from './../layouts/denied';
import RetailerStoreLogoForm from './retailerStoreLogoForm';
import Swal from 'sweetalert2';
toast.configure();
function CreateStore(props) {
	document.title = 'BOstageB - Create Store';
	const ref = useRef();
	let navigate = useNavigate();
	const [elementData, setElementData] = useState("");
	const [buttonGray, setButtonGray] = useState(false);
	const [companyName, setCompanyName] = useState("");
	const [companyId, setCompanyId] = useState("");
	//const [storeName, setStoreName] = useState("");
	//const [storeName, setStoreName] = useState(localStorage.getItem('brandName'));
	const [storeName, setStoreName] = useState(  
		                (localStorage.getItem('status') == 80)?
                        localStorage.getItem('retailBrandName'):
                        localStorage.getItem('brandName'));
	const [imsProvider, setImsProvider] = useState("");
	const [imsId, setImsId] = useState("");
	const [country, setCountry] = useState("");
	const [city, setCity] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [addressOne, setAddressOne] = useState("");
	const [addressTwo, setAddressTwo] = useState("");
	const [shoppingCenter, setShoppingCenter] = useState("");
	const [gPlusCode, setGPlusCode] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [storeNameError, setStoreNameError] = useState(false);
	const [imsProviderError, setImsProviderError] = useState(false);
	const [countryError, setCountryError] = useState(false);
	const [cityError, setCityError] = useState(false);
     
	const [addressOneError, setAddressOneError] = useState(false);
      
	const [countryOption, setCountryOption] = useState([]);
	const [cityOption, setCityOption] = useState([]);
	const [uploadStoreFacade, setUploadStoreFacade] = useState("");
	const [storeFacadeURL, setStoreFacadeURL] = useState("");

	const [latitudeError, setLatitudeError] = useState(false);
	const [longitudeError, setLongitudeError] = useState(false);
	const [storeFacadeError, setStoreFacadeError] = useState(false);
	const [uploadStoreInterior, setUploadStoreInterior] = useState("");
	const [storeInteriorURL, setStoreInteriorURL] = useState("");
	const [storeInteriorError, setStoreInteriorError] = useState(false);
	const [location, setLocation] = useState({ lat: 0, lng: 0 });
	const [plusCode, setPlusCode] = useState("");
	const [countryDefaultOption, setCountryDefaultOption] = useState("")
	const [cityDefaultOption, setCityDefaultOption] = useState("")
	const [imsProviderOption, setImsProviderOption] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false)
	const [preLoader, setPreLoader] = useState(true);
	const [countryLoader, setCoutryLoader] = useState(false)
	const [cityLoader, setCityLoader] = useState(false)
	const [imsLoader, setImsLoader] = useState(false)
	const [dataLoader, setDataLoader] = useState(false)
	const [submitLoader, setSubmitLoader] = useState(false);
	
	const [retailerId, setRetailerId] = useState(""); 
	const [retailerName, setRetailerName] = useState(""); 
	const [retailerOption,setRetailerOption] = useState("");
    const [retailerBrandError, setRetailerBrandError] = useState(false); 
    const [retailDefault,setRetailDefault] = useState(0);
    const [retailLoader,setRetailLoader] = useState(false);
	const [dataReadonly, setDataReadonly] = useState(0);
	const [denied, setDenied] = useState(false);
	const [deliveryBtn,setDeliveryBtn] =useState(false);
	const [showRetailerform, setShowRetailerform] =useState(false);
	const[handleClose,setHandleClose] = useState(0);
    const [imsCode,setImsCode] = useState("");
    const [lock,setLock] =useState(1);  
    
	const [buttonName,setButtonName] =useState("NEXT");
	const [editMode,setEditMode] =useState(0);
    const[editModeBtnFun,setEditModeBtnFun] = useState(0);
 

    const { storeId } = useParams();

	const closeIt= ()=>{
		
		setShowRetailerform(false);
		getRetailerBrandList();   
	}

	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		
		if(typeof props?.compData?.companyDetail != "undefined"){
			if(_data){	
				if(!_data?.StoresMenu){
					setPreLoader(false);
					setDenied(true)
				}else{
					setDenied(false)
				}
		  }
	   }
		
	},[props.compData]);


	  /*******************save popup********************** */
      
	  const refWarp = useRef(null);   //new datatttt
	  useEffect(() => {
	  
		  function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode);
			  
				//menu
			  let pclass = event?.target?.parentNode?.parentNode?.parentNode?.parentNode;
			  let pclassimg = event?.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
			  // rightcontrolserch
			  let prClassIcon = event?.target?.parentNode?.parentNode?.parentNode;
			  let prClass = event?.target?.parentNode?.parentNode;
			   //center upper btn
			 let pCNClass = event?.target?.parentNode;
			   
			  if(editMode && !editModeBtnFun){  
				  //  console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp?.current && !refWarp?.current?.contains(event.target) && (event?.target?.classList?.contains("saveIt") || pclass?.classList?.contains("menusections") || pclass?.classList?.contains("navitems-area") || pclassimg?.classList?.contains("navitems-area")|| pclassimg?.classList?.contains("menusections") || prClassIcon?.classList?.contains("saveIt")  || prClass?.classList?.contains("saveIt") || pCNClass?.classList?.contains("saveIt") )  ) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					  }).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);   
							handleSubmit(event); 		
						}
						else if(result.isDenied){
						   setEditModeBtnFun(1);
						 }
						
					})   
   
				
			}
		   }
		  }
		  document.addEventListener("mousedown", handleSaveOutside);
		  return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		  };
		}, [refWarp,editMode,editModeBtnFun]);   
   
   
   
   
	  /**************************************************** */


	useEffect(()=>{
	 	if(countryLoader && imsLoader && dataLoader && retailLoader){
	 		setPreLoader(false)
	 	}
	},[countryLoader, imsLoader, dataLoader,retailLoader]);
	
	useEffect(() => {
		setElementData(ref.current);
		if (typeof props?.compData?.Companies != 'undefined') {
			setCompanyName(props?.compData?.Companies[0]?.companyName)
			setCompanyId(props?.compData?.Companies[0]?.companyID)
		}
		getRetailerBrandList();
		getCountryList();
		getIms();
		//setStatus(props?.compData?.completePercentage);
	    setStatus(localStorage.getItem('status'));
		checkAndGetStoreData();
		
	}, [props.compData]);
	const getCountryList = async () => {
		const array = [];
		await axios.post(API_URL + 'get-country-master-data'+API_KEY_B).then((res) => {
			if (res.data.status === 200) {
				
				let data = res.data.data;
				data.forEach((da) => {
					array.push({ value: da.name, label: da.name });
				});
				setCountryOption(array);
			}
			setCoutryLoader(true)
		})
	}
  
	useEffect(()=>{ 
		
		if(typeof storeId != undefined){ 
		      if(localStorage.getItem('viewMode')=="editmode"){
				    setEditMode(1);
					setEditModeBtnFun(1);
					setButtonName("SAVE");
					//console.log("viewMode12",storeId);
			  }  
		 }else{ 
			localStorage.removeItem('viewMode');
			setEditMode(0)
		}
	},[storeId]);  

	
    const getRetailerBrandList = async () => {
          let companyId="";
		if (typeof props?.compData?.Companies != 'undefined') {
		
			companyId = props?.compData?.Companies[0]?.companyID;
		}

		const array = [];
		await axios.post(API_URL + 'get-retail-brand-data'+ API_KEY_B, { companyID: companyId }).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {
					array.push({ value: da?.ourID, label: da?.name });
				});
				//console.log(array)
				setRetailerOption(array);
				
				if(localStorage.getItem('status')!=100 && localStorage.getItem('status')!="Completed")
				   {
					if(typeof array !=undefined){
					setRetailBrandVal(array[0]);
					setRetailerId(array[0]?.value);
					setRetailerName(array[0]?.label);
					setRetailDefault(1);
					//setStoreName(localStorage.getItem('brandName'));
					localStorage.setItem('retailBrandName',array[0]?.label);
                    if(localStorage.getItem('status')>=80){
						setStoreName(localStorage.getItem('retailBrandName'));
						}else{
						setStoreName(localStorage.getItem('brandName'));
						}

					}

				   }
			}
			setRetailLoader(true);
		})
	} 
       

	const getCityList = async (countryName) => {
		const array = [];
		await axios.post(API_URL + 'master-city-list' + API_KEY_B, { country: countryName }).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {
					array.push({ value: da.city, label: da.city });
				});
				setCityOption(array);
			}
		})
	}
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		noOptionsMessage:(styles) => ({...styles, backgroundColor: '#333F50', color: '#d1d1d1'}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
			    borderRadius: "10px"
			}
		}),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};
	const openFileSelector = (e) => {
		let checkClass = e.target.classList
		if (checkClass.contains('profile-div') || checkClass.contains('fa-upload') || checkClass.contains('upload-profile-text')) {
			document.getElementById('storeFacade').click();
		}

	}

	function nameFile(inEl, newName){
		var file = inEl[0];
		let uu = new File([file], newName, {type: file.type})
		return uu;
	  }
	  const FileUpload = async (e) => {
		setStoreFacadeError(false)
		let fNmae = nameFile(e.target.files, Math.floor((Math.random() * 1000000) + 1)+"-"+e.target.files[0].name)
		console.log("data", fNmae);
		setUploadStoreFacade(fNmae);
		if (e.target.files.length) {
			setStoreFacadeURL(URL.createObjectURL(fNmae));
		}
		if(editMode){
			setEditModeBtnFun(0);
		  }
	};
	const openInteriorFileSelector = (e) => {
		let checkClass = e.target.classList
		if (checkClass.contains('profile-div') || checkClass.contains('fa-upload') || checkClass.contains('upload-profile-text')) {
			document.getElementById('storeInterior').click();
		}
	}
	const interiorUpload = async (e) => {
		//setStoreInteriorError(false)
		let fNmae = nameFile(e.target.files, Math.floor((Math.random() * 1000000) + 1)+"-"+e.target.files[0].name)
		console.log("test", fNmae);
		setUploadStoreInterior(fNmae);
		if (e.target.files.length) {
			setStoreInteriorURL(URL.createObjectURL(fNmae));
		}
		if(editMode){
			setEditModeBtnFun(0);
		  }
	};

	const shopingCenterOption = [
		{ value: 'Yes', label: 'Yes' },
		{ value: 'No', label: 'No' }
	];

	const handleStoreName = (e) => {
		setStoreNameError(false);
		setStoreName(e.target.value)
		if(editMode){
			setEditModeBtnFun(0);
		}
	}
	const handleImsProvider = (e) => {
		setImsProviderError(false);
		setImsProvider(e.value)
		setImsId(e.value)
		setImpProvidVal({value:e.value,label:e.label});
		if(editMode){
			setEditModeBtnFun(0);
		}
	}

    const handleRetailerBrand = (e) => {
		
		setRetailerBrandError(false);
		setRetailerId(e.value);
		setRetailerName(e.label);
		//setStoreName(e.label);
		
		setRetailBrandVal({value:e.value,label:e.label});
	}

	const handleImsId = (e) => {
		//setImsId(e.target.value)
		setImsCode(e.target.value);
		if(editMode){
			setEditModeBtnFun(0);
		}
	}
	const handleCountry = (e) => {
		setCountryError(false);
		setCountry(e.value)
		setCountryDefaultOption(e)
		getLatLong(city + ' ' + e.value)
	}
	const handleCity = (e) => {
		setCityError(false);
		setCity(e.value)
		setCityDefaultOption(e)
		getLatLong(e.value + ' ' + country)
	}
	useEffect(() => {
		if (retailerId && storeName && imsProvider && country && city && uploadStoreFacade &&  addressOne && latitude && longitude) {
			setButtonGray(true)
		} else {
			setButtonGray(false)
		}
		
		//console.log(storeName, imsProvider, country, city, uploadStoreFacade, uploadStoreInterior,addressOne);
		
	}, [retailerId,storeName, imsProvider, country, city, uploadStoreFacade, addressOne, latitude, longitude]);
	
	useEffect(()=>{
		getCityList(country);
	},[country]);

	const handlePostalCode = (e) => {
		setPostalCode(e.target.value)
	}
	const handleAddressOne = (e) => {
		//console.log(e.target.value);
		setAddressOneError(false);
		setAddressOne(e.target.value);
	}
	const handleAddressTwo = (e) => {
		setAddressTwo(e.target.value)
	}
	const handleShoppingCenter = (e) => {
		setShoppingCenter(e.value);
		setShopCenterVal({value:e.value,label:e.label})
		if(editMode){
			setEditModeBtnFun(0);
		  }
	}
	const handleLatitude = (e) => {
		setLatitude(e.target.value)
		if (e.target.value && longitude) {
			const center = {
				lat: (e.target.value) ? parseFloat(e.target.value) : 0,
				lng: (longitude) ? parseFloat(longitude) : 0
			};
			setLocation(center)
			getPlusCodeUsingLatLng(center.lat, center.lng)
		}
		setLatitudeError(false);

	}
	const handleLongitude = (e) => {
		setLongitude(e.target.value)
		if (e.target.value && latitude) {
			const center = {
				lat: (latitude) ? parseFloat(latitude) : 0,
				lng: (e.target.value) ? parseFloat(e.target.value) : 0
			};
			setLocation(center)
			getPlusCodeUsingLatLng(center.lat, center.lng)
		}
		setLongitudeError(false);
	}
	const handleGPlusCode = (e) => {
		setGPlusCode(e.target.value)
		if (e.target.value) {
			getLatLngUsingPlusCode(e.target.value)
		} else {
			setPostalCode("")
			setAddressOne("")
			setAddressTwo("")
			setLatitude("")
			setLongitude("")
			setCountry("")
			setCity("");
			setCityDefaultOption({});
			setCountryDefaultOption({});
		}
		if(editMode){
			setEditModeBtnFun(0);
		}
	}
	const handleValidation = () => {
		
		let formIsValid = true;
		if(!retailerId){
			setRetailerBrandError(true);
			formIsValid = false;
		}
		if (!storeName) {
			setStoreNameError(true);
			formIsValid = false;
		}
		if (!imsProvider) {
			setImsProviderError(true);
			formIsValid = false;
		}
		if (!country) {
			setCountryError(true);
			formIsValid = false;
		}
		if (!city) {
			setCityError(true);
			formIsValid = false;
		}
		if (!uploadStoreFacade) {
			setStoreFacadeError(true)
			formIsValid = false;
		}
		/*if (!uploadStoreInterior) {
			setStoreInteriorError(true)
			formIsValid = false;
		} */
		if (!addressOne) {
			setAddressOneError(true);
			formIsValid = false;
		}
		if (!latitude) {
			setLatitudeError(true);
			formIsValid = false;
		}
		if (!longitude) {
			setLongitudeError(true);
			formIsValid = false;
		}
		return formIsValid;
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		if(dataReadonly){
         navigate("/store/open/" +localStorage.getItem("storeID"));
		}else{
		
		let fileArray = [];
		if (handleValidation()) {
			if(localStorage.getItem('status') != 100 &&  localStorage.getItem('status') != 'Completed') {
		        setDataReadonly(true);
			}
			setSubmitLoader(true);
			fileArray.push(uploadStoreFacade);
			if(uploadStoreInterior.name!=undefined){
			   fileArray.push(uploadStoreInterior)
			}
			
			//console.log("uploadStoreInterior",uploadStoreInterior.name);
			let jsonFormData =""; 
			if(localStorage.getItem('viewMode')=='editmode'){

			 jsonFormData = {
				mode:'edit',
				storeID:storeId,
				companyId: companyId,
				//retailerID: retailerId,
				//retailerName: retailerName,
				IMS_ID: imsId,
				POS_IMS_ID:imsCode,
				name: storeName,
				facecadeURL:(uploadStoreFacade.name!=undefined)? BLOB_URL + BLOB_STORE_CONTAINER + '/'+uploadStoreFacade.name:"",
				interiorURL:(uploadStoreInterior.name!=undefined)? BLOB_URL + BLOB_STORE_CONTAINER + '/'+uploadStoreInterior.name:(storeInteriorURL)?"exist":"",
				country: country,
				city: city,
				postalCode: postalCode,
				address: addressOne,
				addressLine2: addressTwo,
				shopingCenter: shoppingCenter,
				googlePlusCode: gPlusCode,
				latitude: latitude,
				longitude: longitude,
				/*brands: [{
					brandID: (localStorage.getItem('brandID')) ? localStorage.getItem('brandID') : "",
					brandName: (localStorage.getItem('brandName')) ? localStorage.getItem('brandName') : "",
					status: "Active"
				}] */
			}
		}else{
			jsonFormData = {
				companyId: companyId,
				retailerID: retailerId,
				retailerName: retailerName,
				IMS_ID: imsId,
				POS_IMS_ID:imsCode,
				name: storeName,
				facecadeURL: BLOB_URL + BLOB_STORE_CONTAINER + '/'+uploadStoreFacade.name,
				interiorURL:(uploadStoreInterior.name!=undefined)? BLOB_URL + BLOB_STORE_CONTAINER + '/'+uploadStoreInterior.name:"",
				country: country,
				city: city,
				postalCode: postalCode,
				address: addressOne,
				addressLine2: addressTwo,
				shopingCenter: shoppingCenter,
				googlePlusCode: gPlusCode,
				latitude: latitude,
				longitude: longitude,
				brands: [{
					brandID: (localStorage.getItem('brandID')) ? localStorage.getItem('brandID') : "",
					brandName: (localStorage.getItem('brandName')) ? localStorage.getItem('brandName') : "",
					status: "Active"
				}]
			}

		}
		//console.log("jsonFormData",jsonFormData);
			
			const blobsInContainer = await uploadFileToBlob(fileArray, BLOB_STORE_CONTAINER).then(res => {
				if(res) {
					 axios.post(API_URL + "create-store-id-data" + API_KEY_B, jsonFormData).then((res) => {
					 //  axios.post("http://localhost:7071/api/create-store-id-data", jsonFormData).then((res) => {
						if(res.data.status == 200) {
							localStorage.setItem("storeID",res.data.insertedID);
							props.loginFun(localStorage.getItem('token') , '85');
                              
							toast.success(res.data.message,
								{
									theme: "dark",
									icon: ({ theme, type }) => <img src={errorSuccess} />,
									onClose: () => {
										if(!editMode){
										   navigate("/store/open/" + res.data.insertedID);
										}
									}
								});
								

							setSubmitLoader(false);
						} else {
							toast.error(res.data.message, {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorWarning} />
							})
						}

						if(editMode){
							
								setEditModeBtnFun(1);
							
						}

						
					})
				}
			})
		}
		
	   }
	}

	useEffect(() => {
		Geocode.setApiKey(MAP_KEY);
		Geocode.setLanguage("en");
	}, [])
	const getPlusCodeUsingLatLng = (lat, lng) => {
		axios.get('https://plus.codes/api?address=' + lat + ',' + lng).then((res) => {
			if (res.data?.status == "OK") {
				setGPlusCode(res.data?.plus_code?.global_code)
			}
		})
	}
	const getLatLngUsingPlusCode = (plusCode) => {
		axios.get('https://plus.codes/api?address=' + encodeURIComponent(plusCode)+'&ekey='+MAP_KEY).then((res) => {
			const { lat, lng } = res.data?.plus_code?.geometry?.location
			const center = {
				lat: lat,
				lng: lng
			};
			setLatitude(lat)
			setLongitude(lng)
			setLocation(center)
			getAddress(lat, lng)
		})
		
	}
	const getAddress = (lat, lng) => {
		Geocode.fromLatLng(lat, lng).then(
			(response) => {
				if (response && response?.results.length) {
					setCountryError(false);
					setCityError(false);
					let addressComponent = response.results[0].address_components;
					let arr = response.results[0].formatted_address.split(', ');
					arr.splice(arr.length - 2, 2);
					setAddressOne(arr.join(', '));
					//console.log(response.results);
					addressComponent.forEach((v, i) => {
						if (v.types.includes('postal_code')) {
							setPostalCode(v.long_name)
						}
						if (v.types.includes("locality") || v.types.includes("sublocality")) {
							setAddressTwo(v.long_name)
						}
						if (v.types.includes("country")) {
							setCountry(v.long_name)
							setCountryDefaultOption({ value: v.long_name, label: v.long_name })
						}
						if (v.types.includes("postal_town") || v.types.includes("locality") || v.types.includes("administrative_area_level_3")) {
							setCity(v.long_name)
							setCityDefaultOption({ value: v.long_name, label: v.long_name })
						}

					})
				}
			});
	}
	const getLatLong = (address) => {
		Geocode.fromAddress(address).then(
			(response) => {
				const { lat, lng } = response?.results[0]?.geometry.location;
				const center = {
					lat: lat,
					lng: lng
				};
				setLatitude(lat)
				setLongitude(lng)
				setLocation(center)
				getPlusCodeUsingLatLng(lat, lng)
			},
			(error) => {
				console.error(error);
			}
		);
	}
	const options = { closeBoxURL: '', enableEventPropagation: false };
	const containerStyle = {
		width: '385px',
		height: '118px'
	};
	const handleDragMarker = (e) => {
		const center = {
			lat: e.latLng.lat(),
			lng: e.latLng.lng()
		};
		setLatitude(center.lat)
		setLongitude(center.lng)
		setLocation(center)
		getPlusCodeUsingLatLng(center.lat, center.lng)
	}

	const cancelFun = (e) => {
		if(!dataReadonly){
			setStoreFacadeURL("");
		setUploadStoreFacade("");
	  }
		
	}

	const cancelFun1 = (e) => {
		if(editMode){
			setEditModeBtnFun(0);
		}
		if(!dataReadonly){
		e.preventDefault();
		setStoreInteriorURL("");
		setUploadStoreInterior("");
	}
	}

	const handleClick = () => {
		if(status ==100 || status == 'Completed'){
			setShowRetailerform(true);
		}
		

	}


	const storeOption1 = [];
	const getIms = () => {

		axios.get(API_URL + 'get-ims'+ API_KEY_B).then((res) => {
			if (res.data.status === 200) {
				
				let data = res.data.data;
				data.forEach((da) => {
					storeOption1.push({ value: da.companyID, label: da.companyName });
				});

				setImsProviderOption(storeOption1);

			}
			setImsLoader(true)
		})
	}
    const [retailBrandVal,setRetailBrandVal] = useState(null);
	const [impProvidVal,setImpProvidVal] = useState(null);
	const [shopCenterVal,setShopCenterVal] =useState(null);
	
	

	const checkAndGetStoreData  = () => {
		let formData ="";
		if(localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed'){
			 formData ={"id":localStorage.getItem("storeID")}
		}else{
			if(typeof storeId !== 'undefined'){  
				formData ={"id":storeId}
			}else{
				formData ={"id":""}
			}
			
		} 
		axios.post(API_URL+"get-store-info" + API_KEY_B,formData).then((res)=>{
			//console.log("test data sotre1",res.data.data);
			if(res.data.status != 200){
					navigate("/store");
			}else{	    
				let sdata=res.data.data; 
				if(sdata?.name && sdata?.facecadeURL && sdata?.IMS_ID && sdata?.country && sdata?.city && sdata?.address){
					if(localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed'){
					 	setDataReadonly(1);
						if(res.data.data?.openHours!=undefined){
							setDeliveryBtn(true);
						}
					}
					/*if(editMode){
						console.log("data-data",res.data.data);
						if(res.data.data?.openHours!=undefined){
							setDeliveryBtn(true);
						}
					}*/
			    }
				setRetailBrandVal({value:sdata?.retailerID,label:sdata?.retailerName});
				setRetailerId(sdata?.retailerID);
			    setStoreName(sdata?.name); 
				setGPlusCode(sdata?.googlePlusCode);
				setImsId(sdata?.IMS_ID);
				setImsProvider(sdata?.IMS_ID);
				setImsCode(sdata?.POS_IMS_ID);
				/*let dat = storeOption1?.filter((re) => {
					if(re.value== sdata?.IMS_ID){
						return re;
					}
				});
				setImpProvidVal(dat[0]);*/
				setData2(sdata?.IMS_ID);
				setShopCenterVal({value:sdata?.shopingCenter,label:sdata?.shopingCenter});
				setShoppingCenter(sdata?.shopingCenter);
				setStoreFacadeURL(sdata?.facecadeURL);
				setStoreInteriorURL(sdata?.interiorURL);
				setAddressOne(sdata?.address)
				setCountry(sdata?.country)
				setCountryDefaultOption({value:sdata?.country , label:sdata?.country})
				setCityDefaultOption({value:sdata?.city , label:sdata?.city})
				setCity(sdata?.city)
				setPostalCode(sdata?.postalCode)
				setAddressTwo(sdata?.addressLine2)
				setLatitude(sdata?.latitude)
				setLongitude(sdata?.longitude)
				const center = {
					lat: sdata?.latitude,
					lng: sdata?.longitude
				};
				setLocation(center)
				/*if(sdata?.googlePlusCode){
					let gdat = getLatLngUsingPlusCode(sdata?.googlePlusCode);
				}*/
                
				if(localStorage.getItem('viewMode')=='editmode'){
					setUploadStoreFacade(sdata?.facecadeURL);
					setUploadStoreInterior(sdata?.interiorURL);
					//setDeliveryBtn(true);
				}

				
			}
			setDataLoader(true)
		})
	}
	
	const removeViewMode = () =>{
		
		localStorage.removeItem('viewMode');
			setEditMode(0);
			setRetailBrandVal("");
			setStoreName("");
			setImpProvidVal(null);
			setImsCode("");
			setStoreFacadeURL("");
			setStoreInteriorURL("");
			setGPlusCode("");
			setShopCenterVal(null);
			setPostalCode("")
			setAddressOne("")
			setAddressTwo("")
			setLatitude("")
			setLongitude("")
			setCountry("")
			setCity("");
			setCityDefaultOption({});
			setCountryDefaultOption({});
			setDeliveryBtn(false);
			setEditModeBtnFun(0);
			setDataReadonly(0)
			
	}
    
	const [data2,setData2] = useState(0);

	useEffect(() => {
		if (data2 && imsProviderOption.length) {
			let dat = imsProviderOption?.filter((re) => {
				if (re.value == data2) {
					return re;
				}
			});

			setImpProvidVal(dat[0]);
		}
		console.log(imsProviderOption,data2);
	}, [imsProviderOption, data2]);
       

	console.log("deliveryBtn",deliveryBtn);

	return (
		<>
			{(submitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="conten-wrapper enroll-wrapper">
				{(preLoader)?<div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<>
					<div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" className="button-not-active" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
								<span className="searchprodicttext text-center">Stores</span>
							</Link>
						</div>
						<div className="centerbtn">
							{/* <Link to={(status === 'Completed' || props.compData?.status =='100') ? "/store" : "#"} className={(status === 'Completed' || props.compData?.status =='100') ? "btn btn-success" : "btn btn-success button-not-active"}>Stores</Link> */}
							<Link to={(status ==100 || status == 'Completed') ? "/store/list" : "#"} className={(status ==100 || status == 'Completed') ? "btn btn-success saveIt" : "btn btn-success button-not-active"}>Stores</Link> 
							<Link to="#" className={(editMode)?"btn btn-success active saveIt":"btn btn-success button-not-active"}>Edit Store</Link>
							<Link to="/store" className={(editMode)?"btn btn-success saveIt":"btn btn-success active saveIt" } onClick={removeViewMode}  >New Store</Link>
							{/* <Link to="#" className="btn btn-success">Importer</Link> */}
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas saveIt">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link>
							</div>
							<div className={(dataReadonly || editMode)?"productsearch rightareas saveIt ":"productsearch rightareas notactive"}>
								<Link to={(dataReadonly)?(localStorage.getItem('storeID'))?"/store/open/"+localStorage.getItem('storeID'):"#":(editMode)?"/store/open/"+storeId:"#"} role="button">
									<span className="btn btn-success"><i className="fa-thin fa-clock"></i></span>
									<span className="searchprodicttext">Open</span>
								</Link>
							</div>
							<div className={(deliveryBtn || editMode)?"productsearch rightareas saveIt":"productsearch rightareas notactive"}>
								<Link data-widget="search" to={(deliveryBtn)?"/store/delivery/"+localStorage.getItem('storeID'):(editMode)?"/store/delivery/"+storeId:"#"} role="button">
									<span className="btn btn-success"><i className="fa-thin fa-box"></i></span>
									<span className="searchprodicttext text-center">Delivery</span>
								</Link>
							</div>
							<div className="productsearch rightareas notactive">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-people-group"></i></span>
									<span className="searchprodicttext text-center">Staff</span>
								</Link>
							</div>
						</div>
					</div>
					{(preLoader) ? <div className="pre-loader-img top-content"><div className="maaintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> :
						<><div ref={refWarp}>	
							{(!showRetailerform)?<><div className="filtertopcombo">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
										<div className="row">
											<div className="col-md-3">
												{(localStorage.getItem('status') != 100 && localStorage.getItem('status') !='Completed')?
								             <div className="labelgreentext billing-for infoicons"><i className="fas fa-info-circle" aria-hidden="true"></i> <span className="green-tet-name">Enrolling store to company:<br /><span className="itvalue">{companyName}</span></span></div>:""
								            }
											</div>
											<div className="col-md-6">
												<StepCombination cdata={props.compData} />
											</div>
											<div className="col-md-3">
												<div className="filtersection creatorsbtnset enroll-brand ">
												<span className={(buttonGray) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
													<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun)?true:''}>{buttonName} {(loader) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-chevron-right"></i>)}</button>
												</span>
											</div>
											</div>
										</div>
										</div>
									</div>
								</div>
							</div></>:""}
							{showRetailerform? <RetailerStoreLogoForm getClose={closeIt}/>:<div className="creators-section">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12">
											<div className="cardsection">
												<div className="card-header">
													<h3 className="card-title">STORE ID<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
												</div>
												<div className="card-body">
													<form>
														<div className="row">
															<div className="col-md-6">
																<div className="row">
																	<div className="col-md-6">
																		<div className={(retailDefault || dataReadonly || editMode)?"form-group inActiveborder ":"form-group activeborder"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> To Retail Brand </label>
																			<div className="select-and-plus">
																				<div className="select-block">
																					<Select value={retailBrandVal} className={(retailerBrandError) ? "selects error-input" : "selects"}
																						styles={colourStyles}
																						onChange={handleRetailerBrand}
																						options={retailerOption}
																						placeholder={<div className="italic-placeholder">Select...</div>}
																						isDisabled ={(retailDefault || dataReadonly || editMode)?true:false}
																					/>
																				</div>
																				<div className={(dataReadonly || editMode)?"plus-container notactive":"plus-container "}  onClick={(dataReadonly || editMode)?"":handleClick}>
																					<span className="plus-icon"><i className="fa-thin fa-plus"></i></span>
																				</div>
																			</div>
																			

																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className={(dataReadonly)?"form-group inActiveborder":"form-group activeborder"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Store name</label>
																			<input type="text" value={storeName} onChange={handleStoreName} readOnly={(dataReadonly)?true:false} className={(storeNameError) ? "form-control error-input" : "form-control"} />
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																		<div className={(dataReadonly)?"form-group inActiveborder ":"form-group activeborder "}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> IMS/POS provider</label>
																			<Select value={impProvidVal} className={(imsProviderError) ? "selects error-input" : "selects"}
																				styles={colourStyles}
																				onChange={handleImsProvider}
																				options={imsProviderOption}
																				placeholder={<div className="italic-placeholder">Select...</div>}
																				isDisabled ={(dataReadonly)?true:false}
																			/>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className={(dataReadonly)?"form-group  lockedNoborder": "form-group"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> IMS/POS ID</label>
																			<input type="text" value={imsCode} onChange={handleImsId} className="form-control"  readOnly={(dataReadonly)?true:false}/>
																		</div>
																	</div>
																</div>


															</div>
															<div className="col-md-6">
																<div className="row">
																	<div className="col-md-6">
																		<div className="company-logo-previewarea">
																			<div className="user-profile-picture">
																				<div className={(dataReadonly)?"form-group inActiveborder inActiveClose ":"form-group activeborder"}>
																					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Store facade</label>

																					<div className={(storeFacadeError) ? "profile-div error-input" : "profile-div"} onClick={openFileSelector}>
																						<div className="customprofile-pic" style={{ "display": (storeFacadeURL) ? "none" : "block" }}>
																							<i className="fa-thin fa-upload" aria-hidden="true"></i>
																							<span className="upload-profile-text">Upload</span>
																						</div>
																						<input id="storeFacade" type="file" className="upload-profile-text" accept="image/*" onChange={FileUpload} style={{ "display": "none" }} disabled={(dataReadonly)?true:false} />
																						{(storeFacadeURL) ? (<span className="close-image" onClick={cancelFun}></span>) : ""}
																						<img src={storeFacadeURL} style={{ "display": (storeFacadeURL) ? "block" : "none", "height":"100%", "width":"auto" }} />
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="col-md-6">
																		<div className="company-logo-previewarea">
																			<div className="user-profile-picture">
																				{/* <div className={(dataReadonly)?"form-group inActiveborder inActiveClose ":"form-group activeborder"}> */}
																				<div className={(dataReadonly)?"form-group  inActiveClose ":"form-group "}> 
																					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Store interior</label>

																					<div className={(storeInteriorError) ? "profile-div error-input" : "profile-div"} onClick={openInteriorFileSelector}><div className={(dataReadonly)?"customprofile-pic notactive":"customprofile-pic"} style={{ "display": (storeInteriorURL) ? "none" : "block" }}><i className="fa-thin fa-upload" aria-hidden="true"></i>
																						<span className="upload-profile-text">Upload</span>
																					</div>
																						<input id="storeInterior" type="file" className="upload-profile-text" accept="image/*" onChange={interiorUpload} style={{ "display": "none" }}  disabled={(dataReadonly)?true:false} />
																						{(storeInteriorURL) ? (<span className="close-image" onClick={cancelFun1}></span>) : ""}
																						<img src={storeInteriorURL} style={{ "display": (storeInteriorURL) ? "block" : "none", "height":"100%", "width":"auto" }} />
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>


																</div>

															</div>
														</div>
													</form>
												</div>
											</div>

											<div className="cardsection secondchild">
												<div className="card-header">
													<h3 className="card-title">STORE LOCATION</h3>
												</div>
												<div className="card-body">
													<form>
														<div className="row">
															<div className="col-md-6">
																<div className="row">
																	<div className="col-md-6">
																	   <div className={(dataReadonly)?"form-group  lockedNoborder": "form-group"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Google Plus Code</label>
																			<input type="text" value={gPlusCode} onChange={handleGPlusCode} className="form-control" readOnly={(dataReadonly)?true:false} />
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className={(dataReadonly || lock)?"form-group inActiveborder ":"form-group activeborder "}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Country</label>
																			<Select className={(countryError) ? "selects error-input" : "selects"}
																				styles={colourStyles}
																				onChange={handleCountry}
																				value={countryDefaultOption}
																				options={countryOption}
																				placeholder={<div className="italic-placeholder">Select...</div>}
																				isDisabled ={(dataReadonly || lock)?true:false}
																			/>
																		</div>
																	</div>

																</div>
																<div className="row">
																	<div className="col-md-6">
																		<div className={(dataReadonly || lock)?"form-group inActiveborder":"form-group activeborder"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Address line 1</label>
																			<input type="text" value={addressOne} onChange={handleAddressOne}  className={(addressOneError) ? "form-control error-input" : "form-control"} readOnly={(dataReadonly || lock)?true:false}/>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className={(dataReadonly || lock)?"form-group  lockedNoborder": "form-group"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Address line 2</label>
																			<input type="text" value={addressTwo} onChange={handleAddressTwo} className="form-control"  readOnly={(dataReadonly || lock)?true:false} />
																		</div>
																	</div>

																</div>
																<div className="row">
																	<div className="col-md-6">
																		<div className={(dataReadonly || lock)?"form-group inActiveborder":"form-group activeborder"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Latitude</label>
																			<input type="text" name="" onChange={handleLatitude} value={latitude} className={(latitudeError) ? "form-control error-input" : "form-control"}  readOnly={(dataReadonly || lock)?true:false} />
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className={(dataReadonly || lock)?"form-group inActiveborder":"form-group activeborder"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Longitude</label>
																			<input type="text" name="" onChange={handleLongitude} value={longitude} className={(longitudeError) ? "form-control error-input" : "form-control"} readOnly={(dataReadonly || lock)?true:false} />
																		</div>
																	</div>
																</div>


															</div>
															<div className="col-md-6">
																<div className="row">
																	<div className="col-md-6">
																		<div className={(dataReadonly || lock)?"form-group inActiveborder ":"form-group activeborder "}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> City</label>
																			<Select className={(cityError) ? "selects error-input" : "selects"}
																				options={cityOption}
																				onChange={handleCity}
																				styles={colourStyles}
																				value={cityDefaultOption}
																				placeholder={<div className="italic-placeholder">Select...</div>}
																				isDisabled ={(dataReadonly || lock)?true:false}
																			/>
																		</div>
																	</div>
																	<div className="col-md-6">
																	    <div className={(dataReadonly || lock)?"form-group  lockedNoborder": "form-group"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Postal code</label>
																			<input type="text" value={postalCode} onChange={handlePostalCode} className="form-control"  readOnly={(dataReadonly || lock)?true:false} />
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																	    <div className={(dataReadonly)?"form-group inActiveborder": "form-group"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Shopping center</label>
																			<Select value={shopCenterVal} className={"selects"}
																				options={shopingCenterOption}
																				styles={colourStyles}
																				onChange={handleShoppingCenter}
																				placeholder={<div className="italic-placeholder">Select...</div>}
																				isDisabled={(dataReadonly)?true:false}
																			/>
																		</div>
																	</div>
																	<div className="col-md-6">
																	     <div className={(dataReadonly || lock)?"form-group  lockedNoborder": "form-group"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> On the map</label>
																			<div className="store-location-map">
																				{(!latitude && !longitude) ? <div className="store-location-map-icon"><i className="fa-thin fa-map-marked"></i></div> :
																					<LoadScript googleMapsApiKey="AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0">
																						<GoogleMap mapContainerStyle={containerStyle} center={location} zoom={10} clickableIcons={false} options={{ draggable: true }}>
																						  {/* <Marker onDragEnd={handleDragMarker} position={location} draggable={true} /> */}
																							<Marker onDragEnd={handleDragMarker} position={location} draggable={(dataReadonly || lock)?false:true} />
																							<InfoBox options={options} position={location}>
																								<div style={{ width: '140px', opacity: 1, paddingTop: '9px' }}>
																									<div style={{ fontSize: '12px', width: '140px', color: `red`, padding: '0px' }}>{(storeName) ? storeName + ' Flagship Store' : ''}</div>
																								</div>
																							</InfoBox>
																						</GoogleMap>
																					</LoadScript>
																				}
																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>}</div></>}
							
							</> }
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
			
		</>
	)
}
export default CreateStore;
