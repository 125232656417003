import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import BrandNavigation from "./BrandDashboardHeader";
import BrandAside from "./BrandAside";
import BrandFooter from "./BrandFooter";
//import BoimageOne from './../../../assets/images/uploads/boimageone.jpg';  blueboimageson.png
//import BoimageTwo from './../../../assets/images/uploads/boimagetwo.jpg';
import BlueAccountOn from './../../assets/images/blueaccounton.png';
import BlueBrandOn from './../../assets/images/bluebrandon.png';
import BlueBoImagesOn from './../../assets/images/blueboimageson.png';
import GrayBrandoff from './../../assets/images/graybrandoff.png';
import GrayBoimgoff from './../../assets/images/grayboimageson.png';
import Graystoreoff from './../../assets/images/graystoreson.png';
import './../../assets/css/inputTags.css';
import StoreProgressIcon from './../../assets/images/progress-bar-store-icon.png';  
import StoreProgressIconWhite from './../../assets/images/progress-bar-store-icon-white.png';  
import BoimageProgressIcon from './../../assets/images/progress-bar-boimage-icon.png'; 
import BoimageProgressIconWhite from './../../assets/images/progress-bar-boimage-icon-white.png'; 







function CommonLayout(props) {
    return (
		(localStorage.getItem("status") != 'Completed' &&  localStorage.getItem("status") != '100')?
		<>
		
           <div className="stepcombination">
			<ul>
				<li className={(parseInt(localStorage.getItem("status")) == 25)?"step-onec bydefaultcircle active addgreenline addgreenlineactive":(parseInt(localStorage.getItem("status")) > 25)?"step-onec bydefaultcircle active addgreenline addgreenlineactive":"step-onec bydefaultcircle active"}>					
						
						<span className="circlele-tick"><Link to="/account/company"><i className="fa-thin fa-user"></i></Link></span>
											
				</li>
				<li className={(parseInt(localStorage.getItem("status")) == 50)?"step-twoc bydefaultcircle active addgreenline addgreenlineactive":(parseInt(localStorage.getItem("status")) > 50)?"step-twoc bydefaultcircle active addgreenline addgreenlineactive":(parseInt(localStorage.getItem("status")) < 50 && parseInt(localStorage.getItem("status")) >= 25)?"bydefaultcircle active ":""}>
					
					<span className="circlele-tick">{(props.cdata?.BrandsMenu)?<Link to="/brand/creator"><i className="fa-thin fa-tag"></i></Link>:<><i className="fa-thin fa-tag"></i></>}</span>
						
				</li>
				<li className={(parseInt(localStorage.getItem("status")) == 75)?"bydefaultcircle active addgreenline addgreenlineactive":(parseInt(localStorage.getItem("status")) > 75)?"bydefaultcircle active addgreenline addgreenlineactive":(parseInt(localStorage.getItem("status")) < 75 && parseInt(localStorage.getItem("status")) >= 50)?"bydefaultcircle active":""}>					
					<span className="circlele-tick">
					{(props.cdata?.ProductsMenu)?<Link to="/boimages"><img src={BoimageProgressIconWhite} /></Link>:<><img src={BoimageProgressIcon} /></>}
					</span>
					
				</li>
				<li className={(parseInt(localStorage.getItem("status")) >= 75)?"bydefaultcircle active":""}>
						{(parseInt(localStorage.getItem("status")) >= 75) ? <span className="circlele-tick"><img src={StoreProgressIconWhite} /></span> : <span className="circlele-tick"><img src={StoreProgressIcon} /></span>}
					
				</li>
			</ul>
		</div>
 
  </>:""
)}

export default CommonLayout;